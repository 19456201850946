import { useReducer, useState } from "react"
import { Grid, Card, Button } from "@mui/joy"
import ScrollTrigger from "react-scroll-trigger"
import Slide from "@mui/material/Slide/Slide"
import { useWindowDimensions } from "../../api/hooks/windowDimentionProvider"
import Footer from "../footer/Footer"
import copy from "copy-to-clipboard"


const defaultVisiblityStates = {
    section1: false,
    section2: false,
    section3: false,
    section4: false,
    section5: false
}

function Home ()  {

    const {width, height} = useWindowDimensions()
    const [copiedText, setCopiedText] = useState('')

    const [ visibilityStates, dispatchVisibilityUpdate ] = useReducer( 
        visibilityReducer, 
        {
            section1: false,
            section2: false,
            section3: false,
            section4: false,
            section5: false
        } 
    )


    const handleCopyText = (address) => {
        copy(address)
        setCopiedText(address)
      }
    

    function visibilityReducer(visibilityStates, action) {

        switch (action.type) {

            case 'section1EnteredView': {
                return {
                    ...visibilityStates,
                    section1: true
                }
            }
            case 'section2EnteredView': {
                return {
                    ...visibilityStates,
                    section2: true
                }
            }
            case 'section3EnteredView': {
                return {
                    ...visibilityStates,
                    section3: true
                }
            }
            case 'section4EnteredView': {
                return {
                    ...visibilityStates,
                    section4: true
                }
            }
            case 'section5EnteredView': {
                return {
                    ...visibilityStates,
                    section5: true
                }
            }
        }
      }
      


    return(
        <Grid container sx={{backgroundColor:'#000'}}>

            <Grid 
                container
                style={{minHeight:'70vh', marginBottom:'10vh', marginTop:'10vh'}}
            >
                <ScrollTrigger 
                    onEnter={()=>dispatchVisibilityUpdate({type:'section1EnteredView'})} 

                    style={{width:'100%'}}
                >
                    <div className='section1-bg'>
                        <Grid 
                            
                            xs={12}
                            justifyContent='center' alignItems='center' 
                            style={{ 
                                margin: 'auto',
                                padding:'auto',
                                marginTop: 100,
                                maxWidth:'2000px',
                                opacity: visibilityStates?.section1? 1:0,
                                transition:'all 1s ease-in-out',
                                
                            }}
                        >
                          
                            <Grid                     
                                justifyContent='center' alignItems='center'
                                direction='row'
                                flexDirection='row'
                                xs={12} sm={12} md={6} lg={6}
                                style={{  
                                    color: "#31f8ff",
                                    margin:'auto',
                                    transition: 'all 1s ease-in-out', maxWidth:'900px', padding:'30px',
                                    
                                }}
                            >
                                <p style={{color:'white', marginTop:'50px'}}>
                                Marksman is a single-stop information aggregator platform that enables you to watch, filter, and track contracts as soon as they hit the block-chain, investigate all contract metrics, and provides all necessary links, all in an easy to read, responsive, and very fast dashboard.

                                </p>
                            </Grid>
                            <Grid
                                justifyContent='center' alignItems='center'
                                direction='row'
                                flexDirection='row'
                                xs={11} sm={11} md={6} lg={6}
                                style={{  
                                    color: "#2495ff",
                                    margin:'auto',
                                    transition: 'all 1s ease-in-out',
                                    height: 'calc(300px + 15vh)',
                                   
                                    backgroundImage:'url(demo-1.webp)', backgroundRepeat:'no-repeat', backgroundSize:'contain', backgroundPosition:'center center'

                                }}
                            />
                        </Grid>
                       
                    </div>
                </ScrollTrigger>
            </Grid>


            <Grid                     
                justifyContent='space-evenly' alignItems='center'
                direction='row'
                flexDirection='row'
                xs={12} sm={12} md={12} lg={12} xl={12}
                container
                spacing={2}
                style={{  
                    color: "#31f8ff",
                    margin:'auto',
                    transition: 'all 1s ease-in-out', maxWidth:'900px', padding:'30px',
                    
                }}
            >
                <Grid xs={12} md={4}>
                        
                    <Button className='front-page-button' sx={{ height:'70px', marginTop:'10px', width:'100%'}} >
                        <a 
                            href='https://t.me/BananaGunSniper_bot?start=snp_marksman_0x0A8f4C4F23D72857745e26695DCD8deDf8E349B9' 
                            rel="noreferrer noopener" target="_blank"
                            style={{textDecoration:'none'}}
                        >
                            Buy With Banana Bot
                        </a>
                    </Button>
                </Grid>
                <Grid xs={12} md={4}>
                    
                    <Button className='front-page-button' sx={{ height:'70px', marginTop:'10px', width:'100%'}} >
                        <a 
                            href='https://app.uniswap.org/#/swap?outputCurrency=0x0A8f4C4F23D72857745e26695DCD8deDf8E349B9' 
                            rel="noreferrer noopener" target="_blank"
                            style={{textDecoration:'none'}}
                        >
                            Buy With Uniswap
                        </a>
                    </Button>
                </Grid>
                <Grid xs={12} md={4}>
                    
                    <Button className='front-page-button' sx={{ height:'70px', marginTop:'10px', width:'100%'}} >
                        <a 
                            href='https://www.dextools.io/app/en/ether/pair-explorer/0x0A8f4C4F23D72857745e26695DCD8deDf8E349B9' 
                            rel="noreferrer noopener" target="_blank"
                            style={{textDecoration:'none'}}
                        >
                            Chart
                        </a>
                    </Button>
                </Grid>


            </Grid>
          
            <Grid                     
                justifyContent='center' alignItems='center'
                direction='row'
                flexDirection='row'
                xs={12} sm={12} md={8} lg={8}
                container
                style={{  
                    color: "#31f8ff",
                    margin:'auto',
                    transition: 'all 1s ease-in-out', maxWidth:'900px', padding:'30px',
                    
                }}
            >
                <span  style={{fontSize:'24px', cursor:'pointer', fontFamily: 'Ac437_IBM_BIOS', wordBreak:'break-all'}} onClick={()=>handleCopyText('0x0A8f4C4F23D72857745e26695DCD8deDf8E349B9')} >CA: 0x0A8f4C4F23D72857745e26695DCD8deDf8E349B9</span>

            </Grid>
            <Grid 
                container
                sx={{minHeight:'40vh', marginBottom:'10vh', paddingTop:'10vh'}}
                className='section2-bg'
            >
                <Grid xs={12} className='large-headings' textAlign='center' justifyContent='center' alignItems='center' mt='-25px' mb='25px'>Features</Grid>
                <ScrollTrigger 
                    onEnter={()=>dispatchVisibilityUpdate({type:'section2EnteredView'})} 
                    
                    style={{width:'100%'}}
                >
                    <div style={{width:'100vw'}}>
                       
                        <Grid 
                            container

                            xs={12}
                            justifyContent='center' alignItems='center' 
                            sx={{ 
                                margin: 'auto',
                                padding:'auto',
                                maxWidth:'1920px',
                                opacity: visibilityStates?.section2? 1:0,
                                transition:'all 1s ease-in-out',
                         
                            }}
                            
                        >

                            <Grid sx={{  filter:'drop-shadow(0px 10px 10px rgba(0,0,0,.5))', height:'inherit',  margin:'10px', padding:'0px', width:'calc(300px + 10vw)', maxWidth:'500px', textAlign:'center', justifyContent:'center', alignItems:'center'}}>
                                <Grid container sx={{width:'100%', justifyContent:'center', alignItems:'center', padding:'10px'}}>
                                    <Grid>
                                        <img alt='aggregate icon' src='aggregate-icon.ico' style={{width:'64px', margin:'auto', marginRight:'10px'}}/>
                                    </Grid>
                                    <Grid>
                                        <span className="info-card-headings" style={{height:'64px!important', margin:'auto'}}>
                                            Dashboard
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid  container sx={{height:'200px', width:'100%', justifyContent:'center', alignItems:'center', margin:'0px', padding:'0px'}}>
                                    <span className="info-card-contents" style={{height:'200px!important', margin:'0px', padding:'10px'}}>
                                        The Marksman dashboard is a platform that collects metrics from various available tools and compiles them into data meaningful for snipers and contract hunters
                                    </span>
                                </Grid>
                            </Grid>

                            <Grid sx={{  filter:'drop-shadow(0px 10px 10px rgba(0,0,0,.5))', height:'inherit',  margin:'10px', padding:'0px', width:'calc(300px + 10vw)', maxWidth:'500px', textAlign:'center', justifyContent:'center', alignItems:'center'}}>
                                <Grid container sx={{width:'100%', justifyContent:'center', alignItems:'center', padding:'10px'}}>
                                    <Grid>
                                        <img alt='notify icon' src='notify-icon.ico' style={{width:'64px', margin:'auto', marginRight:'10px'}}/>
                                    </Grid>
                                    <Grid>
                                        <span className="info-card-headings" style={{height:'64px!important', margin:'auto'}}>
                                            Marksman Score
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid  container sx={{height:'200px', width:'100%', justifyContent:'center', alignItems:'center', margin:'0px', padding:'0px'}}>
                                    <span className="info-card-contents" style={{height:'200px!important', margin:'0px', padding:'10px'}}>
                                        This formula measures the hype of tokens pre and post launch. The score takes a multitude of factors in, measure of hype, token security, telegram and twitter chatter, and many others
                                    </span>
                                </Grid>
                            </Grid>
                            
                            <Grid sx={{  filter:'drop-shadow(0px 10px 10px rgba(0,0,0,.5))', height:'inherit',  margin:'10px', padding:'0px', width:'calc(300px + 10vw)', maxWidth:'500px', textAlign:'center', justifyContent:'center', alignItems:'center'}}>
                                <Grid container sx={{width:'100%', justifyContent:'center', alignItems:'center', padding:'10px'}}>
                                    <Grid>
                                        <img alt='snipe icon' src='snipe-icon.ico' style={{width:'64px', margin:'auto', marginRight:'10px'}}/>
                                    </Grid>
                                    <Grid>
                                        <span className="info-card-headings" style={{height:'64px!important', margin:'auto'}}>
                                            Caller Watch
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid  container sx={{height:'200px', width:'100%', justifyContent:'center', alignItems:'center', margin:'0px', padding:'0px'}}>
                                    <span className="info-card-contents" style={{height:'200px!important', margin:'0px', padding:'10px'}}>
                                        Marksman monitors over 270 popular telegram caller/influencer channels. Once our monitors detect a call, we list it on our website with full details
                                    </span>
                                </Grid>
                            </Grid>

                            
                        </Grid>
                    </div>
                </ScrollTrigger>
            </Grid>


            {/* <Grid 
                container
                style={{minHeight:'40vh', marginBottom:'10vh'}}
            >
                <ScrollTrigger 
                    onEnter={()=>dispatchVisibilityUpdate({type:'section2EnteredView'})} 
                    onExit={()=>dispatchVisibilityUpdate({type:'section2ExitedView'})}
                    style={{width:'100%'}}
                >
                    <div className='section2-bg'>
                        <Grid 
                            container
                            xs={12}
                            justifyContent='center' alignItems='center' 
                            style={{ 
                                margin: 'auto',
                                padding:'auto',
                                marginTop: 100,
                                maxWidth:'2000px',
                                opacity: visibilityStates?.section2? 1:0,
                                transition:'all 1s ease-in-out',
                                
                            }}
                        >
                               <Grid
                                justifyContent='center' alignItems='center'
                                direction='column'
                                flexDirection='column'
                                xs={11} sm={11} md={6} lg={6}
                                style={{  
                                    color: "#2495ff",
                                    margin:'auto',
                                    transition: 'all 1s ease-in-out',
                                    height: 'calc(300px + 15vh)',
                                    maxWidth:'600px',
                                    backgroundImage:'url(demo-2.webp)', backgroundRepeat:'no-repeat', backgroundSize:'contain', backgroundPosition:'center center'

                                }}
                            />
                            <Grid                     
                                justifyContent='center' alignItems='center'
                                direction='column'
                                flexDirection='column'
                                xs={12} sm={12} md={6} lg={6}
                                style={{  
                                    color: "#31f8ff",
                                    margin:'auto',
                                    transition: 'all 1s ease-in-out', maxWidth:'900px', padding:'30px',
                                    
                                }}
                            >
                                <p style={{color:'white', marginBottom:'50px'}}>
                                The Marksman score  is a unique feature that is only found on Marksman. This is a formula that measures a contracts hype pre release. Some factors that go into our secret sauce formula are token metrics and security, as well as telegram and twitter chatter. These factors along with many others go into the formula and give you the number displayed. It is very hard to miss any hyped launch using this feature. 
                                </p>
                            </Grid>
                       
                        </Grid>
                    </div>
                </ScrollTrigger>
            </Grid>          
             */}
            <Grid 
                container
                style={{paddingBottom:'10vh'}}
            >
                <ScrollTrigger 
                    onEnter={()=>dispatchVisibilityUpdate({type:'section3EnteredView'})} 

                    style={{width:'100%'}}
                >
                    <div style={{width:'100vw'}} className='section3-bg'>
                        <Grid 
                            container
                            xs={12}
                            justifyContent='center' alignItems='center' 
                            style={{ 
                                margin: 'auto',
                                padding:'auto',
                                marginTop: 100,
                                maxWidth:'1920px',
                                opacity: visibilityStates?.section3? 1:0,
                                transition:'all 1s ease-in-out',
                                
                            }}
                        >

                            <Grid      
                                container               
                                justifyContent='center' alignItems='center'
                                direction='column'
                                flexDirection='column'
                                xs={12} sm={12} md={7} lg={7}
                                style={{  
                                    color: "#31f8ff",
                                    margin:'auto',
                                    transition: 'all 1s ease-in-out', maxWidth:'900px', padding:'3%', fontSize:'18px'
                                    
                                }}
                            >
                               
                                <h1 className="large-headings">Revenue Sharing with Marksman Auto Sniper</h1>
                                <p style={{color:'white', marginTop:width<900?'150px':'auto'}}>
                                    Marksman Autosniper is a bot that will automatically snipe tokens based on certain conditions we have set, and take profits using multiple different strategies. <br/>
                                </p>
                                <ul>
                                    <li>
                                        Uses our exclusive Marksman Score to determine and filter hyped and profitable tokens
                                    </li>
                                    <li>
                                        Initiates trade after going through multiple security and checksum checks
                                    </li>
                                    <li>
                                        Multiple profit taking strategies used
                                    </li>
                                    <li>
                                        50% of profits will be shared with $MARKS holders
                                    </li>
                                </ul>
                            </Grid>
           
                                <img 
                                    src="sniper-bot.png" 
                                    style={{
                                        height: 'calc(300px + 15vh)',
                                        backgroundRepeat:'no-repeat', backgroundSize:'contain', margin: 'auto', padding:'auto'
                                    }}
                                
                                />
                       
                          
                        </Grid>
                    </div>
                </ScrollTrigger>
            </Grid>



            <Grid 
                container
                justifyContent='center' alignItems='center' 
                sx={{ margin:'auto', padding:'auto', marginBottom:'10vh', paddingTop:'10vh', paddingBottom:'10vh'}}
                className='section2-bg'
            >
                <Grid className='large-headings' textAlign='center' justifyContent='center' alignItems='center' mt='-25px' mb='25px'>Stats</Grid>
                <ScrollTrigger 
                    onEnter={()=>dispatchVisibilityUpdate({type:'section4EnteredView'})} 
                  
                    style={{width:'100%'}}
                >
               
                       
                    <div style={{width:'100vw', maxWidth:'1920px', justifyContent:'center', alignItems:'center', margin:'auto', padding:'auto'}}>
                       
                       <Grid 
                           container

                           xs={12}
                           justifyContent='center' alignItems='center' 
                           sx={{ 
                               margin: 'auto',
                               padding:'auto',
                 
                               opacity: visibilityStates?.section4? 1:0,
                               transition:'all 1s ease-in-out',
                           }}
                           
                       >

                            <Grid xs={6} md={3} lg={3}  xl={3}  sx={{  filter:'drop-shadow(0px 10px 10px rgba(0,0,0,.5))', height:'calc(150px +2vw)',  margin:'10px', padding:'5px', width:'calc(150px + 10vw)', maxWidth:'500px', textAlign:'center', justifyContent:'center', alignItems:'center', border: '1px solid rgba(255,255,255,.2)'}}>
                                <Grid container sx={{width:'100%',  height:'calc(140px + 2vw)', justifyContent:'center', alignItems:'center', padding:'10px', border: '1px solid rgba(255,255,255,.2)'}}>
                                    <Grid>
                                        <img alt='signup icon' src='signup-icon.ico' style={{width:'64px', margin:'auto', marginRight:'10px'}}/>
                                    </Grid>
                             
                                    
                                    <Grid xs={12} mb='10px'>
                                        <span className="info-card-contents" style={{height:'64px!important', margin:'auto'}}>
                                            Signups
                                        </span>
                                    </Grid>
                                    <Grid xs={12} mb={1}>
                                        <span className="info-card-headings" style={{height:'64px!important', margin:'auto'}}>
                                            250+
                                        </span>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid xs={6} md={3} lg={3}  xl={3}  sx={{  filter:'drop-shadow(0px 10px 10px rgba(0,0,0,.5))', height:'calc(150px +2vw)',  margin:'10px', padding:'5px', width:'calc(120px + 10vw)', maxWidth:'500px', textAlign:'center', justifyContent:'center', alignItems:'center', border: '1px solid rgba(255,255,255,.2)'}}>
                                <Grid container sx={{width:'100%',  height:'calc(140px + 2vw)', justifyContent:'center', alignItems:'center', padding:'10px', border: '1px solid rgba(255,255,255,.2)'}}>
                                    <Grid>
                                        <img alt='active icon' src='active-icon.ico' style={{width:'64px', margin:'auto', marginRight:'10px'}}/>
                                    </Grid>
                                    <Grid xs={12} mb='10px'>
                                        <span className="info-card-contents" style={{height:'64px!important', margin:'auto'}}>
                                            Active Users
                                        </span>
                                    </Grid>
                                    <Grid xs={12} mb={1}>
                                        <span className="info-card-headings" style={{height:'64px!important', margin:'auto'}}>
                                            200+
                                        </span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Grid xs={6} md={3} lg={3}  xl={3}  sx={{  filter:'drop-shadow(0px 10px 10px rgba(0,0,0,.5))', height:'calc(150px +2vw)',  margin:'10px', padding:'5px', width:'calc(120px + 10vw)', maxWidth:'500px', textAlign:'center', justifyContent:'center', alignItems:'center', border: '1px solid rgba(255,255,255,.2)'}}>
                                <Grid container sx={{width:'100%',  height:'calc(140px + 2vw)', justifyContent:'center', alignItems:'center', padding:'10px', border: '1px solid rgba(255,255,255,.2)'}}>
                                    <Grid>
                                        <img alt='alert icon' src='alert-icon.ico' style={{width:'64px', margin:'auto', marginRight:'10px'}}/>
                                    </Grid>
                                    <Grid xs={12} mb='10px'>
                                        <span className="info-card-contents" style={{height:'64px!important', margin:'auto'}}>
                                            Daily Notifications
                                        </span>
                                    </Grid>
                                    <Grid xs={12} mb={1}>
                                        <span className="info-card-headings" style={{height:'64px!important', margin:'auto'}}>
                                            7+
                                        </span>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid xs={6} md={3} lg={3}  xl={3}  sx={{  filter:'drop-shadow(0px 10px 10px rgba(0,0,0,.5))', height:'calc(150px +2vw)',  margin:'10px', padding:'5px', width:'calc(120px + 10vw)', maxWidth:'500px', textAlign:'center', justifyContent:'center', alignItems:'center', border: '1px solid rgba(255,255,255,.2)'}}>
                                <Grid container sx={{width:'100%',  height:'calc(140px + 2vw)', justifyContent:'center', alignItems:'center', padding:'10px', border: '1px solid rgba(255,255,255,.2)'}}>
                                    <Grid>
                                        <img alt='profitability icon' src='profitability-icon.ico' style={{width:'64px', margin:'auto', marginRight:'10px'}}/>
                                    </Grid>
                                    <Grid xs={12} mb='10px'>
                                        <span className="info-card-contents" style={{height:'64px!important', margin:'auto'}}>
                                            Profitability
                                        </span>
                                    </Grid>
                                    <Grid xs={12} mb={1}>
                                        <span className="info-card-headings" style={{height:'64px!important', margin:'auto'}}>
                                            62%
                                        </span>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid xs={6} md={3} lg={3}  xl={3}  sx={{  filter:'drop-shadow(0px 10px 10px rgba(0,0,0,.5))', height:'calc(150px +2vw)',  margin:'10px', padding:'5px', width:'calc(120px + 10vw)', maxWidth:'500px', textAlign:'center', justifyContent:'center', alignItems:'center', border: '1px solid rgba(255,255,255,.2)'}}>
                                <Grid container sx={{width:'100%',  height:'calc(140px + 2vw)', justifyContent:'center', alignItems:'center', padding:'10px', border: '1px solid rgba(255,255,255,.2)'}}>
                                    <Grid>
                                        <img alt='range icon' src='range-icon.ico' style={{width:'64px', margin:'auto', marginRight:'10px'}}/>
                                    </Grid>
                                    <Grid xs={12} mb='10px'>
                                        <span className="info-card-contents" style={{height:'64px!important', margin:'auto'}}>
                                            Most Profitabe Marksman Score Range
                                        </span>
                                    </Grid>
                                    <Grid xs={12} mb={1}>
                                        <span className="info-card-headings" style={{height:'64px!important', margin:'auto'}}>
                                            75 - 89
                                        </span>
                                    </Grid>
                                </Grid>
                            </Grid>
                            
                            <Grid xs={6} md={3} lg={3}  xl={3}  sx={{  filter:'drop-shadow(0px 10px 10px rgba(0,0,0,.5))', height:'calc(150px +2vw)',  margin:'10px', padding:'5px', width:'calc(120px + 10vw)', maxWidth:'500px', textAlign:'center', justifyContent:'center', alignItems:'center', border: '1px solid rgba(255,255,255,.2)'}}>
                                <Grid container sx={{width:'100%',  height:'calc(140px + 2vw)', justifyContent:'center', alignItems:'center', padding:'10px', border: '1px solid rgba(255,255,255,.2)'}}>
                                    <Grid>
                                        <img alt='scope icon' src='scope-icon.ico' style={{width:'64px', margin:'auto', marginRight:'10px'}}/>
                                    </Grid>
                                    <Grid xs={12} mb='10px'>
                                        <span className="info-card-contents" style={{height:'64px!important', margin:'auto'}}>
                                            Headshots (1M+ Market Cap)
                                        </span>
                                    </Grid>
                                    <Grid xs={12} mb={1}>
                                        <span className="info-card-headings" style={{height:'64px!important', margin:'auto'}}>
                                            8
                                        </span>
                                    </Grid>
                                </Grid>
                            </Grid>

                        </Grid>
                    </div>                
                </ScrollTrigger>
            </Grid>


            <Grid 
                container
                sx={{ paddingBottom:'10vh', paddingTop:'10vh', marginBottom:'10vh'}}
                className='section3-bg'
            >
                <Grid xs={12} className='large-headings' textAlign='center' justifyContent='center' alignItems='center' >Roadmap</Grid>
                <ScrollTrigger 
                    onEnter={()=>dispatchVisibilityUpdate({type:'section5EnteredView'})} 
      
                    style={{width:'100%'}}
                >
                    <div style={{width:'100vw'}}>
                        <Grid 
                            container

                            xs={12}
                            justifyContent='center' alignItems='center' 
                            sx={{ 
                                margin: 'auto',
                                padding:'auto',
                                maxWidth:'1920px',
                                opacity: visibilityStates?.section5? 1:0,
                                transition:'all 1s ease-in-out',
                         
                            }}
                            
                        >

                            <Grid sx={{  filter:'drop-shadow(0px 10px 10px rgba(0,0,0,.5))',  margin:'auto', marginTop:'50px', padding:'0px', width:'calc(300px + 10vw)', maxWidth:'500px', textAlign:'center', justifyContent:'center', alignItems:'center'}}>
                                <Grid container sx={{width:'100%', justifyContent:'center', alignItems:'center', padding:'10px', height:'64px'}}>
                                    <Grid>
                                        <img alt='roadmap icon' src='roadmap-icon.ico' style={{width:'64px', margin:'auto', marginRight:'10px'}}/>
                                    </Grid>
                                    <Grid>
                                        <span className="info-card-headings" style={{height:'64px!important', margin:'auto'}}>
                                            Phase 1
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid  container sx={{ width:'100%', justifyContent:'center', alignItems:'center', margin:'0px', padding:'0px'}}>
                                    <span className="info-card-contents" style={{ margin:'0px', padding:'10px', textAlign:'left'}}>
                                       <ul>
                                        <li id='completed'>
                                            <span>Dashboard beta launch and user onboarding</span>
                                        </li>
                                        <li id='completed'>
                                            <span>Open Socials</span>
                                        </li>
                                        <li id='completed'>
                                            <span>Block Simulator</span>
                                        </li>
                                        <li id='completed'>
                                            <span>Telegram Marksman Score notification bot</span>
                                        </li>
                                        <li id='completed'>
                                            <span>Publish Documentation</span>
                                        </li>
                                        <li id='completed'>
                                            <span>Web3 Integration</span>
                                        </li>
                                        <li id='completed'>
                                            <span>Alpha Score 2.0</span>
                                        </li>
                                       </ul>
                                    </span>
                                </Grid>
                            </Grid>

                            <Grid sx={{  filter:'drop-shadow(0px 10px 10px rgba(0,0,0,.5))',  margin:'auto', marginTop:'50px', padding:'0px', width:'calc(300px + 10vw)', maxWidth:'500px', textAlign:'center', justifyContent:'center', alignItems:'center'}}>
                                <Grid container sx={{width:'100%', justifyContent:'center', alignItems:'center', padding:'10px', height:'64px'}}>
                                    <Grid>
                                        <img alt='roadmap icon' src='roadmap-icon.ico' style={{width:'64px', margin:'auto', marginRight:'10px'}}/>
                                    </Grid>
                                    <Grid>
                                        <span className="info-card-headings" style={{height:'64px!important', margin:'auto'}}>
                                            Phase 2
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid  container sx={{ width:'100%', justifyContent:'center', alignItems:'center', margin:'0px', padding:'0px'}}>
                                    <span className="info-card-contents" style={{ margin:'0px', padding:'10px', textAlign:'left'}}>
                                       <ul>
                                        <li>
                                            Beta End
                                        </li>
                                        <li>
                                            $MARKS token launch
                                        </li>
                                        <li>
                                            Large marketing push and onboarding mass users
                                        </li>
                                        <li>
                                            Live tokens trending page with quick buy options
                                        </li>
                                        <li>
                                            Persistent Token Tracking
                                        </li>
                                        <li>
                                            Full filters for notification bot
                                        </li>
                                        <li>
                                            Marksman Score 60/80/100 TG channels + Deployment feed channel + Liquidity burn feed channel
                                        </li>
                                       </ul>
                                    </span>
                                </Grid>
                            </Grid>
                            
                            <Grid sx={{  filter:'drop-shadow(0px 10px 10px rgba(0,0,0,.5))', margin:'auto', marginTop:'50px', padding:'0px', width:'calc(300px + 10vw)', maxWidth:'500px', textAlign:'center', justifyContent:'center', alignItems:'center'}}>
                                <Grid container sx={{width:'100%', justifyContent:'center', alignItems:'center', padding:'10px', height:'64px'}}>
                                    <Grid>
                                        <img alt='roadmap icon' src='roadmap-icon.ico' style={{width:'64px', margin:'auto', marginRight:'10px'}}/>
                                    </Grid>
                                    <Grid>
                                        <span className="info-card-headings" style={{height:'64px!important', margin:'auto'}}>
                                            Phase 3
                                        </span>
                                    </Grid>
                                </Grid>
                                <Grid  container sx={{ width:'100%', justifyContent:'center', alignItems:'center', margin:'0px', padding:'0px'}}>
                                    <span className="info-card-contents" style={{ margin:'0px', padding:'10px', textAlign:'left'}}>
                                       <ul>
                                        <li>
                                            Marksman Score 60/80/100 TG channels + Deployment feed channel + Liquidity burn feed channel
                                        </li>
                                        <li>
                                            Tiered Features
                                        </li>
                                        <li>
                                            Marksman Auto-sniper with profit / token tax Rev share release
                                        </li>
                                        <li>
                                            Dashboard for user Rev Share
                                        </li>
                                        <li>
                                            Multi-chain support ( ARB , BASE, BNB )
                                        </li>
                                        <li>
                                            Discord listing bot ( to invite to your own server )
                                        </li>
                                       </ul>
                                    </span>
                                </Grid>
                            </Grid>
                      
                           
                       
                        </Grid>
                    </div>
                </ScrollTrigger>
            </Grid>
            <Grid xs={12} sx={{marginTop:"auto", marginBottom:"20px"}}>
                <Footer/>
            </Grid>
        </Grid>
    )

    

}

export default Home