import React, { useState, useEffect, useCallback } from "react"
import { 
    Grid,
    Card,
    Tooltip,
    TooltipProps,
    tooltipClasses,
    Modal,
    ModalDialog
} from "@mui/joy"
import {
    Table,
    TableCell,
    TableRow,
    TableBody, 
    TableContainer, 
    Checkbox, 
    TableSortLabel, 
    Box, 
    Input
} from '@mui/material'
import { useAppSelector, useAppDispatch } from "../../../../app/hooks"

import { 
    handleCopyText,
    convertToInternationalCurrencySystem,
    truncateAddress,
    truncateTxAmt,
    getScoreColor,
    getScoreColorTable,
    getPct,
    ParseDeadblockData,
    getTaxColor,
    getScamColor,
    getScamPercent,
    isTokenBeyondThreshold,
    DEAD_ADDRESS_1, 
    DEAD_ADDRESS_2,    
    isLiquidityBurned,
    isLiquidityLocked,
    userHasSolanaAccess
} from "../../../../utilities/utilities"

import { v4 as uuidv4 } from 'uuid'
import { TokenData, selectUser, selectMarksInWallet, selectActiveChain, ChainIds, selectSolanaSubscriptionExpirationDate } from "../../tokenSlice"
import {Demodal} from 'demodal'
import moment from "moment"

import { CallerModal } from "../CallerModal"
import { McapModal } from "../McapModal"
import { McapTooltip } from "../McapTooltip"
import { FieldModal } from "../FieldModal"
import { MoreInfoModal } from "../MoreInfoModal"
import { LockedModal } from "../LockedModal"



export const ObfuscatedSolanaCard = React.memo(({token, isMobile, cardWidth}: {token: TokenData, isMobile: boolean, cardWidth: string}) => {
    
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)

    const handleTrackToggle = ({id}:{id:number}) => {
        dispatch({type:"tokens/toggleTrackingForToken", payload:id})
    }

    return(
      <Grid sx={{ filter:'drop-shadow(0px 0px 7px black)', opacity:isTokenBeyondThreshold(token, user)? '0.5':'1'}}>
        <Card className='card-back' sx={{width: cardWidth, padding:0, border:'1px solid rgba(255,255,255,.1)'}}>
          <Grid container xs={12}  sx={{margin:0, padding:0}}>
            <Grid container xs={12}  sx={{height:'30px', marginTop:'0px'}}>
                
                <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginLeft:'10px!important'}}>
                    &#128274;
                </Grid>

                <Grid container  justifyContent='center' alignItems='center' flexDirection='row' sx={{margin:'auto', padding:'auto', marginRight:'0px!important'}}>
                
                    <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}>
                        &#128274;
                    </Grid>

                    {/* {userHasSolanaAccess()?
                    <>
                    {token.contractVerified && (token.contractVerified === true || token.contractVerified === 1)?
                        <Grid 
                        className='big-check-icon-bg'
                        sx={{width:'18px', height:'18px', margin:'auto', padding:'auto', marginRight:'10px!important'}}
                        bgcolor={token.contractVerified?'#12af32':'#000000'}
                        onClick={
                            () => Demodal.open(
                            FieldModal, 
                            {
                                fieldTitle: 'Contract Verified', 
                                fieldData: null
                            }
                            )
                        }
                        />
                        :
                        <></>
                    }
                    </>
                    
                    :
                    <Grid 
                        sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}
                    >
                        &#128274;
                    </Grid>
                    } */}
                    

                    {/* 
                    {userHasSolanaAccess()?
                    <>
                    {token.parsedLiquidityArray && isLiquidityLocked({liquidityArray: token.parsedLiquidityArray})?
                    
                    <Grid 
                        className='card-lock-icon-bg'
                        sx={{width:'18px', height:'18px', margin:'auto', padding:'auto', marginRight:'10px!important'}}
                        bgcolor='#12af32'
                        onClick={
                        () => Demodal.open(
                            LockedModal, 
                            { parsedLiquidityArray: token.parsedLiquidityArray }
                        )
                        }
                    />
                    :
                    <></>
                    }
                    </>
                    :
                    <Grid 
                        sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}
                    >
                        &#128274;
                    </Grid>
                    } 
                    */}
                
                    <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}>
                        &#128274;
                    </Grid>
            

                    <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}>
                        &#128274;
                    </Grid>
                </Grid>

                <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}>
                    &#128274;
                </Grid>

                <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}>
                    &#128274;
                </Grid>
            </Grid>
          </Grid>


          <Grid className='card-mid' sx={{margin:0}}>

            <Grid container xs= {12} justifyContent='center' flexShrink={1} display='flex' sx={{marginBottom:0, paddingBottom:'0px'}}>
              <span style={{marginRight:'5px'}} className='card-creation-date-heading'> Symbol:  </span>
              {token.symbol &&
                
                <>
                {isMobile?

                  <div className='card-creation-date' onClick={()=>Demodal.open(FieldModal, {fieldTitle: 'Symbol:', fieldData: token.symbol})}>
                  {token.symbol &&
                    token.symbol.substring(0,10)
                  }
                  </div>
                  :
                  <Tooltip title={token.symbol} placement="top" arrow>

                    <div className='card-creation-date'>
                    {token.symbol &&
                      token.symbol.substring(0,10)
                    }
                    </div>
                  </Tooltip>
                }
                </>
              }
            </Grid>

            <Grid container xs={12}  sx={{marginTop:'0px', paddingTop:'0px'}}>
              <Grid xs={5} alignItems='space-evenly' flexDirection='column' direction='column' flexGrow={1}  > 
                <Grid textAlign='left'  className='card-headings'  xs={12} >
                  Name
                </Grid>
              
                <Grid  xs={12} className='card-data'>
                {isMobile?

                  <div className='card-creation-date' onClick={()=>{Demodal.open( FieldModal, {fieldTitle: 'Name:', fieldData: token.name})}}>
                  {token.name &&
                    token.name.substring(0,10)
                  }
                  </div>
                :
                  <Tooltip title={token.name} placement="top" arrow>

                    <div className='card-creation-date'>
                    {token.name &&
                      token.name.substring(0,10)
                    }
                    </div>
                  </Tooltip>
                }
                </Grid>
               
                <Grid textAlign='left'  className='card-headings'  xs={12} >
                  Creation Date
                </Grid>
                <Grid  xs={12} className='card-data'>
                  <Tooltip title={ `${moment(token.createdAt).format('llll').toLocaleString()}` } placement="top-start" arrow>
                    <div>
                    {token.createdAt?
                      moment(token.createdAt).fromNow()
                      :
                      '-'
                    }
                    </div>
                  </Tooltip>

                </Grid>
               
                <Grid textAlign='left'  className='card-headings'  xs={12}>
                  Contract
                </Grid>
                <Tooltip title='Copy Address' placement='top-start' arrow>
                <Grid  
                  xs={12} 
                  className='card-data copyable-field'  
                  onClick={()=>handleCopyText(token.address)} 
                  style={{ color: '#4a91ff' }}
                >
                    {token.address?
                      truncateAddress(token.address)
                      :
                      '-'
                    }
                  </Grid>
                </Tooltip>

                <Grid textAlign='left'  className='card-headings'  xs={12}>
                  Deployer
                </Grid>

                <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}>
                    &#128274;
                </Grid>

                {/* <Grid textAlign='left'  className='card-headings'  xs={12}>
                  Eth In Wallet
                </Grid>
                <Grid
                className='card-data'  
                  xs={12} 
                >
                {token.ethInWallet? token.ethInWallet: 'Loading...'}
                </Grid> */}

              </Grid>

             

              <Grid xs={7} flexDirection='column' direction='column' flexGrow={1} justifyContent='flex-end' alignItems='flex-end' > 
                <Grid className='card-headings'  container xs={12} justifyContent='flex-end'>
                    Marksman Score
                </Grid>
               
                <Grid container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end'>
                    &#128274;
                </Grid>
            
                {/* <Grid className='card-headings'  justifyContent='flex-end' textAlign='right'>
                    Sniper Score
                </Grid>
                <Grid container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end'>
                    &#128274;
                </Grid>
      */}


{/*      
                <Grid  container xs={12} justifyContent='flex-end' style={{marginTop:'10px'}}>
                  <span className='small-card-headings'>
                    Maestro:
                  </span>
                  <span className='small-card-data'  >

                  {token.alphaHitsMaestro?
                    token.alphaHitsMaestro: '-'  
                  }
                  </span>
                </Grid> */}

                <Grid container xs={12} justifyContent='flex-end' alignItems='center'>
                    <span className='small-card-headings'>
                        Market Cap:
                    </span>
                  
                    <Grid container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'>
                        &#128274;
                    </Grid>
                </Grid>

                <Grid container xs={12} justifyContent='flex-end' alignItems='center'>
                    <span className='small-card-headings'>
                        Num Calls:
                    </span>
                 
                    <Grid container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'>
                      &#128274;
                    </Grid>
                </Grid>
               
                {/* <Grid textAlign='right' className='card-headings'  xs={12} style={{margin:0, marginBottom:'5px', padding:0}}>
                  Banana
                </Grid>

                <Grid textAlign='right' className='card-data'  xs={12} style={{margin:0, marginBottom:'5px', padding:0}}>
                  {token.alphaHitsMaestro && token.alphaHitsMaestro >= 0?
                    <>
                    {getBananaNumber(token.alphaBanana)}
                    </>
                    :
                    <>
                      -
                    </>
                  }
                </Grid> */}

                <Grid container xs={12} justifyContent='flex-end'>
                    <span className='small-card-headings'>
                        Buys 24h:
                    </span>
                  
                    <Grid container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'>
                        &#128274;
                    </Grid>
                </Grid>
                
                <Grid container xs={12} justifyContent='flex-end'>
                    <span className='small-card-headings'>
                        Sells 24h:
                    </span>
                    <Grid container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'>
                      &#128274;
                    </Grid>
                </Grid>

             
                {/* <Grid  container xs={12} justifyContent='flex-end'>
                  <span className='small-card-headings'>
                    Buy / Sell?:
                  </span>
                  <span className='small-card-data'  >

                  {token.canBuy?
                    getCanBuySell(token.canBuy):"-"} / {token.canSell?getCanBuySell(token.canSell):'-'
                  }
                  </span>
                </Grid> */}

                <Grid container xs={12} justifyContent='flex-end'>
                    <span className='small-card-headings'>
                        Owner Transfer %:
                    </span>
                    <Grid container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'>
                      &#128274;
                    </Grid>
                </Grid>
                <Grid container xs={12} justifyContent='flex-end'>
                    <span className='small-card-headings'>
                        Owner Hold %:
                    </span>
                    <Grid container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'>
                        &#128274;
                    </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid className='card-data' justifyContent='center' container xs={12} sx={{width: cardWidth}}>
            <Grid textAlign='center'  className='block-sim-headings'  xs={12} sx={{marginTop:'0px', paddingTop:'0px', marginBottom:'5px'}}>
                  Simulated Block Data 
            </Grid>

            <Grid container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'>
                &#128274;
            </Grid>
        </Grid>
         */}
         
          <Grid container xs={12}  justifyContent='center' flexDirection='row' flexGrow={1} style={{marginBottom:'0px!important', paddingBottom:'0px!important', marginTop:'0px!important', paddingTop:'0px!important'}}>
            <Grid container direction='row' xs={12} justifyContent='center' alignItems='flex-start'>

              <Grid>
                <a className="card-links" href={`https://solscan.io/token/${token.address}`}  target='_blank' rel="noreferrer noopener">
                  Solscan
                </a>
              </Grid>
              <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
              <Grid>
                <a className='card-links' href={`https://dexscreener.com/solana/${token.address}`} target='_blank' rel="noreferrer noopener">
                  Dexscreener
                </a>
              </Grid>

              <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
              <Grid>
                <a className='card-links' href={`https://www.dextools.io/app/en/solana/pair-explorer/${token.address}`} target='_blank' rel="noreferrer noopener">
                  Dextools
                </a>
              </Grid>     
              
              <Grid>
                    <span style={{marginLeft: 5, marginRight: 5}}>|</span>   

                    <a className="card-links"  href={`https://rugcheck.xyz/tokens/${token.address}`} target='_blank' rel="noreferrer noopener">
                    Rugcheck
                    </a>
              </Grid>

            </Grid>

            <Grid container direction='row' xs={12} justifyContent='center' alignContent='space-evenly' mb='10px'>

                 <Grid>
                    <a className="card-links"  href={`https://t.me/solana_unibot?start=r-marksmantools-${token.address}`} target='_blank' rel="noreferrer noopener">
                        Unibot
                    </a>
                </Grid>

                <span style={{marginLeft: 5, marginRight: 5}}>|</span>   

                <Grid>
                    <a className="card-links"  href={`https://t.me/BananaGunSolana_bot?start=snp_marksman_${token.address}`} target='_blank' rel="noreferrer noopener">
                        Banana
                    </a>
                </Grid>

                <span style={{marginLeft: 5, marginRight: 5}}>|</span>

                <Grid>
                    <a className="card-links"  href={`https://t.me/bonkbot_bot?start=ref_3th28_ca_${token.address}`}>
                        Bonk
                    </a>
                </Grid>

                <span style={{marginLeft: 5, marginRight: 5}}>|</span>      


                <Grid>
                    <a className="card-links"  href={`https://raydium.io/swap/?outputCurrency=${token.address}&inputCurrency=sol`} target='_blank' rel="noreferrer noopener">
                        Raydium
                    </a>
                </Grid>

            </Grid>
          </Grid>
        </Card>
      </Grid>
    )
  })