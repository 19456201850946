

import React from "react"
import { 
  Grid,
} from "@mui/joy"

import { 
  ChainIds,
    TokenData, selectActiveChain, selectMarksInWallet, selectSolanaSubscriptionExpirationDate,
} from "../tokenSlice"
import { TokenCard } from "./ethereum/TokenCard"
import { v4 as uuidv4 } from 'uuid'
import { useAppSelector } from "../../../app/hooks"
import { SolanaTokenCard } from "./solana/SolanaTokenCard"
import { ObfuscatedCard } from "./ethereum/ObfuscatedCard"
import { userHasAccess } from "../../../utilities/utilities"
import { ObfuscatedSolanaCard } from "./solana/ObfuscatedSolanaCard"


export const CardView = React.memo(({data, belowThresholdData, title, subTitle, titleColor, cardWidth, isMobile}:{data:TokenData[], belowThresholdData:TokenData[], title:string, subTitle:string, titleColor:string,  cardWidth:string, isMobile:boolean}) => {
    
    const activeChain = useAppSelector(selectActiveChain)
    const marksInWallet = useAppSelector(selectMarksInWallet)
    const solanaSubscriptionExpirationDate = useAppSelector(selectSolanaSubscriptionExpirationDate)

    const DisplayCard = ({row}:{row:TokenData}) => {
      return(
        <Grid  key={uuidv4()}>
            {activeChain === ChainIds.Ethereum &&
              <TokenCard token={row} cardWidth={cardWidth} isMobile={isMobile} />
            }
            {activeChain === ChainIds.Solana &&
              <SolanaTokenCard token={row} cardWidth={cardWidth} isMobile={isMobile} />
            }  
            {activeChain === ChainIds.Base &&
              <TokenCard token={row} cardWidth={cardWidth} isMobile={isMobile} />
            }  
        </Grid>
      )
    }

    const DisplayObfuscatedCard = ({row}:{row:TokenData}) => {
      return(
        <Grid  key={uuidv4()}>
            {activeChain === ChainIds.Ethereum &&
              <ObfuscatedCard token={row} cardWidth={cardWidth} isMobile={isMobile} />
            }
            {activeChain === ChainIds.Solana &&
              <ObfuscatedSolanaCard token={row} cardWidth={cardWidth} isMobile={isMobile} />
            }    
        </Grid>
      )
    }

    return( 
      <div style={{backgroundColor:titleColor, border:`none`, filter:'drop-shadow(0px 0px 5px black)',  minHeight:'300px'}}>
        <Grid container xs={12} direction='row' style={{paddingBottom:'4px', paddingLeft:'5px'}} >
          
          {title&& 
            <Grid  direction='column' className='table-legend' style={{ fontSize: '2em', paddingLeft: 'calc(5px)' }} >
              { title}
            </Grid>
          }
          {subTitle &&
            <Grid  direction='column' className='table-legend' style={{ fontSize: '1em', lineHeight:'2.5em'}}>
              {subTitle}
            </Grid>
          }
        </Grid>
        
        <Grid  container columns={{xs:12, sm: 12, md: 12, lg: 12}} spacing={{ xs: 1, sm: 2 }} justifyContent='center'> 

          {data && userHasAccess({activeChain:activeChain, marksInWallet:marksInWallet, solanaSubscriptionExpirationDate:solanaSubscriptionExpirationDate})?
            data.map((row, index) => (
              <DisplayCard row={row} key={uuidv4()}/>
            ))
            :
            data.map((row, index) => (
              <DisplayObfuscatedCard row={row} key={uuidv4()}/>
            ))
          }

          {belowThresholdData && userHasAccess({activeChain:activeChain, marksInWallet:marksInWallet, solanaSubscriptionExpirationDate:solanaSubscriptionExpirationDate})?
            belowThresholdData.map((row, index) => (
              <DisplayCard row={row} key={uuidv4()}/>
            ))
            :
            belowThresholdData.map((row, index) => (
              <DisplayObfuscatedCard row={row} key={uuidv4()}/>
            ))
          }

        </Grid>

      </div>
  
    )
  })
