import  { useState, useEffect, useCallback, useRef } from "react"
import { 
  Grid,
  Modal
} from "@mui/joy"
import {
  Table,
  TableContainer, 
  TableSortLabel, 
  Box, 
  Input} from '@mui/material'
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { 
  TokenData,
  searchTokenByNameOrAddressAsync,
  selectUserId,
  selectNonce,
  selectActiveChain
} from "../tokenSlice"
import moment from "moment"
import { v4 as uuidv4 } from 'uuid';
import _ from 'lodash'
import { SearchModal } from "./SearchModal"
import { Demodal } from 'demodal'
import { sortData } from "../../../utilities/utilities"



export const Searchbar = ({isMobile}:{isMobile:boolean}) => {


    const dispatch = useAppDispatch()
    const searchFieldRef = useRef<HTMLInputElement>(null)
    const userId = useAppSelector(selectUserId)
    const nonce = useAppSelector(selectNonce)
    const activeChain = useAppSelector(selectActiveChain)

    const searchAsync = async ( event:any ) => {
        if(searchFieldRef && searchFieldRef.current && searchFieldRef.current.value && searchFieldRef.current.value.length > 0){
            await dispatch(searchTokenByNameOrAddressAsync({searchString:searchFieldRef.current.value, id: userId, nonce: nonce, chain: activeChain}))
            
            Demodal.open(SearchModal, {isMobile: isMobile})
        }
    }

    const handleSearch = (event: any) => {
        event.preventDefault()
        searchAsync(event)
    }
    
    return(

        <Grid id='searct-parent-container' container  sx={{marign:'auto', padding:'auto', width:'100%'}} justifyContent='space-between'>
            <Grid id='search-input-parent' sx={{width:'calc(100% - 30px)'}}>

                {/* <Input
                placeholder={width>400?"Search Name or Address":"Search..."}
                sx={{ padding:'0px', color:'white', paddingLeft:'5px', width:'100%'}}
                onChange = {(e) => handleSearchTextChange(e.target.value)}
                value={searchText}
                name='search-field'
                id='token-page-search-field'
                /> */}

                <form onSubmit={(event)=>handleSearch(event)}>
                    <input
                        ref={searchFieldRef}
                        placeholder={"Search..."}
                        style={{ 
                            padding:'0px', 
                            color:'white', 
                            paddingLeft:'5px', 
                            width:'100%',
                            border: 'none',
                            fontSize:'12pt',
                            background:'transparent'
                        }}
                        name='search-field'
                        id='token-page-search-field'
                        type='text'
                        autoComplete='false'
                    />
                </form>
            </Grid>
            <Grid id='search-button-parent' sx={{width:'24px', height:'24px', padding:'0px', margin:'auto'}} justifyContent='center' alignItems='center'>
                <div
                    id='token-page-search-button' 
                    className='search-icon-bg top-icons' 
                    style={{width:'24px', height:'24px'}}
                    onClick={(event)=>handleSearch(event)}
                />
            </Grid>
        </Grid>
    )
}