import { useReducer, useState } from "react"
import { Grid, Card, Button } from "@mui/joy"
import ScrollTrigger from "react-scroll-trigger"
import Slide from "@mui/material/Slide/Slide"
import { useWindowDimensions } from "../../api/hooks/windowDimentionProvider"
import Footer from "../footer/Footer"
import copy from "copy-to-clipboard"


const defaultVisiblityStates = {
    section1: false,
    section2: false,
    section3: false,
    section4: false,
    section5: false
}

function Contact ()  {

    return(
        <Grid container sx={{backgroundColor:'#000', width:'100vw'}} justifyContent='center' alignItems='center'>

            <Grid

                container
                justifyContent='center' alignItems='center' textAlign='center'
                style={{minHeight:'60vh', marginBottom:'10vh', marginTop:'15vh', maxWidth:'1000px'}}

            >
                <Grid xs={12} sx={{fontSize:'20px', margin:'auto', padding:'auto'}} >
                    <Grid xs={12}>
                        Telegram:
                    </Grid>
                    <Grid xs={12}>

                        <a 
                            href='https://t.me/MarksmanPortal' 
                            rel="noreferrer noopener" target="_blank"
                            style={{textDecoration:'none', marginLeft:'20px', wordBreak:'break-all'}}
                        >
                            https://t.me/MarksmanPortal
                        </a>
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{fontSize:'20px', margin:'auto', padding:'auto'}} >
                    <Grid xs={12}>
                        Twitter:
                    </Grid>
                    <Grid xs={12}>
                        <a 
                            href='https://twitter.com/MarksmanTools' 
                            rel="noreferrer noopener" target="_blank"
                            style={{textDecoration:'none', marginLeft:'20px', wordBreak:'break-all'}}
                        >
                            https://twitter.com/MarksmanTools
                        </a>
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{fontSize:'20px', margin:'auto', padding:'auto'}} >
                    <Grid xs={12}>
                        Gitbooks:
                    </Grid>
                    <Grid xs={12}>

                        <a 
                            href='https://marksman-app.gitbook.io/marksman/' 
                            rel="noreferrer noopener" target="_blank"
                            style={{textDecoration:'none', marginLeft:'20px', wordBreak:'break-all'}}
                        >
                            https://marksman-app.gitbook.io/marksman/
                        </a>
                    </Grid>
                </Grid>
                <Grid xs={12} sx={{fontSize:'20px', margin:'auto', padding:'auto'}} >
                    <Grid xs={12}>
                    Email:
                    </Grid>
                    <Grid xs={12}>

                        <a 
                            href='mailto:marksmananalytics@outlook.com' 
                            rel="noreferrer noopener" target="_blank"
                            style={{textDecoration:'none', marginLeft:'20px', wordBreak:'break-all'}}
                        >
                            marksmananalytics@outlook.com
                        </a>
                    </Grid>
                </Grid>
            </Grid>
            <Grid xs={12} sx={{marginTop:"auto", marginBottom:"20px", position:'absolute', bottom:'0px'}}>
                <Footer/>
            </Grid>
        </Grid>
    )

    

}

export default Contact