import { 
    Dialog 
} from '@mui/material'
import { 
    Grid,
    Card,
    Tooltip,
} from "@mui/joy"

import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'
import moment from 'moment';



export const McapModal = Demodal.create(
    (
        {symbol: symbol, mCap: mCap, mcapAt: mcapAt, liquidityV2:liquidityV2, liquidityV3: liquidityV3 }: 
        {symbol: string, mCap: number, mcapAt: number, liquidityV2: string, liquidityV3: string }
    ) => {

    const mcapModal = useModal()

    return (
        <Dialog
            {...muiDialog(mcapModal)}
        >
            <Card className='modal-bg' style={{ padding: '10px', border: '1px solid rgba(255,255,255,0.2)', borderRadius:'0px', filter: 'drop-shadow(0px 0px 15px black)', overflowY: 'scroll',  minHeight:'10vh', alignItems:'center', justifyContent:'center' }}>
                    
                <Grid textAlign='center' className='small-modal-headings' sx={{ marginBottom: '0px', paddingBottom: '0px' }}>
                    <span>Symbol:</span>
                    <Tooltip title={symbol} placement='top' arrow >
                        <span className='small-modal-data' >
                            {symbol ?
                                symbol.substring(0, 10)
                                :
                                '-'
                            }
                        </span>
                    </Tooltip>
                </Grid>

                <Grid container xs={12} justifyContent='center' alignItems='center' flexDirection='row' flexGrow={1} sx={{ marginTop: '0px' }}>
                    <table>
                        <tbody>

                            <tr style={{justifyContent:'space-between'}}>
                                <td className="small-card-headings">
                                    Market Cap:
                                </td>
                                <td className='small-card-data'>
                                {mCap?
                                    '$'+(Number(mCap).toLocaleString()): '-'
                                }
                                </td>
                            </tr>
                                <tr style={{justifyContent:'space-between'}}>
                                <td className="small-card-headings">
                                    Time:
                                </td>
                                <td className='small-card-data'>
                                {mcapAt?
                                    moment(mcapAt).format('llll').toLocaleString(): '-'
                                }
                                </td>
                            </tr>
                                <tr style={{justifyContent:'space-between'}}>
                                <td className="small-card-headings">
                                    Liquidity V2 (Eth):
                                </td>
                                <td className='small-card-data'>
                                {liquidityV2?
                                    parseFloat(liquidityV2).toFixed(4): '-'
                                }
                                </td>
                            </tr>
                                <tr style={{justifyContent:'space-between'}}>
                                <td className="small-card-headings">
                                    Liquidity V3 (Eth):
                                </td>
                                <td className='small-card-data'>
                                {liquidityV3?
                                    parseFloat(liquidityV3).toFixed(4): '-'
                                }
                                </td>
                            </tr>
                        </tbody>
                    </table>
        
                </Grid>
            </Card>
        </Dialog>
    )
  })

