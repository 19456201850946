
import { 
    Dialog,
    Modal 
} from '@mui/material'
import { 
    Grid,

} from "@mui/joy"

import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'
import moment from 'moment';
import { selectSearchedTokens, selectUser, selectSortBy } from '../tokenSlice';
import { useAppSelector, useAppDispatch } from '../../../app/hooks';
import { TableView } from './TableView';
import { isTokenBeyondThreshold, sortData, } from '../../../utilities/utilities';


export const SearchModal = Demodal.create(({isMobile}:{isMobile:boolean}) => {

    const searchModal = useModal()
    const searchedTokens = useAppSelector(selectSearchedTokens)
    const user = useAppSelector(selectUser)
    const sortTokensBy = useAppSelector(selectSortBy)
    const dispatch = useAppDispatch()

    const handleModalClose = async () => {
        await dispatch({type:'tokens/clearSearchedTokens'})
        searchModal.close()
    }

    return (
        <Modal
            {...muiDialog(searchModal)}
            disableAutoFocus
            disableEnforceFocus
            onClose={()=>handleModalClose()}
            sx={{width:'max-content', maxWidth: isMobile?'90vw':'80vw', margin:'auto', justifyContent:'center', alignItems:'center', marginTop:'15vh'}}
        >
            <div  style={{filter:'drop-shadow(0px 8px 7px black)', backgroundColor:'black', height:'max-content', maxHeight:'70vh', overflowY: 'scroll'}}>
                <div className='close-icon-bg' style={{width: '30px', height:'30px'}} onClick={()=>handleModalClose()}/>
                {searchedTokens.length > 0?
                    <TableView 
                        data={sortData({sortBy: sortTokensBy , data:(searchedTokens).filter((t) => !isTokenBeyondThreshold(t, user))})} 
                        belowThresholdData={sortData({sortBy: sortTokensBy , data:(searchedTokens).filter((t) => isTokenBeyondThreshold(t, user))})} 
                        title='Search Results' subTitle='' titleColor='rgba(0,0,0,1)' borderColor='rgba(0,0,0,0)'  isMobile={isMobile}
                    />
                    :
                    <Grid  style={{height:'100%!important', fontWeight:'bold', fontSize:'20pt', margin:'auto', padding:'auto'}} justifyContent='center' alignItems='center' textAlign='center'>
                    No Tokens Matched Search Query
                    </Grid>
                }
                
            </div>
        </Modal>
    )
  })

