import { 
    Button,
    Dialog, Input , 
} from '@mui/material'
import { 
    Grid,
    Card,
} from "@mui/joy"

import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'
import { checkifNumber } from '../../utilities/utilities';
import { ReactNode, useState } from 'react';
import { useAppDispatch } from '../../app/hooks';


export enum ThresholdTypes{
    SniperScore = 0,
    MarksmanScore,
    NumCallers
}



const ErrorMessages = [
    'Threshold must be greater than or equal to 40 or 0 to disable.', 
    'Threshold must be greater than or equal to 100 or 0 to disable.', 
    'Threshold must be greater than or equal to 3 and less than or equal to 100. Or 0 to disable.'
]

// TODO: Implement getMinThresholdByType and getErrorMessageByType

export const SetThresholdModal = Demodal.create(
    (
        { fieldTitle: fieldTitle, fieldData: fieldData, savedValue, asyncUpdateCallback, cardWidth, minValue, type}: 
        { fieldTitle: ReactNode, fieldData: number, savedValue: number, asyncUpdateCallback: Function, cardWidth: string, minValue: number, type: ThresholdTypes}
    ) => {

    const setFieldModal = useModal()
    const dispatch = useAppDispatch()
    const [fieldVal, setFieldVal] = useState(fieldData)

    const handleSetField = (value:number) => {
        setFieldVal(value)
    }

    const shouldDisableSaveButton = (type:ThresholdTypes) => {
        switch(type){
            case ThresholdTypes.SniperScore:
                return (( fieldVal < 40 && fieldVal !== 0 ) || ( savedValue === fieldVal ))
            case ThresholdTypes.MarksmanScore:
                return (( fieldVal < 100  && fieldVal !== 0 ) || ( savedValue === fieldVal ))
            case ThresholdTypes.NumCallers:
                return (( fieldVal < 3 && fieldVal !== 0 ) || ( savedValue === fieldVal || fieldVal > 100 ))
        }
    }
    
    const shouldShowErrorMessage = (type:ThresholdTypes) => {
        switch(type){
            case ThresholdTypes.SniperScore:
                return ( fieldVal < 40 && fieldVal !== 0 ) 
            case ThresholdTypes.MarksmanScore:
                return ( fieldVal < 100  && fieldVal !== 0 ) 
            case ThresholdTypes.NumCallers:
                return (( fieldVal < 3 && fieldVal !== 0 ) || ( fieldVal > 100 ))
        }
    }

    return (
        <Dialog 
            {...muiDialog(setFieldModal)}
            sx={{          
                filter:'drop-shadow(0px 4px 5px black)', 
                borderRadius: '0px',
                border:'none',
                overflow:'clip',
            }}
        >
            <Card 
                style={{
                    padding: '0px',
                    border: 'none', borderRadius: '0px',
                    backgroundColor:'#1a1b1d', 
                    filter: 'drop-shadow(0px 0px 15px black)', 
                    overflowY: 'scroll', 
                    alignItems:'center', justifyContent:'center',
                    width: cardWidth,
                    overflow: 'clip',
                    maxWidth: cardWidth,
                }}
            >
            
                <Grid textAlign='center' className='small-modal-headings' sx={{ marginBottom: '0px', paddingBottom: '0px' }}>
                    <Grid xs={12} container textAlign='center' alignItems='center'  justifyContent='center' className='profile-headings' sx={{backgroundColor:'#2a2b2d', marginTop:'0px!important'}} >
                        <Grid xs={12}  pl={4} pr={4} pt={2} pb={2}>
                        {
                            fieldTitle
                        }
                        </Grid>
                        <Grid xs={1} justifyContent='flex-end'>
                            <div className='close-icon-bg-profile' style={{width: '25px', height:'25px'}} onClick={()=>setFieldModal.close()}/>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent='center' alignItems='space-between' textAlign='center' sx={{margin:'auto', height:'100%', paddingBottom:'50px'}}>
                        <Grid xs={12} spacing={2}>
                            <Input
                                placeholder="Enter Sniper Score Min Threshold"
                                sx={{ textAlign:'center', marginRight:'10px', color:'white', paddingLeft:'10px', paddingTop:'10px', paddingBottom:'10px',width:'60%', marginTop:'100px', backgroundColor:'#051020'}}
                                onChange = {(e) => handleSetField(e.target.value?parseInt(e.target.value): 0)}
                                value={fieldVal}
                                name='tg-field'
                                id='tg-name-input-field'
                                error={(fieldVal < 40  && fieldVal !== 0 )|| !checkifNumber(fieldVal) }
                                
                            />
                            <Button 
                                disabled={shouldDisableSaveButton(type)}
                                className='connect-button' 
                                onClick={ ()=> asyncUpdateCallback(fieldVal)}
                                style={{marginTop:'10px',marginBottom:'10px'}}
                            >
                                
                                Save
                            </Button>
                        </Grid>
                        <Grid xs={12}>

                        {shouldShowErrorMessage(type) &&
                            <div style={{color:'red', maxWidth: cardWidth}}>
                            {
                                ErrorMessages[type]
                            }
                            </div>
                        }
                        {!checkifNumber(fieldVal)  &&
                            <div style={{color:'red'}}>
                                Threshold must be a number
                            </div>
                        }
                        </Grid>
                    </Grid>
            
                </Grid>
            </Card>
        </Dialog>
    )
})

