import { BrowserRouter as Router, Routes, Route, redirect } from 'react-router-dom'

import { Grid } from "@mui/joy"
import { Tokens } from "./features/token-scan/Token";
import Home from './features/home/Home.jsx';
import "./App.css"
import ResponsiveAppBar from './features/navbar/navbar'
import ScrollButton from './features/back-to-top/BackToTop';
import Footer from './features/footer/Footer';
import { SingleToken } from './features/single-token-page/SingleToken';
import { useAppSelector, useAppDispatch  } from './app/hooks';
import { UserProfile } from './features/profile/UserProfile';
import { selectUserId,  } from './features/token-scan/tokenSlice';
import { Navigate } from 'react-router-dom';
import Contact from './features/contact/Contact';
import { useAccount} from 'wagmi';
import { Demodal } from "demodal";


function App() {
  const userId = useAppSelector(selectUserId)

  return (
    <div className='app-main'  style={{padding:"0px"}}>
      <Grid container sx={{padding:"0px"}}>
        
        <Grid xs={12} sx={{padding:"0px", overflowX:'clip'}}>
          <Router>
          <Grid xs={12}>
            <ResponsiveAppBar/>
          </Grid>
            <Routes>
              <Route   path="/" element={<Home />} />
              <Route path="/tracker" element={<Tokens/>} />
              <Route path="/:chain/:id" element={< SingleToken/>}/>
              <Route path="/profile" element={userId?<UserProfile/>: <Navigate to="/" replace />}/>
              <Route path="/contact" element={<Contact/>} />
            </Routes>
          </Router>
            
        </Grid>
      
      </Grid>
      <ScrollButton/>
      <Demodal.Container/>
    </div>
  )
}

export default App
