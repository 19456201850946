import { 
    Dialog, Popover , 
} from '@mui/material'
import { 
    Grid,
    Card,
} from "@mui/joy"

import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'



export const FieldModal = Demodal.create(
    (
        { fieldTitle, fieldData, anchor }: 
        { fieldTitle: String, fieldData: any, anchor: Element }
    ) => {

    const fieldModal = useModal()

    return (
      <Popover 
        {...muiDialog(fieldModal)}
        anchorEl={anchor}
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
          }}
          transformOrigin={{
              vertical: 'bottom',
              horizontal: 'center',
          }}
      >
          <Card className='modal-bg' style={{ padding: '10px', border: '1px solid rgba(255,255,255,0.2)', borderRadius:'0px', filter: 'drop-shadow(0px 0px 15px black)', overflowY: 'scroll', minWidth:'200px', alignItems:'center', justifyContent:'center' }}>
            <Grid textAlign='center' className='small-modal-headings' sx={{ marginBottom: '0px', paddingBottom: '0px' }}>
              <span style={{marginRight:'5px'}} className='card-creation-date-heading'> 
                {fieldTitle && fieldTitle}  
              </span>

              <div className='card-creation-date'>
              {fieldData &&
                fieldData
              }
              </div>
            </Grid>
          </Card>
      </Popover>
    )
})

