import * as React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  AppBar,
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Link,
  Container,
  Avatar,
  Button,
  Tooltip,
  MenuItem,
  Drawer,
} from "@mui/material";
import { Grid } from '@mui/joy';
import MenuIcon from '@mui/icons-material/Menu';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide/Slide';
//import { Profile } from '../user-login/Wallet2';
import { Profile } from '../user-login/Wallet4';
import { useWindowDimensions } from '../../api/hooks/windowDimentionProvider';

const pages = [
  { title: 'Home', linkTo: '/' },
  { title: 'Tracker', linkTo: '/tracker' },
  { title: 'Contact Us', linkTo: '/contact'}

];

const socials = [
  { title: 'Telegram', linkTo: 'https://t.me/MarksmanPortal', className: 'telegram-icon-bg' },
  { title: 'Twitter', linkTo: 'https://twitter.com/MarksmanTools', className: 'twitter-icon-bg' },
  { title: 'Gitbooks', linkTo: 'https://marksman-app.gitbook.io/marksman/', className: 'gitbooks-icon-bg' },
];


function HideOnScroll({ children }: { children: React.ReactElement }) {


  const trigger = useScrollTrigger({
    target: window
  });

  return (
    <Slide appear={false} direction="down" in={!trigger} >
      {children}
    </Slide>
  );
}

function ResponsiveAppBar() {
  const {width, height} = useWindowDimensions()
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const navigate = useNavigate();


  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {

    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <HideOnScroll>
      <AppBar sx={{ backgroundColor: 'rgba(0,0,0,.6)', backdropFilter: 'blur(5px)', height:'64px!important' }} id='back-to-top-anchor'>
        <Grid
          container
          flexGrow={1}
          xs={12}
        >
          <Toolbar disableGutters sx={{width:'100%'}}>
            <Grid container xs={12} justifyContent='flex-start'>
              <div className='marksman-logo'/>
              {width > 630 &&
                <Typography
                  variant="h6"
                  noWrap
                  component="a"
                  href="/"
                  sx={{
                    mr: 2,
                    display: { xs: 'flex', md: 'flex' },
                    justifyContent:'flex-start',
                    fontWeight: 700,
                    letterSpacing: '.2rem',
                    color: 'inherit',
                    textDecoration: 'none',
                    fontFamily: 'Ac437_IBM_BIOS',
                    fontSize: '2em',
                  }}
                >
                  Marksman
                </Typography> 
              }
            </Grid>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex', lg:'none' }, justifyContent: 'flex-end' }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Drawer
                id="menu-appbar"
                anchor='left'
                keepMounted
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  width: '150px',
                  textAlign: 'center',
                  '& .MuiDrawer-paper': {
                    bgcolor: 'rgba(0,0,0,.2)',
                    width: '150px',
                    boxSizing: 'border-box',
                    paddingTop: '100px',
                    backdropFilter: 'blur(7px)'
                  },
                }}
              >
                <Grid container justifyContent='center'>
                  {pages.map((page) => (
                    <MenuItem key={page.title} onClick={handleCloseNavMenu}>
                      <Typography textAlign="center">

                        <Button href={page.linkTo} disabled={page.linkTo === window.location.pathname} className='top-nav-buttons'>
                          {page.title}
                        </Button>
                      </Typography>
                    </MenuItem>
                  ))}
                </Grid>

                <Grid container justifyContent='space-evenly' alignItems='center'>
                  {socials.map((page) => (
                    <Grid key={page.title} style={{ height: '30px', width: page.title === 'Gitbooks' ? '42px' : '30px', marginTop: 'auto', marginBottom: 'auto' }}>
                      <a href={page.linkTo} rel="noreferrer noopener">
                        <div
                          style={{ height: '100%' }}
                          className={`top-icons ${page.className}`}
                        >
                        </div>

                      </a>

                    </Grid>
                  ))}
                </Grid>
              </Drawer>
            </Box>
            <Grid xs={ 12} sx={{ flexGrow: 1, display: { xs: 'none', md: 'none', lg: 'flex' }, justifyContent: 'flex-end' }} >
              {pages.map((page) => (
                <div key={page.title} >
                  <Typography textAlign="center" >
                    <Button onClick={() => navigate(page.linkTo)} disabled={page.linkTo === window.location.pathname} className='top-nav-buttons'>
                      {page.title}
                    </Button>
                  </Typography>
                </div>
              ))}
              <Grid container sx={{ width: '140px!important' }} >
                {socials.map((page) => (
                  <Grid key={page.title} style={{ height: '30px', width: page.title === 'Gitbooks' ? '42px' : '30px', marginTop: 'auto', marginBottom: 'auto', marginRight: '5px', marginLeft: '5px' }}>

                    <a href={page.linkTo} rel="noreferrer noopener" target="_blank">
                      <div
                        style={{ height: '100%' }}
                        className={`top-icons ${page.className}`}>
                      </div>
                    </a>
                  </Grid>
                ))}
              </Grid>

         
            </Grid>
            <Grid container justifyContent='center' alignItems='center' sx={{display:'contents'}} >
                <Profile />
            </Grid>
          </Toolbar>
        </Grid>
      </AppBar>
    </HideOnScroll>
  );
}
export default ResponsiveAppBar;