import { 
    Dialog , 
    Backdrop, 
    Popover, 
    Table,
    TableBody,
    TableRow,
    TableCell

} from '@mui/material'
import { 
    Grid,
    Card,
    Tooltip,
} from "@mui/joy"

import { Demodal, useModal } from "demodal";
import { Caller } from '../tokenSlice';
import { muiDialog } from 'demodal/material-ui'
import moment from 'moment';



function convertToInternationalCurrencySystem (value:number) {

    // Nine Zeroes for Billions
    return Math.abs((value)) >= 1.0e+9

    ? (Math.abs((value)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs((value)) >= 1.0e+6

    ? (Math.abs((value)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs((value)) >= 1.0e+3

    ? (Math.abs((value)) / 1.0e+3).toFixed(2) + "K"

    : Math.abs((value));

  }


export const McapTooltip = Demodal.create(
    (
        {symbol: symbol, mCap: mCap, mcapAt: mcapAt, liquidityV2:liquidityV2, liquidityV3: liquidityV3 , anchor}: 
        {symbol: string, mCap: number, mcapAt: number, liquidityV2: string | null, liquidityV3: string | null, anchor:Element }
    ) => {

    const mcapTooltip = useModal()

    return (
            <Popover
                {...muiDialog(mcapTooltip)}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} 
            >
                <div style={{padding:'5px', backgroundColor:'black'}}>
                    <table>
                        <tbody>
    
                        <tr style={{justifyContent:'space-between'}}>
                            <td className="small-card-headings">
                            Market Cap:
                            </td>
                            <td className='small-card-data'>
                            {mCap?
                            '$'+(Number(mCap).toLocaleString()): '-'
                            }
                            </td>
                        </tr>
                        <tr style={{justifyContent:'space-between'}}>
                            <td className="small-card-headings">
                            Time:
                            </td>
                            <td className='small-card-data'>
                            {mcapAt?
                            moment(mcapAt).format('llll').toLocaleString(): '-'
                            }
                            </td>
                        </tr>
                        <tr style={{justifyContent:'space-between'}}>
                            <td className="small-card-headings">
                            Liquidity V2 (Eth):
                            </td>
                            <td className='small-card-data'>
                            {liquidityV2?
                            parseFloat(liquidityV2).toFixed(4): '-'
                            }
                            </td>
                        </tr>
                        <tr style={{justifyContent:'space-between'}}>
                            <td className="small-card-headings">
                            Liquidity V3 (Eth):
                            </td>
                            <td className='small-card-data'>
                            {liquidityV3?
                            parseFloat(liquidityV3).toFixed(4): '-'
                            }
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </Popover>
    )
  })

