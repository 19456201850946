import React, { useState, useEffect } from 'react'



function debounce(fn, ms) {

  let timer

  return _ => {

    clearTimeout(timer)

    timer = setTimeout(_ => {
      timer = null
      fn.apply(this, arguments)
    }, ms)
  };
}


function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height, scrollY: scrollY, scrollX: scrollX } = window;
    return {
      width,
      height,
      scrollX,
      scrollY
    };
  }
  
  export function useWindowDimensions() {
    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
  
    useEffect(() => {


      const debouncedHandleResize = debounce(function handleResize(){
        setWindowDimensions(getWindowDimensions());
      }, 100)

      const debouncedHandleScroll = debounce(function handleScroll(){
        setWindowDimensions(getWindowDimensions());
      }, 100)
    
  
      window.addEventListener('resize', debouncedHandleResize);
      window.addEventListener('scroll', debouncedHandleScroll);
      
      return () => {
        window.removeEventListener('resize', debouncedHandleResize);
        window.removeEventListener('scroll', debouncedHandleScroll)
      }
    }, []);
  
    return windowDimensions;
  }
  