import { createRef } from 'react';
import { 
    Dialog, 
    Table,
    TableBody,
    TableRow,
    TableCell,
    Checkbox

} from '@mui/material'
import { 
    Grid,
    Card,
    Tooltip,
} from "@mui/joy"

import { Demodal, useModal } from "demodal";
import { Caller, ChainIds, TrackedToken, disableTrackingForToken, enableTrackingForToken, selectActiveChain, selectNonce, selectTrackingData, selectUserId, selectUserRequestLoading, selectUserTrackedTokenIds } from '../tokenSlice';
import { muiDialog } from 'demodal/material-ui'
import moment from 'moment';
import { TokenData } from '../tokenSlice';
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { selectMarksInWallet } from '../tokenSlice';

import { 
    handleCopyText,
    convertToInternationalCurrencySystem,
    truncateAddress,
    truncateTxAmt,
    getScoreColor,
    getScoreColorTable,
    getPct,
    ParseDeadblockData,
    getTaxColor,
    getScamColor,
    getScamPercent,
    isLiquidityLocked,
    isLiquidityBurned
} from "../../../utilities/utilities"

import { FieldModal } from './FieldModal';


// const ParseDeadblockData = ({pct:pct}:  {pct:any}) => {
//     if(pct === 'Fail'){
//       return  <div className='clear-icon-bg'  style={{display:'inline-flex'}}/>
//     }
//     else if (pct.length >= 1){
//       return pct.replace('%','')
//     }
//     else{
//       return  <Grid container className='clear-icon-bg'  style={{display:'inline-flex', justifyContent:'center', alignItems:'center', textAlign:'center'}}/>
//     }
//   }


export const MoreInfoModal = Demodal.create(
    (
        {token: token, isMobile: isMobile}: 
        {token: TokenData, isMobile: boolean }
    ) => {

    const moreInfoModal = useModal()
    const dispatch = useAppDispatch()

    const marksInWallet = useAppSelector(selectMarksInWallet)
    const activeChain = useAppSelector(selectActiveChain)
    const userId = useAppSelector(selectUserId)
    const trackedTokenIds = useAppSelector(selectUserTrackedTokenIds)
    const nonce = useAppSelector(selectNonce)
    const trackingData = useAppSelector(selectTrackingData)
    const loadingStatus = useAppSelector(selectUserRequestLoading)

    const handleTrackToggle = async ({tokenId}:{tokenId:number}) => {

        if(trackedTokenIds.includes(tokenId)){
          const toggledToken:TrackedToken[] = trackingData.filter((token)=> token.tokenId === tokenId)
          await dispatch(disableTrackingForToken({trackData: toggledToken[0], nonce: nonce}))
        }
        else{
          await dispatch(enableTrackingForToken({userId:userId, tokenId: tokenId, nonce: nonce}))
        }
        moreInfoModal.close()
        
    }
    
    return (
        <Dialog 
            {...muiDialog(moreInfoModal)}
        >
            <Card 
                className='modal-bg' 
                style={{ 
                    padding: '10px', 
                    border: '1px solid rgba(255,255,255,0.2)', 
                    borderRadius:'0px', 
                    filter: 'drop-shadow(0px 0px 15px black)', 
                    overflowY: 'scroll',  alignItems:'center', justifyContent:'center' 
                }}
            >
                <Grid container xs={12}  sx={{margin:0, padding:0}}>
                    <Grid direction='row' container xs={12} justifyContent='flex-start' alignItems='flex-start' display='flex' sx={{height:'30px', marginTop:'0px'}}>
                        <Grid container  justifyContent='center' alignItems='center' flexDirection='row' sx={{margin:'auto', padding:'auto', marginLeft:'0px!important'}}>
                        {token.socialLinks && token.socialLinks.length >=1 &&
                        JSON.parse(token.socialLinks).map((socialLink:string)=>
                            <div key={socialLink}>
                            {socialLink.includes('twitter') || socialLink.includes('x.com')&&
                            <a  href={socialLink} target='_blank' rel="noreferrer noopener" >
                                <div className="twitter-icon-bg-small top-icons" style={{width:'18px', height:'18px', marginLeft:'5px'}}/>
                            </a>
                            }
                            {socialLink.includes('t.me') &&
                            <a  href={socialLink} target='_blank' rel="noreferrer noopener" >
                                <div className="telegram-icon-bg-small top-icons" style={{width:'18px', height:'18px', marginLeft:'5px'}}/>
                            </a>
                            }
                            {socialLink.includes('medium') &&
                            <a  href={socialLink} target='_blank' rel="noreferrer noopener">
                                <div className="medium-icon-bg-small top-icons" style={{width:'18px', height:'18px', marginLeft:'5px'}}/>
                            </a>
                            }
                            {!socialLink.includes('t.me') && 
                            !socialLink.includes('twitter') && 
                            !socialLink.includes('x.com') && 
                            !socialLink.includes('youtube') &&
                            !socialLink.includes('tiktok') &&
                            !socialLink.includes('gitbook') &&
                            !socialLink.includes('doc') &&
                            !socialLink.includes('medium') &&
                    
                            <a  href={socialLink} target='_blank' rel="noreferrer noopener" >
                                <div className="globe-icon-bg-small top-icons" style={{width:'18px', height:'18px', marginLeft:'5px'}}/>
                            </a>

                            }
                            </div>
                        )
                        }
                        </Grid>
                       
                        <Grid container  justifyContent='center' alignItems='center' flexDirection='row' sx={{margin:'auto', padding:'auto', marginRight:'0px!important'}}>
    
                        <>
                        {token.isRenounced &&  token.isRenounced === 1 ?
                            <Grid 
                            className='card-renounced-icon-bg'
                            sx={{width:'24px', height:'24px', margin:'auto', padding:'auto', marginRight:'10px!important'}}
                            bgcolor={token.isRenounced === 1?'#12af32':'#000000'}
                            />
                            :
                            <></>
                        }
                        </>
                        
      
                        <>
                        {token.parsedLiquidityArray && isLiquidityLocked({liquidityArray: token.parsedLiquidityArray})?
                        
                        <Grid 
                            className='card-lock-icon-bg'
                            sx={{width:'18px', height:'18px', margin:'auto', padding:'auto', marginRight:'10px!important'}}
                            bgcolor='#12af32'
                        />
                        :
                        <></>
                        }
                        </>
           
                        
       

                        <>
                        {token.parsedLiquidityArray && isLiquidityBurned({liquidityArray: token.parsedLiquidityArray})?
                        <Grid 
                            className='card-burn-icon-bg'
                            sx={{width:'18px', height:'18px', margin:'auto', padding:'auto', marginRight:'10px!important'}}
                            bgcolor='#12af32'
                        />
                        :
                        <></>
                        }
                        </>
          
 
                        <Grid 
                            className={`${token.launchedAt?'card-launched-icon-bg':'card-unlaunched-icon-bg'}`} 
                            sx={{width:'18px', height:'18px', margin:'auto', padding:'auto', marginRight:'10px!important'}}
                            bgcolor={token.launchedAt?'#12af32':'#cf0a0a'}
                        />
       
                        </Grid>

                        <Grid > 
                        {token.address &&
                            <Checkbox
                                id={`moreinfo-checkbox-id-${token.id}`} 
                                checked={token.isTracked? true: false}
                                onChange={()=>handleTrackToggle({tokenId: token.id})} 
                                disabled={loadingStatus==='loading' || !(userId) || !nonce}
                                size='small'
                                sx={{
                                color: 'white',
                                '&.Mui-checked': {
                                    color:'#4a91ff',
                                },
                                }} 
                            />
                        }
                        </Grid>
                    </Grid>
                </Grid>
                <Grid textAlign='center' className='small-modal-headings' sx={{ marginBottom: '0px', paddingBottom: '0px' }}>
                    <span>Symbol:</span>
                    <Tooltip title={token.symbol} placement='top' arrow >
                        <span className='small-modal-data' >
                        {token.symbol ?
                        token.symbol.substring(0, 10)
                        :
                        '-'
                        }
                        </span>
                    </Tooltip>
                </Grid>

                <Grid container xs={12} >
                    <Grid xs={5} flexDirection='column' direction='column' flexGrow={1}>
                        <Grid textAlign='left' className='modal-headings' xs={12} >
                            Name
                        </Grid>
                        <Tooltip title={token.name} placement='top' arrow>
                            <Grid xs={12} className='modal-data' textAlign='left' alignItems='left' justifyContent='left'  >
                            {token.name ?
                                token.name.substring(0, 10)
                                :
                                '-'
                            }
                            </Grid>
                        </Tooltip>

                        <Grid xs={6} className='modal-headings' container >
                        Creation Date
                        </Grid>
                        <Grid xs={6} className='modal-data'>
                        {token.createdAt &&
                            <Tooltip title={`${moment(token.createdAt).format('llll').toLocaleString()}`} placement="top" arrow>
                                <Grid>
                                {token.createdAt ?
                                moment(token.createdAt).fromNow()
                                :
                                '-'
                                }
                                </Grid>
                            </Tooltip>
                        }
                        </Grid>

                        <Grid textAlign='left' className='modal-headings' xs={12} >
                            Contract
                        </Grid>
                        <Tooltip title='Copy Deployer Address' placement='left' arrow>
                            <Grid
                                xs={12}
                                className='modal-data copyable-field'
                                onClick={() => handleCopyText(token.address)}
                                style={{ color: '#4a91ff' }}
                            >
                            {token.deployerAddress ?
                                truncateAddress(token.address)
                                :
                                '-'
                            }
                            </Grid>
                        </Tooltip>

                        <Grid textAlign='left' className='modal-headings' xs={12} >
                        Deployer
                        </Grid>
                        <Grid xs={12}>
                        {token.deployerAddress ?
                            <a className="copyable-field modal-data" href={`${token.chain === 'base' ? 'https://basescan.org' : 'https://etherscan.io'}/address/${token.deployerAddress}`} target='_blank' rel="noreferrer noopener">
                            {
                                truncateAddress(token.deployerAddress)
                            }
                            </a>
                            :
                            '-'
                        }
                        </Grid>

                        <Grid textAlign='left' className='modal-headings' xs={12} >
                            Total Supply
                        </Grid>
                        <Grid textAlign='left' className='modal-data' xs={12}>
                        {token.supplyAmount ?
                            getPct(token.supplyAmount)
                            :
                            '-'
                        }
                        </Grid>

                        <Grid textAlign='left' className='modal-headings' xs={12} >
                            Market Cap
                        </Grid>
                        <Grid textAlign='left' className='modal-data' xs={12}>
                        {token.mCap?
                        '$'+(Number(token.mCap).toLocaleString()): '-'
                        }
                        </Grid>

                        <Grid textAlign='left' className='modal-headings' xs={12} >
                        Time Recorded
                        </Grid>
                        <Grid textAlign='left' className='modal-data' xs={12}>
                        {token.mcapAt?
                            moment(token.mcapAt).format('llll').toLocaleString(): '-'
                        }
                        </Grid>
                    </Grid>

                    <Grid xs={7} flexDirection='column' direction='column' flexGrow={1} >
                        <Grid className='modal-headings' container xs={12} justifyContent='flex-end'>
                        Marksman Score
                        </Grid>
                        <Tooltip
                            placement="top-end"
                            arrow
                            title={
                                <>
                                <Grid className='modal-headings' justifyContent='center' textAlign='center'>
                                    Sniper Score
                                </Grid>
                                <Grid className='sniper-field' alignContent='center' alignItems='center' justifyContent='center'>
                                    <div style={{ width: '125px', backgroundColor: `${getScoreColor({ score: token.sniperScore })}`, color: 'black', textAlign: 'center' }}>
                                    {token.sniperScore ?
                                    token.sniperScore : '-'
                                    }
                                    </div>
                                </Grid>
                                </>
                                }
                        >
                            <Grid className='alpha-field' container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end'>
                                <div style={{ width: '125px', backgroundColor: `${getScoreColor({ score: token.alphaScore })}`, color: 'black', textAlign: 'center' }}>
                                {token.alphaScore ?
                                token.alphaScore : '-'
                                }
                                </div>

                            </Grid>
                        </Tooltip>

                        {/* <Grid textAlign='right' className='small-modal-headings'  xs={12} >
                            <span>
                            Maestro: 
                            </span>
                            <span className="small-modal-data">
                            {token.alphaHitsMaestro && token.alphaHitsMaestro >= 0?
                            token.alphaHitsMaestro
                            :
                            "-"
                            }
                            </span>
                        </Grid> */}
                        <Grid container xs={12} justifyContent='flex-end'>
                            <span className='small-modal-headings'>
                                Num Calls:
                            </span>
                            <span className='small-modal-data'  >
                            {token.parsedAlphaCallChannels ?
                                token.parsedAlphaCallChannels.length : '-'
                            }
                            </span>
                        </Grid>
                        <Grid textAlign='right' className='small-modal-headings' xs={12} >
                            <span>
                                Holders:
                            </span>
                            <span className="small-modal-data">
                            {token.holdersCount && token.holdersCount >= 0 ?
                                token.holdersCount
                                :
                                '-'
                            }
                        </span>
                        </Grid>


                        {/* <Grid textAlign='right' className='card-headings'  xs={12} >
                        Banana
                        </Grid> */}

                                    {/* <Grid textAlign='right' className='card-data'  xs={12} >
                            {token.alphaHitsMaestro && token.alphaHitsMaestro >= 0?
                            <>
                            {getBananaNumber(token.alphaBanana)}
                            </>
                            :
                            <>
                                -
                            </>
                            }
                        </Grid> */}
                                    {/* <Grid textAlign='right' className='small-modal-headings'  xs={12} >
                            <span>
                            Can Buy / Sell?:
                            </span>
                            <span className="small-modal-data">
                            {getCanBuySell(token.canBuy)} / {getCanBuySell(token.canSell)} 
                            </span>
                        </Grid> */}

                        <Grid textAlign='right' className='small-modal-headings' xs={12}>
                            <span>Buy Tax %: </span>
                            <span className='small-modal-data  '>
                            {token.taxesBuy?
                                token.taxesBuy:
                                '-'
                            }
                            </span>
                        </Grid>
                    
                        <Grid textAlign='right' className='small-modal-headings' xs={12}>
                            <span>Sell Tax %: </span>
                            <span className='small-modal-data ' >
                            {token.taxesSell?
                                token.taxesSell:
                                '-'
                            }
                            </span>
                        </Grid>
                        <Grid textAlign='right' className='small-modal-headings' xs={12} >
                            <span>Max Wallet %:</span>
                            <span className='small-modal-data'>
                            {
                                getPct(token.maxWalletPct)
                            }
                            </span>
                        </Grid>

                        <Grid textAlign='right' className='small-modal-headings' xs={12} >
                            <span>Max Wallet: </span>
                            <span className='small-modal-data' onClick={() => handleCopyText(token.maxWalletAmount)} >
                            {
                                truncateTxAmt(token.maxWalletAmount)
                            }
                            </span>
                        </Grid>


                        <Grid textAlign='right' className='small-modal-headings' xs={12} >
                            Max Tx %:
                            <span className="small-modal-data">
                            {
                                getPct(token.maxTxPct)
                            }
                            </span>
                        </Grid>

                        <Grid textAlign='right' className='small-modal-headings' xs={12} >
                            <span>Max Tx:</span>
                            <span className='small-modal-data' onClick={() => handleCopyText(token.maxTxAmount)} >
                            {
                                truncateTxAmt(token.maxTxAmount)
                            }
                            </span>
                        </Grid>
                        <Grid  container xs={12} justifyContent='flex-end'>
                            <span  className='small-modal-headings'>
                                Scam %:
                            </span>
                            <span className='small-modal-data' style={{color: getScamColor(token.totalCopiesCount, token.scamsCount), fontSize:'10pt'}}>
                            {token.totalCopiesCount && token.scamsCount?
                                getScamPercent(token.totalCopiesCount, token.scamsCount)
                            :
                            '-'
                            }
                            {token.warningMessage &&
                                <span style={{marginLeft:token.warningMessage?'5px':'auto'}}>
                                |
                                {isMobile?

                                <div
                                onClick={()=>Demodal.open( FieldModal, {fieldTitle:'Warning', fieldData: token.warningMessage })} 
                                
                                className='warning-icon'  
                                style={{ display:'inline-block', width:'14px', height:'14px', margin:'0px',  padding:'0px', marginLeft:'5px', justifyContent:'center', alignItems:'center', verticalAlign:'center'}}
                                />
                                :
                                <Tooltip title={<div style={{maxWidth:'300px'}}>{token.warningMessage}</div>} placement="top" arrow>
                                    <div 
                                    className='warning-icon'  
                                    style={{ display:'inline-block', width:'14px', height:'14px', margin:'0px',  padding:'0px', marginLeft:'5px', justifyContent:'center', alignItems:'center', verticalAlign:'center'}}
                                    />
                                </Tooltip>
                                }
                                </span>
                            }
                            </span>
                        </Grid>

                        <Grid textAlign='right' className='small-modal-headings' xs={12} >
                            <span>Liquidity V2 (Eth):</span>
                            <span className='small-modal-data'>
                            {token.liquidityV2?
                                parseFloat(token.liquidityV2).toFixed(4): '-'
                            }
                            </span>
                        </Grid>

                        <Grid textAlign='right' className='small-modal-headings' xs={12} >
                            <span>Liquidity V3 (Eth):</span>
                            <span className='small-modal-data'>
                            {token.liquidityV3?
                                parseFloat(token.liquidityV3).toFixed(4): '-'
                            }
                            </span>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid container xs={12} justifyContent='space-between' flexDirection='row' flexGrow={1} sx={{ marginTop: '0px' }}>
                    <Grid className='card-data' justifyContent='center' container xs={12} sx={{ marginBottom: '10px!important' }}>
                        <Grid textAlign='center' className='block-sim-headings' xs={12} sx={{ marginTop: '0px', paddingTop: '0px', marginBottom: '5px' }}>
                        Simulated Block Data
                        </Grid>
                        <Grid container className="small-card-headings" style={{ textAlign: 'center', marginBottom: '5px' }}>(  #: Block Num,  B: Buy Tax %, S: Sell Tax % )</Grid >
                        {token.parsedSimulationJSON && token.parsedSimulationJSON.length > 0 ?
                        <>
                        <Table size="small" sx={{ height: '100px!important' }}>
                            <TableBody >
                            <TableRow>
                                <TableCell sx={{ lineHeight: '4pt', paddingTop: '0px', paddingBottom: '0px', color: '#ffffff', fontWeight: 'bold', paddingLeft: '2px', paddingRight: '2px', textAlign: 'center', border: '1px solid white' }}>#</TableCell>
                                {token.parsedSimulationJSON.map(block =>
                                <TableCell key={`${token.id}-${block.blockNum}-more-info`} sx={{ lineHeight: '4pt', paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center', border: '1px solid #ffffff', color: '#ffffff' }}>
                                    {block.blockNum}
                                </TableCell>
                                )}
                            </TableRow>
                            <TableRow>
                            <TableCell sx={{ lineHeight: '4pt', paddingTop: '0px', paddingBottom: '0px', color: '#ffffff', fontWeight: 'bold', paddingLeft: '2px', paddingRight: '2px', textAlign: 'center', border: '1px solid white' }}>B</TableCell>
                            {token.parsedSimulationJSON.map(block =>
                                <TableCell key={`${token.id}-${block.blockNum}-more-info`} sx={{ lineHeight: '4pt', paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center', border: '1px solid #ffffff', color: getTaxColor({ pct: block.buy }) }}>
                                <ParseDeadblockData pct={block.buy} />
                                </TableCell>
                            )}
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ lineHeight: '4pt', paddingTop: '0px', paddingBottom: '0px', color: '#ffffff', fontWeight: 'bold', paddingLeft: '2px', paddingRight: '2px', textAlign: 'center', border: '1px solid white' }}>S</TableCell>
                                {token.parsedSimulationJSON.map(block =>
                                <TableCell key={`${token.id}-${block.blockNum}-more-info`} sx={{ lineHeight: '4pt', paddingTop: '0px', paddingBottom: '0px', paddingLeft: '0px', paddingRight: '0px', textAlign: 'center', border: '1px solid #ffffff', color: getTaxColor({ pct: block.sell }) }}>
                                    <ParseDeadblockData pct={block.sell} />
                                </TableCell>
                                )}
                            </TableRow>
                            </TableBody>
                        </Table>
                        </>
                        :
                        <Grid
                        container
                        sx={{
                            fontSize: '10pt!important',
                            backgroundColor: 'black',
                            height: '100px', lineHeight: '10pt',
                            width: '100%',
                        }}
                        justifyContent='center' alignItems='center'
                        >
                            Data Not Yet Available
                        </Grid>
                    }
                    </Grid>

                    <Grid container xs={12}  justifyContent='center' flexDirection='row' flexGrow={1} style={{marginBottom:'0px!important', paddingBottom:'0px!important', marginTop:'0px!important', paddingTop:'0px!important'}}>
                        <Grid container direction='row' xs={12} justifyContent='center' alignItems='flex-start'>

                            <Grid>
                            <a className="card-links" href={`${activeChain === ChainIds.Ethereum?'https://etherscan.io':'https://basescan.org'}/token/${token.address}`}  target='_blank' rel="noreferrer noopener">
                            {activeChain === ChainIds.Ethereum?
                                'Etherscan':
                                'Basescan'
                            }  
                            </a>
                            </Grid>
                            <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
                            <Grid>
                            <a className='card-links' href={`https://dexscreener.com/${activeChain === ChainIds.Ethereum?'ethereum':'base'}/${token.address}`} target='_blank' rel="noreferrer noopener">
                                Dexscreener
                            </a>
                            </Grid>
                            <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
                            
                            <Grid>
                            
                            <a className='card-links' href={`https://www.dextools.io/app/en/${activeChain === ChainIds.Ethereum?'ether':'base'}/pair-explorer/${token.address}`} target='_blank' rel="noreferrer noopener">
                                Dextools
                            </a>
                            </Grid>     
                            
                            <Grid  >
                            {token.address && activeChain === ChainIds.Ethereum &&
                            <>
                            <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
                            <a className="card-links"  href={`https://honeypot.is/${token.chain === 'base'?'base':'ethereum'}?address=${token.address}`} target='_blank' rel="noreferrer noopener">
                                Honeypot
                            </a>
                            </>
                            }
                            {token.address && activeChain === ChainIds.Solana &&
                            <>
                            <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
                            <a className="card-links"  href={`https://rugcheck.xyz/tokens/${token.address}`} target='_blank' rel="noreferrer noopener">
                                Rugcheck
                            </a>
                            </>
                            }
                            </Grid>
                        </Grid>

                        <Grid container direction='row' xs={12} justifyContent='center' alignContent='space-evenly' >

                            {activeChain === ChainIds.Base && 
                            <>
                             <Grid>
                                <a className="card-links"  href={`https://tokensniffer.com/token/base/${token.address}`} target='_blank' rel="noreferrer noopener">
                                    Token Sniffer
                                </a>
                            </Grid>

                            <span style={{marginLeft: 5, marginRight: 5}}>|</span>

                            <Grid>
                                <a className="card-links"  href={`https://gopluslabs.io/token-security/8453/${token.address}`} target='_blank' rel="noreferrer noopener">
                                    Go Plus Labs
                                </a>
                            </Grid>

                            </>
                            }
                        </Grid>

                        <Grid container direction='row' xs={12} justifyContent='center' alignContent='space-evenly' mb='10px'>

                            <Grid>
                            <a className="card-links"  href={activeChain === ChainIds.Ethereum? `https://t.me/BananaGunSniper_bot?start=snp_marksman_${token.address}`: `https://t.me/BananaGunSolana_bot?start=snp_marksman_${token.address}`} target='_blank' rel="noreferrer noopener">
                                Banana
                            </a>
                            </Grid>

                            <span style={{marginLeft: 5, marginRight: 5}}>|</span>

                            <Grid>
                            <a className="card-links"  href={activeChain === ChainIds.Ethereum? `https://t.me/SigmaTrading4_bot?start=x6272345274-${token.address}`: `https://t.me/SigmaTrading4_bot?start=x6272345274-${token.address}-base`} target='_blank' rel="noreferrer noopener">
                                Sigma
                            </a>
                            </Grid>

                            <span style={{marginLeft: 5, marginRight: 5}}>|</span>

                            {activeChain === ChainIds.Base && 
                            <>
                            <Grid>
                                <a className="card-links"  href={`https://photon-base.tinyastro.io/en/r/@marksman/${token.address}`} target='_blank' rel="noreferrer noopener">
                                Photon
                                </a>
                            </Grid>
                            </>
                            }

                            {/*
                            <Grid>
                            <a className="card-links"  href={`https://t.me/MaestroProBot?start=${token.address}`} target='_blank' rel="noreferrer noopener">
                                Maestro Pro
                            </a>
                            </Grid>
                            <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
                            <Grid>
                            <a className="card-links"  href={`https://t.me/MaestroSniperBot?start=${token.address}`} target='_blank' rel="noreferrer noopener">
                                Maestro
                            </a>
                            </Grid> 
                            <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
                            */}
                            {activeChain === ChainIds.Ethereum &&
                            <Grid>
                                <a className="card-links"  href={`https://app.uniswap.org/#/swap?outputCurrency=${token.address}`} target='_blank' rel="noreferrer noopener">
                                    Uniswap
                                </a>
                            </Grid>
                            }

                        </Grid>
                    </Grid>
                </Grid>
            </Card>
        </Dialog>     
    )
  })

