import { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import { 
  Grid,
} from "@mui/joy"

import { useAppSelector, useAppDispatch } from "../../app/hooks"
import { 
  TokenData,
  selectSearchedTokens,
  searchTokenByNameOrAddressAsync,
  selectUserId,
  selectNonce,
  selectMarksInWallet,
  selectSolanaSubscriptionExpirationDate,
} from "../token-scan/tokenSlice"
import { TokenCard } from "../token-scan/components/ethereum/TokenCard"
import { SingleTokenCard } from "./components/SingleTokenCard"
import moment from "moment"
import { useWindowDimensions } from "../../api/hooks/windowDimentionProvider"
import Footer from "../footer/Footer"
import { v4 as uuidv4 } from 'uuid';
import { ChainIds } from "../token-scan/tokenSlice"
import { SolanaTokenCard } from "../token-scan/components/solana/SolanaTokenCard"
import { userHasAccess, userHasEthereumAccess, userHasSolanaAccess } from "../../utilities/utilities"
import { ObfuscatedSolanaCard } from "../token-scan/components/solana/ObfuscatedSolanaCard"
import { ObfuscatedCard } from "../token-scan/components/ethereum/ObfuscatedCard"


// import {TableRows, MonitorHeart, GridView, Check, Clear, Radar} from 'react-icons/md'


export enum ViewModes  {
  Table = 1,
  Grid,
}

export enum DataSources  {
  Live = 1,
  Tracked,
}



moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s:  'seconds',
    ss: '%ss',
    m:  '1m',
    mm: '%dm',
    h:  '1h',
    hh: '%dh',
    d:  'a day',
    dd: '%dd',
    M:  'a month',
    MM: '%dM',
    y:  'a year',
    yy: '%dY'
  }
});


export function SingleToken() {

    const {chain, id} = useParams();
    const {width, height} = useWindowDimensions()
    
    const searchedTokens = useAppSelector(selectSearchedTokens)
    const userId = useAppSelector(selectUserId)
    const nonce = useAppSelector(selectNonce)
    const marksInWallet = useAppSelector(selectMarksInWallet)
    const solanaSubscriptionExpirationDate = useAppSelector(selectSolanaSubscriptionExpirationDate)
    

    const [isMobile, setIsMoble] = useState(false)

    const dispatch = useAppDispatch()
    
    useEffect(() => { 
      if(id && id.length > 0){
        dispatch({type:'tokens/clearSearchedTokens'})
        handleSearchAsync({searchString:id?id:''})
      }
      return () => {}
    },[id, userId, nonce, chain]);


    useEffect(() => {     
      if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
        || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.slice(0,4))) { 
        setIsMoble(true);
      }
    },[]);


    const handleSearchAsync = async ({searchString}:{searchString:string}) => {
      console.log(`chain: ${chain} id: ${id}`)
      await dispatch(searchTokenByNameOrAddressAsync({searchString:searchString, id: userId, nonce: nonce, chain: parseInt(chain? chain: '1')}))
    }

    const getCardWidth = () => {
      if(width > 450){
          return '400px!important'
      }
      else if(width > 340){
          return '320px!important'
      }
      else{
          return '260px!important'
      }
    }

    const DisplayCard = ({row}:{row:TokenData}) => {
      return(
        <Grid  key={uuidv4()}>
          {chain && parseInt(chain) === ChainIds.Ethereum &&
            <TokenCard token={row} cardWidth={getCardWidth()} isMobile={isMobile} />
          }
          {chain && parseInt(chain) === ChainIds.Solana &&
            <SolanaTokenCard token={row} cardWidth={getCardWidth()} isMobile={isMobile} />
          }
          {chain && parseInt(chain) === ChainIds.Base &&
            <TokenCard token={row} cardWidth={getCardWidth()} isMobile={isMobile} />
          }    
        </Grid>
      )
    }

    const DisplayObfuscatedCard = ({row}:{row:TokenData}) => {
      return(
        <Grid  key={uuidv4()}>
          {chain && parseInt(chain) === ChainIds.Ethereum &&
            <ObfuscatedCard token={row} cardWidth={getCardWidth()} isMobile={isMobile} />
          }
          {chain && parseInt(chain) === ChainIds.Solana &&
            <ObfuscatedSolanaCard token={row} cardWidth={getCardWidth()} isMobile={isMobile} />
          }    
        </Grid>
      )
    }

    const DisplayTheCardsBro = ({token}:{token: TokenData}) => {
      if(  (chain === ChainIds.Ethereum.toString() || chain === ChainIds.Base.toString())){
        //if(userHasEthereumAccess({marksInWallet:marksInWallet})){
          return (
            <TokenCard token={token} cardWidth={getCardWidth()} isMobile={isMobile} />
          )
        //}
        // else{
        //   return (
        //     <ObfuscatedCard token={token} cardWidth={getCardWidth()} isMobile={isMobile} />
        //   )
        // }
      }
      else if(chain === ChainIds.Solana.toString()){
        //if(userHasSolanaAccess({marksInWallet:marksInWallet, solanaSubscriptionExpirationDate: solanaSubscriptionExpirationDate})){
          return (
            <SolanaTokenCard token={token} cardWidth={getCardWidth()} isMobile={isMobile} />
          )
        //}
        // else{
        //   return (
        //     <ObfuscatedSolanaCard token={token} cardWidth={getCardWidth()} isMobile={isMobile} />
        //   )
        // }
      }
    }

    return (

      <Grid container  spacing={2} justifyContent='center'  alignSelf='center' alignItems='center' xs={12} md={12}  style={{flexGrow: 1, margin:'auto', padding:'10px', height:'80vh', marginTop:'64px'}}>         
        <Grid container columns={{xs:12, sm: 12, md: 12, lg: 12}} spacing={2} >
          {searchedTokens && chain &&
            searchedTokens.map((token:TokenData)=>(
              <Grid key={`${token.id}`}>
                <DisplayTheCardsBro token={token}/>
              </Grid>
            ))
          }
        </Grid>
      
        <Grid xs={12} sx={{marginTop:"20px", marginBottom:"20px"}}>
            <Footer/>
        </Grid>
      </Grid>
    )
}
