import { 
    Dialog, Popover 
} from '@mui/material'
import { 
    Grid,
    Card,
    Tooltip,
} from "@mui/joy"

import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'
import moment from 'moment';
import { LiquidityArrayT } from '../tokenSlice';
import { isDeadAddress } from '../../../utilities/utilities';



export const LockedModal = Demodal.create(
    (
        {parsedLiquidityArray, anchor}:{parsedLiquidityArray: LiquidityArrayT, anchor: Element}
    ) => {

    const lockedModal = useModal()

    return (
        <Popover
            {...muiDialog(lockedModal)}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
        >
            <Card className='modal-bg' style={{ padding: '10px', border: '1px solid rgba(255,255,255,0.2)', borderRadius:'0px', filter: 'drop-shadow(0px 0px 15px black)', overflowY: 'scroll',  minHeight:'10vh', alignItems:'center', justifyContent:'center' }}>
            <div style={{padding:'5px'}}>
                {parsedLiquidityArray?.locked_detail?
                    <table>
                        <thead>
                            <tr className="table-header">
                            <th className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                Locker
                            </th>
                            <th className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                Lock<br/>Date
                            </th>
                            <th className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                Unlock<br/>Date
                            </th>
                            <td className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                Lock or<br/>Burn %
                            </td>
                            </tr>
                        </thead>
                        <tbody>
                            {parsedLiquidityArray.locked_detail.map((lockDetail, index)=>
                            <tr key={index} className="table-rows" style={{textAlign:'center', justifyContent:'center', alignItems:'center'}}>
                                <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                {parsedLiquidityArray?.tag && parsedLiquidityArray.tag !== ''?
                                parsedLiquidityArray.tag
                                : 
                                <>
                                {parsedLiquidityArray?.address && isDeadAddress({address:parsedLiquidityArray.address})?
                                    'Null Address'
                                    :
                                    '-'
                                }
                                </>
                                }
                                </td>

                                
                                <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                {lockDetail && lockDetail.opt_time?
                                moment(lockDetail.opt_time).format('llll').toLocaleString(): '-'
                                }
                                </td>
                            
                            
                                <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                {lockDetail && lockDetail.end_time?
                                moment(lockDetail.end_time).format('llll').toLocaleString(): '-'
                                }
                                </td>
                        
                                <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                {parsedLiquidityArray?.percent?
                                (parsedLiquidityArray.percent * 100 ).toFixed(2): '-'
                                }
                                </td>
                            </tr>
                            
                            )

                            }
                        </tbody>
                    </table>
                    :
                    <table>
                        <thead>
                            <tr className="table-header">
                            <th className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                Locker
                            </th>
                            <th className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                Lock<br/>Date
                            </th>
                            <th className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                Unlock<br/>Date
                            </th>
                            <td className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                Lock or<br/>Burn %
                            </td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="table-rows" style={{textAlign:'center', justifyContent:'center', alignItems:'center'}}>
                                <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                {parsedLiquidityArray?.tag && parsedLiquidityArray.tag !== ''?
                                parsedLiquidityArray.tag
                                : 
                                <>
                                {parsedLiquidityArray?.address && isDeadAddress({address:parsedLiquidityArray.address})?
                                    'Null Address'
                                    :
                                    '-'
                                }
                                </>
                                }
                                </td>

                                
                                <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                -
                                </td>
                            
                            
                                <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                -
                                </td>
                        
                                <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                                {parsedLiquidityArray?.percent?
                                (parsedLiquidityArray.percent * 100 ).toFixed(2): '-'
                                }
                                </td>
                            </tr>
                        </tbody>
                    </table>
                }
                </div>
                
            </Card>
        </Popover>
    )
  })

