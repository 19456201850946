import { Tooltip } from "@mui/joy"
import { Demodal, useModal } from "demodal"
import { Caller } from "../../tokenSlice"
import { muiDialog } from "demodal/material-ui"
import moment from "moment"
import { Popover } from "@mui/material"

function convertToInternationalCurrencySystem(value: number) {
  // Nine Zeroes for Billions
  return Math.abs(value) >= 1.0e9
    ? (Math.abs(value) / 1.0e9).toFixed(2) + "B"
    : // Six Zeroes for Millions
    Math.abs(value) >= 1.0e6
    ? (Math.abs(value) / 1.0e6).toFixed(2) + "M"
    : // Three Zeroes for Thousands
    Math.abs(value) >= 1.0e3
    ? (Math.abs(value) / 1.0e3).toFixed(2) + "K"
    : Math.abs(value)
}

export const SolanaMcapTooltip = Demodal.create(
  ({
    symbol,
    mCap,
    mcapAt,
    liquidityV2,
    anchor
  }: {
    symbol: string
    mCap: number
    mcapAt: number
    liquidityV2: string | null,
    anchor: Element
  }) => {
    const mcapTooltip = useModal()

    return (
        <Popover
            {...muiDialog(mcapTooltip)}
            anchorEl={anchor}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            
        >
            <div style={{ padding: "5px", backgroundColor:'black' }}>
                <table>
                <tbody>
                    <tr style={{ justifyContent: "space-between" }}>
                    <td className="small-card-headings">Market Cap:</td>
                    <td className="small-card-data">
                        {mCap ? "$" + Number(mCap).toLocaleString() : "-"}
                    </td>
                    </tr>
                    <tr style={{ justifyContent: "space-between" }}>
                    <td className="small-card-headings">Time:</td>
                    <td className="small-card-data">
                        {mcapAt
                        ? moment(mcapAt).format("llll").toLocaleString()
                        : "-"}
                    </td>
                    </tr>
                    <tr style={{ justifyContent: "space-between" }}>
                    <td className="small-card-headings">Liquidity (Sol):</td>
                    <td className="small-card-data">
                        {liquidityV2 ? parseFloat(liquidityV2).toFixed(4) : "-"}
                    </td>
                    </tr>
                </tbody>
                </table>
            </div>
        </Popover>
    )
  },
)
