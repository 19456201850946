import { createRef } from 'react';
import {
    Dialog,
    Popover
} from '@mui/material'
import {
    Grid,
    Card,
} from "@mui/joy"

import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'

import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import {
    ViewModes,
    LaunchedTypes,
    selectRequestSource,
    selectPrevRequestSource,
    selectPrevSearchQuery,
    selectArchivePageSize,
    selectCurrentPage,
    selectDefaultViewMode,
    selectUserId,
    selectUser,
    selectDefaultPageSize,
    selectDefaultFilterMode,
    selectViewMode,
    setDefaultViewMode,
    setFilterMode,
    setDefaultPageSize,
    NUM_LIVE_DATA_ENTRIES
} from "../tokenSlice"


export const SettingsModal = Demodal.create(
    (
        { anchor: anchor }: { anchor: Element }
    ) => {

        const settingsModal = useModal()
        const requestedSource = useAppSelector(selectRequestSource)
        const prevSearchQuery = useAppSelector(selectPrevSearchQuery)
        const prevRequestedSource = useAppSelector(selectPrevRequestSource)
        const archivePageSize = useAppSelector(selectArchivePageSize)
        const currentPage = useAppSelector(selectCurrentPage)
        const userId = useAppSelector(selectUserId)
        const pageSize = useAppSelector(selectDefaultPageSize)
        const defaultViewMode = useAppSelector(selectDefaultViewMode)
        const defaultFilterMode = useAppSelector(selectDefaultFilterMode)
        const viewMode = useAppSelector(selectViewMode)
        const user = useAppSelector(selectUser)

        const dispatch = useAppDispatch()

        // const handleSourceChange = async (newValue: DataSources) => {
        //     await setLiveOrTracked(newValue)
        //     await settingsModal.close()
        // }

        const handleSwitchToLaunchedType = async (newValue: LaunchedTypes) => {
            if (user && user.id !== undefined && user.address !== undefined && user.filterMode !== newValue) {
                await dispatch(setFilterMode({ filterMode: newValue, id: userId })).unwrap()
            }
            await dispatch({ type: "tokens/switchToLaunchedType", payload: newValue })
            //setRequestedSource(newValue)
            await settingsModal.close()
        }

        const handleSwitchViewMode = async (newValue: ViewModes) => {
            if (user && user.id !== undefined && user.address !== undefined && user.viewMode !== newValue) {
                await dispatch(setDefaultViewMode({ viewMode: newValue, id: userId })).unwrap()
            }
            await dispatch({ type: "tokens/setViewMode", payload: newValue })

            //setRequestedSource(newValue)
            settingsModal.close()
        }

        const handleViewModeChange = (
            newValue: ViewModes
        ) => {

            handleSwitchViewMode(newValue)
            settingsModal.close()
        };



        const handleSwitchArchivePageSize = async (pageSize: number) => {
            if (user && user.id !== undefined && user.address !== undefined) {
                await dispatch(setDefaultPageSize({ pageSize: pageSize, id: userId })).unwrap()
            }
            await dispatch({ type: "tokens/switchArchivePageSize", payload: pageSize })
            settingsModal.close()
        }

        return (

            <Popover
                {...muiDialog(settingsModal)}
                anchorEl={anchor}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                sx={{

                }}

            >

                <Grid container justifyContent='space-evenly' xs={12} sx={{ padding: '10px', backgroundColor: '#000000', color: '#ffffff', borderRadius: '0px' }}>

                    <Grid xs={12} direction='row' className='card-headings' textAlign='center'>
                        View
                    </Grid>
                    <Grid direction='column' container  alignItems='center' justifyContent='center'>

                        <Grid textAlign='center' sx={{ backgroundColor: '#000000', borderRadius: '0px', color: 'white' }}>
                            Grid
                        </Grid>
                        <Grid
                            alignItems='center' justifyContent='center'
                            xs={12}
                            sx={{

                                height: '24px',
                                width: '24px',
                            }}
                            direction='column'
                            className={`top-icons grid-icon-bg ${viewMode === ViewModes.Grid ? 'active' : ''}`}
                            onClick={() => { viewMode === ViewModes.Table ? handleViewModeChange(ViewModes.Grid) : {} }}
                        />
                    </Grid>
                    <Grid direction='column' container alignItems='center' justifyContent='center'>

                        <Grid textAlign='center' sx={{ backgroundColor: '#000000', borderRadius: '0px', color: 'white' }}>
                            Table
                        </Grid>
                        <Grid
                            alignItems='center' justifyContent='center'
                            sx={{
                                height: '24px',
                                width: '24px',
                            }}
                            xs={12}
                            direction='column'
                            className={`top-icons table-icon-bg ${viewMode === ViewModes.Table ? 'active' : ''}`}
                            onClick={() => { viewMode === ViewModes.Grid ? handleViewModeChange(ViewModes.Table) : {} }}
                        />
                    </Grid>
                </Grid>

                <Grid container justifyContent='space-between' xs={12} sx={{ backgroundColor: '#000000', padding: '10px', borderRadius: '0px' }}>
                    <Grid xs={12} direction='row' className='card-headings' textAlign='center'>
                        Filters
                    </Grid>

                    {/* <Grid  xs={12} direction='row' className='card-headings' textAlign='center'>
                    Status
                </Grid> */}
                    <Grid direction='column' container alignItems='center' justifyContent='center'>
                        <Grid textAlign='center' sx={{ color: 'white' }}>
                            Live
                        </Grid>
                        <Grid
                            xs={12}
                            sx={{
                                height: '24px',
                                width: '24px',
                            }}
                            direction='column'
                            className={`top-icons launched-icon-bg ${requestedSource === LaunchedTypes.Launched ? 'active' : ''}`}
                            onClick={() => { requestedSource !== LaunchedTypes.Launched ? handleSwitchToLaunchedType(LaunchedTypes.Launched) : {} }}
                        />
                    </Grid>

                    <Grid direction='column' container alignItems='center' justifyContent='center'>
                        <Grid textAlign='center' sx={{ color: 'white' }}>
                            Not Live
                        </Grid>
                        <Grid
                            xs={12}
                            sx={{
                                height: '24px',
                                width: '24px',
                            }}
                            direction='column'
                            className={`top-icons unlaunched-icon-bg ${requestedSource === LaunchedTypes.Unlaunched ? 'active' : ''}`}
                            onClick={() => { requestedSource !== LaunchedTypes.Unlaunched ? handleSwitchToLaunchedType(LaunchedTypes.Unlaunched) : {} }}
                        />
                    </Grid>

                    <Grid direction='column' container alignItems='center' justifyContent='center'>
                        <Grid textAlign='center' sx={{ color: 'white' }}>
                            All
                        </Grid>
                        <Grid
                            xs={4}
                            sx={{
                                height: '24px',
                                width: '24px',
                            }}
                            direction='column'
                            className={`top-icons all-icon-bg ${requestedSource === LaunchedTypes.All ? 'active' : ''}`}
                            onClick={() => { requestedSource !== LaunchedTypes.All ? handleSwitchToLaunchedType(LaunchedTypes.All) : {} }}
                        />
                    </Grid>
                </Grid>

                <Grid container justifyContent='space-between' xs={12} sx={{ backgroundColor: '#000000', padding: '10px', borderRadius: '0px' }}>
                    <Grid xs={12} direction='row' className='card-headings' textAlign='center'>
                        Archive Page Size
                    </Grid>

                    <Grid
                        xs={3}
                        direction='column'
                        textAlign='center'
                        className={`archive-size-buttons ${archivePageSize === 10 ? 'active' : ''}`}
                        onClick={() => { archivePageSize !== 10 ? handleSwitchArchivePageSize(10) : {} }}
                    >
                        10
                    </Grid>
                    <Grid
                        xs={3}
                        direction='column'
                        textAlign='center'
                        className={`archive-size-buttons ${archivePageSize === 20 ? 'active' : ''}`}
                        onClick={() => { archivePageSize !== 20 ? handleSwitchArchivePageSize(20) : {} }}
                    >
                        20
                    </Grid>
                    <Grid
                        xs={3}
                        direction='column'
                        textAlign='center'
                        className={`archive-size-buttons ${archivePageSize === 50 ? 'active' : ''}`}
                        onClick={() => { archivePageSize !== 50 ? handleSwitchArchivePageSize(50) : {} }}
                    >
                        50
                    </Grid>
                    <Grid
                        xs={3}
                        direction='column'
                        textAlign='center'
                        className={`archive-size-buttons ${archivePageSize === 100 ? 'active' : ''}`}
                        onClick={() => { archivePageSize !== 100 ? handleSwitchArchivePageSize(100) : {} }}
                    >
                        100
                    </Grid>
                </Grid>
            </Popover>
        )
    })

