import { memo } from "react"
import { 
    Grid,
    Tooltip,
} from "@mui/joy"

import { useAppSelector, useAppDispatch } from "../../../../app/hooks"

import { 
    handleCopyText,
    truncateAddress,
    isTokenBeyondThreshold,
} from "../../../../utilities/utilities"

import { v4 as uuidv4 } from 'uuid'
import { TokenData, selectUser } from "../../tokenSlice"

import moment from "moment"


export const ObfuscatedSolanaRow = memo(({row, isMobile}: {row:TokenData, isMobile: boolean}) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)


    const handleTrackToggle = ({id}:{id:number}) => {
        dispatch({type:"tokens/toggleTrackingForToken", payload:id})
    }

    
    return(
      <tr className='table-rows' key={uuidv4()} style={{opacity:isTokenBeyondThreshold(row, user)? '0.5':'1'}}>

        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>

        <td>
          <Tooltip title={row.symbol} placement='top' arrow sx={{maxWidth:'320px'}}>
            <div>
            {row.symbol &&
              row.symbol.substring(0,8) //.substring(0, 12) 
            }
            </div>
          </Tooltip>
        </td>
      
        <td>
          <Tooltip title={row.name} placement='top' arrow sx={{maxWidth:'320px'}}>
            <div >
              {row.name &&
                row.name.substring(0,8)
              }
            </div>
          </Tooltip>
        </td>
        
        <Tooltip title='Copy Address' placement='top' arrow>
          <td className="copyable-table-field" onClick={()=>handleCopyText(row.address)} style={{color: '#4a91ff'}}>
            {row.address &&
              truncateAddress(row.address)
            }
          </td>
        </Tooltip>

        <td>
          <Grid container   flexShrink={1} justifyContent='center' alignItems='center'>
            <Grid  xs={5}>
              {row.address &&
                <a href={`https://solscan.io/token/${row.address}`} target='_blank' rel="noreferrer noopener">
                  <div  className='solscan-link' />  
                </a>
              }
            </Grid>
            
            <Grid xs={5}>
                <a  href={`https://dexscreener.com/solana/${row.address}`} target='_blank' rel="noreferrer noopener">
                  <div  className='chart-link' />  
                </a>
              {/* </Tooltip> */}
            </Grid>
            
            <Grid xs={5}>
              <a  href={`https://rugcheck.xyz/tokens/${row.address}`} target='_blank' rel="noreferrer noopener">
                <div  className='rugcheck-link' />  
              </a>
            </Grid>

            <Grid xs={5}>
              <a href={`https://www.dextools.io/app/en/solana/pair-explorer/${row.address}`}  target='_blank' rel="noreferrer noopener">
                <div className='dextools-link' />  
              </a>
            </Grid>
          </Grid>
        </td>

        <td>
          <Grid container   flexShrink={1} justifyContent='center' alignItems='center'>
            
            <Grid  xs={5}>
              {row.address &&
                <a href={`https://t.me/solana_unibot?start=r-marksmantools-${row.address}`} target='_blank' rel="noreferrer noopener">
                  <div  className='unibot-link' />  
                </a>
              }
            </Grid>

            <Grid  xs={5}>
              {row.address &&
                <a href={`https://t.me/BananaGunSolana_bot?start=snp_marksman_${row.address}`} target='_blank' rel="noreferrer noopener">
                  <div  className='banana-link' />  
                </a>
              }
            </Grid>

            <Grid xs={5}>
                <a  href={`https://raydium.io/swap/?outputCurrency=${row.address}&inputCurrency=sol`} target='_blank' rel="noreferrer noopener">
                    <div  className='raydium-link' />  
                </a>
            </Grid>
            <Grid xs={5}>
                <a href={`https://t.me/bonkbot_bot?start=ref_3th28_ca_${row.address}`}   target='_blank' rel="noreferrer noopener">
                    <div  className='bonk-link' />  
                </a>
            </Grid>


            {/* <Grid xs={5}>
            {row.address &&
              <a  href={`https://t.me/MaestroProBot?start=${row.address}`} target='_blank' rel="noreferrer noopener">
                <div  className='maestro-pro-link' />  
              </a>
            }
            </Grid>

            <Grid xs={5}>
            {row.address &&
              <a href={`https://t.me/MaestroSniperBot?start=${row.address}`} target='_blank' rel="noreferrer noopener">
                <div className='maestro-link' />  
              </a>
            }
            </Grid> */}
          </Grid>
        </td>

        <td>
          <Tooltip title={ `${moment(row.createdAt).format('llll').toLocaleString()}` } placement="top" arrow>
            <Grid>
            {row.createdAt &&
              moment(row.createdAt).fromNow()
            }
            </Grid>
          </Tooltip>
        </td>
        
        {/* <td style={{fontSize:'12pt', color: getSniperColorTable({score: row.sniperScore})}}>
        {
          returnDataOrObfuscated( {data: row.sniperScore})
        }
        </td> */}

        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>

      
        {/* 
          <td >
          <Tooltip title='Open in Maestro' arrow placement='top'>

            <a  style={{ color: 'inherit', textDecoration:'none' }} href={`https://t.me/MaestroProBot?start=${row.address}`} target='_blank' rel="noreferrer noopener">
              <div className='copyable-table-field'>

              { row.alphaHitsMaestro ?
                row.alphaHitsMaestro :' -'
              }
              </div>
            </a>
          </Tooltip>
          </td> 
        */}

          {/* <td >
          <Tooltip title='Open in Banana' arrow placement='top'>

            <a  style={{ color: 'inherit', textDecoration:'none' }} href={`https://t.me/BananaGunSniper_bot?start=${row.address}`} target='_blank' rel="noreferrer noopener">
              <div className='copyable-table-field'>

              { getBananaScore(row) &&
                getBananaScore(row) 
              }
              </div>
            </a>
          </Tooltip>
          </td>

        */}

          {/* <td >
          {row.parsedSecurityDetails.owner_transfer_pct?
              row.parsedSecurityDetails.owner_transfer_pct
              :
              'fuck'
          }
          </td>

          <td >
          {row.parsedSecurityDetails.owner_hold_pct?
              row.parsedSecurityDetails.owner_hold_pct
              :
              'fuck'
          }
          </td> */}

          {/* <td >
          { getCanBuySell(row.canBuy) } / { getCanBuySell(row.canSell) }
          </td> */}
          {/* 
          {userHasAccess()?
          <td >
            <Grid container sx={{width:'100%'}} justifyContent='center' alignItems='center' textAlign='center'>
              <Tooltip title={`(Num Scams: ${row.scamsCount} / Checksum Matches: ${!row.totalCopiesCount || row.totalCopiesCount === 0?1:row.totalCopiesCount}`} arrow placement="top">
                <div style={{textAlign:'center', color: getScamColor(!row.totalCopiesCount || row.totalCopiesCount === 0?1:row.totalCopiesCount, row.scamsCount)}}>
                  {getScamPercent(!row.totalCopiesCount || row.totalCopiesCount === 0?1:row.totalCopiesCount, row.scamsCount)}
                </div>
              </Tooltip>
              {row.warningMessage &&
                <div style={{marginLeft:row.warningMessage?'5px':'auto'}}>
                {isMobile?
                  <div
                  onClick={()=>Demodal.open( FieldModal, {fieldTitle:'Warning', fieldData: row.warningMessage })} 
                  className='warning-icon'  
                  style={{ display:'inline-block', width:'14px', height:'14px', margin:'0px',  padding:'0px', marginLeft:'5px', justifyContent:'center', alignItems:'center', verticalAlign:'center'}}
                  />
                  :
                  <Tooltip title={<div style={{maxWidth:'300px'}}>{row.warningMessage}</div>} placement="top" arrow>
                    <div 
                      className='warning-icon'  
                      style={{ display:'inline-block', width:'14px', height:'14px', margin:'0px',  padding:'0px', marginLeft:'5px', justifyContent:'center', alignItems:'center', verticalAlign:'center'}}
                    />
                  </Tooltip>
                }
                </div>
              }
            </Grid>
          </td>
          :
          <td>
            &#128274;
          </td>
        } 
        */}
      </tr>
    )
  })