import React, { useState, useEffect, useCallback } from "react"
import { 
    Grid,
    Card,
    Tooltip,
    TooltipProps,
    tooltipClasses,
    Modal,
    ModalDialog
} from "@mui/joy"
import {
    Table,
    TableCell,
    TableRow,
    TableBody, 
    TableContainer, 
    Checkbox, 
    TableSortLabel, 
    Box, 
    Input
} from '@mui/material'
import { useAppSelector, useAppDispatch } from "../../../../app/hooks"

import { 
    handleCopyText,
    convertToInternationalCurrencySystem,
    truncateAddress,
    truncateTxAmt,
    getScoreColor,
    getScoreColorTable,
    getSniperColorTable,
    getPct,
    ParseDeadblockData,
    getTaxColor,
    getScamColor,
    getScamPercent,
    isTokenBeyondThreshold,
    returnDataOrObfuscated,
    isLiquidityLocked,
    isLiquidityBurned,
    DEAD_ADDRESS_1,
    DEAD_ADDRESS_2,
    isDeadAddress,
    userHasEthereumAccess
} from "../../../../utilities/utilities"

import { v4 as uuidv4 } from 'uuid'
import { TokenData, selectUser, selectMarksInWallet, selectActiveChain, ChainIds } from "../../tokenSlice"
import {Demodal} from 'demodal'
import moment from "moment"

import { CallerModal } from "../CallerModal"
import { McapModal } from "../McapModal"
import { McapTooltip } from "../McapTooltip"
import { FieldModal } from "../FieldModal"
import { MoreInfoModal } from "../MoreInfoModal"



export const ObfuscatedRow = React.memo(({row, isMobile}: {row:TokenData, isMobile: boolean}) => {
  const dispatch = useAppDispatch()
  const user = useAppSelector(selectUser)
  const activeChain = useAppSelector(selectActiveChain)

  const handleTrackToggle = ({id}:{id:number}) => {
      dispatch({type:"tokens/toggleTrackingForToken", payload:id})
  }

  
  return(
    <tr className='table-rows' key={uuidv4()} style={{opacity:isTokenBeyondThreshold(row, user)? '0.5':'1'}}>
    

        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>

        <td>
            <Tooltip title={row.symbol} placement='top' arrow sx={{maxWidth:'320px'}}>
            <div>
            {row.symbol &&
                row.symbol.substring(0,8) //.substring(0, 12) 
            }
            </div>
            </Tooltip>
        </td>
        
        <td>
            <Tooltip title={row.name} placement='top' arrow sx={{maxWidth:'320px'}}>
            <div >
                {row.name &&
                row.name.substring(0,8)
                }
            </div>
            </Tooltip>
        </td>
        
        <Tooltip title='Copy Address' placement='top' arrow>
            <td className="copyable-table-field" onClick={()=>handleCopyText(row.address)} style={{color: '#4a91ff'}}>
            {row.address &&
                truncateAddress(row.address)
            }
            </td>
        </Tooltip>

        <td >
            <Grid container   flexShrink={1} justifyContent='center' alignItems='center'>
            <Grid  xs={5}>
                {row.address &&
            
                <a href={`https://etherscan.io/token/${row.address}`} target='_blank' rel="noreferrer noopener">
                    <div  className='scan-link' />  
                </a>
                }
            </Grid>
            
            <Grid xs={5}>
                <a  href={`https://dexscreener.com/ethereum/${row.address}`} target='_blank' rel="noreferrer noopener">
                    <div  className='chart-link' />  
                </a>
                {/* </Tooltip> */}
            </Grid>
            
            <Grid xs={5}>
            {row.address &&
                <a  href={`https://honeypot.is/${row.chain === 'base'?'base':'ethereum'}?address=${row.address}`} target='_blank' rel="noreferrer noopener">
                <div  className='honeypot-link' />  
                </a>
            }
            </Grid>

            <Grid xs={5}>
            
                <a href={`https://www.dextools.io/app/en/${activeChain === ChainIds.Ethereum?'ether':'solana'}/pair-explorer/${row.address}`}  target='_blank' rel="noreferrer noopener">
                <div className='dextools-link' />  
                </a>
            
            </Grid>
            </Grid>
        </td>

        <td >
            <Grid container   flexShrink={1} justifyContent='center' alignItems='center'>
            <Grid  xs={5}>
                {row.address &&
                <a href={`https://t.me/BananaGunSniper_bot?start=snp_marksman_${row.address}`}   target='_blank' rel="noreferrer noopener">
                    <div  className='banana-link' />  
                </a>
                }
            </Grid>

            <Grid xs={5}>
                <a  href={`https://app.uniswap.org/#/swap?outputCurrency=${row.address}`} target='_blank' rel="noreferrer noopener">
                <div  className='uniswap-link' />  
                </a>
                {/* </Tooltip> */}
            </Grid>

            {/* <Grid xs={5}>
            {row.address &&
                <a  href={`https://t.me/MaestroProBot?start=${row.address}`} target='_blank' rel="noreferrer noopener">
                <div  className='maestro-pro-link' />  
                </a>
            }
            </Grid>

            <Grid xs={5}>
            {row.address &&
                <a href={`https://t.me/MaestroSniperBot?start=${row.address}`} target='_blank' rel="noreferrer noopener">
                <div className='maestro-link' />  
                </a>
            }
            </Grid> */}
            </Grid>
        </td>

        <td>
            <Tooltip title={ `${moment(row.createdAt).format('llll').toLocaleString()}` } placement="top" arrow>
            <Grid>
            {row.createdAt &&
                moment(row.createdAt).fromNow()
            }
            </Grid>
            </Tooltip>
        </td>
        

        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>
        <td>&#128274;</td>


      {/* 
        <td >
        <Tooltip title='Open in Maestro' arrow placement='top'>

          <a  style={{ color: 'inherit', textDecoration:'none' }} href={`https://t.me/MaestroProBot?start=${row.address}`} target='_blank' rel="noreferrer noopener">
            <div className='copyable-table-field'>

            { row.alphaHitsMaestro ?
              row.alphaHitsMaestro :' -'
            }
            </div>
          </a>
        </Tooltip>
        </td> 
      */}

      {/* <td >
        <Tooltip title='Open in Banana' arrow placement='top'>

          <a  style={{ color: 'inherit', textDecoration:'none' }} href={`https://t.me/BananaGunSniper_bot?start=${row.address}`} target='_blank' rel="noreferrer noopener">
            <div className='copyable-table-field'>

            { getBananaScore(row) &&
              getBananaScore(row) 
            }
            </div>
          </a>
        </Tooltip>
        </td>

      */}

      {/* <td >
      { getCanBuySell(row.canBuy) } / { getCanBuySell(row.canSell) }
      </td> */}
    </tr>
  )
})