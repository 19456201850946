import React from "react";
import { Grid } from "@mui/joy";
import copy from "copy-to-clipboard"
import { AuthState, TokenData, LockedDetail, SortTypes, LiquidityArrayT, ChainIds, selectMarksInWallet, selectSolanaSubscriptionExpirationDate, selectActiveChain, selectEthereumTrackedTokens, selectSolanaTrackedTokens } from "../features/token-scan/tokenSlice";
import { SUPPORTED_CHAINS } from "../chainConfig";
import EthereumIcon from '../icons/eth-icon.png'
import SolanaIcon from '../icons/solana-icon.png'
import BaseIcon from '../icons/base-icon.png'
import moment, { Moment } from "moment";
import { useAppSelector } from "../app/hooks";

export const truncateAddress = (addr:string) => {

    if (addr === undefined || addr === null)
        return "";

    var processedAddr = "";
    processedAddr = processedAddr.concat(addr)

    return processedAddr.substring(0,5) + ".." + processedAddr.substring(processedAddr.length - 3, processedAddr.length)
  }

  export const truncateTxAmt = (amt:string) => {

    if (amt === undefined || amt === null)
        return " -";

    var processedAmt = "";
    processedAmt = amt.length> 7? processedAmt.concat(amt).substring(0,7) + ".." : amt

    return processedAmt
}


export const getScoreColor = ({score}: {score:number}) => {
    if(score === 0){
      return 'rgb( 125, 125, 125)'
    }
    else if( score <= 60){
      return 'rgba(255, 0, 0, .6)'
    }
    else if(score <= 100){
      return 'rgba(180, 180, 0, .8)'
    }
    else if(score < 125){
      return `rgba(80, 150, 0, .8)`
    }
    else if(score >= 125){
      return `rgba(0, 255, 0, .7)`
    }
    else if(score < 0){
      return 'rgb(255, 0, 0)'
    }
    else{
      return 'rgb(125, 125, 125)'
    }
}


export const getScoreColorTable = ({score}: {score:number}) => {
    if(score === 0){
      return 'rgb( 255, 255, 255)'
    }
    else if( score <= 60){
      return 'rgba(255, 0, 0, 1)'
    }
    else if(score <= 100){
      return 'rgba(180, 180, 0, 1)'
    }
    else if(score < 125){
      return `rgba(80, 150, 0, 1)`
    }
    else if(score >= 125){
      return `#25ff25`
    }
    else if(score < 0){
      return 'rgb(255, 0, 0)'
    }
    else{
      return 'rgb(255, 255, 255)'
    }
}

export const getSniperColorTable = ({score}: {score:number}) => {
  if(score < 50){
    return 'rgb( 255, 0, 0)'
  }
  else if( score >= 50 && score < 60){
    return 'rgb(255, 255, 0)'
  }
  else if(score >= 60 && score < 90){
    return 'rgb(0, 255, 0)'
  }
  else if(score >= 90 ){
    return `rgb(255, 0, 0)`
  }
  else{
    return 'rgb(255, 255, 255)'
  }
}

  
  export const getPct = (pct:string) =>{
    if( pct && (pct === null || pct.length === 0 || pct === undefined || !pct || pct === "-%" )){
      return '-'
    }
    else{
      return pct
    } 
  }


export const ParseDeadblockData = ({pct:pct}:  {pct:any}) => {
    if(pct === 'Fail'){
        return  <div className='clear-icon-bg'  style={{display:'inline-flex'}}/>
    }
    else if (pct.length >= 1){
        return pct.replace('%','')
    }
    else{
        return  <Grid container className='clear-icon-bg'  style={{display:'inline-flex', justifyContent:'center', alignItems:'center', textAlign:'center'}}/>
    }
}


export const getTaxColor = ({pct:pct}:{pct:any}) => {
    const pctAsNumber = parseFloat(pct.replace('%',''))
    if(pctAsNumber <= 5 ){
        return '#15ff15'
    }
    else if(pctAsNumber >= 60 ){
        return '#ff1515'
    }
    else{
        return '#ffffff'
    }
}

export function convertToInternationalCurrencySystem (value:number) {

    // Nine Zeroes for Billions
    return Math.abs((value)) >= 1.0e+9

    ? (Math.abs((value)) / 1.0e+9).toFixed(2) + "B"
    // Six Zeroes for Millions 
    : Math.abs((value)) >= 1.0e+6

    ? (Math.abs((value)) / 1.0e+6).toFixed(2) + "M"
    // Three Zeroes for Thousands
    : Math.abs((value)) >= 1.0e+3

    ? (Math.abs((value)) / 1.0e+3).toFixed(2) + "K"

    : Math.abs((value));

}

export const handleCopyText = (address:string) => {
    copy(address)
  }

export const getScamPercent = (total:number, scams:number) => {
    return ((((scams?scams:0 )/(total?total:1)) * 100)).toFixed(2)
}

export const getScamColor = (total:number, scams:number) => {
    const fraction = (scams/total) 

    if(fraction * 100 > 90){
      return 'rgb(255, 0, 0)'
    }
    else if(fraction * 100 > 80){
      return 'rgb(255, 70, 25)'
    }
    else if(fraction * 100 > 70){
      return 'rgb(255, 140, 25)'
    }
    else if(fraction * 100 > 60){
      return 'rgb(255, 210, 25)'
    }
    else if(fraction * 100 > 50){
      return 'rgb(255, 255, 25)'
    }
    else if(fraction * 100 > 40){
      return 'rgb(210, 255, 25)'
    }
    else if(fraction * 100 > 30){
      return 'rgb(140, 255, 25)'
    }
    else if(fraction * 100 > 20){
      return 'rgb(70, 255, 25)'
    }
    else if(fraction * 100 > 10){
      return 'rgb(0, 255, 25)'
    }
    else if(fraction * 100 > 0){
      return 'rgb( 35, 255, 25)'
    }
    else if(fraction * 100 === 0){
      return 'rgb(0, 255, 0)'
    }


    return `rgba(${fraction*255},  ${fraction * 255} , 0, 1)`
}


export const isMobileDevice = () => {
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.slice(0,4))) { 
      return true;
    }
    return false;
}


export enum ThresholdError{
  Sniper= 0,
  Marksman,
  Caller,

}



// temporarily commented until dashboard filters are made available
export const isTokenBeyondThreshold = (token:TokenData, user: AuthState) => {
  //var errorList:ThresholdError[] = []

  // if((token.parsedAlphaCallChannels.length < user.callerThreshold) && (user.callerThreshold !== 0)){
  //   return true
  // }

  // if((token.alphaScore < user.marksmanNotificationThreshold) && (user.marksmanNotificationThreshold !== 0)){
  //   return true
  // }

  // if((token.sniperScore < user.notificationThreshold) && (user.notificationThreshold !== 0)){
  //   return true
  // }
  
  return false;
}



export const sortData = ({sortBy, data}:{sortBy:SortTypes, data:TokenData[]}) => {
  var sortedData:TokenData[] = [...data]
  if(sortBy === SortTypes.CreationDateDescending) {
    sortedData = sortedData.sort((a,b) => {
        if(a.createdAt < b.createdAt )
            return 1

        else if(a.createdAt > b.createdAt )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.CreationDateAscending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a.createdAt > b.createdAt )
            return 1

        else if(a.createdAt < b.createdAt )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.AddressDescending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a.address.toLowerCase() < b.address.toLowerCase() )
            return 1

        else if(a.address.toLowerCase() > b.address.toLowerCase() )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.AddressAscending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a.address.toLowerCase() > b.address.toLowerCase() )
            return 1

        else if(a.address.toLowerCase() < b.address.toLowerCase() )
            return -1

        else return 0
    })
  }
  
  if(sortBy === SortTypes.TokenSymbolDescending) {
    sortedData = sortedData.sort((a,b) => {
        if(a.symbol.toLowerCase() < b.symbol.toLowerCase() )
            return 1

        else if(a.symbol.toLowerCase() > b.symbol.toLowerCase() )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.TokenSymbolAscending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a.symbol.toLowerCase() > b.symbol.toLowerCase() )
            return 1

        else if(a.symbol.toLowerCase() < b.symbol.toLowerCase() )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.TokenNameDescending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a?.name?.toLowerCase() < b?.name?.toLowerCase() )
            return 1

        else if(a?.name?.toLowerCase() > b?.name?.toLowerCase() )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.TokenNameAscending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a?.name?.toLowerCase() > b?.name?.toLowerCase() )
            return 1

        else if(a?.name?.toLowerCase() < b?.name?.toLowerCase() )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.MaxTxDescending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a?.maxTxPct?.toLowerCase() < b?.maxTxPct?.toLowerCase() )
            return 1

        else if(a?.maxTxPct?.toLowerCase() > b?.maxTxPct?.toLowerCase() )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.MaxTxAscending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a?.maxTxPct?.toLowerCase() > b?.maxTxPct?.toLowerCase() )
            return 1

        else if(a?.maxTxPct?.toLowerCase() < b?.maxTxPct?.toLowerCase() )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.MaxWalletDescending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a?.maxWalletPct?.toLowerCase() < b?.maxWalletPct?.toLowerCase() )
            return 1

        else if(a?.maxWalletPct?.toLowerCase() > b?.maxWalletPct?.toLowerCase() )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.MaxWalletAscending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a?.maxWalletPct?.toLowerCase() > b?.maxWalletPct?.toLowerCase() )
            return 1

        else if(a?.maxWalletPct?.toLowerCase() < b?.maxWalletPct?.toLowerCase() )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.BuyTaxDescending) {
    sortedData =  sortedData.sort((a,b) => {
      return b.taxesBuy - a.taxesBuy
    })
  }

  if(sortBy === SortTypes.BuyTaxAscending) {
    sortedData =  sortedData.sort((a,b) => {
      return a.taxesBuy - b.taxesBuy
    })
  }

  if(sortBy === SortTypes.SellTaxDescending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a.taxesSell < b.taxesSell )
            return 1

        else if( a.taxesSell > b.taxesSell )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.SellTaxAscending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a.taxesSell > b.taxesSell )
            return 1

        else if(a.taxesSell < b.taxesSell )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.Buys24hDescending) {
    sortedData =  sortedData.sort((a,b) => {
      return b.buys24h - a.buys24h
    })
  }

  if(sortBy === SortTypes.Buys24hAscending) {
    sortedData =  sortedData.sort((a,b) => {
      return a.buys24h - b.buys24h
    })
  }


  if(sortBy === SortTypes.Sells24hDescending) {
    sortedData =  sortedData.sort((a,b) => {
      return b.sells24h - a.sells24h
    })
  }

  if(sortBy === SortTypes.Sells24hAscending) {
    sortedData =  sortedData.sort((a,b) => {
      return a.sells24h - b.sells24h
    })
  }

  if(sortBy === SortTypes.OwnerHoldPercentDescending) {
    sortedData =  sortedData.sort((a,b) => {
      return  (b.parsedSecurityDetails.owner_hold_pct?b.parsedSecurityDetails.owner_hold_pct:0) - (a.parsedSecurityDetails.owner_hold_pct?a.parsedSecurityDetails.owner_hold_pct:0)
    })
  }

  if(sortBy === SortTypes.OwnerHoldPercentAscending) {
    sortedData =  sortedData.sort((a,b) => {
      return  (a.parsedSecurityDetails.owner_hold_pct?a.parsedSecurityDetails.owner_hold_pct:0) - (b.parsedSecurityDetails.owner_hold_pct?b.parsedSecurityDetails.owner_hold_pct:0)
    })
  }

  if(sortBy === SortTypes.OwnerTransferPercentDescending) {
    sortedData =  sortedData.sort((a,b) => {
      return  (b.parsedSecurityDetails.owner_transfer_pct?b.parsedSecurityDetails.owner_transfer_pct:0) - (a.parsedSecurityDetails.owner_transfer_pct?a.parsedSecurityDetails.owner_transfer_pct:0)
    })
  }

  if(sortBy === SortTypes.OwnerTransferPercentAscending) {
    sortedData =  sortedData.sort((a,b) => {
      return  (a.parsedSecurityDetails.owner_transfer_pct?a.parsedSecurityDetails.owner_transfer_pct:0) - (b.parsedSecurityDetails.owner_transfer_pct?b.parsedSecurityDetails.owner_transfer_pct:0)
    })
  }

  if(sortBy === SortTypes.ScamPctDescending) {
    sortedData =  sortedData.sort((a,b) => {
      let aScamPct = (a.scamsCount / (a.totalCopiesCount + 1)) * 100
      let bScamPct = (b.scamsCount / (b.totalCopiesCount + 1)) * 100
        if(aScamPct < bScamPct )
            return 1

        else if(aScamPct > bScamPct )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.ScamPctAscending) {
    sortedData =  sortedData.sort((a,b) => {
      let aScamPct = (a.scamsCount / (a.totalCopiesCount + 1)) * 100
      let bScamPct = (b.scamsCount / (b.totalCopiesCount + 1)) * 100
        if(aScamPct > bScamPct )
            return 1

        else if(aScamPct < bScamPct )
            return -1

        else return 0
    })
  }

  // if(sortBy === SortTypes.MaestroDescending) {
  //   sortedData =  sortedData.sort((a,b) => {
  //       if(a.alphaHitsMaestro < b.alphaHitsMaestro )
  //           return 1

  //       else if(a.alphaHitsMaestro > b.alphaHitsMaestro )
  //           return -1

  //       else return 0
  //   })
  // }

  // if(sortBy === SortTypes.MaestroAscending) {
  //   sortedData =  sortedData.sort((a,b) => {
  //       if(a.alphaHitsMaestro > b.alphaHitsMaestro )
  //           return 1

  //       else if(a.alphaHitsMaestro < b.alphaHitsMaestro )
  //           return -1

  //       else return 0
  //   })
  // }

  if(sortBy === SortTypes.HoldersDescending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a.holdersCount < b.holdersCount )
            return 1

        else if(a.holdersCount > b.holdersCount )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.HoldersAscending) {
    sortedData =  sortedData.sort((a,b) => {
        if(a.holdersCount > b.holdersCount )
            return 1

        else if(a.holdersCount < b.holdersCount )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.AlphaScoreDescending) {
    sortedData =  sortedData.sort((a,b) => {

        if(a.alphaScore < b.alphaScore )
            return 1

        else if(a.alphaScore > b.alphaScore )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.AlphaScoreAscending) {
    sortedData =  sortedData.sort((a,b) => {

        if(a.alphaScore > b.alphaScore )
            return 1

        else if(a.alphaScore < b.alphaScore )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.SniperScoreDescending) {
    sortedData =  sortedData.sort((a,b) => {

        if(a.sniperScore < b.sniperScore )
            return 1

        else if(a.sniperScore > b.sniperScore )
            return -1

        else return 0
    })
  }

  if(sortBy === SortTypes.SniperScoreAscending) {
    sortedData =  sortedData.sort((a,b) => {

        if(a.sniperScore > b.sniperScore )
            return 1

        else if(a.sniperScore < b.sniperScore )
            return -1

        else return 0
    })
  }

  if (sortBy === SortTypes.NumCallsDescending) {
    sortedData = sortedData.sort((a, b) => {

      if (a.parsedAlphaCallChannels.length < b.parsedAlphaCallChannels.length)
        return 1

      else if (a.parsedAlphaCallChannels.length > b.parsedAlphaCallChannels.length)
        return -1

      else return 0
    })
  }

  if (sortBy === SortTypes.NumCallsAscending) {
    sortedData = sortedData.sort((a, b) => {

      if (a.parsedAlphaCallChannels.length > b.parsedAlphaCallChannels.length)
        return 1

      else if (a.parsedAlphaCallChannels.length < b.parsedAlphaCallChannels.length)
        return -1

      else return 0
    })
  }
  if(sortBy === SortTypes.MarketCapDescending) {
    sortedData =  sortedData.sort((a,b) => {
      return b.mCap - a.mCap
    })
  }

  if(sortBy === SortTypes.MarketCapAscending) {
    sortedData =  sortedData.sort((a,b) => {
      return a.mCap - b.mCap
    })
  }
  return sortedData
}



export const isLiquidityLocked = ({liquidityArray}:{liquidityArray: LiquidityArrayT}) => {
  if(liquidityArray && ((liquidityArray.is_locked === 1) || (!!liquidityArray.tag))){
    return true
  }
  else{
    return false
  }
}

export const isLiquidityBurned = ({liquidityArray}:{liquidityArray: LiquidityArrayT}) => {
  if(liquidityArray && ((liquidityArray.address === DEAD_ADDRESS_1) || (liquidityArray.address === DEAD_ADDRESS_2) ||
  (liquidityArray.address == DEAD_ADDRESS_3))){
    return true
  }
  else{
    return false
  }
}



export const DEAD_ADDRESS_1 = '0x0000000000000000000000000000000000000000'

export const DEAD_ADDRESS_2 = '0x000000000000000000000000000000000000DEAD'

export const DEAD_ADDRESS_3 = '0x000000000000000000000000000000000000dead'


export const isDeadAddress = ({address}:{address:string}) =>{
  if(
    (address == DEAD_ADDRESS_1) ||
    (address == DEAD_ADDRESS_2) ||
    (address == DEAD_ADDRESS_3)
  )
  {
    return true;
  }
  return false;
}

export const getChainIcon = (chainId: number) => {
  if(chainId === ChainIds.Ethereum){
    return EthereumIcon
  }
  else if (chainId ===  ChainIds.Solana){
    return SolanaIcon
  }
  else if (chainId ===  ChainIds.Base){
    return BaseIcon
  }
}

export const shouldDisablePrevButtons = (currentPage: number) => {
  if(currentPage <= 1){
    return true;
  }
  else{
    return false;
  }
}

export const checkifNumber = (input:any) => {
  const regex = /^[0-9\b]+$/;
  if (input === "" || regex.test(input)) {
      return true
  }
  else{
      return false
  }
}



export const returnDataOrObfuscated = ({data}:{data: any | undefined})=> {
  

  if( data){
    return data
  }
  else{
    return '-'
  }
}

export const userHasEthereumAccess = ({marksInWallet}:{marksInWallet: number}) => {
    
  return true
}

export const userHasSolanaAccess = ({marksInWallet, solanaSubscriptionExpirationDate}:{marksInWallet: number, solanaSubscriptionExpirationDate: string | undefined}) => {
  
  
  return true
}

export const userHasAccess = ({activeChain, marksInWallet, solanaSubscriptionExpirationDate}:{activeChain:ChainIds, marksInWallet: number, solanaSubscriptionExpirationDate: string | undefined}) => {
    
  return true
}

/*

The code below goes through the actual licensing flow.
It is currently being removed to remove token gating

export const userHasEthereumAccess = ({marksInWallet}:{marksInWallet: number}) => {
    
  if( marksInWallet && marksInWallet >= 500){
    return true
  }
  else{
    return false
  }
}

export const userHasSolanaAccess = ({marksInWallet, solanaSubscriptionExpirationDate}:{marksInWallet: number, solanaSubscriptionExpirationDate: string | undefined}) => {
  
  
  if( marksInWallet && marksInWallet >= 500){
    return true
  }
  else if( moment().isBefore(moment(solanaSubscriptionExpirationDate)) ){
    return true
  }
  else{
    return false
  }
}

export const userHasAccess = ({activeChain, marksInWallet, solanaSubscriptionExpirationDate}:{activeChain:ChainIds, marksInWallet: number, solanaSubscriptionExpirationDate: string | undefined}) => {
    
  if(activeChain === ChainIds.Ethereum){
    return userHasEthereumAccess({marksInWallet: marksInWallet})
  }
  else if(activeChain === ChainIds.Solana){
    return userHasSolanaAccess({marksInWallet:marksInWallet, solanaSubscriptionExpirationDate: solanaSubscriptionExpirationDate})
  }
  else{
    return false
  }
}


export const returnDataOrObfuscated = ({data}:{data: any | undefined})=> {
  
  const marksInWallet = useAppSelector(selectMarksInWallet)
  if( (!marksInWallet || (marksInWallet && marksInWallet < 500))){
    return <>&#128274;</>
  }
  else if( data){
    return data
  }
  else{
    return '-'
  }
}

*/