import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState, AppThunk } from "../../app/store"
import axios from "axios"
import moment, { Moment } from "moment"

import { Address } from "wagmi"
import { zeroAddress } from "viem"
import { fetchBalance } from '@wagmi/core'
import { SiweMessage } from "siwe"
import { Root } from "react-dom/client"



const DEFAULT_FETCH_COUNT = 20;
const MAX_ALLOWED_TRACKS = 15;
const DEFAULT_PAGE_SIZE = 50;
const MAX_TIMEOUT = 20000 //ms

//Testing
// const EndPoint = 'http://localhost:11000/api'
// const RootUrl = 'http://localhost:5173'

//Azure Deployment
const EndPoint = 'https://hwsrv-1133553.hostwindsdns.com/api'
const RootUrl = 'https://marksman.pro'

export const NUM_LIVE_DATA_ENTRIES = 50; 

export type TgHandle = `@${string}`


export enum ChainIds{
  Ethereum = 1,
  Base = 8453,
  Solana = 65537
}


export interface AuthState {
  id: number | undefined,
  jwt: string,
  address: Address | undefined,
  marksInWallet: number,
  pageSize: number,
  trackedTokens: string[],
  trackedTokenIds: number[],
  trackedEthereumTokenData: TokenData[],
  trackedSolanaTokenData: TokenData[],
  trackedBaseTokenData: TokenData[],
  trackingData: TrackedToken[],
  maxAllowedTracks: number,
  error: string,
  nonce: string,
  status: 'idle' | 'loading' | 'failed',
  isAuthorized: boolean,
  currentAuthStep: AuthStep,
  message: string | undefined,
  signature: string,
  tgHandle: string | undefined,
  notificationThreshold: number,
  marksmanNotificationThreshold: number,
  sniperNotificationThresholdSOL: number,
  marksmanNotificationThresholdSOL: number,
  callerNotificationThresholdSOL: number,
  marksmanNotificationThresholdBASE: number,
  callerNotificationThresholdBASE: number,
  //buyTaxThreshold: number,
  //sellTaxThreshold: number,
  viewMode: ViewModes,
  //scamThreshold: number,
  callerThreshold: number,
  swapAddress:Address | undefined,
  filterMode: LaunchedTypes,
  errorMessage: string,
  solanaSubscriptionExpirationDate: string | undefined,
  activeChain: ChainIds
}

export enum ViewModes  {
  Table = 1,
  Grid,
}

export enum DataSources  {
  Live = 1,
  Tracked,
}


export enum AuthStep {
Idle = 0,
GetNonce,
GetSignature,
WaitingForSignature,
CheckAuth,
GetUserData,
Authorized,
Failed,
AccountChanged

}


export enum SortTypes  {
  CreationDateAscending = 1,
  CreationDateDescending,
  AddressAscending,
  AddressDescending,
  TokenNameAscending,
  TokenNameDescending,
  TokenSymbolAscending,
  TokenSymbolDescending,
  AlphaHitsAscending,
  AlphaHitsDescending,
  MaxTxAscending,
  MaxTxDescending,
  MaxWalletAscending,
  MaxWalletDescending,
  BuyTaxAscending,
  BuyTaxDescending,
  SellTaxAscending,
  SellTaxDescending,
  ScamPctAscending,
  ScamPctDescending,
  MaestroAscending,
  MaestroDescending,
  HoldersAscending,
  HoldersDescending,
  AlphaScoreAscending,
  AlphaScoreDescending,
  SniperScoreAscending,
  SniperScoreDescending,
  NumCallsAscending,
  NumCallsDescending,
  MarketCapAscending,
  MarketCapDescending,
  ApprovalsDescending,
  ApprovalsAscending,
  Buys24hDescending,
  Buys24hAscending,
  Sells24hDescending,
  Sells24hAscending,
  OwnerTransferPercentDescending,
  OwnerTransferPercentAscending,
  OwnerHoldPercentDescending,
  OwnerHoldPercentAscending
}

type FetchBalanceResult = {
  decimals: number;
  formatted: string;
  symbol: string;
  value: bigint;
};

export enum LaunchedTypes {
  Launched = 1,
  Unlaunched,
  All
}

export const defaultToken: TokenData = {
  id: 0,
  address: "",
  symbol: "",
  chain: "",
  createdAt: "",
  pairAddress: '',
  updatedAt: "",
  launchedAt: "",
  swapRouter: "",
  swapName: "",
  alphaHitsProdigy: 0,
  canBuy: '',
  canSell: '',
  taxes: "",
  taxesSell: 0,
  taxesBuy: 0,
  deployerAddress: zeroAddress,
  isTracked: false,
  alphaHitsMaestro: 0,
  alphaBanana: "",
  name: "",
  approvalsCount: 0,
  maxTxPct: "string",
  maxWalletPct: "string",
  totalCopiesCount: 0,
  scamsCount: 0,
  deadBlocks: 0,
  supplyAmount: '',
  maxTxAmount:  '',
  maxWalletAmount:  '',
  sniperScore: 0,
  alphaScore: 0,
  simulationJSON: '',
  parsedSimulationJSON: [],
  ethInWallet: '0',
  alphaCallChannels: '',
  parsedAlphaCallChannels: [],
  socialLinks: '',
  warningMessage: '',
  mCap: 0,
  mcapAt: 0,
  liquidityV2: null,
  liquidityV3: null,
  holdersCount: 0,
  isRenounced: 0,
  liquidityArray: null,
  parsedLiquidityArray: null,
  contractVerified: false,
  buys24h: 0,
  sells24h: 0,
  parsedSecurityDetails: {
    mint_authority_revoked: false,
    liquidity_burnt_pct: 0,
    owner_transfer_pct: 0,
    owner_hold_pct: 0
  },
  securityArray: null
}

export enum PageSizeOptions {
  Ten = 1,
  Twenty,
  Fifty,
  Hundred
}


export const initialAuthState: AuthState = {
  id: undefined,
  jwt: '',
  marksInWallet: 0,
  pageSize: 50,
  trackedTokens: [],
  trackedTokenIds: [],
  trackingData: [],
  trackedEthereumTokenData: [],
  trackedSolanaTokenData: [],
  trackedBaseTokenData: [],
  maxAllowedTracks: 15,
  error: '',
  status: 'idle',
  nonce: '',
  address: undefined,
  isAuthorized: false,
  currentAuthStep: AuthStep.Idle,
  signature: '',
  message: undefined,
  tgHandle: undefined,
  notificationThreshold: 0,
  marksmanNotificationThreshold: 0,
  sniperNotificationThresholdSOL: 0,
  marksmanNotificationThresholdSOL: 0,
  callerNotificationThresholdSOL: 0,
  marksmanNotificationThresholdBASE: 0,
  callerNotificationThresholdBASE: 0,
  //buyTaxThreshold: 30,
  //sellTaxThreshold: 30,
  viewMode: ViewModes.Table,
  //scamThreshold: 40,
  callerThreshold: 0,
  swapAddress: undefined,
  filterMode: LaunchedTypes.All,
  errorMessage: '',
  activeChain: ChainIds.Ethereum,
  solanaSubscriptionExpirationDate: undefined //moment().add(7, 'd').format('ddd, DD MMM YYYY HH:mm:ss ZZ').toString(),
}

export interface TrackedToken {
  userId: number,
  tokenId: number,
  id: number,
}

export interface TokenState {
  archivedData: TokenData[],
  liveUpdatedData: TokenData[],
  trackedTokens: TokenData[],
  searchedTokens: TokenData[],
  archivedTokenIds: number[],
  liveUpdatedTokenIds: number[],
  trackedTokenIds: number[],

  solanaArchivedData: TokenData[],
  solanaLiveUpdatedData: TokenData[],
  solanaTrackedTokens: TokenData[],
 // solanaSearchedTokens: TokenData[],
  solanaArchivedTokenIds: number[],
  solanaLiveUpdatedTokenIds: number[],
  solanaTrackedTokenIds: number[],


  baseArchivedData: TokenData[],
  baseLiveUpdatedData: TokenData[],
  baseTrackedTokens: TokenData[],
 // baseSearchedTokens: TokenData[],
  baseArchivedTokenIds: number[],
  baseLiveUpdatedTokenIds: number[],
  baseTrackedTokenIds: number[],

  status: "idle" | "loading" | "failed",
  sortBy: SortTypes,
  prevSortBy: SortTypes,
  nextTokenEntry: number,
  timeOfLastLoad: string,
  isRequestFromTimedFetch: boolean,
  userMaxAllowedTracks: number,
  tokenDataSource: LaunchedTypes,
  prevTokenDataSource: LaunchedTypes,
  prevSearchQuery: string,
  userSelectedPageSize: number,
  currentPage: number,
  solanaCurrentPage: number,
  baseCurrentPage: number,
  user: AuthState,
  viewMode: ViewModes
}


// state to persist in cookies
export const paths = {
  session: {
    name: 'session'
  },
  'tokens.user.nonce': {
    name: 'user_nonce',
    //equalityCheck: isEqual
  },
  'tokens.user.address': {
    name: 'user_address',
    //equalityCheck: isEqual
  },
  'tokens.user.id': {
    name: 'user_id',
    //equalityCheck: isEqual
  },
  'tokens.user.pageSize': {
    name: 'user_page_size',
    //equalityCheck: isEqual
  },
};


const initialState: TokenState = {
  archivedData: [],
  solanaArchivedData: [],
  baseArchivedData: [],
  liveUpdatedData: [],
  solanaLiveUpdatedData: [],
  baseLiveUpdatedData: [],
  trackedTokens: [],
  solanaTrackedTokens: [],
  baseTrackedTokens: [],
  searchedTokens: [],
  archivedTokenIds: [],
  solanaArchivedTokenIds: [],
  baseArchivedTokenIds: [],
  liveUpdatedTokenIds: [],
  solanaLiveUpdatedTokenIds: [],
  baseLiveUpdatedTokenIds: [],
  trackedTokenIds: [],
  solanaTrackedTokenIds: [],
  baseTrackedTokenIds: [],
  status: "idle",
  tokenDataSource: LaunchedTypes.All,
  prevTokenDataSource: LaunchedTypes.All,
  sortBy: SortTypes.CreationDateDescending,
  prevSortBy: SortTypes.CreationDateDescending,
  nextTokenEntry: 20,
  timeOfLastLoad: moment().format('yyyy-MM-DD HH:mm:ss'),
  isRequestFromTimedFetch: false,
  userMaxAllowedTracks: MAX_ALLOWED_TRACKS,
  prevSearchQuery: '',
  userSelectedPageSize: DEFAULT_PAGE_SIZE,
  currentPage: 1,
  solanaCurrentPage: 1,
  baseCurrentPage: 1,
  user: initialAuthState,
  viewMode: ViewModes.Table
}

export interface Caller {
  channelUsername: string
  score: number
  firstCalled: string
  mCap: string
  callUrl: string
  channelDisplayName: string
}

export interface TokenData {
  id: number
  address: string
  symbol: string
  name: string
  chain: string
  pairAddress: string
  createdAt: string
  updatedAt: string
  launchedAt: string
  swapRouter: string
  swapName: string
  alphaHitsProdigy: number
  alphaHitsMaestro: number
  alphaBanana: string
  approvalsCount: number
  canBuy: string
  canSell: string
  taxes: string
  taxesBuy: number
  taxesSell: number
  deployerAddress: Address
  isTracked: boolean
  maxTxPct: string
  maxWalletPct: string
  totalCopiesCount: number
  scamsCount: number
  deadBlocks: number
  supplyAmount: string
  maxTxAmount: string
  maxWalletAmount: string
  sniperScore: number
  alphaScore: number
  simulationJSON: string
  parsedSimulationJSON: Block[]
  ethInWallet: string
  alphaCallChannels: string
  parsedAlphaCallChannels: Caller[],
  socialLinks: string,
  warningMessage: string,
  mCap: number,
  mcapAt: number,
  liquidityV2: string | null,
  liquidityV3: string | null,
  holdersCount: number,
  isRenounced: number,
  liquidityArray: string | null,
  parsedLiquidityArray: LiquidityArrayT | null,
  contractVerified : boolean | undefined,
  buys24h: number,
  sells24h: number,
  securityArray: string | null,
  parsedSecurityDetails: SecurityDetails,


}

export interface SecurityDetails {
  mint_authority_revoked: boolean | undefined,
  liquidity_burnt_pct: number | undefined,
  owner_transfer_pct: number | undefined,
  owner_hold_pct: number | undefined
}

export interface LockedDetail {
  amount: number,
  end_time: string,
  opt_time: string
}

export interface LiquidityArrayT {
  address: string,
  tag: string | null,
  value: number | null,
  balance: number | null,
  is_locked: number | null,
  locked_detail: LockedDetail[],
  percent: number | null
}

export interface Block {
  blockNum: number,
  buy: string,
  sell: string,
  transfer: string
}

const headers = {
    "Access-Control-Allow-Origin": RootUrl,
    "Content-type": "application/json",
}

const instance = axios.create();
instance.defaults.headers.common["Content-Type"] = "application/json"
instance.defaults.baseURL = EndPoint
instance.defaults.timeout = MAX_TIMEOUT
instance.defaults.headers['Access-Control-Allow-Origin'] = `${RootUrl}`


// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(fetchTokensAsync())`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched. Thunks are
// typically used to make async requests.



export const getCookiesOnServer = createAsyncThunk(
  "token/getCookieOnServer",
  async () => {
    
    const response = await axios.get(`${EndPoint}/users/getCookie`,{headers})
    return response.data
  },
)

export const setCookiesOnServer = createAsyncThunk(
  "token/setCookieOnServer",
  async () => {
    
    const response = await axios.get(`${EndPoint}/users/setCookie`,{headers})
    return response.data
  },
)

export const loginUserAsync = createAsyncThunk(
  "token/login",
  async (address:Address | undefined) => {
    const response = await axios.get(`${EndPoint}/users/login`,
      {
        params: {
          address: address
        },
        headers: {
          ...headers
        },
        timeout: MAX_TIMEOUT
      }
    )

    return response.data
  },
)

export const setActiveChain = createAsyncThunk(
  "token/setActiveChain",
  async ( {userId, activeChain, nonce}: {userId: number| undefined, activeChain: ChainIds, nonce: string}) => {

    const response = await instance.request(
    {
        method:'PUT',
        url:`/users/setActiveChain`,
        data:{
            userId: userId,
            activeChain: activeChain,
            nonce: nonce
          }
    })

    return response.data
  },
)

export const setTelegramHandleAsync = createAsyncThunk(
  "token/setTgHandle",
  async ({id, tgHandle}:{id: number | undefined, tgHandle: string}) => {
    if(!id ) return
    const response = await instance.request({
      method: 'PUT',
      url:`/users/setTgHandle`,
      data:{
        id: id,
        tgHandle: tgHandle
      }
    })

    return response.data
  },
)

export const setDefaultPageSize = createAsyncThunk(
  "token/setDefaultPageSize",
  async ( {id, pageSize}: {id: number| undefined, pageSize: number}) => {

    const response = await instance.request(
    {
        method:'PUT',
        url:`/users/setDefaultPageSize`,
        data:{
            id: id,
            pageSize: pageSize
          }
    })

    return response.data
  },
)

export const setDefaultViewMode = createAsyncThunk(
  "token/setDefaultViewMode",
  async ( {id, viewMode}: {id: number| undefined, viewMode: ViewModes}) => {

    const response = await instance.request({
      method: 'PUT',
      url:`/users/setDefaultViewMode`,
      data:{
          id: id,
          viewMode: viewMode
      }
    }
    )


    return response.data
  },
)

export const setFilterMode = createAsyncThunk(
  "token/setFilterMode",
  async ({ id, filterMode }: { id: number | undefined, filterMode: LaunchedTypes }) => {

    const response = await instance.request(
      
      {
        method: 'PUT',
        url:`/users/setFilterMode`,
        data: {
          id: id,
          filterMode: filterMode
        },
      }
    )


    return response.data
  },
)


export const setNotificationThreshold = createAsyncThunk(
  "token/setNotificationThreshold",
  async ( {id, notificationThreshold}: {id: number | undefined, notificationThreshold: number}) => {
    if(!id || notificationThreshold === undefined || notificationThreshold === null) return
    const response = await instance.request(
      {
        method: 'PUT',
        url:`/users/setNotificationThreshold`,
        data: {
          id: id,
          notificationThreshold: notificationThreshold
        },
      }
    )
    return response.data
  },
)


export const setMarksmanNotificationThreshold = createAsyncThunk(
  "token/setMarksmanNotificationThreshold",
  async ( {id, marksmanNotificationThreshold}: {id: number | undefined, marksmanNotificationThreshold: number}) => {
    if(!id || marksmanNotificationThreshold === undefined || marksmanNotificationThreshold === null) return
    const response = await instance.request(
      {
        method: 'PUT',
        url:`/users/setMarksmanNotificationThreshold`,
        data: {
          id: id,
          marksmanNotificationThreshold: marksmanNotificationThreshold
        },
      }
    )

    return response.data
  },
)

// export const setSellTaxThreshold = createAsyncThunk(
//   "token/setSellTaxThreshold",
//   async ( {id, sellTaxThreshold}: {id: number | undefined, sellTaxThreshold: number}) => {
//     if(!id || sellTaxThreshold === undefined || sellTaxThreshold === null) return
//     const response = await instance.request(
//       {
//         method: 'PUT',
//         url:`/users/setSellTaxThreshold`,
//         data: {
//           id: id,
//           sellTaxThreshold: sellTaxThreshold
//         },
//       }
//     )
//     return response.data
//   },
// )

// export const setBuyTaxThreshold = createAsyncThunk(
//   "token/setBuyTaxThreshold",
//   async ( {id, buyTaxThreshold}: {id: number | undefined, buyTaxThreshold: number}) => {
//     if(!id || buyTaxThreshold === undefined || buyTaxThreshold === null) return
//     const response = await instance.request(
//       {
//         method: 'PUT',
//         url:`/users/setBuyTaxThreshold`,
//         data: {
//           id: id,
//           buyTaxThreshold: buyTaxThreshold
//         },
//       }
//     )
//     return response.data
//   },
// )

export const setScamThreshold = createAsyncThunk(
  "token/setScamThreshold",
  async ( {id, scamThreshold}: {id: number | undefined, scamThreshold: number}) => {
    if(!id || scamThreshold === undefined || scamThreshold === null) return
    const response = await instance.request(
      {
        method: 'PUT',
        url:`/users/setScamThreshold`,
        data: {
          id: id,
          scamThreshold: scamThreshold
        }
      }
    )

    return response.data
  },
)

export const setCallerThreshold = createAsyncThunk(
  "token/setCallerThreshold",
  async ( {id, callerThreshold}: {id: number | undefined, callerThreshold: number}) => {
    if(!id || callerThreshold === undefined || callerThreshold === null) return
    const response = await instance.request(
      {
        method: 'PUT',
        url:`/users/setCallerThreshold`,
        data: {
          id: id,
          callerThreshold: callerThreshold
        }
      }
    )

    return response.data
  },
)


export const setNotificationThresholdSOL = createAsyncThunk(
  "token/setNotificationThresholdSOL",
  async ( {id, notificationThreshold}: {id: number | undefined, notificationThreshold: number}) => {
    if(!id || notificationThreshold === undefined || notificationThreshold === null) return
    const response = await instance.request(
      {
        method: 'PUT',
        url:`/users/setNotificationThresholdSOL`,
        data: {
          id: id,
          notificationThreshold: notificationThreshold
        },
      }
    )
    return response.data
  },
)


export const setMarksmanNotificationThresholdSOL = createAsyncThunk(
  "token/setMarksmanNotificationThresholdSOL",
  async ( {id, marksmanNotificationThreshold}: {id: number | undefined, marksmanNotificationThreshold: number}) => {
    if(!id || marksmanNotificationThreshold === undefined || marksmanNotificationThreshold === null) return
    const response = await instance.request(
      {
        method: 'PUT',
        url:`/users/setMarksmanNotificationThresholdSOL`,
        data: {
          id: id,
          marksmanNotificationThreshold: marksmanNotificationThreshold
        },
      }
    )

    return response.data
  },
)

export const setCallerThresholdSOL = createAsyncThunk(
  "token/setCallerThresholdSOL",
  async ( {id, callerThreshold}: {id: number | undefined, callerThreshold: number}) => {
    if(!id || callerThreshold === undefined || callerThreshold === null) return
    const response = await instance.request(
      {
        method: 'PUT',
        url:`/users/setCallerThresholdSOL`,
        data: {
          id: id,
          callerThreshold: callerThreshold
        }
      }
    )

    return response.data
  },
)

export const setMarksmanNotificationThresholdBASE = createAsyncThunk(
  "token/setMarksmanNotificationThresholdBASE",
  async ( {id, marksmanNotificationThreshold}: {id: number | undefined, marksmanNotificationThreshold: number}) => {
    if(!id || marksmanNotificationThreshold === undefined || marksmanNotificationThreshold === null) return
    const response = await instance.request(
      {
        method: 'PUT',
        url:`/users/setMarksmanNotificationThresholdBASE`,
        data: {
          id: id,
          marksmanNotificationThreshold: marksmanNotificationThreshold
        },
      }
    )

    return response.data
  },
)

export const setCallerThresholdBASE = createAsyncThunk(
  "token/setCallerThresholdBASE",
  async ( {id, callerThreshold}: {id: number | undefined, callerThreshold: number}) => {
    if(!id || callerThreshold === undefined || callerThreshold === null) return
    const response = await instance.request(
      {
        method: 'PUT',
        url:`/users/setCallerThresholdBASE`,
        data: {
          id: id,
          callerThreshold: callerThreshold
        }
      }
    )

    return response.data
  },
)

export const logout = createAsyncThunk(
  "token/logout",
  async (address:Address | undefined) => {
    const response = await axios.get(`${EndPoint}/users/logout`,
      {
        params: {
          address: address
        },
        headers: {
          ...headers
        },
        timeout: MAX_TIMEOUT
      }
    )

    return response.data
  },
)

export const getUserData = createAsyncThunk(
  "token/getUserData",
  async (address:Address | undefined) => {
    const response = await axios.get(`${EndPoint}/users/getUserData`,
      {
        params: {
          address: address
        },
        headers: {
          ...headers
        },
        timeout: MAX_TIMEOUT,
        withCredentials: false
      }
    )

    return response.data
  },
)


export const authorizeUserAsync = createAsyncThunk(
  "token/authorize",
  async ({message, signature}:{message:SiweMessage, signature:any})=> {
    
    const response = await axios.get(`${EndPoint}/users/authorize`,
    {
      params: {
        message: message.prepareMessage(),
        signature: signature
      },
      headers: {
        ...headers
      },
      timeout: MAX_TIMEOUT

    })
    return response.data
  },
)

export const fetchTokensAsync = createAsyncThunk(
  "token/fetchTokens",
  async () => {
    
    const response = await axios.get(`${EndPoint}/token/all`,{headers})
    return response.data
  },
)


export const updateArchiveAsync = createAsyncThunk(
  "token/updateArchives",

  async ({requestedSource, fetchCount, startAt}:{requestedSource:LaunchedTypes, fetchCount: number, startAt: number}) => {
 
    const response = await instance.request(
      {
        method: 'GET',
        url:`/token/updateArchive`,
        params: {
          fetchCount: fetchCount,
          requestedSource: requestedSource,
          startAt: startAt
        },
      }
    )  

    return {data: response.data}
  },
)


//
export const lazyLoadTokensAsync = createAsyncThunk(
  "token/lazyLoadTokens",

  async ({requestedPage, sortBy, prevSort, requestedSource, fetchCount, startAt, id, nonce, chain}:{requestedPage:number, sortBy:SortTypes, prevSort:SortTypes, requestedSource:LaunchedTypes, fetchCount: number, startAt: number, id: number | undefined, nonce: string | undefined, chain: ChainIds}) => {
    
    var chainString = ''

    if(chain === ChainIds.Ethereum){
      chainString = 'eth'
    }
    else if(chain === ChainIds.Solana){
      chainString = 'solana'
    }
    else if(chain === ChainIds.Base){
      chainString = 'base'
    }

    const response = await instance.request(
      
      { 
        method:'POST',
        url: `${EndPoint}/token/all`, 
        data: {
          startAt: startAt > 0 && startAt !== NUM_LIVE_DATA_ENTRIES?(NUM_LIVE_DATA_ENTRIES - (startAt )) + ((requestedPage - 1) * fetchCount ):(NUM_LIVE_DATA_ENTRIES) + ((requestedPage - 1) * fetchCount ),
          sortBy: sortBy,
          retrieveQuantity:  fetchCount,
          requestedSource: requestedSource,
          id: id,
          nonce: nonce,
          chain: chainString
        },
          headers: {
            ...headers
          },
          timeout: MAX_TIMEOUT
      }
    )  

    return {data: response.data, requestedPage: requestedPage, requestedSource: requestedSource}
  },
)

export const liveUpdateTokensAsync = createAsyncThunk(
  "token/live",

  async ({requestedSource, id, nonce, chain}:{requestedSource: LaunchedTypes, id: number | undefined, nonce: string | undefined, chain: ChainIds}) => {
    
    var chainString = ''

    if(chain === ChainIds.Ethereum){
      chainString = 'eth'
    }
    else if(chain === ChainIds.Solana){
      chainString = 'solana'
    }
    else if(chain === ChainIds.Base){
      chainString = 'base'
    }
    

    const response = await instance.request({
      method: 'POST',
      url:`/token/live`,
      data:{
        requestedSource: LaunchedTypes.All,
        fetchCount: NUM_LIVE_DATA_ENTRIES,
        id: id,
        nonce: nonce,
        chain: chainString
      }
    })

      return { data: response.data, requestedSource: requestedSource }

  },
)

export const getTokensById = createAsyncThunk(
  "token/getTokensById",

  async ({ id, nonce, trackingData}:{ id: number | undefined, nonce: string | undefined, trackingData: TrackedToken[]}) => {
    const response = await instance.request({
      method: 'POST',
      url:`/token/getTokensById`,
      data:{
        requestedSource: LaunchedTypes.All,
        fetchCount: NUM_LIVE_DATA_ENTRIES,
        id: id,
        nonce: nonce,
        tokenIds: trackingData.map((tt)=> tt.tokenId)
      }
    })

      return  response.data 

  },
)

// Testing using a different implementation approach for the request.
// export const liveUpdateTokensAsync = createAsyncThunk(
//   "token/live",
//   async ({ requestedSource }: { requestedSource:LaunchedTypes }) => {

//     const response = await instance.request(
//       {
//         method: 'GET',
//         url:`/token/live`,
//         data: {
//           requestedSource: LaunchedTypes.All,
//           fetchCount: NUM_LIVE_DATA_ENTRIES
//         },
//       }
//     )

//     return response.data
//   },
// )

export const getDeployerBalances = createAsyncThunk(
  "token/deployerBalances",

  async ({tokens}:{tokens:TokenData[]}) => {

    var liveBalances: string[] = []
    for(let i = 0; i < tokens.length; i++){

      if(!tokens[i].ethInWallet || tokens[i].ethInWallet === '0'){
        const ethBalance =  await fetchBalance({
          address: tokens[i].deployerAddress
        })
        liveBalances.push(ethBalance.formatted.substring(0,8))
      }
      else{
        liveBalances.push(tokens[i].ethInWallet)
      }

    }
    return({balances: liveBalances})
  }
) 

const getDepBalance = async (address:Address) =>{
  const ethBalance =  await fetchBalance({
    address: address
  }).then((balance:FetchBalanceResult)=>{
    return balance.formatted.substring(0,8)
  })
  return ethBalance
}

export const searchTokenByNameOrAddressAsync = createAsyncThunk(
  "token/search",

  async ({searchString, id, nonce, chain}:{searchString: string, id: number | undefined, nonce: string | undefined, chain: ChainIds}) => {
    var chainString = ''

    if(chain === ChainIds.Ethereum){
      chainString = 'eth'
    }
    else if(chain === ChainIds.Solana){
      chainString = 'solana'
    }
    else if(chain === ChainIds.Base){
      chainString = 'base'
    }
    
    const response = await instance.request(
      {
        method: 'POST',
        url:`/token/search`,
        data: {
          searchString: searchString,
          id: id,
          nonce: nonce,
          chain: chainString
        },
        headers: {
          ...headers
        },
        timeout: MAX_TIMEOUT
      }
    )    
    return { data: response.data, searchString: searchString }
  },
)



const getAlphaScore = (row:TokenData) => {
  switch (row.alphaBanana) {
    case "There are no wallets participating in the First Bundle or Fail**": 
      return row.alphaHitsMaestro + row.approvalsCount + row.alphaHitsProdigy / 10;
    case "There are less than 10 wallets participating in the First Bundle or Fail**": 
      return row.alphaHitsMaestro + row.approvalsCount + 10 + row.alphaHitsProdigy / 10;
    case "There are at least 10 wallets participating in the First Bundle or Fail**": 
      return row.alphaHitsMaestro + row.approvalsCount + 25 + row.alphaHitsProdigy / 10;
    case "There are at least 20 wallets participating in the First Bundle or Fail**": 
      return row.alphaHitsMaestro + row.approvalsCount + 50 + row.alphaHitsProdigy / 10;
    case "There are at least 30 wallets participating in the First Bundle or Fail**": 
      return row.alphaHitsMaestro + row.approvalsCount + 100 + row.alphaHitsProdigy / 10;
    case "There are at least 40 wallets participating in the First Bundle or Fail**": 
      return row.alphaHitsMaestro + row.approvalsCount + 200 + row.alphaHitsProdigy / 10;;
    case "There are at least 50 wallets participating in the First Bundle or Fail**": 
      return row.alphaHitsMaestro + row.approvalsCount + 300 + row.alphaHitsProdigy / 10;
    default: return row.alphaHitsMaestro + row.approvalsCount + row.alphaHitsProdigy / 10;
  }
}

// left here for reference on passing params in.
// export const liveUpdateTokens = createAsyncThunk(
//   "token/loadNewTokens",

//   async ({ prevLoadTime, currentTime}:{prevLoadTime: string, currentTime: string}) => {

//     const response = await axios.get(
//       `${EndPoint}/token/new`, 
//      { 
//       params: {
//         startTime: prevLoadTime
//       }
//     })    

//     return { data: response.data, startTime: currentTime}
//   },
// )


export const enableTrackingForToken = createAsyncThunk(
  "users/enableTrackingForToken",
  async ( {userId, tokenId, nonce}: {userId: number| undefined, tokenId: number, nonce: string}) => {

    const response = await instance.request(
    {
        method:'PUT',
        url:`/users/enableTrackingForToken`,
        data:{
          userId: userId,
          tokenId: tokenId,
          nonce: nonce
        }
    })
    return response.data
  },
)

export const disableTrackingForToken = createAsyncThunk(
  "users/disableTrackingForToken",
  async ( {trackData, nonce}: {trackData: TrackedToken, nonce: string }) => {
    const response = await instance.request(
    {
        method:'POST',
        url:`/users/disableTrackingForToken`,
        data:{ 
          userId: trackData.userId,
          tokenId: trackData.tokenId,
          nonce: nonce,
          trackId: trackData.id,
        }
    })

    return response.data
  },
)


export const tokenSlice = createSlice({
  name: "tokens",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    // Use the PayloadAction type to declare the contents of `action.payload`
    updateTokenData: (state, action: PayloadAction<[TokenData]>) => {
      state.archivedData = action.payload
    },
    sortBy: (state, action: PayloadAction<SortTypes>) => {
      state.sortBy = action.payload
      // state.liveUpdatedData = [...sortData({sortBy:action.payload, data:state.liveUpdatedData})]
      // state.archivedData = [...sortData({sortBy:action.payload, data:state.archivedData})]
      // state.searchedTokens = [...sortData({sortBy:action.payload, data:state.searchedTokens})]
    },
    resetLoadingStatus: (state) => {
      state.status = 'idle'
    },
    switchArchivePageSize: (state, action: PayloadAction<10 | 20 | 50 | 100>) => {
      state.userSelectedPageSize = action.payload
    },
    toggleTrackingForToken: (state, action: PayloadAction<number>) => {

      // Find the token entry we want to toggle tracking on
      let liveElementIndex = state.liveUpdatedData.findIndex(token => token.id == action.payload )
      let archivedElementIndex = state.archivedData.findIndex(token => token.id == action.payload )
      let trackedElementIndex = state.trackedTokens.findIndex(token => token.id == action.payload)
      let searchedElementIndex = state.searchedTokens.findIndex(token => token.id == action.payload )
      
      // if the element exists within the archived data array 
      if( archivedElementIndex >= 0){
        state.archivedData[archivedElementIndex].isTracked = !state.archivedData[archivedElementIndex].isTracked
      }

      if(liveElementIndex >= 0){
        state.liveUpdatedData[liveElementIndex].isTracked = ! state.liveUpdatedData[liveElementIndex].isTracked
      }
      if(searchedElementIndex >= 0){
        state.searchedTokens[searchedElementIndex].isTracked = ! state.searchedTokens[searchedElementIndex].isTracked
      }

      // If the user checked the box from either of the catagories, we want to add it to the tracked list
      // Both could be true if the item exists in both lists (which only occurs on the following edge case)
      //     case 1: user has 500 rows of data in their archive section and sorted it by name. 
      //             Lets suppose token named '111' with address '123' is the top item in the archive
      //             This item could have been an item added to the liveUpdate list recently...
      if( state.liveUpdatedData[liveElementIndex]?.isTracked ){
         state.trackedTokens.unshift(state.liveUpdatedData[liveElementIndex])
         state.trackedTokenIds.unshift(state.liveUpdatedData[liveElementIndex].id)
      }
      else if( state.archivedData[archivedElementIndex]?.isTracked ){
        state.trackedTokens.unshift(state.archivedData[archivedElementIndex])
        state.trackedTokenIds.unshift(state.archivedData[archivedElementIndex].id)
      }
      else if( state.searchedTokens[searchedElementIndex]?.isTracked ){

        state.trackedTokens.unshift(state.searchedTokens[searchedElementIndex])
        state.trackedTokenIds.unshift(state.searchedTokens[searchedElementIndex].id)
      }

      // If the user unchecked the the box somewhere, remove it from the tracked list
      if( ( liveElementIndex >= 0 ) && 
          ( !state.liveUpdatedData[liveElementIndex].isTracked ) && 
          ( trackedElementIndex >= 0 )){
        state.trackedTokens = state.trackedTokens.filter( (token) => token.id !== state.liveUpdatedData[liveElementIndex].id);
        state.trackedTokenIds = state.trackedTokenIds.filter((tokenId) => tokenId !== state.liveUpdatedData[liveElementIndex].id)
      }
      else if(
        ( archivedElementIndex >= 0 ) && 
        ( !state.archivedData[archivedElementIndex].isTracked ) &&
        ( trackedElementIndex >= 0)){
        state.trackedTokens = state.trackedTokens.filter( (token) =>  token.id !== state.archivedData[archivedElementIndex].id);
        state.trackedTokenIds = state.trackedTokenIds.filter((tokenId) => tokenId !== state.archivedData[archivedElementIndex].id)
      }
      else if(
        ( searchedElementIndex >= 0 ) && 
        ( !state.searchedTokens[searchedElementIndex].isTracked ) &&
        ( trackedElementIndex >= 0)){
        state.trackedTokens = state.trackedTokens.filter( (token) =>  token.id !== state.searchedTokens[searchedElementIndex].id);
      }
      else if( 
        ( trackedElementIndex >= 0 ) && 
        ( state.trackedTokens[trackedElementIndex].isTracked )){
         
          state.trackedTokens = state.trackedTokens.filter( (token) =>  token.id !== state.trackedTokens[trackedElementIndex].id);
          state.trackedTokenIds = state.trackedTokenIds.filter( (tokenId) =>  tokenId !== state.trackedTokenIds[trackedElementIndex]);
      }
      

      // If the user is over their alloted trackedToken ammount, remove the oldest entry
      if(state.trackedTokens.length > state.userMaxAllowedTracks){

        let removedToken = state.trackedTokens.pop()
        let uncheckedElement = state.archivedData.findIndex(token => token.id == removedToken?.id )


        if(uncheckedElement >= 0){
          state.archivedData[uncheckedElement].isTracked = !state.archivedData[uncheckedElement].isTracked
        }

        uncheckedElement = state.liveUpdatedData.findIndex(token => token.id == removedToken?.id )
        if(uncheckedElement >= 0){
          state.liveUpdatedData[uncheckedElement].isTracked = !state.liveUpdatedData[uncheckedElement].isTracked
        }

        uncheckedElement = state.searchedTokens.findIndex(token => token.id == removedToken?.id )
        if(uncheckedElement >= 0){
          state.searchedTokens[uncheckedElement].isTracked = !state.searchedTokens[uncheckedElement].isTracked
        }

      }
    },
    switchToLaunchedType: (state, action: PayloadAction<LaunchedTypes>) => {
      state.prevTokenDataSource = state.tokenDataSource
      state.tokenDataSource = action.payload
    },
    setViewMode: (state, action:PayloadAction<ViewModes>) => {
      state.viewMode = action.payload
    },
    setLocalChain: (state, action:PayloadAction<ChainIds>) => {
      state.user.activeChain = action.payload
    },
    // Use the PayloadAction type to declare the contents of `action.payload`
    updateUser: (state, action: PayloadAction<AuthState>) => {
      state.user = {...state.user, ...action.payload}
      state.userSelectedPageSize = state.user.pageSize
      state.tokenDataSource = state.user.filterMode
      state.viewMode = state.user.viewMode
    },
    userAccountChangedSuccessfully: (state, action: PayloadAction<AuthState>) => {
      state.user = {...state.user, ...action.payload} 
    },

    awaitingUserSignature: (state) => {
      state.status = 'loading'
      state.user.currentAuthStep = AuthStep.WaitingForSignature
    },
    setCurrentAuthStep: (state, action:PayloadAction<AuthStep>) => {
      state.user = {...state.user, currentAuthStep:action.payload}
    },
    updateSignatureAndMessage: (state, action:PayloadAction<any,string>) => {
      state.user.message = action.payload.message
      state.user.signature = action.payload.signature
    },
    updateMessage: (state, action:PayloadAction<SiweMessage>) => {
      state.user = {...state.user, message: JSON.stringify(action.payload) }
    },
    userLoginInitiated: (state) => {
      state.user.status = 'loading'
    },
    userDeniedSignature: (state) => {
      state.user.isAuthorized = false
      state.user.status = 'idle'
    },
    userAccountChanged: (state, action:PayloadAction<Address>) => {
      // console.log(`slice uac ${action.payload}`)
     // state.user.address = action.payload
      state.user = {...state.user, currentAuthStep:AuthStep.AccountChanged, swapAddress: action.payload}
      if(state.user.address !== undefined && state.user.address !== action.payload){
        // console.log(`could this work`)
        state.user = {...state.user, currentAuthStep:AuthStep.AccountChanged}
      }
      // console.log(state.user)
    },
    clearSearchedTokens: (state) => {
      state.searchedTokens = []
    }

  },
  // The `extraReducers` field lets the slice handle actions defined elsewhere,
  // including actions generated by createAsyncThunk or in other slices.
  extraReducers: (builder) => {
    builder
      .addCase(fetchTokensAsync.pending, (state) => {
        state.status = "loading"
      })
      .addCase(fetchTokensAsync.fulfilled, (state, action) => {
        state.status = "idle"
        state.archivedData = action.payload
      })
      .addCase(fetchTokensAsync.rejected, (state) => {
        state.status = "failed"
      })

      .addCase(lazyLoadTokensAsync.pending, (state) => {
        state.isRequestFromTimedFetch = false
        state.status = "loading"
      })
      .addCase(lazyLoadTokensAsync.fulfilled, (state, action) => {

        

        if(state.user.activeChain === ChainIds.Ethereum){
          state.archivedTokenIds = []
          state.archivedData = []
          state.currentPage = action.payload.requestedPage
        }
        if(state.user.activeChain === ChainIds.Solana){

          state.solanaArchivedTokenIds = []
          state.solanaArchivedData = []
          state.solanaCurrentPage = action.payload.requestedPage
        }
        if(state.user.activeChain === ChainIds.Base){
          state.baseArchivedTokenIds = []
          state.baseArchivedData = []
          state.baseCurrentPage = action.payload.requestedPage
        }
        

        action.payload.data.map((token:TokenData) => {
          let tempToken = token
          let parsedSimData = token.simulationJSON? JSON.parse(token.simulationJSON): []
          let parsedLiquidityData = token.liquidityArray? JSON.parse(token.liquidityArray): []
          let parsedCallerChannels = token.alphaCallChannels ? JSON.parse(token.alphaCallChannels) : []
          let preParsedSecurityData = token.securityArray ? 
            JSON.parse(token.securityArray) : 
            {
              mint_authority_revoked: null,
              liquidity_burnt_pct: undefined,
              owner_transfer_pct: undefined,
              owner_hold_pct: undefined
            }
          let parsedSecurityData: SecurityDetails = {
            mint_authority_revoked: preParsedSecurityData.mint_authority_revoked?preParsedSecurityData.mint_authority_revoked:null,
            liquidity_burnt_pct: preParsedSecurityData.liquidity_burnt_pct? parseInt(preParsedSecurityData.liquidity_burnt_pct):undefined,
            owner_transfer_pct: preParsedSecurityData.owner_transfer_pct? parseInt(preParsedSecurityData.owner_transfer_pct):undefined,
            owner_hold_pct: preParsedSecurityData.owner_hold_pct? parseInt(preParsedSecurityData.owner_hold_pct): undefined
          }

          tempToken = { ...tempToken, parsedAlphaCallChannels: parsedCallerChannels, parsedSimulationJSON: parsedSimData, parsedLiquidityArray:parsedLiquidityData, parsedSecurityDetails: parsedSecurityData }

          
          if(state.user.activeChain === ChainIds.Ethereum){
            state.archivedData.push(tempToken)
            state.archivedTokenIds.push(token.id)
          }
          else if(state.user.activeChain === ChainIds.Solana){
            state.solanaArchivedData.push(tempToken)
            state.solanaArchivedTokenIds.push(token.id)
          }
          else if(state.user.activeChain === ChainIds.Base){
            state.baseArchivedData.push(tempToken)
            state.baseArchivedTokenIds.push(token.id)
          }
        })

        //state.archivedData = [...sortData({sortBy:state.sortBy, data:state.archivedData})]
        state.status = "idle"
      })
      .addCase(lazyLoadTokensAsync.rejected, (state) => {
        state.status = "failed"
      })

      .addCase(liveUpdateTokensAsync.pending, (state) => {
        state.isRequestFromTimedFetch = true
        state.status = "loading"
        
      })
      .addCase(liveUpdateTokensAsync.fulfilled, (state, action) => {
 
        if(state.user.activeChain === ChainIds.Ethereum){
          state.liveUpdatedData = []
          state.liveUpdatedTokenIds = []
        }
        else if(state.user.activeChain === ChainIds.Solana){
          state.solanaLiveUpdatedData = []
          state.solanaLiveUpdatedTokenIds = []
        }
        else if(state.user.activeChain === ChainIds.Base){
          state.baseLiveUpdatedData = []
          state.baseLiveUpdatedTokenIds = []
        }

        action.payload.data.map((token:TokenData, index:number)=>{
          
          let parsedSimData = token.simulationJSON? JSON.parse(token.simulationJSON): []
          let parsedCallerChannels = token.alphaCallChannels ? JSON.parse(token.alphaCallChannels) : []
          let parsedSecurityData = token.securityArray ? 
            JSON.parse(token.securityArray) : 
            {
              mint_authority_revoked: null,
              liquidity_burnt_pct: undefined,
              owner_transfer_pct: undefined,
              owner_hold_pct: undefined
            }
          let parsedLiquidityData = token.liquidityArray ? JSON.parse(token.liquidityArray) : []

          let tempToken = token
          tempToken = { ...tempToken, parsedSimulationJSON: parsedSimData, parsedAlphaCallChannels: parsedCallerChannels, parsedLiquidityArray: parsedLiquidityData, parsedSecurityDetails: parsedSecurityData}

          if(state.user.activeChain === ChainIds.Ethereum){

            // if(state.user.trackedTokenIds.includes(token.id)){
            //   tempToken = { ...tempToken, isTracked: true } 
            //  }

            state.liveUpdatedData = [...state.liveUpdatedData, { ...tempToken }]
            state.liveUpdatedTokenIds = [...state.liveUpdatedTokenIds, token.id]
          }
          else if(state.user.activeChain === ChainIds.Solana){

            // if(state.user.trackedTokenIds.includes(token.id)){
            //   tempToken = { ...tempToken, isTracked: true } 
            // }

            state.solanaLiveUpdatedData = [...state.solanaLiveUpdatedData, { ...tempToken }]
            state.solanaLiveUpdatedTokenIds = [...state.solanaLiveUpdatedTokenIds, token.id]
          }
          else if(state.user.activeChain === ChainIds.Base){

            // if(state.user.trackedTokenIds.includes(token.id)){
            //   tempToken = { ...tempToken, isTracked: true } 
            //  }

            state.baseLiveUpdatedData = [...state.baseLiveUpdatedData, { ...tempToken }]
            state.baseLiveUpdatedTokenIds = [...state.baseLiveUpdatedTokenIds, token.id]
          }
        })

        state.status = "idle"
        state.isRequestFromTimedFetch = false
        state.prevTokenDataSource = action.payload.requestedSource
      })
      // .addCase(liveUpdateTokensAsync.fulfilled, (state, action) => {
        
      //   let tempIdArray:number[] = []
        
      //   // Use eth tokens as default
      //   let currentFirstItem = {...state.liveUpdatedData[0]}
      //   let tokensArrayLength = state.liveUpdatedData.length

      //   if(state.activeChain === ChainIds.Solana){
      //     currentFirstItem = {...state.solanaLiveUpdatedData[0]}
      //     tokensArrayLength = state.solanaLiveUpdatedData.length
      //   }

      //   if(tokensArrayLength > 0){
      //     for(let i = 0, j =  (tokensArrayLength - 1); i< action.payload.data.length; i++, j--){

      //       if(action.payload.data[i].id === currentFirstItem.id)
      //         break;


      //       if(state.activeChain === ChainIds.Ethereum){
      //         state.archivedData.unshift( state.liveUpdatedData[j] )
      //         state.archivedTokenIds.unshift( state.liveUpdatedData[j].id )
      //         state.archivedData.pop()
      //         state.archivedTokenIds.pop()
      //       }
      //       else if(state.activeChain === ChainIds.Solana){
      //         state.solanaArchivedData.unshift( state.solanaLiveUpdatedData[j] )
      //         state.solanaArchivedTokenIds.unshift( state.solanaLiveUpdatedData[j].id )
      //         state.solanaArchivedData.pop()
      //         state.solanaArchivedTokenIds.pop()
      //       }

      //     }
      //   }
        
      //   action.payload.data.map((token:TokenData, index:number)=>{

      //     let oldDataIndex
      //     let oldData
      //     let tempToken
      //     let parsedSimData = token.simulationJSON? JSON.parse(token.simulationJSON): []
      //     let parsedCallerChannels = token.alphaCallChannels ? JSON.parse(token.alphaCallChannels) : []
      //     let parsedSecurityData = token.securityArray ? 
      //       JSON.parse(token.securityArray) : 
      //       {
      //         mint_authority_revoked: null,
      //         liquidity_burnt_pct: undefined,
      //         owner_transfer_pct: undefined,
      //         owner_hold_pct: undefined
      //       }
      //     let parsedLiquidityData = token.liquidityArray ? JSON.parse(token.liquidityArray) : []

      //     // let parsedSecurityData: SecurityDetails = {
      //     //   mint_authority_revoked: preParsedSecurityData.mint_authority_revoked?preParsedSecurityData.mint_authority_revoked:null,
      //     //   liquidity_burnt_pct: preParsedSecurityData.liquidity_burnt_pct? parseInt(preParsedSecurityData.liquidity_burnt_pct):undefined,
      //     //   owner_transfer_pct: preParsedSecurityData.owner_transfer_pct? parseInt(preParsedSecurityData.owner_transfer_pct):undefined,
      //     //   owner_hold_pct: preParsedSecurityData.owner_hold_pct? parseInt(preParsedSecurityData.owner_hold_pct): undefined
      //     // }

          
      //     if( state.liveUpdatedTokenIds.includes(token.id)){
      //       oldDataIndex = state.liveUpdatedData.findIndex((t) => t.id === token.id)
      //       oldData = { ...state.liveUpdatedData[oldDataIndex] }
      //       tempToken = { ...token }
      //       tempToken = { ...tempToken, isTracked: oldData.isTracked }
      //     }
      //     else if(state.solanaLiveUpdatedTokenIds.includes(token.id)){
      //       oldDataIndex = state.solanaLiveUpdatedData.findIndex((t) => t.id === token.id)
      //       oldData = { ...state.solanaLiveUpdatedData[oldDataIndex] }
      //       tempToken = { ...token }
      //       tempToken = { ...tempToken, isTracked: oldData.isTracked }
      //     }
      //     else{
      //       tempToken = token
      //     }

      //     tempIdArray.push(token.id)
      //     tempToken = { ...tempToken, parsedSimulationJSON: parsedSimData, parsedAlphaCallChannels: parsedCallerChannels, parsedLiquidityArray: parsedLiquidityData, parsedSecurityDetails: parsedSecurityData}

      //     if(state.trackedTokenIds.includes(token.id) && state.activeChain === ChainIds.Ethereum){
      //      tempToken = { ...tempToken, isTracked: true } 
      //     }
      //     else if(state.solanaTrackedTokenIds.includes(token.id) && state.activeChain === ChainIds.Solana){
      //       tempToken = { ...tempToken, isTracked: true } 
      //     }

      //     if(state.activeChain === ChainIds.Ethereum){
      //       state.liveUpdatedData[index] = { ...tempToken }
      //     }
      //     else if(state.activeChain === ChainIds.Solana){
      //       state.solanaLiveUpdatedData[index] = { ...tempToken }
      //     }
      //   })

      //   if(state.activeChain === ChainIds.Ethereum){
      //     state.liveUpdatedTokenIds = tempIdArray
      //   }
      //   else if(state.activeChain === ChainIds.Solana){
      //     state.solanaLiveUpdatedTokenIds = tempIdArray
      //   }

       

      //   state.status = "idle"
      //   state.isRequestFromTimedFetch = false
      //   state.prevTokenDataSource = action.payload.requestedSource
      // })
      .addCase(liveUpdateTokensAsync.rejected, (state) => {
        state.status = "failed",
        state.isRequestFromTimedFetch = false
      })
      .addCase(searchTokenByNameOrAddressAsync.pending, (state) => {
        state.isRequestFromTimedFetch = true
        state.status = "loading"
        
      })
      .addCase(searchTokenByNameOrAddressAsync.fulfilled, (state, action) => {

        //state.searchedTokens = action.payload.data

        action.payload.data.map((token:TokenData)=>{
          let parsedSimData = token.simulationJSON ? JSON.parse(token.simulationJSON) : []
          let parsedCallerChannels = token.alphaCallChannels ? JSON.parse(token.alphaCallChannels) : []
          let parsedLiquidityData = token.liquidityArray ? JSON.parse(token.liquidityArray) : []
          let parsedSecurityData = token.securityArray ? 
            JSON.parse(token.securityArray) : 
            {
              mint_authority_revoked: undefined,
              liquidity_burnt_pct: undefined,
              owner_transfer_pct: undefined,
              owner_hold_pct: undefined
            }
          // let parsedSecurityData: SecurityDetails = {
          //   mint_authority_revoked: preParsedSecurityData.mint_authority_revoked?preParsedSecurityData.mint_authority_revoked:null,
          //   liquidity_burnt_pct: preParsedSecurityData.liquidity_burnt_pct? parseInt(preParsedSecurityData.liquidity_burnt_pct):undefined,
          //   owner_transfer_pct: preParsedSecurityData.owner_transfer_pct? parseInt(preParsedSecurityData.owner_transfer_pct):undefined,
          //   owner_hold_pct: preParsedSecurityData.owner_hold_pct? parseInt(preParsedSecurityData.owner_hold_pct): undefined
          // }

          let tempToken = { ...token, parsedSimData: parsedSimData, parsedAlphaCallChannels: parsedCallerChannels, parsedLiquidityArray: parsedLiquidityData, parsedSecurityDetails: parsedSecurityData  }
         
          state.searchedTokens = [...state.searchedTokens, tempToken]
          //state.archivedTokenIds = [token.id, ...state.archivedTokenIds]

        })

        state.status = "idle"
        state.isRequestFromTimedFetch = false
        
      })
      .addCase(searchTokenByNameOrAddressAsync.rejected, (state) => {
        state.status = "failed",
        state.isRequestFromTimedFetch = false
      })
      .addCase(updateArchiveAsync.pending, (state) => {
        state.isRequestFromTimedFetch = false
        state.status = "loading"
      })
      .addCase(updateArchiveAsync.fulfilled, (state, action) => {
          state.nextTokenEntry = NUM_LIVE_DATA_ENTRIES
          state.currentPage = 1
          state.archivedTokenIds = []
          state.archivedData = []
          state.solanaArchivedTokenIds = []
          state.solanaArchivedData = []

          action.payload.data.map((token:TokenData)=>{
            let parsedSimData = token.simulationJSON ? JSON.parse(token.simulationJSON) : []
            let parsedCallerChannels = token.alphaCallChannels ? JSON.parse(token.alphaCallChannels) : []
            let parsedLiquidityData = token.liquidityArray ? JSON.parse(token.liquidityArray) : []
            let parsedSecurityData = token.securityArray ? 
              JSON.parse(token.securityArray) : 
              {
                mint_authority_revoked: undefined,
                liquidity_burnt_pct: undefined,
                owner_transfer_pct: undefined,
                owner_hold_pct: undefined
              }
            // let parsedSecurityData: SecurityDetails = {
            //   mint_authority_revoked: preParsedSecurityData.mint_authority_revoked?preParsedSecurityData.mint_authority_revoked:null,
            //   liquidity_burnt_pct: preParsedSecurityData.liquidity_burnt_pct? parseInt(preParsedSecurityData.liquidity_burnt_pct):undefined,
            //   owner_transfer_pct: preParsedSecurityData.owner_transfer_pct? parseInt(preParsedSecurityData.owner_transfer_pct):undefined,
            //   owner_hold_pct: preParsedSecurityData.owner_hold_pct? parseInt(preParsedSecurityData.owner_hold_pct): undefined
            // }
            let tempToken = { ...token, parsedSimData: parsedSimData, parsedAlphaCallChannels: parsedCallerChannels, parsedLiquidityArray: parsedLiquidityData, parsedSecurityDetails: parsedSecurityData }
           
            if(state.user.activeChain === ChainIds.Ethereum){
              state.archivedData = [...state.archivedData, tempToken]
              state.archivedTokenIds = [token.id, ...state.archivedTokenIds]
            }
            else if(state.user.activeChain === ChainIds.Solana ){
              state.solanaArchivedData = [...state.solanaArchivedData, tempToken]
              state.solanaArchivedTokenIds = [token.id, ...state.solanaArchivedTokenIds]
            }

          })
          //state.archivedData = [...action.payload.data]
          state.status = "idle"
      })
      .addCase(updateArchiveAsync.rejected, (state) => {
        state.status = "failed"
      })
      .addCase(getDeployerBalances.pending, (state) => {
        state.status = "loading"
      })
      .addCase(getDeployerBalances.fulfilled, (state, action) => {
        state.status = "idle"
       
        state.liveUpdatedData.map((token, index)=>{
          state.liveUpdatedData[index].ethInWallet = action.payload.balances[index]
        })
        
      })
      .addCase(getDeployerBalances.rejected, (state) => {
        state.status = "failed"
      })
      .addCase(authorizeUserAsync.pending, (state) => {
        state.status = 'loading'
     
      })
      .addCase(authorizeUserAsync.fulfilled, (state, action) => {
        if(action.payload.ok === true){
          state.user.isAuthorized = true
        }
        else{
          state.user.isAuthorized = false
        }  
      })
      .addCase(authorizeUserAsync.rejected, (state) => {
        state.user.status = 'failed'
      })

      .addCase(loginUserAsync.pending, (state) => {
        state.user.status = 'loading'
        state.user.currentAuthStep = AuthStep.GetNonce  
      })
      .addCase(loginUserAsync.fulfilled, (state, action) => {
        state.user = {...state.user, currentAuthStep: AuthStep.GetSignature}
        state.user.nonce = action.payload
      })
      .addCase(loginUserAsync.rejected, (state) => {
        state.user.status = 'failed'
      })
      .addCase(getUserData.pending, (state) => {
        state.user.currentAuthStep = AuthStep.GetUserData
        state.user.status = 'loading' 
      
      })
      .addCase(getUserData.fulfilled, (state, action) => {
        state.user = {
          ...state.user, 
          ...action.payload, 
          currentAuthStep: AuthStep.Authorized
        }

        state.user.trackedTokenIds = []

        action.payload.trackingData.map( (tt:TrackedToken) =>{
          state.user.trackedTokenIds = [...state.user.trackedTokenIds, tt.tokenId]
        })

        state.userSelectedPageSize = action.payload.pageSize
        state.tokenDataSource = action.payload.filterMode
        state.prevTokenDataSource = action.payload.filterMode
        state.viewMode = action.payload.viewMode

        localStorage.setItem('user', JSON.stringify(state.user))

        state.user.status = 'idle' 
      })
      .addCase(getUserData.rejected, (state) => {
        state.user.status = 'failed'
      }) 
      .addCase(logout.pending, (state) => {
        state.user.status = 'loading' 
      })
      .addCase(logout.fulfilled, (state, action) => {
        state.user = {...initialAuthState}
        state.user = {...state.user, status: 'idle'}
        localStorage.setItem('user', JSON.stringify(state.user))
      })
      .addCase(logout.rejected, (state) => {
        state.user.status = 'failed'
      }) 
      .addCase(setTelegramHandleAsync.pending, (state) => {
        state.user.status = 'loading' 
      })
      .addCase(setTelegramHandleAsync.fulfilled, (state, action) => {
        state.user = {...state.user, tgHandle:action.payload}
        state.user.status = 'idle'
        state.user.errorMessage = ''
        localStorage.setItem('user',JSON.stringify(state.user))
      })
      .addCase(setTelegramHandleAsync.rejected, (state, action) => {
        state.user.status = 'failed'
        state.user.errorMessage = 'Error: TG Handle Taken'
      })
      .addCase(setDefaultPageSize.pending, (state) => {
        state.user.status = 'loading' 
      })
      .addCase(setDefaultPageSize.fulfilled, (state, action) => {
        state.user = {...state.user, pageSize: parseInt(action.payload)}
        state.userSelectedPageSize = parseInt(action.payload)
        localStorage.setItem('user',JSON.stringify(state.user))
        state.user.status = 'idle'
      })
      .addCase(setDefaultPageSize.rejected, (state) => {
        state.user.status = 'failed'
      })
      .addCase(setDefaultViewMode.pending, (state) => {
        state.user.status = 'loading' 
      })
      .addCase(setDefaultViewMode.fulfilled, (state, action) => {
        state.user = {...state.user, viewMode: parseInt(action.payload)}
        localStorage.setItem('user', JSON.stringify(state.user))
        state.user.status = 'idle'
      })
      .addCase(setDefaultViewMode.rejected, (state) => {
        state.user.status = 'failed'
      })

      .addCase(setNotificationThreshold.pending, (state) => {
        state.user.status = 'loading' 
      })
      .addCase(setNotificationThreshold.fulfilled, (state, action) => {
        state.user = {...state.user, notificationThreshold:action.payload}
        localStorage.setItem('user', JSON.stringify(state.user))
        state.user.status = 'idle'
      })
      .addCase(setNotificationThreshold.rejected, (state) => {
        state.user.status = 'failed'
      })

      .addCase(setMarksmanNotificationThreshold.pending, (state) => {
        state.user.status = 'loading' 
      })
      .addCase(setMarksmanNotificationThreshold.fulfilled, (state, action) => {
        state.user = {...state.user, marksmanNotificationThreshold:action.payload}
        localStorage.setItem('user', JSON.stringify(state.user))
        state.user.status = 'idle'
      })
      .addCase(setMarksmanNotificationThreshold.rejected, (state) => {
        state.user.status = 'failed'
      })

      // .addCase(setSellTaxThreshold.pending, (state) => {
      //   state.user.status = 'loading' 
      // })
      // .addCase(setSellTaxThreshold.fulfilled, (state, action) => {
      //   state.user = {...state.user, sellTaxThreshold:action.payload}
      //   localStorage.setItem('user', JSON.stringify(state.user))
      //   state.user.status = 'idle'
      // })
      // .addCase(setSellTaxThreshold.rejected, (state) => {
      //   state.user.status = 'failed'
      // })    

      // .addCase(setBuyTaxThreshold.pending, (state) => {
      //   state.user.status = 'loading' 
      // })
      // .addCase(setBuyTaxThreshold.fulfilled, (state, action) => {
      //   state.user = {...state.user, buyTaxThreshold:action.payload}
      //   localStorage.setItem('user', JSON.stringify(state.user))
      //   state.user.status = 'idle'
      // })
      // .addCase(setBuyTaxThreshold.rejected, (state) => {
      //   state.user.status = 'failed'
      // })  

      // .addCase(setScamThreshold.pending, (state) => {
      //   state.user.status = 'loading' 
      // })
      // .addCase(setScamThreshold.fulfilled, (state, action) => {
      //   state.user = {...state.user, scamThreshold:action.payload}
      //   localStorage.setItem('user', JSON.stringify(state.user))
      //   state.user.status = 'idle'
      // })
      // .addCase(setScamThreshold.rejected, (state) => {
      //   state.user.status = 'failed'
      // })  

      .addCase(setCallerThreshold.pending, (state) => {
        state.user.status = 'loading' 
      })
      .addCase(setCallerThreshold.fulfilled, (state, action) => {
        state.user = {...state.user, callerThreshold:action.payload}
        localStorage.setItem('user', JSON.stringify(state.user))
        state.user.status = 'idle'
      })
      .addCase(setCallerThreshold.rejected, (state) => {
        state.user.status = 'failed'
      })

      .addCase(setNotificationThresholdSOL.pending, (state) => {
        state.user.status = 'loading' 
      })
      .addCase(setNotificationThresholdSOL.fulfilled, (state, action) => {
        state.user = {...state.user, sniperNotificationThresholdSOL:action.payload}
        localStorage.setItem('user', JSON.stringify(state.user))
        state.user.status = 'idle'
      })
      .addCase(setNotificationThresholdSOL.rejected, (state) => {
        state.user.status = 'failed'
      })

      .addCase(setMarksmanNotificationThresholdSOL.pending, (state) => {
        state.user.status = 'loading' 
      })
      .addCase(setMarksmanNotificationThresholdSOL.fulfilled, (state, action) => {
        state.user = {...state.user, marksmanNotificationThresholdSOL:action.payload}
        localStorage.setItem('user', JSON.stringify(state.user))
        state.user.status = 'idle'
      })
      .addCase(setMarksmanNotificationThresholdSOL.rejected, (state) => {
        state.user.status = 'failed'
      })

      .addCase(setCallerThresholdSOL.pending, (state) => {
        state.user.status = 'loading' 
      })
      .addCase(setCallerThresholdSOL.fulfilled, (state, action) => {
        state.user = {...state.user, callerNotificationThresholdSOL:action.payload}
        localStorage.setItem('user', JSON.stringify(state.user))
        state.user.status = 'idle'
      })
      .addCase(setCallerThresholdSOL.rejected, (state) => {
        state.user.status = 'failed'
      })    


      .addCase(setMarksmanNotificationThresholdBASE.pending, (state) => {
        state.user.status = 'loading' 
      })
      .addCase(setMarksmanNotificationThresholdBASE.fulfilled, (state, action) => {
        state.user = {...state.user, marksmanNotificationThresholdBASE:action.payload}
        localStorage.setItem('user', JSON.stringify(state.user))
        state.user.status = 'idle'
      })
      .addCase(setMarksmanNotificationThresholdBASE.rejected, (state) => {
        state.user.status = 'failed'
      })

      .addCase(setCallerThresholdBASE.pending, (state) => {
        state.user.status = 'loading' 
      })
      .addCase(setCallerThresholdBASE.fulfilled, (state, action) => {
        state.user = {...state.user, callerNotificationThresholdBASE:action.payload}
        localStorage.setItem('user', JSON.stringify(state.user))
        state.user.status = 'idle'
      })
      .addCase(setCallerThresholdBASE.rejected, (state) => {
        state.user.status = 'failed'
      })    


      .addCase(setFilterMode.pending, (state) => {
        state.user.status = 'loading'
      })
      .addCase(setFilterMode.fulfilled, (state, action) => {
        state.user = { ...state.user, filterMode: parseInt(action.payload) }
        state.tokenDataSource = parseInt(action.payload)
        localStorage.setItem('user', JSON.stringify(state.user))
        state.user.status = 'idle'
      })
      .addCase(setFilterMode.rejected, (state) => {
        state.user.status = 'failed'
      }) 

      .addCase(enableTrackingForToken.pending, (state) => {
        state.user.status = 'loading'
      })
      .addCase(enableTrackingForToken.fulfilled, (state, action) => {
        state.user.trackedTokenIds = [...state.user.trackedTokenIds, action.payload.tokenId]
        state.user.trackingData = [...state.user.trackingData, action.payload]
       
        // The token was on eth and exists in the live updated token section
        if (state.liveUpdatedTokenIds.includes(action.payload.tokenId)){
          let index = state.liveUpdatedData.findIndex((token)=> token.id === action.payload.tokenId)
          state.liveUpdatedData[index].isTracked = true
        }
        // The token was on Solana and exists in the live data section
        else if(state.solanaLiveUpdatedTokenIds.includes(action.payload.tokenId)){
          let index = state.solanaLiveUpdatedTokenIds.findIndex((tokenId)=> tokenId === action.payload.tokenId)
          state.solanaLiveUpdatedData[index].isTracked = true
        }
        else if(state.baseLiveUpdatedTokenIds.includes(action.payload.tokenId)){
          let index = state.baseLiveUpdatedTokenIds.findIndex((tokenId)=> tokenId === action.payload.tokenId)
          state.baseLiveUpdatedData[index].isTracked = true
        }
        


        if (state.archivedTokenIds.includes(action.payload.tokenId)){
          let index = state.archivedData.findIndex((token)=> token.id === action.payload.tokenId)
          state.archivedData[index].isTracked = true
        }
        // The token was on Solana and exists in the live data section
        else if(state.solanaArchivedTokenIds.includes(action.payload.tokenId)){
          let index = state.solanaArchivedData.findIndex((token)=> token.id === action.payload.tokenId)
          state.solanaArchivedData[index].isTracked = true
        }
        else if(state.baseArchivedTokenIds.includes(action.payload.tokenId)){
          let index = state.baseArchivedData.findIndex((token)=> token.id === action.payload.tokenId)
          state.baseArchivedData[index].isTracked = true
        }

        // The token is in the searched token list
        const toggledItem = state.searchedTokens.findIndex((token)=> token.id === action.payload.tokenId)

        if (toggledItem >= 0){
          //let index = state.searchedTokens.findIndex((token)=> token.id === action.payload.tokenId)
          state.searchedTokens[toggledItem].isTracked = true
        }

        state.user.status = 'idle'
      })
      .addCase(enableTrackingForToken.rejected, (state) => {
        state.user.status = 'failed'
      }) 

      .addCase(disableTrackingForToken.pending, (state) => {
        state.user.status = 'loading'
      })
      .addCase(disableTrackingForToken.fulfilled, (state, action) => {
       
        state.user.trackedTokenIds = state.user.trackedTokenIds.filter((tokenId) => tokenId !== action.payload.tokenId)
        state.user.trackingData = state.user.trackingData.filter((trackedToken) => trackedToken.tokenId !== action.payload.tokenId)

        state.user.trackedEthereumTokenData = state.user.trackedEthereumTokenData.filter((token)=> token.id !== action.payload.tokenId)
        state.user.trackedSolanaTokenData = state.user.trackedSolanaTokenData.filter((token)=> token.id !== action.payload.tokenId)
        state.user.trackedBaseTokenData = state.user.trackedBaseTokenData.filter((token)=> token.id !== action.payload.tokenId)

        if (state.liveUpdatedTokenIds.includes(action.payload.tokenId)){
          let index = state.liveUpdatedData.findIndex((token)=> token.id === action.payload.tokenId)
          state.liveUpdatedData[index].isTracked = false
        }
        // The token was on Solana and exists in the live data section
        else if(state.solanaLiveUpdatedTokenIds.includes(action.payload.tokenId)){
          let index = state.solanaLiveUpdatedData.findIndex((token)=> token.id === action.payload.tokenId)
          state.solanaLiveUpdatedData[index].isTracked = false
        }
        else if(state.baseLiveUpdatedTokenIds.includes(action.payload.tokenId)){
          let index = state.baseLiveUpdatedData.findIndex((token)=> token.id === action.payload.tokenId)
          state.baseLiveUpdatedData[index].isTracked = false
        }

        if (state.archivedTokenIds.includes(action.payload.tokenId)){
          let index = state.archivedData.findIndex((token)=> token.id === action.payload.tokenId)
          state.archivedData[index].isTracked = false
        }
        // The token was on Solana and exists in the live data section
        else if(state.solanaArchivedTokenIds.includes(action.payload.tokenId)){
          let index = state.solanaArchivedData.findIndex((token)=> token.id === action.payload.tokenId)
          state.solanaArchivedData[index].isTracked = false
        }
        else if(state.baseArchivedTokenIds.includes(action.payload.tokenId)){
          let index = state.baseArchivedData.findIndex((token)=> token.id === action.payload.tokenId)
          state.baseArchivedData[index].isTracked = false
        }

        // The token is in the searched token list
        const toggledItem = state.searchedTokens.findIndex((token)=> token.id === action.payload.tokenId)

        if (toggledItem >= 0){
          //let index = state.searchedTokens.findIndex((token)=> token.id === action.payload.tokenId)
          state.searchedTokens[toggledItem].isTracked = false
        }

        state.user.status = 'idle'
      })
      .addCase(disableTrackingForToken.rejected, (state) => {
        state.user.status = 'failed'
      }) 
      .addCase(getTokensById.pending, (state) => {
        state.user.status = 'loading'
      })
      .addCase(getTokensById.fulfilled, (state, action) => {
        state.user.trackedEthereumTokenData = []
        state.user.trackedSolanaTokenData = []
        state.user.trackedBaseTokenData = []

        action.payload.map((token:TokenData)=>{
          let parsedSimData = token.simulationJSON? JSON.parse(token.simulationJSON): []
          let parsedCallerChannels = token.alphaCallChannels ? JSON.parse(token.alphaCallChannels) : []
          let parsedSecurityData = token.securityArray ? 
            JSON.parse(token.securityArray) : 
            {
              mint_authority_revoked: null,
              liquidity_burnt_pct: undefined,
              owner_transfer_pct: undefined,
              owner_hold_pct: undefined
            }

          let parsedLiquidityData = token.liquidityArray ? JSON.parse(token.liquidityArray) : []
          let tempToken = token

          tempToken = { ...tempToken, parsedSimulationJSON: parsedSimData, parsedAlphaCallChannels: parsedCallerChannels, parsedLiquidityArray: parsedLiquidityData, parsedSecurityDetails: parsedSecurityData, isTracked: true}

          if(tempToken.chain.toLowerCase() === 'eth'){
            state.user.trackedEthereumTokenData = [...state.user.trackedEthereumTokenData, { ...tempToken }]
          }
          else if(tempToken.chain.toLowerCase() === 'solana'){
            state.user.trackedSolanaTokenData = [...state.user.trackedSolanaTokenData, { ...tempToken }]
          }
          else if(tempToken.chain.toLowerCase() === 'base'){
            state.user.trackedBaseTokenData = [...state.user.trackedBaseTokenData, { ...tempToken }]
          }
          
          
        })

        state.user.status = 'idle'
      })
      .addCase(getTokensById.rejected, (state) => {
        state.user.status = 'failed'
      }) 
      .addCase(setActiveChain.pending, (state) => {
        state.user.status = 'loading' 
      })
      .addCase(setActiveChain.fulfilled, (state, action) => {
        state.user = {...state.user, activeChain:action.payload}
        localStorage.setItem('user', JSON.stringify(state.user))
        state.user.status = 'idle'
      })
      .addCase(setActiveChain.rejected, (state) => {
        state.user.status = 'failed'
      })   
      
   }
   
})

export const { updateTokenData } = tokenSlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const selectArchivedTokens = (state: RootState) => state.tokens.archivedData
export const selectLiveUpdatedTokens = (state: RootState) => state.tokens.liveUpdatedData
export const selectSolanaArchivedTokens = (state: RootState) => state.tokens.solanaArchivedData
export const selectSolanaLiveUpdatedTokens = (state: RootState) => state.tokens.solanaLiveUpdatedData
export const selectEthereumTrackedTokens = (state: RootState) => state.tokens.user.trackedEthereumTokenData
export const selectSolanaTrackedTokens = (state: RootState) => state.tokens.user.trackedSolanaTokenData
export const selectBaseArchivedTokens = (state: RootState) => state.tokens.baseArchivedData
export const selectBaseLiveUpdatedTokens = (state: RootState) => state.tokens.baseLiveUpdatedData
export const selectBaseTrackedTokens = (state: RootState) => state.tokens.user.trackedBaseTokenData
export const selectSearchedTokens = (state: RootState) => state.tokens.searchedTokens
export const selectSortBy = (state: RootState) => state.tokens.sortBy
export const selectPrevSortBy = (state: RootState) => state.tokens.prevSortBy
export const selectNextTokenEntry = (state: RootState) => state.tokens.nextTokenEntry
export const selectLoadingState = (state: RootState) => state.tokens.status
export const selectTimeOfLastLoad = (state: RootState) => state.tokens.timeOfLastLoad
export const selectIsRequestFromTimedFetch = (state: RootState) => state.tokens.isRequestFromTimedFetch
export const selectRequestSource = (state: RootState) => state.tokens.tokenDataSource
export const selectPrevRequestSource = (state: RootState) => state.tokens.prevTokenDataSource
export const selectPrevSearchQuery = (state: RootState) => state.tokens.prevSearchQuery
export const selectArchivePageSize = (state: RootState) => state.tokens.user.pageSize
export const selectCurrentPage = (state: RootState) => state.tokens.currentPage
export const selectSolanaCurrentPage = (state: RootState) => state.tokens.solanaCurrentPage
export const selectBaseCurrentPage = (state: RootState) => state.tokens.baseCurrentPage
export const selectViewMode = (state:RootState) => state.tokens.viewMode

export const selectUser = (state: RootState) => state.tokens.user
export const selectMarksInWallet = (state: RootState) => state.tokens.user.marksInWallet
export const selectNonce = (state: RootState) => state.tokens.user.nonce
export const selectAddress = (state: RootState)=> state.tokens.user.address
export const selectSwapAddress = (state: RootState)=> state.tokens.user.swapAddress
export const selectUserAuthorized = (state: RootState) => state.tokens.user.isAuthorized
export const selectCurrentAuthStep = (state: RootState) => state.tokens.user.currentAuthStep
export const selectLoginInProgress = (state:RootState) => state.tokens.user.status
export const selectSignedMessage = (state:RootState) => state.tokens.user.message
export const selectDefaultFilterMode = (state: RootState) => state.tokens.user.filterMode

export const selectDefaultViewMode = (state: RootState) => state.tokens.user.viewMode
export const selectUserId = (state: RootState) => state.tokens.user.id
export const selectTgHandle = (state: RootState) => state.tokens.user.tgHandle
export const selectDefaultPageSize = (state: RootState) => state.tokens.user.pageSize
export const selectNotificationThreshold = (state: RootState)=> state.tokens.user.notificationThreshold
// export const selectScamThreshold = (state: RootState)=> state.tokens.user.scamThreshold
// export const selectBuyTaxThreshold = (state: RootState)=> state.tokens.user.buyTaxThreshold
// export const selectSellTaxThreshold = (state: RootState)=> state.tokens.user.sellTaxThreshold

export const selectMarksmanNotificationThreshold = (state: RootState) => state.tokens.user.marksmanNotificationThreshold
export const selectCallerThreshold = (state: RootState) => state.tokens.user.callerThreshold
export const selectErrorMessage = (state: RootState) => state.tokens.user.errorMessage
export const selectActiveChain = (state: RootState) => state.tokens.user.activeChain

export const selectMarksmanNotificationThresholdSOL = (state: RootState) => state.tokens.user.marksmanNotificationThresholdSOL
export const selectCallerThresholdSOL = (state: RootState) => state.tokens.user.callerNotificationThresholdSOL
export const selectNotificationThresholdSOL = (state: RootState)=> state.tokens.user.sniperNotificationThresholdSOL

export const selectMarksmanNotificationThresholdBASE = (state: RootState) => state.tokens.user.marksmanNotificationThresholdBASE
export const selectCallerThresholdBASE = (state: RootState) => state.tokens.user.callerNotificationThresholdBASE

export const selectUserTrackedTokenIds = (state: RootState) => state.tokens.user.trackedTokenIds
export const selectTrackingData = (state: RootState) => state.tokens.user.trackingData
export const selectUserRequestLoading = (state: RootState) => state.tokens.user.status

export const selectSolanaSubscriptionExpirationDate = (state: RootState) => state.tokens.user.solanaSubscriptionExpirationDate
// We can also write thunks by hand, which may contain both sync and async logic.
// Here's an example of conditionally dispatching actions based on current state.
export const updateTokenSingle =
  (data: TokenData): AppThunk =>
  (dispatch, getState) => {
    const currentData = selectArchivedTokens(getState())
    if (currentData) {
      dispatch(fetchTokensAsync)
    }
  }

export default tokenSlice.reducer
