
export const SUPPORTED_CHAINS = [

	{
		name: 'Ethereum',
		shortName: 'Eth',
		chainId: 1,
		hexChainId: 0x1
	},
    {
        name: 'Solana',
		shortName: 'Sol',
		chainId: 65537,
		hexChainId: 0x10001
    },
    {
        name: 'Base',
		shortName: 'Base',
		chainId: 8453,
		hexChainId: 0x2105
    }
]
