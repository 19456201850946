import * as React from 'react';
import { 
    AppBar,
    Box,
    Toolbar,
    IconButton,
    Typography,
    Menu,
    Link,
    Container,
    Avatar,
    Button,
    Tooltip,
    MenuItem,
    Drawer ,
} from "@mui/material";
import { Grid } from '@mui/joy';
import MenuIcon from '@mui/icons-material/Menu';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide/Slide';

const pages = [
  {title:'Home', linkTo:'/'}, 
  {title:'Tracker', linkTo:'/tracker'},
  { title: 'Contact Us', linkTo: '/contact'}
  
];

const socials = [
    {title:'Telegram', linkTo:'https://t.me/MarksmanPortal', className:'telegram-icon-bg'}, 
    {title:'Twitter', linkTo:'https://twitter.com/MarksmanTools', className:'twitter-icon-bg'},
    {title:'Gitbooks', linkTo:'https://marksman-app.gitbook.io/marksman/', className:'gitbooks-icon-bg'},
];


function HideOnScroll({children}:{children:React.ReactElement}) {


  const trigger = useScrollTrigger({
    target: window
  });

  return (
    <Slide appear={false} direction="down" in={!trigger} >
      {children}
    </Slide>
  );
}

function Footer() {
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(null);
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
  
    
    <Grid 
        container  
        flexGrow={1} 
        xs={12} 
    >
        <Toolbar disableGutters sx={{width:'100%'}}>

            <Grid container  sx={{ flexGrow: 1, display: { md: 'flex' }, justifyContent:'center' }} >
            {pages.map((page) => (
              <div key={page.title} >
                <Typography textAlign="center" >
                  <Button href={page.linkTo} disabled={page.linkTo === window.location.pathname} className='top-nav-buttons'>
                
                    {page.title}
                
                  </Button>
                  
                </Typography>
              </div>
            ))}
            <Grid container sx={{width:'140px!important'}} justifyContent='flex-end'>
            {socials.map((page) => (
                <Grid 
                  key={page.title}  
                  style={{height:'30px', width:page.title==='Gitbooks'?'42px':'30px', marginTop:'auto', marginBottom:'auto', marginRight:'5px', marginLeft:'5px'}}
                >
                        
                    <a href={page.linkTo}  rel="noreferrer noopener" target='_blank'>
                    <div
                        style={{height:'100%'}}
                        
                        className={`top-icons ${page.className}`}>

                    </div>

                    </a>
                </Grid>
            ))}
            </Grid>
        </Grid>
        </Toolbar>
    </Grid>

  );
}
export default Footer;