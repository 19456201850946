import React, { useState, useEffect, useCallback } from "react"
import { 
    Grid,
    Card,
    Tooltip,
    TooltipProps,
    tooltipClasses,
    Modal,
    ModalDialog
} from "@mui/joy"
import {
    Table,
    TableCell,
    TableRow,
    TableBody, 
    TableContainer, 
    Checkbox, 
    TableSortLabel, 
    Box, 
    Input
} from '@mui/material'
import { useAppSelector, useAppDispatch } from "../../../../app/hooks"

import { 
    handleCopyText,
    convertToInternationalCurrencySystem,
    truncateAddress,
    truncateTxAmt,
    getScoreColor,
    getScoreColorTable,
    getSniperColorTable,
    getPct,
    ParseDeadblockData,
    getTaxColor,
    getScamColor,
    getScamPercent,
    isTokenBeyondThreshold,
    returnDataOrObfuscated,
    isLiquidityLocked,
    isLiquidityBurned,
    DEAD_ADDRESS_1,
    DEAD_ADDRESS_2,
    isDeadAddress,
    userHasAccess,
    userHasSolanaAccess
} from "../../../../utilities/utilities"

import { v4 as uuidv4 } from 'uuid'
import { 
  TokenData, 
  selectUser, selectUserId, selectUserTrackedTokenIds, 
  enableTrackingForToken, disableTrackingForToken, 
  selectNonce, selectTrackingData, TrackedToken, selectLoadingState, selectUserRequestLoading } from "../../tokenSlice"
import {Demodal} from 'demodal'
import moment from "moment"

import { CallerModal } from "../CallerModal"
import { SolanaMoreInfoModal } from "./SolanaMoreInfoModal"



export const SolanaTokenRow = React.memo(({row, isMobile}: {row:TokenData, isMobile: boolean}) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const userId = useAppSelector(selectUserId)
    const trackedTokenIds = useAppSelector(selectUserTrackedTokenIds)
    const nonce = useAppSelector(selectNonce)
    const trackingData = useAppSelector(selectTrackingData)
    const loadingStatus = useAppSelector(selectUserRequestLoading)

    const handleTrackToggle = async ({tokenId}:{tokenId:number}) => {

      if(trackedTokenIds.includes(tokenId)){
        const toggledToken:TrackedToken[] = trackingData.filter((token)=> token.tokenId === tokenId)
        await dispatch(disableTrackingForToken({trackData: toggledToken[0], nonce: nonce}))
      }
      else{
        await dispatch(enableTrackingForToken({userId:userId, tokenId: tokenId, nonce: nonce}))
      }
      
    }

    
    return(
      <tr className='table-rows' key={uuidv4()} style={{opacity:isTokenBeyondThreshold(row, user)? '0.5':'1'}}>
        
        <td>
          <Checkbox 
            name={`table-checkbox-${row.id}`}
            id={`table-checkbox-id-${uuidv4()}`}
            checked={row.isTracked? true: false}
            onChange={()=>handleTrackToggle({tokenId: row.id})} 
            disabled={loadingStatus==='loading' || !userId || !nonce}
            size='small'
            sx={{
              color: 'white',
              '&.Mui-checked': {
                color:'#4a91ff',
              },
              '&:hover':{
                color:'#23f9ff'
              }
            }} 
          />
        </td>

        <Tooltip title='More Info' placement='top' arrow>
          <td className="copyable-table-field" style={{fontSize:'18pt'}} onClick={()=>Demodal.open(SolanaMoreInfoModal, { token: row, isMobile: isMobile})}>
              +
          </td>
        </Tooltip>

        <td>
          <Grid 
              direction='column' 
              container
              justifyContent='center' 
              alignItems='center'
          >
            <Grid 
              container
              direction='row' 
              className={`${row.launchedAt?'data-launched-icon-bg':'data-unlaunched-icon-bg'}`} 
              bgcolor={row.launchedAt?'#12af32':'#cf0a0a'}
              sx={{width:'14px', height:'14px'}}
            />
          </Grid>
        </td>
        <td>
          <Tooltip title={row.symbol} placement='top' arrow sx={{maxWidth:'320px'}}>
            <div>
            {row.symbol &&
              row.symbol.substring(0,8) //.substring(0, 12) 
            }
            </div>
          </Tooltip>
        </td>
      
        <td>
          <Tooltip title={row.name} placement='top' arrow sx={{maxWidth:'320px'}}>
            <div >
              {row.name &&
                row.name.substring(0,8)
              }
            </div>
          </Tooltip>
        </td>
        
        <Tooltip title='Copy Address' placement='top' arrow>
          <td className="copyable-table-field" onClick={()=>handleCopyText(row.address)} style={{color: '#4a91ff'}}>
            {row.address &&
              truncateAddress(row.address)
            }
          </td>
        </Tooltip>

        <td>
          <Grid container   flexShrink={1} justifyContent='center' alignItems='center'>
            <Grid  xs={5}>
              {row.address &&
                <a href={`https://solscan.io/token/${row.address}`} target='_blank' rel="noreferrer noopener">
                  <div  className='solscan-link' />  
                </a>
              }
            </Grid>
            
            <Grid xs={5}>
                <a  href={`https://dexscreener.com/solana/${row.address}`} target='_blank' rel="noreferrer noopener">
                  <div  className='chart-link' />  
                </a>
              {/* </Tooltip> */}
            </Grid>
            
            <Grid xs={5}>
              <a  href={`https://rugcheck.xyz/tokens/${row.address}`} target='_blank' rel="noreferrer noopener">
                <div  className='rugcheck-link' />  
              </a>
            </Grid>

            <Grid xs={5}>
              <a href={`https://www.dextools.io/app/en/solana/pair-explorer/${row.address}`}  target='_blank' rel="noreferrer noopener">
                <div className='dextools-link' />  
              </a>
            </Grid>
          </Grid>
        </td>

        <td>
          <Grid container   flexShrink={1} justifyContent='center' alignItems='center'>
            <Grid  xs={5}>
              {row.address &&
                <a href={`https://t.me/solana_unibot?start=r-marksmantools-${row.address}`} target='_blank' rel="noreferrer noopener">
                  <div  className='unibot-link' />  
                </a>
              }
            </Grid>
            <Grid  xs={5}>
              {row.address &&
                <a href={`https://t.me/BananaGunSolana_bot?start=snp_marksman_${row.address}`} target='_blank' rel="noreferrer noopener">
                  <div  className='banana-link' />  
                </a>
              }
            </Grid>
              <Grid xs={5}>
                <a  href={`https://raydium.io/swap/?outputCurrency=${row.address}&inputCurrency=sol`} target='_blank' rel="noreferrer noopener">
                  <div  className='raydium-link' />  
                </a>
                {/* </Tooltip> */}
              </Grid>
              <Grid xs={5}>
                <a href={`https://t.me/bonkbot_bot?start=ref_3th28_ca_${row.address}`}   target='_blank' rel="noreferrer noopener">
                  <div  className='bonk-link' />  
                </a>
              </Grid>


            {/* <Grid xs={5}>
            {row.address &&
              <a  href={`https://t.me/MaestroProBot?start=${row.address}`} target='_blank' rel="noreferrer noopener">
                <div  className='maestro-pro-link' />  
              </a>
            }
            </Grid>

            <Grid xs={5}>
            {row.address &&
              <a href={`https://t.me/MaestroSniperBot?start=${row.address}`} target='_blank' rel="noreferrer noopener">
                <div className='maestro-link' />  
              </a>
            }
            </Grid> */}
          </Grid>
        </td>

        <td>
          <Tooltip title={ `${moment(row.createdAt).format('llll').toLocaleString()}` } placement="top" arrow>
            <Grid>
            {row.createdAt &&
              moment(row.createdAt).fromNow()
            }
            </Grid>
          </Tooltip>
        </td>
        
        {/* <td style={{fontSize:'12pt', color: getSniperColorTable({score: row.sniperScore})}}>
        {
          returnDataOrObfuscated( {data: row.sniperScore})
        }
        </td> */}

        <td style={{ color: getScoreColorTable({score: row.alphaScore}), fontSize:'12pt', backgroundColor:'rgba(0,0,0,.25)', zIndex:100}}>
        {row.alphaScore?
          row.alphaScore.toLocaleString()
          :
          '-'
        }
        </td>
          
        <td >
        {row.buys24h?
          row.buys24h
          :
          '-'
        }
        </td>

        <td >
        {row.sells24h?
          row.sells24h
          :
          '-'
        }
        </td>

        <Tooltip 
          title={
            <div style={{padding:'5px'}}>
              <table>
                <tbody>

                  <tr style={{justifyContent:'space-between'}}>
                    <td className="small-card-headings">
                      Market Cap:
                    </td>
                    <td className='small-card-data'>
                    {row.mCap?
                      '$'+(Number(row.mCap).toLocaleString()): '-'
                    }
                    </td>
                  </tr>
                  <tr style={{justifyContent:'space-between'}}>
                    <td className="small-card-headings">
                      Time:
                    </td>
                    <td className='small-card-data'>
                    {row.mcapAt?
                      moment(row.mcapAt).format('llll').toLocaleString(): '-'
                    }
                    </td>
                  </tr>
                  <tr style={{justifyContent:'space-between'}}>
                    <td className="small-card-headings">
                      Liquidity (Sol):
                    </td>
                    <td className='small-card-data'>
                    {row.liquidityV2?
                      parseFloat(row.liquidityV2).toFixed(4): '-'
                    }
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          } 
          placement="top" 
          arrow
        >
          <td>
            {row.mCap? 
              convertToInternationalCurrencySystem(row.mCap): '-'
            }
          </td>
        </Tooltip>

        <td 
          className={`${row.parsedAlphaCallChannels?.length > 0?'table-num-calls-button':'table-data'}`}
          onClick={row.parsedAlphaCallChannels?.length > 0?()=>Demodal.open(CallerModal, {callers: row.parsedAlphaCallChannels}):()=>{}}
        >
          {row.parsedAlphaCallChannels?
              row.parsedAlphaCallChannels.length : '-'
          }
        </td>

        <td>
        {row.parsedSecurityDetails.owner_transfer_pct?
          row.parsedSecurityDetails.owner_transfer_pct
          :
          '-'
        }
        </td>

        <td>
        {row.parsedSecurityDetails.owner_hold_pct?
          row.parsedSecurityDetails.owner_hold_pct
          :
          '-'
        }
        </td>

        <td>
          <Grid container  justifyContent='center' alignItems='center'>
            {row.parsedSecurityDetails && row.parsedSecurityDetails.mint_authority_revoked?
              <Tooltip 
                title={
                  <div style={{padding:'5px', color:'white'}}>
                    Contract Renounced
                  </div>
                } 
                placement="top" 
                arrow
              >
                <Grid 
                  className='renounced-icon-bg'
                  sx={{width:'18px', height:'18px', margin:'auto', padding:'auto'}}
                  bgcolor={row.parsedSecurityDetails.mint_authority_revoked?'#12af32':'#000000'}
                />
              </Tooltip>
              :
              <Grid sx={{width:'18px', height:'18px', margin:'auto', padding:'auto'}} />
            }
              {/* {row.contractVerified &&  (row.contractVerified === true || row.contractVerified === 1)?
              <Tooltip 
                title={
                  <div style={{padding:'5px', color:'white'}}>
                    Contract Verified
                  </div>
                } 
                placement="top" 
                arrow
              >
                <Grid 
                  className='big-check-icon-bg'
                  sx={{width:'18px', height:'18px', margin:'auto', padding:'auto'}}
                  bgcolor={row.contractVerified?'#12af32':'#000000'}
                />
              </Tooltip>
              :
              <Grid sx={{width:'18px', height:'18px', margin:'auto', padding:'auto'}} />
            } */}
            {/* {row.parsedLiquidityArray && isLiquidityLocked({liquidityArray: row.parsedLiquidityArray})?
            
              <Tooltip 
                title={
                  <div style={{padding:'5px'}}>
                  {row.parsedLiquidityArray?.locked_detail?
                    <table>
                      <thead>
                        <tr className="table-header">
                          <th className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            Locker
                          </th>
                          <th className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            Lock<br/>Date
                          </th>
                          <th className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            Unlock<br/>Date
                          </th>
                          <td className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            Lock or<br/>Burn %
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {row.parsedLiquidityArray.locked_detail.map((lockDetail, index)=>
                          <tr key={index} className="table-rows" style={{textAlign:'center', justifyContent:'center', alignItems:'center'}}>
                            <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            {row.parsedLiquidityArray?.tag && row.parsedLiquidityArray.tag !== ''?
                              row.parsedLiquidityArray.tag
                              : 
                              <>
                              {row.parsedLiquidityArray?.address && isDeadAddress({address:row.parsedLiquidityArray.address})?
                                'Null Address'
                                :
                                '-'
                              }
                              </>
                            }
                            </td>

                            
                            <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            {lockDetail && lockDetail.opt_time?
                              moment(lockDetail.opt_time).format('llll').toLocaleString(): '-'
                            }
                            </td>
                        
                        
                            <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            {lockDetail && lockDetail.end_time?
                              moment(lockDetail.end_time).format('llll').toLocaleString(): '-'
                            }
                            </td>
                      
                            <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            {row.parsedLiquidityArray?.percent?
                              (row.parsedLiquidityArray.percent * 100 ).toFixed(2): '-'
                            }
                            </td>
                          </tr>
                        
                        )

                        }
                      </tbody>
                    </table>
                    :
                    <table>
                      <thead>
                        <tr className="table-header">
                          <th className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            Locker
                          </th>
                          <th className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            Lock<br/>Date
                          </th>
                          <th className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            Unlock<br/>Date
                          </th>
                          <td className="table-header" style={{textAlign:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            Lock or<br/>Burn %
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                          <tr key={row.id} className="table-rows" style={{textAlign:'center', justifyContent:'center', alignItems:'center'}}>
                            <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            {row.parsedLiquidityArray?.tag && row.parsedLiquidityArray.tag !== ''?
                              row.parsedLiquidityArray.tag
                              : 
                              <>
                              {row.parsedLiquidityArray?.address && isDeadAddress({address:row.parsedLiquidityArray.address})?
                                'Null Address'
                                :
                                '-'
                              }
                              </>
                            }
                            </td>

                            
                            <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                              -
                            </td>
                        
                        
                            <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                              -
                            </td>
                      
                            <td style={{textAlign:'center', justifyContent:'center', alignItems:'center', paddingLeft:'5px', paddingRight:'5px'}}>
                            {row.parsedLiquidityArray?.percent?
                              (row.parsedLiquidityArray.percent * 100 ).toFixed(2): '-'
                            }
                            </td>
                          </tr>
                      </tbody>
                    </table>
                  }
                  </div>
                } 
                placement="top" 
                arrow
              >
                <Grid 
                  className='card-lock-icon-bg'
                  sx={{width:'18px', height:'18px', margin:'auto', padding:'auto'}}
                  bgcolor='#12af32'
                />
              </Tooltip>
              :
              <Grid sx={{width:'18px', height:'18px', margin:'auto', padding:'auto'}} />
            } */}
            {row.parsedSecurityDetails && row.parsedSecurityDetails.liquidity_burnt_pct && row.parsedSecurityDetails.liquidity_burnt_pct > 0?
              <Tooltip 
                title={
                  <div style={{padding:'5px', color:'white'}}>
                    Burn % :  {row.parsedSecurityDetails && row.parsedSecurityDetails.liquidity_burnt_pct && row.parsedSecurityDetails.liquidity_burnt_pct > 0? row.parsedSecurityDetails.liquidity_burnt_pct : '-' }
                  </div>
                } 
                placement="top" 
                arrow
              >
                <Grid 
                  className='card-burn-icon-bg'
                  sx={{width:'18px', height:'18px', margin:'auto', padding:'auto'}}
                  bgcolor='#12af32'
                />
              </Tooltip>
              :
              <Grid sx={{width:'18px', height:'18px', margin:'auto', padding:'auto'}} />
              
            }
          </Grid>
        </td>

        {/* 
          <td >
          <Tooltip title='Open in Maestro' arrow placement='top'>

            <a  style={{ color: 'inherit', textDecoration:'none' }} href={`https://t.me/MaestroProBot?start=${row.address}`} target='_blank' rel="noreferrer noopener">
              <div className='copyable-table-field'>

              { row.alphaHitsMaestro ?
                row.alphaHitsMaestro :' -'
              }
              </div>
            </a>
          </Tooltip>
          </td> 
        */}

          {/* <td >
          <Tooltip title='Open in Banana' arrow placement='top'>

            <a  style={{ color: 'inherit', textDecoration:'none' }} href={`https://t.me/BananaGunSniper_bot?start=${row.address}`} target='_blank' rel="noreferrer noopener">
              <div className='copyable-table-field'>

              { getBananaScore(row) &&
                getBananaScore(row) 
              }
              </div>
            </a>
          </Tooltip>
          </td>

        */}

          {/* <td >
          {row.parsedSecurityDetails.owner_transfer_pct?
              row.parsedSecurityDetails.owner_transfer_pct
              :
              'fuck'
          }
          </td>

          <td >
          {row.parsedSecurityDetails.owner_hold_pct?
              row.parsedSecurityDetails.owner_hold_pct
              :
              'fuck'
          }
          </td> */}

          {/* <td >
          { getCanBuySell(row.canBuy) } / { getCanBuySell(row.canSell) }
          </td> */}
          {/* 
          {userHasAccess()?
          <td >
            <Grid container sx={{width:'100%'}} justifyContent='center' alignItems='center' textAlign='center'>
              <Tooltip title={`(Num Scams: ${row.scamsCount} / Checksum Matches: ${!row.totalCopiesCount || row.totalCopiesCount === 0?1:row.totalCopiesCount}`} arrow placement="top">
                <div style={{textAlign:'center', color: getScamColor(!row.totalCopiesCount || row.totalCopiesCount === 0?1:row.totalCopiesCount, row.scamsCount)}}>
                  {getScamPercent(!row.totalCopiesCount || row.totalCopiesCount === 0?1:row.totalCopiesCount, row.scamsCount)}
                </div>
              </Tooltip>
              {row.warningMessage &&
                <div style={{marginLeft:row.warningMessage?'5px':'auto'}}>
                {isMobile?
                  <div
                  onClick={()=>Demodal.open( FieldModal, {fieldTitle:'Warning', fieldData: row.warningMessage })} 
                  className='warning-icon'  
                  style={{ display:'inline-block', width:'14px', height:'14px', margin:'0px',  padding:'0px', marginLeft:'5px', justifyContent:'center', alignItems:'center', verticalAlign:'center'}}
                  />
                  :
                  <Tooltip title={<div style={{maxWidth:'300px'}}>{row.warningMessage}</div>} placement="top" arrow>
                    <div 
                      className='warning-icon'  
                      style={{ display:'inline-block', width:'14px', height:'14px', margin:'0px',  padding:'0px', marginLeft:'5px', justifyContent:'center', alignItems:'center', verticalAlign:'center'}}
                    />
                  </Tooltip>
                }
                </div>
              }
            </Grid>
          </td>
          :
          <td>
            &#128274;
          </td>
        } 
        */}
      </tr>
    )
  })