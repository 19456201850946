import React, { useState, useEffect, useCallback } from "react"
import { 
    Grid,
    Card,
    Tooltip,
    TooltipProps,
    tooltipClasses,
    Modal,
    ModalDialog
} from "@mui/joy"
import {
    Table,
    TableCell,
    TableRow,
    TableBody, 
    TableContainer, 
    Checkbox, 
    TableSortLabel, 
    Box, 
    Input
} from '@mui/material'
import { useAppSelector, useAppDispatch } from "../../../../app/hooks"

import { 
    handleCopyText,
    convertToInternationalCurrencySystem,
    truncateAddress,
    truncateTxAmt,
    getScoreColor,
    getScoreColorTable,
    getPct,
    ParseDeadblockData,
    getTaxColor,
    getScamColor,
    getScamPercent,
    isTokenBeyondThreshold,
    DEAD_ADDRESS_1, 
    DEAD_ADDRESS_2,    
    isLiquidityBurned,
    isLiquidityLocked
} from "../../../../utilities/utilities"

import { v4 as uuidv4 } from 'uuid'
import { TokenData, selectUser, selectMarksInWallet, selectActiveChain, ChainIds } from "../../tokenSlice"
import {Demodal} from 'demodal'
import moment from "moment"

import { CallerModal } from "../CallerModal"
import { McapModal } from "../McapModal"
import { McapTooltip } from "../McapTooltip"
import { FieldModal } from "../FieldModal"
import { MoreInfoModal } from "../MoreInfoModal"
import { LockedModal } from "../LockedModal"



export const ObfuscatedCard = React.memo(({token, isMobile, cardWidth}: {token: TokenData, isMobile: boolean, cardWidth: string}) => {
    
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const marksInWallet = useAppSelector(selectMarksInWallet)
    const activeChain = useAppSelector(selectActiveChain)

    const handleTrackToggle = ({id}:{id:number}) => {
        dispatch({type:"tokens/toggleTrackingForToken", payload:id})
    }

    return(
      <Grid sx={{ filter:'drop-shadow(0px 0px 7px black)', opacity:isTokenBeyondThreshold(token, user)? '0.5':'1'}}>
        <Card className='card-back' sx={{width: cardWidth, padding:0, border:'1px solid rgba(255,255,255,.1)'}}>
          <Grid container xs={12}  sx={{margin:0, padding:0}}>
            <Grid container xs={12}  sx={{height:'30px', marginTop:'0px'}}>
                
                <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginLeft:'10px!important'}}>
                    &#128274;
                </Grid>

                <Grid container  justifyContent='center' alignItems='center' flexDirection='row' sx={{margin:'auto', padding:'auto', marginRight:'0px!important'}}>
                    <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}>
                        &#128274;
                    </Grid>
           
                    <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}>
                        &#128274;
                    </Grid>

                    <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}>
                        &#128274;
                    </Grid>
 
                    <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}>
                        &#128274;
                    </Grid>
            
                    <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}>
                        &#128274;
                    </Grid>
                </Grid>

                <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}>
                    &#128274;
                </Grid>

                <Grid sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}>
                    &#128274;
                </Grid>

            </Grid>
          </Grid>


          <Grid className='card-mid' sx={{margin:0}}>

            <Grid container xs= {12} justifyContent='center' flexShrink={1} display='flex' sx={{marginBottom:0, paddingBottom:'0px'}}>
              <span style={{marginRight:'5px'}} className='card-creation-date-heading'> Symbol:  </span>
              {token.symbol &&
                
                <>
                {isMobile?

                  <div className='card-creation-date' onClick={()=>Demodal.open(FieldModal, {fieldTitle: 'Symbol:', fieldData: token.symbol})}>
                  {token.symbol &&
                    token.symbol.substring(0,10)
                  }
                  </div>
                  :
                  <Tooltip title={token.symbol} placement="top" arrow>

                    <div className='card-creation-date'>
                    {token.symbol &&
                      token.symbol.substring(0,10)
                    }
                    </div>
                  </Tooltip>
                }
                </>
              }
            </Grid>

            <Grid container xs={12}  sx={{marginTop:'0px', paddingTop:'0px'}}>
              <Grid xs={5} alignItems='space-evenly' flexDirection='column' direction='column' flexGrow={1}  > 
                <Grid textAlign='left'  className='card-headings'  xs={12} >
                  Name
                </Grid>
              
                <Grid  xs={12} className='card-data'>
                {isMobile?

                  <div className='card-creation-date' onClick={()=>{Demodal.open( FieldModal, {fieldTitle: 'Name:', fieldData: token.name})}}>
                  {token.name &&
                    token.name.substring(0,10)
                  }
                  </div>
                :
                  <Tooltip title={token.name} placement="top" arrow>

                    <div className='card-creation-date'>
                    {token.name &&
                      token.name.substring(0,10)
                    }
                    </div>
                  </Tooltip>
                }
                </Grid>
               
                <Grid textAlign='left'  className='card-headings'  xs={12} >
                  Creation Date
                </Grid>
                <Grid  xs={12} className='card-data'>
                  <Tooltip title={ `${moment(token.createdAt).format('llll').toLocaleString()}` } placement="top-start" arrow>
                    <div>
                    {token.createdAt?
                      moment(token.createdAt).fromNow()
                      :
                      '-'
                    }
                    </div>
                  </Tooltip>

                </Grid>
               
                <Grid textAlign='left'  className='card-headings'  xs={12}>
                  Contract
                </Grid>
                <Tooltip title='Copy Address' placement='top-start' arrow>
                <Grid  
                  xs={12} 
                  className='card-data copyable-field'  
                  onClick={()=>handleCopyText(token.address)} 
                  style={{ color: '#4a91ff' }}
                >
                    {token.address?
                      truncateAddress(token.address)
                      :
                      '-'
                    }
                  </Grid>
                </Tooltip>

                <Grid textAlign='left'  className='card-headings'  xs={12}>
                  Deployer
                </Grid>
                {marksInWallet && marksInWallet >= 500?
                  <Grid  
                    xs={12}  
                  >
                  {token.deployerAddress?
                    <a className="card-data copyable-field" href={`${token.chain === 'base'?'https://basescan.org':'https://etherscan.io'}/address/${token.deployerAddress}`} target='_blank' rel="noreferrer noopener">
                    {
                      truncateAddress(token.deployerAddress)
                    }
                    </a>
                    :
                    '-'
                  }
                  </Grid>
                  :
                  <Grid 
                    sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}
                  >
                    &#128274;
                  </Grid>
                }
                {/* <Grid textAlign='left'  className='card-headings'  xs={12}>
                  Eth In Wallet
                </Grid>
                <Grid
                className='card-data'  
                  xs={12} 
                >
                {token.ethInWallet? token.ethInWallet: 'Loading...'}
                </Grid> */}


                <Grid textAlign='left'  className='card-headings'  xs={12}>
                  Max Tx
                </Grid>
                {marksInWallet && marksInWallet >= 500?
                  <Grid
                    className='card-data copyable-field'  
                    xs={12}  
                    onClick={()=>handleCopyText(token.maxTxAmount)}
                  >
                    {token.maxTxAmount?
                      truncateTxAmt(token.maxTxAmount)
                    :
                    '-'
                  }
                  </Grid>
                  :
                  <Grid 
                    sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}
                  >
                    &#128274;
                  </Grid>
                }

                <Grid textAlign='left'  className='card-headings'  xs={12}>
                  Max Wallet
                </Grid>
                {marksInWallet && marksInWallet >= 500?
                  <Grid
                    className='card-data copyable-field'  
                    xs={12}  
                    onClick={()=>handleCopyText(token.maxWalletAmount)}
                  >
                    {token.maxWalletAmount?
                      truncateTxAmt(token.maxWalletAmount)
                    :
                    '-'
                    }
                  </Grid>
                  :
                  <Grid 
                    sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}
                  >
                    &#128274;
                  </Grid>
                }

              </Grid>

             

              <Grid xs={7} flexDirection='column' direction='column' flexGrow={1} justifyContent='flex-end' alignItems='flex-end' > 
                <Grid className='card-headings'  container xs={12} justifyContent='flex-end'>
                  Marksman Score
                </Grid>
                {marksInWallet && marksInWallet >= 500?
                  <Grid className='alpha-field' container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end'>
                    <div  style={{ width:'125px', backgroundColor:`${getScoreColor({score: token.alphaScore})}`, color:'black', textAlign:'center'}}>
                    {token.alphaScore?
                      token.alphaScore.toLocaleString(): '-'  
                    }
                    </div>
                  </Grid>
                  :
                  <Grid 
                    container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end'
                  >
                    &#128274;
                  </Grid>
                }
            
                <Grid className='card-headings'  justifyContent='flex-end' textAlign='right'>
                  Sniper Score
                </Grid>
                {marksInWallet && marksInWallet >= 500?
                  <Grid container className='sniper-field'  alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end'>
                    <div  style={{ width:'125px', backgroundColor:`${getScoreColor({score: token.sniperScore})}`, color:'black', textAlign:'center', marginBottom:'10px'}}>
                    {token.sniperScore?
                      token.sniperScore.toLocaleString(): '-'  
                    }
                    </div>
                  </Grid>
                  :
                  <Grid 
                    container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end'
                  >
                    &#128274;
                  </Grid>
                }
        


{/*      
                <Grid  container xs={12} justifyContent='flex-end' style={{marginTop:'10px'}}>
                  <span className='small-card-headings'>
                    Maestro:
                  </span>
                  <span className='small-card-data'  >

                  {token.alphaHitsMaestro?
                    token.alphaHitsMaestro: '-'  
                  }
                  </span>
                </Grid> */}

                <Grid container xs={12} justifyContent='flex-end' alignItems='center'>
                  <span className='small-card-headings'>
                    Market Cap:
                  </span>
                  {marksInWallet && marksInWallet >= 500?
                    <span 
                      className='num-calls-button' 
                      style={{marginLeft:'5px', justifyContent: 'center', alignItems: 'center'}} 
                      onClick={()=>Demodal.open( McapModal, { symbol:token.symbol, mCap: token.mCap, mcapAt: token.mcapAt, liquidityV2: token.liquidityV2, liquidityV3: token.liquidityV3 })}
                    >
                      <Grid container style={{justifyContent: 'center', alignItems: 'center'}} >

                      {token.mCap ?
                        '' + convertToInternationalCurrencySystem(token.mCap) : '-'
                      }
                          
                        <div 
                          className="info-icon"
                          style={{
                            margin: 'auto', padding:'auto',
                            marginLeft: '5px',
                            height:'16px', width:'16px',
                            zIndex: 900,
                            
                          }}
                        />
                      </Grid>
            
                    </span>
                    :
                    <Grid 
                      container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'
                    >
                      &#128274;
                    </Grid>

                  }
                </Grid>

                <Grid container xs={12} justifyContent='flex-end' alignItems='center'>
                  <span className='small-card-headings'>
                    Num Calls:
                  </span>
                  {marksInWallet && marksInWallet >= 500?
                    <span 
                      className='small-card-data num-calls-button'
                      style={{marginLeft:'5px', justifyContent: 'center', alignItems: 'center'}} 
                      onClick={token.parsedAlphaCallChannels && token.parsedAlphaCallChannels.length > 0?()=>Demodal.open(CallerModal,{ symbol:token.symbol, callers: token.parsedAlphaCallChannels }):()=>{}}
                    >
                    {token.parsedAlphaCallChannels ?
                      '' + token.parsedAlphaCallChannels.length : '-'
                    }
                    </span>
                    :
                    <Grid 
                      container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'
                    >
                      &#128274;
                    </Grid>
                  }
                </Grid>
                <Grid container xs={12} justifyContent='flex-end'>
                  <span className='small-card-headings'>
                    Holders:
                  </span>
                  {marksInWallet && marksInWallet >= 500?
                    <span className='small-card-data'  >

                    {token.holdersCount?
                      token.holdersCount: '-'  
                    }
                    </span>
                    :
                    <Grid 
                      container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'
                    >
                      &#128274;
                    </Grid>
                  }
                </Grid>

               
                {/* <Grid textAlign='right' className='card-headings'  xs={12} style={{margin:0, marginBottom:'5px', padding:0}}>
                  Banana
                </Grid>

                <Grid textAlign='right' className='card-data'  xs={12} style={{margin:0, marginBottom:'5px', padding:0}}>
                  {token.alphaHitsMaestro && token.alphaHitsMaestro >= 0?
                    <>
                    {getBananaNumber(token.alphaBanana)}
                    </>
                    :
                    <>
                      -
                    </>
                  }
                </Grid> */}

                <Grid container xs={12} justifyContent='flex-end'>
                  <span className='small-card-headings'>
                    Max Tx %:
                  </span>
                  {marksInWallet && marksInWallet >= 500?
                    <span className='small-card-data'  >

                    {token.maxTxPct?
                      token.maxTxPct: ' -'  
                    }
                    </span>
                    :
                    
                    <Grid 
                      container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'
                    >
                      &#128274;
                    </Grid>
                  }
                </Grid>


            
                
                <Grid container xs={12} justifyContent='flex-end'>
                  <span className='small-card-headings'>
                    Max Wallet %:
                  </span>
                  {marksInWallet && marksInWallet >= 500?
                    <span className='small-card-data'  >
                    {token.maxWalletPct?
                      ' ' + getPct(token.maxWalletPct)
                      :
                      ' -'
                    }
                    </span>
                    :
                    <Grid 
                      container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'
                    >
                      &#128274;
                    </Grid>
                  }
                </Grid>

             
                
                {/* <Grid  container xs={12} justifyContent='flex-end'>
                  <span className='small-card-headings'>
                    Buy / Sell?:
                  </span>
                  <span className='small-card-data'  >

                  {token.canBuy?
                    getCanBuySell(token.canBuy):"-"} / {token.canSell?getCanBuySell(token.canSell):'-'
                  }
                  </span>
                </Grid> */}

                <Grid container xs={12} justifyContent='flex-end'>
                  <span className='small-card-headings'>
                    Buy Tax %:
                  </span>
                  {marksInWallet && marksInWallet >= 500?
                    <span className='small-card-data'  >
                    {token.taxesBuy?
                      token.taxesBuy:
                      ' -'
                    }
                    </span>
                    :
                    <Grid 
                      container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'
                    >
                      &#128274;
                    </Grid>
                  }
                </Grid>
                <Grid container xs={12} justifyContent='flex-end'>
                  <span className='small-card-headings'>
                    Sell Tax %:
                  </span>
                  {marksInWallet && marksInWallet >= 500?
                    <span className='small-card-data'  >
                    {token.taxesSell?
                      token.taxesSell
                      :
                      ' -'
                    }
                    </span>
                    :
                    <Grid 
                      container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'
                    >
                      &#128274;
                    </Grid>
                  }
                </Grid>

                <Grid container xs={12} justifyContent='flex-end'>
                  <span className='small-card-headings'>
                    Checksum Matches:
                  </span>
                  {marksInWallet && marksInWallet >= 500?
                    <span className='small-card-data' style={{ fontSize:'10pt'}}>
                      { token.totalCopiesCount?
                        token.totalCopiesCount
                      :
                      '-'
                      }
                    </span>
                    :
                    <Grid 
                      container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'
                    >
                      &#128274;
                    </Grid>
                  }
                </Grid>

                <Grid container xs={12} justifyContent='flex-end'>
                  <span className='small-card-headings' >
                    Num Scams:
                  </span>
                  {marksInWallet && marksInWallet >= 500?
                    <span className='small-card-data' style={{fontSize:'10pt'}}>
                      { token.scamsCount?
                        token.scamsCount
                      :
                      '-'
                      }
                    </span>
                    :
                    <Grid 
                      container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'
                    >
                      &#128274;
                    </Grid>
                  }
                </Grid>
            
                <Grid  container xs={12} justifyContent='flex-end'>
                  <span className='small-card-headings'>
                    Scam %:
                  </span>
                  {marksInWallet && marksInWallet >= 500?
                    <span className='small-card-data' style={{color: getScamColor(token.totalCopiesCount, token.scamsCount), fontSize:'10pt'}}>
                      {token.totalCopiesCount && token.scamsCount?
                        getScamPercent(token.totalCopiesCount, token.scamsCount)
                      :
                      '-'
                      }
                      {token.warningMessage &&
                        <span style={{marginLeft:token.warningMessage?'5px':'auto'}}>
                        |
                        {isMobile?

                          <div
                          onClick={()=>Demodal.open( FieldModal, {fieldTitle:'Warning', fieldData: token.warningMessage })} 
                          className='warning-icon'  
                          style={{ display:'inline-block', width:'14px', height:'14px', margin:'0px',  padding:'0px', marginLeft:'5px', justifyContent:'center', alignItems:'center', verticalAlign:'center'}}
                          />
                          :
                          <Tooltip title={<div style={{maxWidth:'300px'}}>{token.warningMessage}</div>} placement="top" arrow>
                            <div 
                              className='warning-icon'  
                              style={{ display:'inline-block', width:'14px', height:'14px', margin:'0px',  padding:'0px', marginLeft:'5px', justifyContent:'center', alignItems:'center', verticalAlign:'center'}}
                            />
                          </Tooltip>
                        }
                      </span>
                        
                      }
                    </span>
                    :
                    <Grid 
                      container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'
                    >
                      &#128274;
                    </Grid>

                  }
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid className='card-data' justifyContent='center' container xs={12} sx={{width: cardWidth}}>
            <Grid textAlign='center'  className='block-sim-headings'  xs={12} sx={{marginTop:'0px', paddingTop:'0px', marginBottom:'5px'}}>
                  Simulated Block Data 
            </Grid>
            

            {marksInWallet && marksInWallet >= 500?
              <>
                <Grid container className="small-card-headings" style={{ textAlign:'center', marginBottom:'5px'}}>(  #: Block Num,  B: Buy Tax %, S: Sell Tax % )</Grid >
                <>

                  {token.parsedSimulationJSON && token.parsedSimulationJSON.length > 0? 
    
                    <Table size="small" sx={{height:'100px!important'}}>
    
                      <TableBody sx={{width: cardWidth }}>
                        <TableRow>
                          <TableCell sx={{color:'#ffffff', fontWeight:'bold', paddingLeft:'2px', paddingRight:'2px', textAlign:'center', border:'1px solid white'}}>#</TableCell>
                          {token.parsedSimulationJSON.map(block => 
                              <TableCell key={`${token.id}-${block.blockNum}-card`} sx={{ paddingLeft:'0px', paddingRight:'0px', textAlign:'center', border:'1px solid #ffffff', color:'#ffffff'}}> 
                                {block.blockNum}  
                              </TableCell>
                            )}
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{color:'#ffffff', fontWeight:'bold', paddingLeft:'2px', paddingRight:'2px', textAlign:'center', border:'1px solid white'}}>B</TableCell>
                          {token.parsedSimulationJSON.map(block => 
                            <TableCell key={`${token.id}-${block.blockNum}-card`} sx={{ paddingLeft:'0px', paddingRight:'0px', textAlign:'center', border:'1px solid #ffffff', color:getTaxColor({pct:block.buy})}}> 
                              <ParseDeadblockData pct={block.buy} /> 
                            </TableCell>
                          )}
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{color:'#ffffff', fontWeight:'bold', paddingLeft:'2px', paddingRight:'2px', textAlign:'center', border:'1px solid white'}}>S</TableCell>
                          {token.parsedSimulationJSON.map(block => 
                            <TableCell key={`${token.id}-${block.blockNum}-card`} sx={{paddingLeft:'0px', paddingRight:'0px', textAlign:'center', border:'1px solid #ffffff', color:getTaxColor({pct:block.sell})}}> 
                              <ParseDeadblockData pct={block.sell} /> 
                            </TableCell>
                          )}
                        </TableRow>
                      </TableBody>
                    </Table>
    
                    :
                    <Grid 
                      container
                      sx={{
                        fontSize:'10pt!important', 
                        backgroundColor:'black', 
                        height:'100px', lineHeight:'10pt',
                        width: '100%',
                      }}
                      justifyContent='center' alignItems='center'
                    >
                      Data Not Yet Available
                    </Grid>
                  }
                </>
              </>
              :
              <Grid 
                container alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end' ml={1} fontSize='16px'
              >
                &#128274;
              </Grid>
            }
          </Grid>
          <Grid container xs={12}  justifyContent='center' flexDirection='row' flexGrow={1} style={{marginBottom:'0px!important', paddingBottom:'0px!important', marginTop:'0px!important', paddingTop:'0px!important'}}>
            <Grid container direction='row' xs={12} justifyContent='center' alignItems='flex-start'>

              <Grid>
                <a className="card-links" href={`${activeChain === ChainIds.Ethereum?'https://etherscan.io':'https://solscan.io'}/token/${token.address}`}  target='_blank' rel="noreferrer noopener">
                {activeChain === ChainIds.Ethereum?
                  'Etherscan':
                  'Solscan'
                }  
                </a>
              </Grid>
              <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
              <Grid>
                <a className='card-links' href={`https://dexscreener.com/${activeChain === ChainIds.Ethereum?'ethereum':'solana'}/${token.address}`} target='_blank' rel="noreferrer noopener">
                  Dexscreener
                </a>
              </Grid>
              <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
              
              <Grid>
                
                <a className='card-links' href={`https://www.dextools.io/app/en/${activeChain === ChainIds.Ethereum?'ether':'solana'}/pair-explorer/${token.address}`} target='_blank' rel="noreferrer noopener">
                  Dextools
                </a>
              </Grid>     
              
              <Grid  >
              {token.address && activeChain === ChainIds.Ethereum &&
              <>
                <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
                <a className="card-links"  href={`https://honeypot.is/${token.chain === 'base'?'base':'ethereum'}?address=${token.address}`} target='_blank' rel="noreferrer noopener">
                  Honeypot
                </a>
              </>
              }
              {token.address && activeChain === ChainIds.Solana &&
              <>
                <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
                <a className="card-links"  href={`https://rugcheck.xyz/tokens/${token.address}`} target='_blank' rel="noreferrer noopener">
                  Rugcheck
                </a>
              </>
              }
              </Grid>
            </Grid>

            <Grid container direction='row' xs={12} justifyContent='center' alignContent='space-evenly' mb='10px'>

              <Grid>
                <a className="card-links"  href={activeChain === ChainIds.Ethereum? `https://t.me/BananaGunSniper_bot?start=snp_marksman_${token.address}`: `https://t.me/BananaGunSolana_bot?start=snp_marksman_${token.address}`} target='_blank' rel="noreferrer noopener">
                  Banana
                </a>
              </Grid>
              <span style={{marginLeft: 5, marginRight: 5}}>|</span>

              {activeChain === ChainIds.Solana &&
              <>
                <Grid>
                  <a className="card-links"  href={activeChain === ChainIds.Solana? `https://t.me/bonkbot_bot?start=ref_3th28_ca_${token.address}`:''}>
                    Bonk
                  </a>
                </Grid>
                <span style={{marginLeft: 5, marginRight: 5}}>|</span>      
              </>
              }

              {/*
               <Grid>
                <a className="card-links"  href={`https://t.me/MaestroProBot?start=${token.address}`} target='_blank' rel="noreferrer noopener">
                  Maestro Pro
                </a>
              </Grid>
              <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
              <Grid>
                <a className="card-links"  href={`https://t.me/MaestroSniperBot?start=${token.address}`} target='_blank' rel="noreferrer noopener">
                  Maestro
                </a>
              </Grid> 
              <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
            */}
            {activeChain === ChainIds.Ethereum &&
              <Grid>
                <a className="card-links"  href={`https://app.uniswap.org/#/swap?outputCurrency=${token.address}`} target='_blank' rel="noreferrer noopener">
                  Uniswap
                </a>
              </Grid>
            }

            {activeChain === ChainIds.Solana &&
            <>
              <Grid>
                <a className="card-links"  href={`https://raydium.io/swap/?outputCurrency=${token.address}&inputCurrency=sol`} target='_blank' rel="noreferrer noopener">
                  Raydium
                </a>
              </Grid>
              <span style={{marginLeft: 5, marginRight: 5}}>|</span>   
              <Grid>
                <a className="card-links"  href={`https://jup.ag/swap/SOL-${token.address}`} target='_blank' rel="noreferrer noopener">
                  Jupiter
                </a>
              </Grid>
            </>
            }

            </Grid>
          </Grid>

        </Card>
      </Grid>
    )
  })