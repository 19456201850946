import { createRef } from 'react';
import { 
    Dialog,
    Popover
} from '@mui/material'
import { 
    Grid,
    Card,
} from "@mui/joy"

import { Demodal, useModal } from "demodal";
import { muiDialog } from 'demodal/material-ui'

import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { 
  ChainIds,
  selectUserId,
  selectUser,
  selectActiveChain,
  setActiveChain,
  selectNonce
} from "../tokenSlice"

import { getChainIcon } from '../../../utilities/utilities'; 
import { SUPPORTED_CHAINS } from '../../../chainConfig';


export const ChainModal = Demodal.create(
    (
        {anchor}:{anchor: Element}
    ) => {

    const chainModal = useModal()
    const userId = useAppSelector(selectUserId)
    const user = useAppSelector(selectUser)
    const activeChain = useAppSelector(selectActiveChain)
    const nonce = useAppSelector(selectNonce)

    const dispatch = useAppDispatch()

    const handleChainSwitch = async (chain: ChainIds) => {
        await dispatch({ type: "tokens/setLocalChain", payload: chain })
        if(userId && nonce){
            await dispatch(setActiveChain({userId: userId, nonce: nonce, activeChain: chain}))
        }
        await chainModal.close()
    }

    return (

    <Popover
        {...muiDialog(chainModal)} 
        anchorEl={anchor}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}

       onClose={()=>chainModal.close()}
    >

        <Grid container  justifyContent='space-between' xs={12}
            sx={{ height:'max-content', backgroundColor:'#000000', borderRadius: '0px', border: 'none'}}
        >
           
           {SUPPORTED_CHAINS.map((chain)=>
            <Grid 
                key={chain.chainId.toString()}
                container 
                xs={12} 
                justifyContent='center' 
                alignItems='center'
                sx={{
                    margin:'5px',
                    height:'32px', 
                    width: '100%', 
                    backgroundColor: 'black',
                    borderRadius: '0px',
                    border: 'none', 
                    opacity: activeChain === chain.chainId? 1:0.7,
                    cursor:activeChain === chain.chainId?'default':'pointer', 
                    '&:hover':{ 
                        opacity: 1
                    }
                }}
                onClick={()=>{handleChainSwitch(chain.chainId)}}
            >

                <Grid xs={4} container textAlign='center' justifyContent='center' alignItems='center' sx={{height:'32px', width:'32px'}}>
                    <img src={getChainIcon(chain.chainId)} style={{height:'32px', width:'32px'}}/>
                </Grid>
                <Grid
                    container  
                    xs={8} textAlign='left' justifyContent='center' alignItems='center'
                    sx={{backgroundColor:'#000000', borderRadius: '0px', color: 'white', border:'none', height:'32px'}}
                >
                {
                    chain.name
                }
                </Grid> 
            </Grid>
           )

           }
        </Grid>
    </Popover>
    )
})

