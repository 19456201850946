import React, { useState, useEffect } from "react"
import { 
  Grid,
} from "@mui/joy"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import {
  ChainIds, 
  TokenData,
  LaunchedTypes,
  selectArchivedTokens,
  selectLiveUpdatedTokens,
  selectSolanaArchivedTokens,
  selectSolanaLiveUpdatedTokens,  
  lazyLoadTokensAsync,
  liveUpdateTokensAsync,
  updateArchiveAsync, 
  selectSortBy,
  selectPrevSortBy,
  selectLoadingState,
  selectRequestSource,
  selectArchivePageSize,
  selectCurrentPage,
  selectDefaultViewMode,
  selectUserId,
  selectUser,
  selectViewMode,
  setDefaultViewMode,
  NUM_LIVE_DATA_ENTRIES,
  selectNonce,
  selectMarksInWallet,
  selectActiveChain,
  selectSolanaCurrentPage,
  getTokensById,
  selectTrackingData,
  selectSolanaTrackedTokens,
  selectEthereumTrackedTokens,
  selectBaseTrackedTokens,
  selectBaseLiveUpdatedTokens,
  selectBaseArchivedTokens,
  selectBaseCurrentPage,
} from "./tokenSlice"
import { SortTypes } from "./tokenSlice"
import moment from "moment"
import { useWindowDimensions } from "../../api/hooks/windowDimentionProvider"
import Footer from "../footer/Footer"
import _ from 'lodash'
import { TableView } from "./components/TableView"
import { CardView } from "./components/CardView"
import { SettingsModal } from "./components/SettingsModal"
import { Searchbar } from "./components/Searchbar"
import { Demodal } from 'demodal'
import { getChainIcon, isTokenBeyondThreshold, shouldDisablePrevButtons, sortData } from "../../utilities/utilities"
import { ChainModal } from "./components/ChainModal"

// import {TableRows, MonitorHeart, GridView, Check, Clear, Radar} from 'react-icons/md'


export enum ViewModes  {
  Table = 1,
  Grid,
}

export enum DataSources  {
  Live = 1,
  Tracked,
}



moment.locale('en', {
  relativeTime: {
    future: 'in %s',
    past: '%s ago',
    s:  'seconds',
    ss: '%ss',
    m:  '1m',
    mm: '%dm',
    h:  '1h',
    hh: '%dh',
    d:  'a day',
    dd: '%dd',
    M:  'a month',
    MM: '%dM',
    y:  'a year',
    yy: '%dY'
  }
});


export function Tokens() {

  const archivedTokens = useAppSelector(selectArchivedTokens)
  const liveUpdatedTokens = useAppSelector(selectLiveUpdatedTokens)
  const solanaArchivedTokens = useAppSelector(selectSolanaArchivedTokens)
  const solanaLiveUpdatedTokens = useAppSelector(selectSolanaLiveUpdatedTokens)
  const solanaTrackedTokens = useAppSelector(selectSolanaTrackedTokens)
  const ethereumTrackedTokens = useAppSelector(selectEthereumTrackedTokens)
  const baseArchivedTokens = useAppSelector(selectBaseArchivedTokens)
  const baseLiveUpdatedTokens = useAppSelector(selectBaseLiveUpdatedTokens)
  const baseTrackedTokens = useAppSelector(selectBaseTrackedTokens)
  const trackingData = useAppSelector(selectTrackingData)
  const sortTokensBy = useAppSelector(selectSortBy)
  const prevSortTokensBy = useAppSelector(selectPrevSortBy)
  const loadingState = useAppSelector(selectLoadingState)
  const requestedSource = useAppSelector(selectRequestSource)
  const archivePageSize = useAppSelector(selectArchivePageSize)
  const currentPage = useAppSelector(selectCurrentPage)
  const solanaCurrentPage = useAppSelector(selectSolanaCurrentPage)
  const baseCurrentPage = useAppSelector(selectBaseCurrentPage)
  const userId = useAppSelector(selectUserId)
  const defaultViewMode = useAppSelector(selectDefaultViewMode)
  const viewMode = useAppSelector(selectViewMode)
  const {width, height} = useWindowDimensions()
  const user = useAppSelector(selectUser)
  const nonce = useAppSelector(selectNonce)
  const marksInWallet = useAppSelector(selectMarksInWallet)
  const activeChain = useAppSelector(selectActiveChain)

  const dispatch = useAppDispatch()

  const [liveOrTracked, setLiveOrTracked] = useState(DataSources.Live)
  const [isMobile, setIsMoble] = useState(false)
  const [isArchiveLoaded, setIsArchiveLoaded] = useState(false)


  useEffect(()=>{
    if(defaultViewMode && defaultViewMode !== viewMode){
      handleSwitchViewMode(defaultViewMode)
    }
  },[defaultViewMode])

  useEffect(()=>{
    if(activeChain && ((activeChain === ChainIds.Solana && solanaLiveUpdatedTokens.length === 0) || 
      (activeChain === ChainIds.Base && baseLiveUpdatedTokens.length === 0) ||
      (activeChain === ChainIds.Ethereum && liveUpdatedTokens.length === 0)
      )){
      handleFetchLiveData()
    }
  },[activeChain])

  useEffect(()=>{
    if(liveOrTracked === DataSources.Tracked){
      handleFetchTrackedTokens()
    }
  },[liveOrTracked])

  const handleFetchTrackedTokens = async () => {
    try {
      await dispatch( getTokensById({id: userId, nonce: nonce, trackingData: trackingData}) ).unwrap()
      
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      console.error(rejectedValueOrSerializedError)
    }
  }

  const handleSwitchViewMode = async (newValue: ViewModes) => {

    if(user && user.id !== undefined && user.address !== undefined && newValue !== null && newValue !== undefined && user.viewMode !== newValue){
        await dispatch(setDefaultViewMode({viewMode:newValue, id: userId})).unwrap()
    }
    await dispatch({ type: "tokens/setViewMode", payload: newValue })
  }


  const handleFetchLiveData = async () => { 
      try {
        await dispatch( liveUpdateTokensAsync({requestedSource:requestedSource, id: userId, nonce: nonce, chain: activeChain}) ).unwrap()
        
      } catch (rejectedValueOrSerializedError) {
        // handle error here
        console.error(rejectedValueOrSerializedError)
      }
  }

  const handleFetchArchivedData = async ({requestedPage}:{requestedPage:number}) => {
    const filteredLength = filterTokens(liveUpdatedTokens).length
    const startAt = requestedSource === LaunchedTypes.All? NUM_LIVE_DATA_ENTRIES: ( (filteredLength)) >= 0? (NUM_LIVE_DATA_ENTRIES - (filteredLength)): NUM_LIVE_DATA_ENTRIES
    
    try {
      await dispatch( lazyLoadTokensAsync({
        requestedPage: requestedPage, 
        sortBy: sortTokensBy, 
        prevSort: prevSortTokensBy, 
        requestedSource: requestedSource, 
        fetchCount: archivePageSize, 
        startAt: startAt, 
        id: userId, 
        nonce: nonce, 
        chain: activeChain
      }) ).unwrap()
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      console.error(rejectedValueOrSerializedError)
    }

  }

  const handleUpdateArchivedData = async () => {
    const filteredLength = filterTokens(archivedTokens).length
    const startAt = requestedSource === LaunchedTypes.All? NUM_LIVE_DATA_ENTRIES: NUM_LIVE_DATA_ENTRIES - (filteredLength + 1)
    
    try {
      await dispatch( updateArchiveAsync({ requestedSource:requestedSource, fetchCount: archivePageSize, startAt: startAt}) ).unwrap()
    } catch (rejectedValueOrSerializedError) {
      // handle error here
      console.error(rejectedValueOrSerializedError)
    }

  }


  // Load first set of data when component Tokens is mounted  
  useEffect(() => {     
    if(/(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|ipad|iris|kindle|Android|Silk|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(navigator.userAgent) 
      || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(navigator.userAgent.slice(0,4))) { 
      setIsMoble(true);
    }
     //handleFetchLiveData()
    // handleFetchArchivedData({requestedPage: 1})

   },[]);


  // Load new contracts as they are being posted
  // Only load new contracts if the sorting mode is by creationDate 
  useEffect(() => { 
    const timer = setTimeout(() => {
      if(loadingState === 'idle' ){ 
        dispatch( liveUpdateTokensAsync({requestedSource:requestedSource, id: user.id, nonce: user.nonce, chain: activeChain}) )
      }
    }, 30000);
    return () => clearTimeout(timer);
  },[loadingState, activeChain]);

  useEffect(() => { 
    if(nonce && userId){ 
      handleFetchLiveData()
      if(isArchiveLoaded)
        handleFetchArchivedData({requestedPage:1}) 
    }
    else{
      handleFetchLiveData()
      if(isArchiveLoaded)
        handleFetchArchivedData({requestedPage:1}) 
    }
    
  },[nonce, userId]);

  useEffect(()=>{
    if(isArchiveLoaded){
      if(activeChain === ChainIds.Ethereum && archivedTokens.length === 0){
        handleFetchArchivedData({requestedPage:1}) 
      }
      if(activeChain === ChainIds.Solana && solanaArchivedTokens.length === 0){
        handleFetchArchivedData({requestedPage:1}) 
      }
      if(activeChain === ChainIds.Base && baseArchivedTokens.length === 0){
        handleFetchArchivedData({requestedPage:1}) 
      }
    }
  },[isArchiveLoaded, activeChain])

  // useEffect(() => { 
  
  //     if(liveUpdatedTokens.length >= 1){  
  //       dispatch( getDeployerBalances({tokens:liveUpdatedTokens}) )
  //     }

  // },[liveUpdatedTokens]);

  
  useEffect(()=> {
    if (requestedSource && isArchiveLoaded ){
      handleUpdateDataSources()
    }
  },[requestedSource])


  useEffect(() => {
    if(archivePageSize && isArchiveLoaded)
      handleFetchArchivedData({requestedPage: 1})
    
  },[archivePageSize]);


  const handleUpdateDataSources = async() => {
    //await handleFetchLiveData()
    await handleFetchArchivedData({requestedPage: 1})
  }

  const handleLoadArchive = async() => {
    //await handleFetchLiveData()
    await handleFetchArchivedData({requestedPage: 1})
    setIsArchiveLoaded(true)
  }

  const getCardWidth = () => {
      if(width > 450){
          return '400px!important'
      }
      else if(width > 360){
          return '320px!important'
      }
      else{
          return '260px!important'
      }
  }



  const handleSortingChange = (
    event: React.SyntheticEvent | null,
    newValue: SortTypes | null,
  ) => {
    dispatch({ type: "tokens/sortBy", payload: newValue })
  };

  const handleStatusModalClose = () => {
    dispatch({ type: "tokens/resetLoadingStatus"})
  };

  const handleSourceChange = async (newValue: DataSources) => {
    await setLiveOrTracked(newValue)

  }
  

  const filterTokens = (data: TokenData[]) => {

    if(requestedSource === LaunchedTypes.Launched){
      return data.filter((t) => t.launchedAt !== null)
    }
    else if (requestedSource === LaunchedTypes.Unlaunched){
      return data.filter((t) => t.launchedAt === null)
    }
    else{
      return data
    }
  }

  const getTrackedTokensForActiveChain = () => {

    if(activeChain === ChainIds.Ethereum){
      return( ethereumTrackedTokens )
    }
    else if(activeChain === ChainIds.Solana){
      return ( solanaTrackedTokens )
    }
    else if(activeChain === ChainIds.Base){
      return ( baseTrackedTokens )
    }
    else{
      return []
    }
  }

  const getLiveTokensForActiveChain = () => {

    if(activeChain === ChainIds.Ethereum){
      return( filterTokens(liveUpdatedTokens) )
    }
    else if(activeChain === ChainIds.Solana){
      return ( filterTokens(solanaLiveUpdatedTokens) )
    }
    else if(activeChain === ChainIds.Base){
      return ( filterTokens(baseLiveUpdatedTokens) )
    }
    else{
      return []
    }
  }

  const getArchivedTokensForActiveChain = () => {
    if(activeChain === ChainIds.Ethereum){
      return( archivedTokens )
    }
    else if(activeChain === ChainIds.Solana){
      return ( solanaArchivedTokens )
    }
    else if(activeChain === ChainIds.Base){
      return ( baseArchivedTokens )
    }
    else{
      return []
    }
  }

  const getArchivePageNumberForActiveChain = () => {
    if(activeChain === ChainIds.Ethereum){
      return( currentPage )
    }
    else if(activeChain === ChainIds.Solana){
      return ( solanaCurrentPage )
    }
    else if(activeChain === ChainIds.Base){
      return ( baseCurrentPage )
    }
    else{
      return 1
    }
  }

  return (

      <Grid container justifyContent='center' xs={12} md={width<1400?12:12}  sx={{flexShrink: 1, margin:'auto!important', padding:width>400?'10px':'0px', backgroundColor:'#212529', paddingTop:'120px', minHeight:'100vh'}}>
   
        <Grid 
          container justifyContent='center' 
          columns={{ xs: 12, sm: 12, md: 12, lg: 12, xl: 12}}  sx={{ flexShrink: 1, margin:'auto', maxWidth:'1920px' }} 
        >

          <Grid container justifyContent='flex-start' xs={4} sx={{marginBottom:'5px'}}>
            <Grid 
              sx={{
                height:'32px', 
                width:'32px',
                marginRight:'10px',

              }} 
              direction='column' 
              className={`top-icons monitor-hearts-bg  ${liveOrTracked === DataSources.Live?'active':''}`}
              onClick={()=>{ liveOrTracked === DataSources.Tracked? handleSourceChange(DataSources.Live): {}}}
            />

            <Grid 
              sx={{
              
                height:'32px', 
                width:'32px',
              }} 
              direction='column' 
              className={`top-icons radar-icon-bg ${liveOrTracked === DataSources.Tracked?'active':''} ${(!userId || !nonce)?'disabled':''}`}
              onClick={()=>{ liveOrTracked === DataSources.Live? handleSourceChange(DataSources.Tracked): {}}}
            />
          </Grid>
       
          <Grid container xs={8} display='flex'  flexDirection='row' justifyContent='flex-end'  alignItems='center'>  
        
            <Grid xs={5} md={8} lg={6} sx={{marign:'inherit', padding:'inherit'}} justifyContent='center' alignItems='center' 
              style={{
                maxWidth:'40vw', 
                filter:'drop-shadow(0px 0px 2px black)',
                backgroundColor:'#001a2a',
              }}
            >
              <Grid container  sx={{marign:'auto', padding:'auto'}} justifyContent='space-between'>
                <Searchbar isMobile={isMobile}/>
              </Grid>
            </Grid>  

            <Grid 
              sx={{
                height:'32px', 
                width:'32px',
                marginLeft: '15px',
                zIndex: 500,
                cursor:'pointer'
              }} 
              onClick={(event)=>Demodal.open(ChainModal, {anchor:event.currentTarget})}
              direction='column' 
            >
              <img src={getChainIcon(activeChain)} style={{height:'100%', width:'100%'}}/>
            </Grid>
            
            <Grid 
              sx={{
                height:'32px', 
                width:'32px',
                marginLeft: '10px',
                zIndex: 500
              }} 
              onClick={(event)=>Demodal.open(SettingsModal, {anchor:event.currentTarget})}
              direction='column' 
              className={`top-icons gear-icon-bg`} 
            />
            
          </Grid>
    
          {viewMode === ViewModes.Grid &&
            <>
            {liveOrTracked === DataSources.Live &&
              <>
                <Grid xs={12}  sx={{margin: '0px', padding:'0px',  marginTop: '10px', marginBottom:'50px'}}>
                  <CardView 
                    data={
                      sortData({
                        sortBy: sortTokensBy , 
                        data: user && userId && nonce? filterTokens(getLiveTokensForActiveChain()).filter((t) => !isTokenBeyondThreshold(t, user)):(getLiveTokensForActiveChain())
                      })
                    } 
                    belowThresholdData={
                      sortData({
                        sortBy: sortTokensBy , 
                        data: user && userId && nonce? filterTokens(getLiveTokensForActiveChain()).filter((t) => isTokenBeyondThreshold(t, user)):(activeChain === ChainIds.Ethereum?[]:[])
                      })
                    } 
                    title='Live Feed' subTitle="( Recently Deployed Contracts )" titleColor='#091219' isMobile={isMobile} cardWidth={getCardWidth()}
                  />
                </Grid>
                {isArchiveLoaded?
                  <>
                    <Grid container xs={12} sx={{marginBottom:'10px'}}>
                      <Grid container xs={12}  style={{maxWidth:'1920px', marginBottom:'10px'}}>
                        <Grid 
                          xs={2} 
                          sx={{height:'32px', width:'32px', marginRight:'5px'}} 
                          className={`to-first-page-icon top-icons ${shouldDisablePrevButtons(getArchivePageNumberForActiveChain())? 'disabled':''  }`} 
                          onClick={()=> getArchivePageNumberForActiveChain() <= 1? {}: handleFetchArchivedData({requestedPage: 1})}
                        />
                        <Grid 
                          xs={2} 
                          sx={{height:'32px', width:'32px'}} 
                          className={`prev-page-icon top-icons ${shouldDisablePrevButtons(getArchivePageNumberForActiveChain())? 'disabled':''  }`} 
                          onClick={()=> getArchivePageNumberForActiveChain() <=1? {}: handleFetchArchivedData({requestedPage: getArchivePageNumberForActiveChain() - 1})}
                        />
                        <Grid className='page-number-decorator'>
                          {getArchivePageNumberForActiveChain()}
                        </Grid>
                        <Grid 
                          xs={2} 
                          sx={{height:'32px', width:'32px'}} 
                          className={`next-page-icon top-icons`} 
                          onClick={()=>handleFetchArchivedData({requestedPage: getArchivePageNumberForActiveChain() + 1})}
                        />
                      </Grid>
                    </Grid>

                    <Grid xs={12} >
                      <CardView 
                        data={
                          sortData({
                            sortBy: sortTokensBy , 
                            data: filterTokens(getArchivedTokensForActiveChain()).filter((t) => !isTokenBeyondThreshold(t, user))
                          })
                        } 
                        belowThresholdData={
                          sortData({
                            sortBy: sortTokensBy , 
                            data: filterTokens(getArchivedTokensForActiveChain()).filter((t) => isTokenBeyondThreshold(t, user))
                          })
                        }
                        title='Archive' subTitle='' titleColor='black' isMobile={isMobile} cardWidth={getCardWidth()}
                      />
                    </Grid>

                    <Grid container xs={12} sx={{marginTop:'15px'}}>
                      <Grid container xs={12}  style={{maxWidth:'1920px', marginBottom:'10px'}}>
                        <Grid 
                          xs={2} 
                          sx={{height:'32px', width:'32px', marginRight:'5px'}} 
                          className={`to-first-page-icon top-icons ${shouldDisablePrevButtons(getArchivePageNumberForActiveChain())? 'disabled':''  }`} 
                          onClick={()=> getArchivePageNumberForActiveChain() <= 1? {}: handleFetchArchivedData({requestedPage: 1})}
                        />
                        <Grid 
                          xs={2} 
                          sx={{height:'32px', width:'32px'}} 
                          className={`prev-page-icon top-icons ${shouldDisablePrevButtons(getArchivePageNumberForActiveChain())? 'disabled':''  }`} 
                          onClick={()=> getArchivePageNumberForActiveChain() <=1? {}: handleFetchArchivedData({requestedPage: getArchivePageNumberForActiveChain() - 1})}
                        />
                        <Grid className='page-number-decorator'>
                          {getArchivePageNumberForActiveChain()}
                        </Grid>
                        <Grid 
                          xs={2} 
                          sx={{height:'32px', width:'32px'}} 
                          className={`next-page-icon top-icons`} 
                          onClick={()=>handleFetchArchivedData({requestedPage: getArchivePageNumberForActiveChain() + 1})}
                        />
                      </Grid>
                    </Grid>
                  </>
                  :
                  <Grid className='load-archive-button' sx={{color:'white', padding:'20px', marginBottom:'50px'}} onClick={()=>{handleLoadArchive()}}>
                    Load Archive
                  </Grid>
                }
              </>
            }
            {liveOrTracked === DataSources.Tracked &&
              <Grid xs={12} style={{ margin:'0px', padding:'0px', marginTop: '10px'}}>
                <CardView 
                data={
                  sortData({sortBy: sortTokensBy , data: userId? filterTokens(getTrackedTokensForActiveChain() ).filter((t) => !isTokenBeyondThreshold(t, user)):getTrackedTokensForActiveChain()}
                )} 
                belowThresholdData={
                  sortData({sortBy: sortTokensBy , data: userId? filterTokens(getTrackedTokensForActiveChain()).filter((t) => isTokenBeyondThreshold(t, user)):[]}
                )} 
                title='Tracked Tokens' 
                subTitle='Under Development' 
                titleColor='black' isMobile={isMobile} cardWidth={getCardWidth()}/>
                
              </Grid>
            }
            </>
          }
          <>
          {viewMode === ViewModes.Table  &&
            <>
            {liveOrTracked === DataSources.Live &&
              <>
                <Grid xs={12}  justifyContent='center' alignItems='center' style={{margin: '0px', padding:'0px',  marginTop: '10px', marginBottom:'50px', maxWidth:'1920px'}}>
                  <TableView 
                    data={
                      sortData({
                        sortBy: sortTokensBy , 
                        data: user && userId && nonce? filterTokens(getLiveTokensForActiveChain()).filter((t) => !isTokenBeyondThreshold(t, user)):(getLiveTokensForActiveChain())})
                      } 
                    belowThresholdData={
                      sortData({
                        sortBy: sortTokensBy , 
                        data: user && userId && nonce? filterTokens(getLiveTokensForActiveChain()).filter((t) => isTokenBeyondThreshold(t, user)):(activeChain === ChainIds.Ethereum?[]:[])})
                      } 
                    title='Live Feed' subTitle='( Recently Deployed Contracts )' titleColor='black' borderColor='#cccccc' isMobile={isMobile}
                  />
                </Grid>
                <>
                {isArchiveLoaded?
                <>
                  <Grid container xs={12} sx={{marginBottom:'10px'}}>
                      <Grid container xs={12}  style={{maxWidth:'1920px', marginBottom:'10px'}}>
                        <Grid 
                          xs={2} 
                          sx={{height:'32px', width:'32px', marginRight:'5px'}} 
                          className={`to-first-page-icon top-icons ${shouldDisablePrevButtons(getArchivePageNumberForActiveChain())? 'disabled':''  }`} 
                          onClick={()=> getArchivePageNumberForActiveChain() <= 1? {}: handleFetchArchivedData({requestedPage: 1})}
                        />
                        <Grid 
                          xs={2} 
                          sx={{height:'32px', width:'32px'}} 
                          className={`prev-page-icon top-icons ${shouldDisablePrevButtons(getArchivePageNumberForActiveChain())? 'disabled':''  }`} 
                          onClick={()=> getArchivePageNumberForActiveChain() <=1? {}: handleFetchArchivedData({requestedPage: getArchivePageNumberForActiveChain() - 1})}
                        />
                        <Grid className='page-number-decorator'>
                          {getArchivePageNumberForActiveChain()}
                        </Grid>
                        <Grid 
                          xs={2} 
                          sx={{height:'32px', width:'32px'}} 
                          className={`next-page-icon top-icons`} 
                          onClick={()=>handleFetchArchivedData({requestedPage: getArchivePageNumberForActiveChain() + 1})}
                        />
                      </Grid>
                    </Grid>

                    <Grid xs={12} style={{maxWidth:'1920px'}}>
                      <TableView 
                        data={
                          sortData({
                            sortBy: sortTokensBy , 
                            data: filterTokens(getArchivedTokensForActiveChain()).filter((t) => !isTokenBeyondThreshold(t, user))
                          })
                        } 
                        belowThresholdData={
                          sortData({
                            sortBy: sortTokensBy , 
                            data: filterTokens(getArchivedTokensForActiveChain()).filter((t) => isTokenBeyondThreshold(t, user))
                          })
                        }
                        title='Archive' subTitle='' titleColor='black' borderColor='#cccccc' isMobile={isMobile}
                      />
                    </Grid>

                    <Grid container xs={12} sx={{marginTop:'15px'}}>
                      <Grid container xs={12}  style={{maxWidth:'1920px', marginBottom:'10px'}}>
                        <Grid 
                          xs={2} 
                          sx={{height:'32px', width:'32px', marginRight:'5px'}} 
                          className={`to-first-page-icon top-icons ${shouldDisablePrevButtons(getArchivePageNumberForActiveChain())? 'disabled':''  }`} 
                          onClick={()=> getArchivePageNumberForActiveChain() <= 1? {}: handleFetchArchivedData({requestedPage: 1})}
                        />
                        <Grid 
                          xs={2} 
                          sx={{height:'32px', width:'32px'}} 
                          className={`prev-page-icon top-icons ${shouldDisablePrevButtons(getArchivePageNumberForActiveChain())? 'disabled':''  }`} 
                          onClick={()=> getArchivePageNumberForActiveChain() <=1? {}: handleFetchArchivedData({requestedPage: getArchivePageNumberForActiveChain() - 1})}
                        />
                        <Grid className='page-number-decorator'>
                          {getArchivePageNumberForActiveChain()}
                        </Grid>
                        <Grid 
                          xs={2} 
                          sx={{height:'32px', width:'32px'}} 
                          className={`next-page-icon top-icons`} 
                          onClick={()=>handleFetchArchivedData({requestedPage: getArchivePageNumberForActiveChain() + 1})}
                        />
                      </Grid>
                    </Grid>
                  </>
                :
                  <Grid className='load-archive-button' sx={{color:'white', padding:'20px', marginBottom:'50px'}} onClick={()=>{handleLoadArchive()}}>
                    Load Archive
                  </Grid>
                  
                }
                </>
              </>
            }
            {liveOrTracked === DataSources.Tracked &&
            <>
              <Grid xs={12} style={{margin:'0px', padding:'0px',  marginTop: '10px'}}>
                <TableView 
                  data={
                    sortData({sortBy: sortTokensBy , data: userId? filterTokens(getTrackedTokensForActiveChain() ).filter((t) => !isTokenBeyondThreshold(t, user)):getTrackedTokensForActiveChain()}
                  )} 
                  belowThresholdData={
                    sortData({sortBy: sortTokensBy , data: userId? filterTokens(getTrackedTokensForActiveChain()).filter((t) => isTokenBeyondThreshold(t, user)):[]}
                  )} 
                  title='Tracked Tokens' subTitle='Under Development' titleColor='black' borderColor='#cccccc' isMobile={isMobile}
                />
              </Grid>
            </>
            }
            </>
          } 
          </>
        </Grid>
        
{/* 
        <Modal open={loadingState === 'failed'} onClose={ (e)=>handleStatusModalClose() }>
          <ModalDialog>

          <div style={{color:'red', fontWeight:'bold'}}>
            Failed to communicate with server
          </div>

          </ModalDialog>
        </Modal> 
*/}


      
      <Grid xs={12} sx={{marginTop:"auto", marginBottom:"20px"}}>
        <Footer/>
      </Grid>

    </Grid>
  )
}
