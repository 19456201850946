import { createRef } from 'react';
import { 
    Dialog , 
    Table,
    TableBody,
    TableRow,
    TableCell

} from '@mui/material'
import { 
    Grid,
    Card,
    Tooltip,
} from "@mui/joy"

import { Demodal, useModal } from "demodal";
import { Caller } from '../tokenSlice';
import { muiDialog } from 'demodal/material-ui'
import moment from 'moment';





export const CallerModal = Demodal.create(
    (
        { symbol: symbol, callers: callers }: 
        {symbol: string, callers: Caller[]}
    ) => {
    const callerModal = useModal()

    return (
        <Dialog 
            {...muiDialog(callerModal)}
        >
            <Card className='modal-bg' style={{ padding: '10px', border: '1px solid rgba(255,255,255,0.2)', borderRadius:'0px', filter: 'drop-shadow(0px 0px 15px black)', overflowY: 'scroll', minHeight:'5vh', alignItems:'center', justifyContent:'center' }}>
                    
                <Grid textAlign='center' className='small-modal-headings' sx={{ marginBottom: '0px', paddingBottom: '0px' }}>
                    <span>Symbol:</span>
                    <Tooltip title={symbol} placement='top' arrow >
                        <span className='small-modal-data' >
                            {symbol ?
                                symbol.substring(0, 10)
                                :
                                '-'
                            }
                        </span>
                    </Tooltip>
                </Grid>

                <Grid container xs={12} justifyContent='space-between' flexDirection='row' flexGrow={1} sx={{ marginTop: '0px' }}>
                
                <Grid textAlign='center' className='block-sim-headings' xs={12} sx={{ marginTop: '0px', paddingTop: '0px', marginBottom: '5px' }}>
                    Callers
                </Grid>
                {callers && callers.length > 0 ?
                <>
                    <Table size="small"  sx={{
                    width: 'max-contnet',
                    height:'100%',
                    justifyContent:'space-evenly', alignContent:'space-evenly',
                    '& tr > *:not(:first-of-type)': { textAlign: 'center'},
                    '& tr > *:first-of-type': {
                        textAlign: 'center',
                    },
                    // '& thead th:nth-of-type(1)': { textAlign:'center'},
                    // '& thead th:nth-of-type(2)': { textAlign:'center'},
                    // '& thead th:nth-of-type(3)': { textAlign:'center'},
                    // '& thead th:nth-of-type(4)': { textAlign:'center'},
                    }}>
                    <thead >
                        <tr className="table-header">
                        <th className="table-header">Caller</th>
                        <th className="table-header">Call Time</th>
                        <th className="table-header">Market <br/>Cap</th>
                        <th className="table-header">Caller <br/>Url</th>
                        </tr>
                    </thead>
                        <TableBody >
                            
                        {callers.map(caller =>

                        <TableRow key={`caller-${caller?.channelDisplayName}`} className='caller-table-rows' sx={{textAlign:'center'}}>
                            <TableCell sx={{textAlign:'center', color: '#7ac1ff!important'}}>
                            {caller?.channelDisplayName?
                            caller?.channelDisplayName:
                            '-'}
                            </TableCell>
                            <TableCell  sx={{textAlign:'center', color: '#7ac1ff!important'}}>
                            {caller.firstCalled &&
                                <Tooltip title={ `${moment(caller.firstCalled).fromNow()}` } placement="top" arrow>
                                <div >
                                {caller.firstCalled?
                                
                                moment(caller.firstCalled).format('llll').toLocaleString()
                                :
                                '0'
                                }
                                </div>
                            </Tooltip>
                            }
                            </TableCell>
                            <TableCell sx={{textAlign:'center', color: '#7ac1ff!important'}}>
                            {caller.mCap?
                            caller.mCap:
                            '-'
                            }
                            </TableCell>
                            <TableCell  sx={{textAlign:'center', color: '#7ac1ff!important'}}>
                            {caller.callUrl?
                            <a  href={caller.callUrl} target='_blank' rel="noreferrer noopener" >
                                <div className="telegram-icon-bg-small caller-icons" style={{width:'18px', height:'18px', marginLeft:'5px'}}/>
                            </a>
                            :
                            '-'
                            }
                            </TableCell>
                        </TableRow>
                        )}
                            
                        </TableBody>
                    </Table>
                    </>
                    :
                    <Grid
                        container
                        sx={{
                            fontSize: '10pt!important',
                            backgroundColor: 'black',
                            height: '100px', lineHeight: '10pt',
                            width: '100%',
                        }}
                        justifyContent='center' alignItems='center'
                    >
                        No Callers for this Token
                    </Grid>
                }
                </Grid>
            </Card>
        </Dialog>
    )
  })


  export function openCallerDemodal({ symbol: symbol, callers: callers }: {symbol:string, callers: Caller[]}) {
    Demodal.open(CallerModal, {symbol: symbol, callers: callers})
  }