
import React, { useState, useEffect, useCallback } from "react"
import { 
  Grid,
  Modal
} from "@mui/joy"
import {
  Table,
  TableContainer, 
  TableSortLabel, 
  Box, 
  Input
} from '@mui/material'
import { 
    SortTypes,
    TokenData,
    selectSortBy,
    selectPrevSortBy,
    selectActiveChain,
    ChainIds,
    selectMarksInWallet,
    selectSolanaSubscriptionExpirationDate
} from "../tokenSlice"
import { useAppSelector, useAppDispatch } from "../../../app/hooks"
import { visuallyHidden } from '@mui/utils'
import { TokenRow } from "./ethereum/TokenRow"
import { v4 as uuidv4 } from 'uuid'
import { SolanaTokenRow } from "./solana/SolanaTokenRow"
import { ObfuscatedRow } from "./ethereum/ObfuscatedRow"
import { ObfuscatedSolanaRow } from "./solana/ObfuscatedSolanaRow"
import { userHasAccess } from "../../../utilities/utilities"




export const TableView = ({data, belowThresholdData, title, subTitle, titleColor, borderColor, heightDesired, isMobile}:{data:TokenData[], belowThresholdData: TokenData[], title:string, subTitle:string, titleColor:string, borderColor:string, heightDesired?:string, isMobile: boolean}) => {
    
    const sortTokensBy = useAppSelector(selectSortBy)
    const prevSortTokensBy = useAppSelector(selectPrevSortBy)
    const activeChain = useAppSelector(selectActiveChain)
    const marksInWallet = useAppSelector(selectMarksInWallet)
    const solanaSubscriptionExpirationDate = useAppSelector(selectSolanaSubscriptionExpirationDate)

    const dispatch = useAppDispatch()

    const handleSortingChange = (
        event: React.SyntheticEvent | null,
        newValue: SortTypes | null,
      ) => {
        dispatch({ type: "tokens/sortBy", payload: newValue })
    };
   
    const EthereumTableHeader = () => {
        return(
        
        <thead style={{ position:'sticky'}}>
            <tr className="table-header">
                <th className="table-header">Track</th>
                <th className="table-header">More</th>
                <th className="table-header">Launched</th>
                <th className="table-header">
                    <TableSortLabel
                    sx={{
                        marginLeft:'18px',
                        '&.Mui-active':{
                        color:'#23f9ff!important'
                        },
                        '&:hover':{
                        color:'#23f9ff'
                        }
                    }}
                    active={sortTokensBy ===  SortTypes.TokenSymbolAscending || sortTokensBy ===  SortTypes.TokenSymbolDescending}
                    direction={sortTokensBy === SortTypes.TokenSymbolDescending ? 'desc' : 'asc'}
                    onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.TokenSymbolDescending? SortTypes.TokenSymbolAscending: SortTypes.TokenSymbolDescending)}
                    >
                    
                        {sortTokensBy === SortTypes.TokenSymbolAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Symbol
                    
                    </TableSortLabel>
                </th>

                <th className="table-header">
                    <TableSortLabel
                        sx={{
                        marginLeft:'18px',
                        '&.Mui-active':{
                        color:'#23f9ff!important'
                        },
                        '&:hover':{
                        color:'#23f9ff'
                        }
                    }}
                    active={sortTokensBy ===  SortTypes.TokenNameAscending || sortTokensBy ===  SortTypes.TokenNameDescending}
                    direction={sortTokensBy === SortTypes.TokenNameDescending ? 'desc' : 'asc'}
                    onClick={(e)=>handleSortingChange(e, sortTokensBy === SortTypes.TokenNameDescending? SortTypes.TokenNameAscending: SortTypes.TokenNameDescending)}
                    >
                
                        {sortTokensBy === SortTypes.TokenNameAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Name
                    
                    </TableSortLabel>
                </th>
                <th className="table-header">
                    <TableSortLabel
                        sx={{
                        marginLeft:'18px',
                        '&.Mui-active':{
                            color:'#23f9ff!important'

                        },
                        '&:hover':{
                            color:'#23f9ff'
                        }
                    }}
                    active={sortTokensBy ===  SortTypes.AddressAscending || sortTokensBy ===  SortTypes.AddressDescending}
                    direction={sortTokensBy === SortTypes.AddressDescending ? 'desc' : 'asc'}
                    onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.AddressDescending? SortTypes.AddressAscending: SortTypes.AddressDescending)}
                    >
                
                        {sortTokensBy === SortTypes.AddressAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Address
                
                    </TableSortLabel>
                </th>
                <th className="table-header">Links</th>
                <th className="table-header">Snipe</th>
                <th className="table-header">
                    <TableSortLabel
                        sx={{
                        marginLeft:'18px',
                        '&.Mui-active':{
                            color:'#23f9ff!important'

                        },
                        '&:hover':{
                            color:'#23f9ff'
                        }
                        }}
                        active={sortTokensBy ===  SortTypes.CreationDateAscending || sortTokensBy ===  SortTypes.CreationDateDescending}
                        direction={sortTokensBy === SortTypes.CreationDateDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.CreationDateDescending? SortTypes.CreationDateAscending: SortTypes.CreationDateDescending)}
                    >
                
                        {sortTokensBy === SortTypes.CreationDateAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Creation<br/>Date
                
                    </TableSortLabel>
                </th>

                <th 
                    className="table-header" 

                >
                    <TableSortLabel
                        sx={{
                            marginLeft:'18px',
                            '&.Mui-active':{
                                color:'#23f9ff!important',

                            },
                            '&:hover':{
                                color:'#23f9ff'
                            }
                        }}
                        active={sortTokensBy ===  SortTypes.SniperScoreAscending || sortTokensBy ===  SortTypes.SniperScoreDescending}
                        direction={sortTokensBy === SortTypes.SniperScoreDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.SniperScoreDescending? SortTypes.SniperScoreAscending: SortTypes.SniperScoreDescending)}
                    >
                
                        {sortTokensBy === SortTypes.SniperScoreAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                    
                    Sniper<br/>Score
                    </TableSortLabel>
                </th>
                <th 
                    className="table-header" 

                >
                    <TableSortLabel
                        sx={{
                            marginLeft:'18px',
                            '&.Mui-active':{
                                color:'#23f9ff!important',

                            },
                            '&:hover':{
                                color:'#23f9ff'
                            }
                        }}
                        active={sortTokensBy ===  SortTypes.AlphaScoreAscending || sortTokensBy ===  SortTypes.AlphaScoreDescending}
                        direction={sortTokensBy === SortTypes.AlphaScoreDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.AlphaScoreDescending? SortTypes.AlphaScoreAscending: SortTypes.AlphaScoreDescending)}
                    >
                
                        {sortTokensBy === SortTypes.AlphaScoreAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                    
                    Marksman<br/>Score
                    </TableSortLabel>
                </th>

                <th className="table-header">
                    <TableSortLabel
                        sx={{
                        marginLeft:'18px',
                            '&.Mui-active':{
                                color:'#23f9ff!important'

                            },
                            '&:hover':{
                                color:'#23f9ff'
                            }
                        }}
                        active={sortTokensBy ===  SortTypes.MaxTxAscending || sortTokensBy ===  SortTypes.MaxTxDescending}
                        direction={sortTokensBy === SortTypes.MaxTxDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.MaxTxDescending? SortTypes.MaxTxAscending: SortTypes.MaxTxDescending)}
                    >
                    
                        {sortTokensBy === SortTypes.MaxTxAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Max Tx %
                
                    </TableSortLabel>
                </th>
                <th className="table-header">
                    <TableSortLabel
                        sx={{
                            marginLeft:'18px',
                            '&.Mui-active':{
                                color:'#23f9ff!important'

                            },
                            '&:hover':{
                                color:'#23f9ff'
                            }
                        }}
                        active={sortTokensBy ===  SortTypes.MaxWalletAscending || sortTokensBy ===  SortTypes.MaxWalletDescending}
                        direction={sortTokensBy === SortTypes.MaxWalletDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.MaxWalletDescending? SortTypes.MaxWalletAscending: SortTypes.MaxWalletDescending)}
                    >
                
                        {sortTokensBy === SortTypes.MaxWalletAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Max <br/> Wallet %
                    
                    </TableSortLabel>
                </th>
                <th className="table-header">
                    <TableSortLabel
                        sx={{
                            marginLeft:'18px',
                            '&.Mui-active':{
                                color:'#23f9ff!important'

                            },
                            '&:hover':{
                                color:'#23f9ff'
                            }
                        }}
                        active={sortTokensBy ===  SortTypes.BuyTaxAscending || sortTokensBy ===  SortTypes.BuyTaxDescending}
                        direction={sortTokensBy === SortTypes.BuyTaxDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.BuyTaxDescending? SortTypes.BuyTaxAscending: SortTypes.BuyTaxDescending)}
                    >
                
                        {sortTokensBy === SortTypes.BuyTaxAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Buy/ Sell<br/>Tax %
                    
                    </TableSortLabel>
                </th>
                
                <th className="table-header">
                    <TableSortLabel
                        sx={{
                            marginLeft:'18px',
                            '&.Mui-active':{
                                color:'#23f9ff!important'
                            },
                            '&:hover':{
                                color:'#23f9ff'
                            }
                        }}
                        active={sortTokensBy ===  SortTypes.MarketCapAscending || sortTokensBy ===  SortTypes.MarketCapDescending}
                        direction={sortTokensBy === SortTypes.MarketCapDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.MarketCapDescending? SortTypes.MarketCapAscending: SortTypes.MarketCapDescending)}
                    >
                
                        {sortTokensBy === SortTypes.MarketCapAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Market<br/>Cap
                
                    </TableSortLabel>
                </th>

                {/* <th className="table-header">
                    <TableSortLabel
                    sx={{
                        marginLeft:'18px',
                        '&.Mui-active':{
                        color:'#23f9ff!important'
                        },
                        '&:hover':{
                        color:'#23f9ff'
                        }
                    }}
                    active={sortTokensBy ===  SortTypes.MaestroAscending || sortTokensBy ===  SortTypes.MaestroDescending}
                    direction={sortTokensBy === SortTypes.MaestroDescending ? 'desc' : 'asc'}
                    onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.MaestroDescending? SortTypes.MaestroAscending: SortTypes.MaestroDescending)}
                    >
                
                        {sortTokensBy === SortTypes.MaestroAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Maestro
                
                    </TableSortLabel>
                </th> */}

                {/* <th className="table-header">Banana</th> */}
                <th className="table-header">
                {/* <TableSortLabel
                    sx={{
                    marginLeft: '18px',
                    '&.Mui-active': {
                        color: '#23f9ff!important'

                    },
                    '&:hover': {
                        color: '#23f9ff'
                    }
                    }}
                    active={sortTokensBy === SortTypes.NumCallsAscending || sortTokensBy === SortTypes.NumCallsDescending}
                    direction={sortTokensBy === SortTypes.NumCallsDescending ? 'desc' : 'asc'}
                    onClick={(e) => handleSortingChange(e, sortTokensBy === SortTypes.NumCallsDescending ? SortTypes.NumCallsAscending : SortTypes.NumCallsDescending)}
                >

                    {sortTokensBy === SortTypes.NumCallsAscending ? (
                    <Box component="span" sx={visuallyHidden} />

                    ) : null} */}
                    Num Calls

                {/* </TableSortLabel> */}
                </th>  
                <th className="table-header">
                    <TableSortLabel
                        sx={{
                        marginLeft:'18px',
                        '&.Mui-active':{
                            color:'#23f9ff!important'

                        },
                        '&:hover':{
                            color:'#23f9ff'
                        }
                        }}
                        active={sortTokensBy ===  SortTypes.HoldersAscending || sortTokensBy ===  SortTypes.HoldersDescending}
                        direction={sortTokensBy === SortTypes.HoldersDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.HoldersDescending? SortTypes.HoldersAscending: SortTypes.HoldersDescending)}
                    >
                    
                        {sortTokensBy === SortTypes.HoldersAscending? (
                            <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Holders
                    
                    </TableSortLabel>
                </th>
                {/* <th className="table-header">Buy / Sell?</th> */}
                <th className="table-header">Contract<br/>Security</th>
                
                <th className="table-header">
                    <span style={{width:'100%', wordBreak:'normal'}}>
                        <TableSortLabel
                            sx={{
                                marginLeft:'18px',
                                '&.Mui-active':{
                                color:'#23f9ff!important'

                                },
                                '&:hover':{
                                color:'#23f9ff'
                                }

                            }}
                            active={sortTokensBy ===  SortTypes.ScamPctAscending || sortTokensBy ===  SortTypes.ScamPctDescending}
                            direction={sortTokensBy === SortTypes.ScamPctDescending ? 'desc' : 'asc'}
                            onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.ScamPctDescending? SortTypes.ScamPctAscending: SortTypes.ScamPctDescending)}
                        >
                        
                            {sortTokensBy === SortTypes.ScamPctAscending? (
                            <Box component="span" sx={visuallyHidden} />
                            
                            ) : null}

                            Scam %
                        
                    
                        </TableSortLabel>
                    </span>
                </th>
            </tr>
        </thead>
        )
    }
    
    const SolanaTableHeader = () => {
        return(
        
        <thead style={{ position:'sticky'}}>
            <tr className="table-header">
                <th className="table-header">Track</th>
                <th className="table-header">More</th>
                <th className="table-header">Launched</th>
                <th className="table-header">
                    <TableSortLabel
                    sx={{
                        marginLeft:'18px',
                        '&.Mui-active':{
                        color:'#23f9ff!important'
                        },
                        '&:hover':{
                        color:'#23f9ff'
                        }
                    }}
                    active={sortTokensBy ===  SortTypes.TokenSymbolAscending || sortTokensBy ===  SortTypes.TokenSymbolDescending}
                    direction={sortTokensBy === SortTypes.TokenSymbolDescending ? 'desc' : 'asc'}
                    onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.TokenSymbolDescending? SortTypes.TokenSymbolAscending: SortTypes.TokenSymbolDescending)}
                    >
                    
                        {sortTokensBy === SortTypes.TokenSymbolAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Symbol
                    
                    </TableSortLabel>
                </th>

                <th className="table-header">
                    <TableSortLabel
                        sx={{
                        marginLeft:'18px',
                        '&.Mui-active':{
                        color:'#23f9ff!important'
                        },
                        '&:hover':{
                        color:'#23f9ff'
                        }
                    }}
                    active={sortTokensBy ===  SortTypes.TokenNameAscending || sortTokensBy ===  SortTypes.TokenNameDescending}
                    direction={sortTokensBy === SortTypes.TokenNameDescending ? 'desc' : 'asc'}
                    onClick={(e)=>handleSortingChange(e, sortTokensBy === SortTypes.TokenNameDescending? SortTypes.TokenNameAscending: SortTypes.TokenNameDescending)}
                    >
                
                        {sortTokensBy === SortTypes.TokenNameAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Name
                    
                    </TableSortLabel>
                </th>
                <th className="table-header">
                    <TableSortLabel
                        sx={{
                        marginLeft:'18px',
                        '&.Mui-active':{
                            color:'#23f9ff!important'

                        },
                        '&:hover':{
                            color:'#23f9ff'
                        }
                    }}
                    active={sortTokensBy ===  SortTypes.AddressAscending || sortTokensBy ===  SortTypes.AddressDescending}
                    direction={sortTokensBy === SortTypes.AddressDescending ? 'desc' : 'asc'}
                    onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.AddressDescending? SortTypes.AddressAscending: SortTypes.AddressDescending)}
                    >
                
                        {sortTokensBy === SortTypes.AddressAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Address
                
                    </TableSortLabel>
                </th>
                <th className="table-header">Links</th>
                <th className="table-header">Snipe</th>
                <th className="table-header">
                    <TableSortLabel
                        sx={{
                        marginLeft:'18px',
                        '&.Mui-active':{
                            color:'#23f9ff!important'

                        },
                        '&:hover':{
                            color:'#23f9ff'
                        }
                        }}
                        active={sortTokensBy ===  SortTypes.CreationDateAscending || sortTokensBy ===  SortTypes.CreationDateDescending}
                        direction={sortTokensBy === SortTypes.CreationDateDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.CreationDateDescending? SortTypes.CreationDateAscending: SortTypes.CreationDateDescending)}
                    >
                
                        {sortTokensBy === SortTypes.CreationDateAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Creation<br/>Date
                
                    </TableSortLabel>
                </th>
{/* 
                <th 
                    className="table-header" 

                >
                    <TableSortLabel
                        sx={{
                            marginLeft:'18px',
                            '&.Mui-active':{
                                color:'#23f9ff!important',

                            },
                            '&:hover':{
                                color:'#23f9ff'
                            }
                        }}
                        active={sortTokensBy ===  SortTypes.SniperScoreAscending || sortTokensBy ===  SortTypes.SniperScoreDescending}
                        direction={sortTokensBy === SortTypes.SniperScoreDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.SniperScoreDescending? SortTypes.SniperScoreAscending: SortTypes.SniperScoreDescending)}
                    >
                
                        {sortTokensBy === SortTypes.SniperScoreAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                    
                    Sniper<br/>Score
                    </TableSortLabel>
                </th> 
*/}
                <th 
                    className="table-header" 

                >
                    <TableSortLabel
                        sx={{
                            marginLeft:'18px',
                            '&.Mui-active':{
                                color:'#23f9ff!important',

                            },
                            '&:hover':{
                                color:'#23f9ff'
                            }
                        }}
                        active={sortTokensBy ===  SortTypes.AlphaScoreAscending || sortTokensBy ===  SortTypes.AlphaScoreDescending}
                        direction={sortTokensBy === SortTypes.AlphaScoreDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.AlphaScoreDescending? SortTypes.AlphaScoreAscending: SortTypes.AlphaScoreDescending)}
                    >
                
                        {sortTokensBy === SortTypes.AlphaScoreAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                    
                    Marksman<br/>Score
                    </TableSortLabel>
                </th>

                <th className="table-header">
                    <TableSortLabel
                        sx={{
                        marginLeft:'18px',
                            '&.Mui-active':{
                                color:'#23f9ff!important'

                            },
                            '&:hover':{
                                color:'#23f9ff'
                            }
                        }}
                        active={sortTokensBy ===  SortTypes.Buys24hAscending || sortTokensBy ===  SortTypes.Buys24hDescending}
                        direction={sortTokensBy === SortTypes.Buys24hDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.Buys24hDescending? SortTypes.Buys24hAscending: SortTypes.Buys24hDescending)}
                    >
                    
                        {sortTokensBy === SortTypes.Buys24hAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Buys 24h
                
                    </TableSortLabel>
                </th>
                <th className="table-header">
                    <TableSortLabel
                        sx={{
                            marginLeft:'18px',
                            '&.Mui-active':{
                                color:'#23f9ff!important'

                            },
                            '&:hover':{
                                color:'#23f9ff'
                            }
                        }}
                        active={sortTokensBy ===  SortTypes.Sells24hAscending || sortTokensBy ===  SortTypes.Sells24hDescending}
                        direction={sortTokensBy === SortTypes.Sells24hDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.Sells24hDescending? SortTypes.Sells24hAscending: SortTypes.Sells24hDescending)}
                    >
                
                        {sortTokensBy === SortTypes.Sells24hAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Sells 24h
                    
                    </TableSortLabel>
                </th>
                
                <th className="table-header">
                    <TableSortLabel
                        sx={{
                            marginLeft:'18px',
                            '&.Mui-active':{
                                color:'#23f9ff!important'
                            },
                            '&:hover':{
                                color:'#23f9ff'
                            }
                        }}
                        active={sortTokensBy ===  SortTypes.MarketCapAscending || sortTokensBy ===  SortTypes.MarketCapDescending}
                        direction={sortTokensBy === SortTypes.MarketCapDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.MarketCapDescending? SortTypes.MarketCapAscending: SortTypes.MarketCapDescending)}
                    >
                
                        {sortTokensBy === SortTypes.MarketCapAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Market<br/>Cap
                
                    </TableSortLabel>
                </th>

                {/* <th className="table-header">
                    <TableSortLabel
                    sx={{
                        marginLeft:'18px',
                        '&.Mui-active':{
                        color:'#23f9ff!important'
                        },
                        '&:hover':{
                        color:'#23f9ff'
                        }
                    }}
                    active={sortTokensBy ===  SortTypes.MaestroAscending || sortTokensBy ===  SortTypes.MaestroDescending}
                    direction={sortTokensBy === SortTypes.MaestroDescending ? 'desc' : 'asc'}
                    onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.MaestroDescending? SortTypes.MaestroAscending: SortTypes.MaestroDescending)}
                    >
                
                        {sortTokensBy === SortTypes.MaestroAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Maestro
                
                    </TableSortLabel>
                </th> */}

                {/* <th className="table-header">Banana</th> */}
                <th className="table-header">
                {/* <TableSortLabel
                    sx={{
                    marginLeft: '18px',
                    '&.Mui-active': {
                        color: '#23f9ff!important'

                    },
                    '&:hover': {
                        color: '#23f9ff'
                    }
                    }}
                    active={sortTokensBy === SortTypes.NumCallsAscending || sortTokensBy === SortTypes.NumCallsDescending}
                    direction={sortTokensBy === SortTypes.NumCallsDescending ? 'desc' : 'asc'}
                    onClick={(e) => handleSortingChange(e, sortTokensBy === SortTypes.NumCallsDescending ? SortTypes.NumCallsAscending : SortTypes.NumCallsDescending)}
                >

                    {sortTokensBy === SortTypes.NumCallsAscending ? (
                    <Box component="span" sx={visuallyHidden} />

                    ) : null} */}
                    Num Calls

                {/* </TableSortLabel> */}
                </th>
                <th className="table-header">
                    <TableSortLabel
                        sx={{
                            marginLeft:'18px',
                            '&.Mui-active':{
                                color:'#23f9ff!important'

                            },
                            '&:hover':{
                                color:'#23f9ff'
                            }
                        }}
                        active={sortTokensBy ===  SortTypes.OwnerTransferPercentAscending || sortTokensBy ===  SortTypes.OwnerTransferPercentDescending}
                        direction={sortTokensBy === SortTypes.OwnerTransferPercentDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.OwnerTransferPercentDescending? SortTypes.OwnerTransferPercentAscending: SortTypes.OwnerTransferPercentDescending)}
                    >
                
                        {sortTokensBy === SortTypes.OwnerTransferPercentAscending? (
                        <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                       Owner<br/>Transfer %
                    
                    </TableSortLabel>
                </th>  
                <th className="table-header">
                    <TableSortLabel
                        sx={{
                        marginLeft:'18px',
                        '&.Mui-active':{
                            color:'#23f9ff!important'

                        },
                        '&:hover':{
                            color:'#23f9ff'
                        }
                        }}
                        active={sortTokensBy ===  SortTypes.OwnerHoldPercentAscending || sortTokensBy ===  SortTypes.OwnerHoldPercentDescending}
                        direction={sortTokensBy === SortTypes.OwnerHoldPercentDescending ? 'desc' : 'asc'}
                        onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.OwnerHoldPercentDescending? SortTypes.OwnerHoldPercentAscending: SortTypes.OwnerHoldPercentDescending)}
                    >
                    
                        {sortTokensBy === SortTypes.OwnerHoldPercentAscending? (
                            <Box component="span" sx={visuallyHidden} />
                        
                        ) : null}
                        Owner<br/>Hold %
                    
                    </TableSortLabel>
                </th>
                {/* <th className="table-header">Buy / Sell?</th> */}
                <th className="table-header">Contract<br/>Security</th>
                
                {/* <th className="table-header">
                    <span style={{width:'100%', wordBreak:'normal'}}>
                        <TableSortLabel
                            sx={{
                                marginLeft:'18px',
                                '&.Mui-active':{
                                color:'#23f9ff!important'

                                },
                                '&:hover':{
                                color:'#23f9ff'
                                }

                            }}
                            active={sortTokensBy ===  SortTypes.ScamPctAscending || sortTokensBy ===  SortTypes.ScamPctDescending}
                            direction={sortTokensBy === SortTypes.ScamPctDescending ? 'desc' : 'asc'}
                            onClick={(e)=>handleSortingChange( e, sortTokensBy === SortTypes.ScamPctDescending? SortTypes.ScamPctAscending: SortTypes.ScamPctDescending)}
                        >
                        
                            {sortTokensBy === SortTypes.ScamPctAscending? (
                            <Box component="span" sx={visuallyHidden} />
                            
                            ) : null}

                            Scam %
                        
                    
                        </TableSortLabel>
                    </span>
                </th> */}
            </tr>
        </thead>
        )
    }

    const DisplayRow = ({row}: {row: TokenData}) => {
        if(activeChain === ChainIds.Ethereum){
            return <TokenRow row={row} key={uuidv4()} isMobile={isMobile}/>
        }
        else if(activeChain === ChainIds.Solana){
            return <SolanaTokenRow row={row} key={uuidv4()} isMobile={isMobile}/>
        }
        else{
            return <TokenRow row={row} key={uuidv4()} isMobile={isMobile}/>
        }

    }

    const DisplayObfuscatedRow = ({row} : {row:TokenData}) => {
        if(activeChain === ChainIds.Ethereum){
            return <ObfuscatedRow row={row} key={uuidv4()} isMobile={isMobile}/>
        }
        else if(activeChain === ChainIds.Solana){
            return <ObfuscatedSolanaRow row={row} key={uuidv4()} isMobile={isMobile}/>
        }
        else{
            return <TokenRow row={row} key={uuidv4()} isMobile={isMobile}/>
        }
    }

    const getTableColumnWidths = () => {
        if(activeChain === ChainIds.Ethereum){
            return {
                '& thead th:nth-of-type(1)': { textAlign:'center', width:'3%' },
                '& thead th:nth-of-type(2)': { textAlign:'center', width:'3%' },
                '& thead th:nth-of-type(3)': { textAlign:'center', width:'3%' },
                '& thead th:nth-of-type(4)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(5)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(6)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(7)': { textAlign:'center', width:'2%', minWidth:'50px', maxWidth:'50px'  },
                '& thead th:nth-of-type(8)': { textAlign:'center', width:'2%', minWidth:'50px', maxWidth:'50px'  },
                '& thead th:nth-of-type(9)': { textAlign:'center', width:'7%'  },
                '& thead th:nth-of-type(10)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(11)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(12)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(13)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(14)': { textAlign:'center', width:'8%' },
                '& thead th:nth-of-type(15)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(16)': { textAlign:'center', width:'6%' },
                '& thead th:nth-of-type(17)': { textAlign:'center', width:'5%' },
                '& thead th:nth-of-type(18)': { textAlign:'center', width:'3%', minWidth:'80px', maxWidth:'80px'  },
                '& thead th:nth-of-type(19)': { textAlign:'center', width:'5%' }
            }
        }
        else if(activeChain === ChainIds.Solana){
            return {
                '& thead th:nth-of-type(1)': { textAlign:'center', width:'3%' },
                '& thead th:nth-of-type(2)': { textAlign:'center', width:'3%' },
                '& thead th:nth-of-type(3)': { textAlign:'center', width:'3%' },
                '& thead th:nth-of-type(4)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(5)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(6)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(7)': { textAlign:'center', width:'2%', minWidth:'50px', maxWidth:'50px'  },
                '& thead th:nth-of-type(8)': { textAlign:'center', width:'2%', minWidth:'50px', maxWidth:'50px'  },
                '& thead th:nth-of-type(9)': { textAlign:'center', width:'7%'  },
                '& thead th:nth-of-type(10)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(11)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(12)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(13)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(14)': { textAlign:'center', width:'8%' },
                '& thead th:nth-of-type(15)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(16)': { textAlign:'center', width:'6%' },
                '& thead th:nth-of-type(17)': { textAlign:'center', width:'4%', minWidth:'40px', maxWidth:'40px'  },                
            }
        }
        else if(activeChain === ChainIds.Base){
            return {
                '& thead th:nth-of-type(1)': { textAlign:'center', width:'3%' },
                '& thead th:nth-of-type(2)': { textAlign:'center', width:'3%' },
                '& thead th:nth-of-type(3)': { textAlign:'center', width:'3%' },
                '& thead th:nth-of-type(4)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(5)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(6)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(7)': { textAlign:'center', width:'2%', minWidth:'50px', maxWidth:'50px'  },
                '& thead th:nth-of-type(8)': { textAlign:'center', width:'2%', minWidth:'50px', maxWidth:'50px'  },
                '& thead th:nth-of-type(9)': { textAlign:'center', width:'7%'  },
                '& thead th:nth-of-type(10)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(11)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(12)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(13)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(14)': { textAlign:'center', width:'8%' },
                '& thead th:nth-of-type(15)': { textAlign:'center', width:'7%' },
                '& thead th:nth-of-type(16)': { textAlign:'center', width:'6%' },
                '& thead th:nth-of-type(17)': { textAlign:'center', width:'5%' },
                '& thead th:nth-of-type(18)': { textAlign:'center', width:'3%', minWidth:'80px', maxWidth:'80px'  },
                '& thead th:nth-of-type(19)': { textAlign:'center', width:'5%' }
            }
        }
    }

    return(
      <div style={{backgroundColor:titleColor, padding:'5px',  border:`none`, filter:'drop-shadow(0px 0px 5px black)', height:heightDesired?heightDesired:'auto'}}>
        <Grid container xs={12} spacing={2} direction='row' style={{paddingBottom:'4px', paddingLeft:'5px'}} >
        
            {title&& 
            <Grid  direction='column' className='table-legend' style={{ fontSize: '2em', paddingLeft: 'calc(5px + 1vw)' }} >
                { title}
            </Grid>
            
            }
        
            {subTitle &&
            <Grid  direction='column' className='table-legend' style={{ fontSize: '1em', lineHeight:'2.5em'}}>
                {subTitle}
            </Grid>
            }
            
        </Grid>
        <TableContainer sx={{maxHeight: 'inherit'}}>

            <Table 
            stickyHeader aria-label="sticky table"
            sx={{
                // width: 'max-content',
                height:'100%',
                justifyContent:'space-evenly', alignContent:'space-evenly',
                '& tr > *:not(:first-of-type)': { textAlign: 'center'},
                '& tr > *:first-of-type': {
                textAlign: 'center',

                left: 0,
                },
                '&thead':{
                position:'sticky',
                top:'0px'
                },
                ...getTableColumnWidths()

            }}
            >           
                {activeChain === ChainIds.Ethereum &&
                    <EthereumTableHeader />
                }

                {activeChain === ChainIds.Solana &&
                    <SolanaTableHeader />
                }

                {activeChain === ChainIds.Base &&
                    <EthereumTableHeader />
                }
                
                <tbody >
                {data && userHasAccess({activeChain:activeChain, marksInWallet:marksInWallet, solanaSubscriptionExpirationDate:solanaSubscriptionExpirationDate})?
                    data.map((row:TokenData, index) => (
                        <DisplayRow row={row} key={uuidv4()}/>
                    ))
                    :
                    data.map((row:TokenData, index) => (
                        <DisplayObfuscatedRow row={row} key={uuidv4()}/>
                    ))
                }
                {belowThresholdData && userHasAccess({activeChain:activeChain, marksInWallet:marksInWallet, solanaSubscriptionExpirationDate:solanaSubscriptionExpirationDate})? 
                    belowThresholdData.map((row:TokenData, index) => (
                        <DisplayRow row={row} key={uuidv4()} />
                    ))
                    :
                    belowThresholdData.map((row:TokenData, index) => (
                        <DisplayObfuscatedRow row={row} key={uuidv4()} />
                    ))
                }
                </tbody>
            </Table>
        </TableContainer>
      </div>
   
    )
}