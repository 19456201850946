import {memo} from "react"
import {
  Grid,
  Card,
  Tooltip,

} from "@mui/joy"
import {
  Checkbox,
} from "@mui/material"
import { useAppSelector, useAppDispatch } from "../../../../app/hooks"

import {
  handleCopyText,
  convertToInternationalCurrencySystem,
  truncateAddress,
  getScoreColor,
  isTokenBeyondThreshold,
} from "../../../../utilities/utilities"

import { v4 as uuidv4 } from "uuid"
import {
  TokenData,
  selectUser,
  selectMarksInWallet,
  selectActiveChain,
  selectSolanaSubscriptionExpirationDate,
  selectNonce,
  selectUserRequestLoading,
  selectUserTrackedTokenIds,
  TrackedToken,
  selectTrackingData,
  disableTrackingForToken,
  enableTrackingForToken,
  selectUserId,
} from "../../tokenSlice"
import { Demodal } from "demodal"
import moment from "moment"

import { CallerModal } from "../CallerModal"
import { FieldModal } from "../FieldModal"
import { SolanaMoreInfoModal } from "./SolanaMoreInfoModal"
import { SolanaMcapTooltip } from "./SolanaMcapTooltip"

export const SolanaTokenCard = 
  ({
    token,
    isMobile,
    cardWidth,
  }: {
    token: TokenData
    isMobile: boolean
    cardWidth: string
  }) => {
    const dispatch = useAppDispatch()
    const user = useAppSelector(selectUser)
    const userId = useAppSelector(selectUserId)
    const nonce = useAppSelector(selectNonce)  
    const loadingStatus = useAppSelector(selectUserRequestLoading)  
    const trackedTokenIds = useAppSelector(selectUserTrackedTokenIds)
    const trackingData = useAppSelector(selectTrackingData)

    const handleTrackToggle = async ({tokenId}:{tokenId:number}) => {

      if(trackedTokenIds.includes(tokenId)){
        const toggledToken:TrackedToken[] = trackingData.filter((token)=> token.tokenId === tokenId)
        await dispatch(disableTrackingForToken({trackData: toggledToken[0], nonce: nonce}))
      }
      else{
        await dispatch(enableTrackingForToken({userId:userId, tokenId: tokenId, nonce: nonce}))
      }
      
    }

    return (
      <Grid
        sx={{
          filter: "drop-shadow(0px 0px 7px black)",
          opacity: isTokenBeyondThreshold(token, user) ? "0.5" : "1",
        }}
      >
        <Card
          className="card-back"
          sx={{
            width: cardWidth,
            padding: 0,
            border: "1px solid rgba(255,255,255,.1)",
          }}
        >
          <Grid container xs={12} sx={{ margin: 0, padding: 0 }}>
            <Grid container xs={12} sx={{ height: "30px", marginTop: "0px" }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
                sx={{
                  margin: "auto",
                  padding: "auto",
                  marginLeft: "0px!important",
                }}
              >
                {token.socialLinks &&
                  token.socialLinks.length >= 1 &&
                  JSON.parse(token.socialLinks).map(
                    (socialLink: string, index: number) => (
                      <div key={uuidv4()}>
                        {socialLink.includes("twitter") ||
                          (socialLink.includes("x.com") && (
                            <a
                              href={socialLink}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              <div
                                className="twitter-icon-bg-small top-icons"
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  marginLeft: "5px",
                                }}
                              />
                            </a>
                          ))}
                        {socialLink.includes("t.me") && (
                          <a
                            href={socialLink}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <div
                              className="telegram-icon-bg-small top-icons"
                              style={{
                                width: "18px",
                                height: "18px",
                                marginLeft: "5px",
                              }}
                            />
                          </a>
                        )}
                        {socialLink.includes("medium") && (
                          <a
                            href={socialLink}
                            target="_blank"
                            rel="noreferrer noopener"
                          >
                            <div
                              className="medium-icon-bg-small top-icons"
                              style={{
                                width: "18px",
                                height: "18px",
                                marginLeft: "5px",
                              }}
                            />
                          </a>
                        )}
                        {!socialLink.includes("t.me") &&
                          !socialLink.includes("twitter") &&
                          !socialLink.includes("x.com") &&
                          !socialLink.includes("youtube") &&
                          !socialLink.includes("tiktok") &&
                          !socialLink.includes("gitbook") &&
                          !socialLink.includes("doc") &&
                          !socialLink.includes("medium") && (
                            <a
                              href={socialLink}
                              target="_blank"
                              rel="noreferrer noopener"
                            >
                              <div
                                className="globe-icon-bg-small top-icons"
                                style={{
                                  width: "18px",
                                  height: "18px",
                                  marginLeft: "5px",
                                }}
                              />
                            </a>
                          )}
                      </div>
                    ),
                  )}
              </Grid>

              <Grid
                container
                justifyContent="center"
                alignItems="center"
                flexDirection="row"
                sx={{
                  margin: "auto",
                  padding: "auto",
                  marginRight: "0px!important",
                }}
              >
                {token.parsedSecurityDetails &&
                token.parsedSecurityDetails.mint_authority_revoked ? (
                  <Grid
                    className="card-renounced-icon-bg"
                    sx={{
                      width: "24px",
                      height: "24px",
                      margin: "auto",
                      padding: "auto",
                      marginRight: "10px!important",
                    }}
                    bgcolor={
                      token.parsedSecurityDetails.mint_authority_revoked
                        ? "#12af32"
                        : "#000000"
                    }
                    onClick={(event) =>
                      Demodal.open(FieldModal, {
                        fieldTitle: "Contract Renounced",
                        fieldData: null,
                        anchor: event.target
                      })
                    }
                  />
                ) : (
                  <></>
                )}

                {/* {userHasSolanaAccess()?
                  <>
                  {token.contractVerified && (token.contractVerified === true || token.contractVerified === 1)?
                    <Grid 
                      className='big-check-icon-bg'
                      sx={{width:'18px', height:'18px', margin:'auto', padding:'auto', marginRight:'10px!important'}}
                      bgcolor={token.contractVerified?'#12af32':'#000000'}
                      onClick={
                        () => Demodal.open(
                          FieldModal, 
                          {
                            fieldTitle: 'Contract Verified', 
                            fieldData: null
                          }
                        )
                      }
                    />
                    :
                    <></>
                  }
                  </>
                
                  :
                  <Grid 
                    sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}
                  >
                    &#128274;
                  </Grid>
                } */}

                {/* {userHasSolanaAccess()?
                <>
                {token.parsedLiquidityArray && isLiquidityLocked({liquidityArray: token.parsedLiquidityArray})?
                  
                  <Grid 
                    className='card-lock-icon-bg'
                    sx={{width:'18px', height:'18px', margin:'auto', padding:'auto', marginRight:'10px!important'}}
                    bgcolor='#12af32'
                    onClick={
                      () => Demodal.open(
                        LockedModal, 
                        { parsedLiquidityArray: token.parsedLiquidityArray }
                      )
                    }
                  />
                  :
                  <></>
                }
                </>
                  :
                  <Grid 
                    sx={{ mb:'auto', mt:'auto', padding:'auto', marginRight:'10px!important'}}
                  >
                    &#128274;
                  </Grid>
                } */}

                {token.parsedSecurityDetails &&
                token.parsedSecurityDetails.liquidity_burnt_pct &&
                token.parsedSecurityDetails.liquidity_burnt_pct > 0 ? (
                  <Grid
                    className="card-burn-icon-bg"
                    sx={{
                      width: "18px",
                      height: "18px",
                      margin: "auto",
                      padding: "auto",
                      marginRight: "10px!important",
                      cursor: "pointer",
                    }}
                    bgcolor="#12af32"
                    onClick={(event) =>
                      Demodal.open(FieldModal, {
                        fieldTitle: "Burn % :",
                        fieldData: token.parsedSecurityDetails
                          .liquidity_burnt_pct
                          ? token.parsedSecurityDetails.liquidity_burnt_pct
                          : "-",
                        anchor: event.target
                      })
                    }
                  />
                ) : (
                  <></>
                )}

                <Grid
                  className={`${
                    token.launchedAt
                      ? "card-launched-icon-bg"
                      : "card-unlaunched-icon-bg"
                  }`}
                  sx={{
                    width: "18px",
                    height: "18px",
                    margin: "auto",
                    padding: "auto",
                    marginRight: "10px!important",
                  }}
                  bgcolor={token.launchedAt ? "#12af32" : "#cf0a0a"}
                  onClick={() => {}}
                />
              </Grid>

              <Grid>
                <Checkbox
                  checked={token.isTracked ? true : false}
                  onChange={() => handleTrackToggle({ tokenId: token.id })}
                  size="small"
                  id={`checkbox-${uuidv4()}`}
                  disabled={loadingStatus==='loading' || !userId || !nonce}
                  sx={{
                    color: "white",
                    "&.Mui-checked": {
                      color: "#4a91ff",
                    },
                  }}
                />
              </Grid>

              <Grid
                className="copyable-table-field"
                style={{ fontSize: "18pt", marginRight: "10px" }}
                onClick={() =>
                  Demodal.open(SolanaMoreInfoModal, {
                    token: token,
                    isMobile: isMobile,
                  })
                }
              >
                +
              </Grid>
            </Grid>
          </Grid>

          <Grid className="card-mid" sx={{ margin: 0 }}>
            <Grid
              container
              xs={12}
              justifyContent="center"
              flexShrink={1}
              display="flex"
              sx={{ marginBottom: 0, paddingBottom: "0px" }}
            >
              <span
                style={{ marginRight: "5px" }}
                className="card-creation-date-heading"
              >
                {" "}
                Symbol:{" "}
              </span>
              {token.symbol && (
                <>
                  {isMobile ? (
                    <div
                      className="card-creation-date"
                      onClick={(event) =>
                        Demodal.open(FieldModal, {
                          fieldTitle: "Symbol:",
                          fieldData: token.symbol,
                          anchor: event.target
                        })
                      }
                    >
                      {token.symbol && token.symbol.substring(0, 10)}
                    </div>
                  ) : (
                    <Tooltip title={token.symbol} placement="top" arrow>
                      <div className="card-creation-date">
                        {token.symbol && token.symbol.substring(0, 10)}
                      </div>
                    </Tooltip>
                  )}
                </>
              )}
            </Grid>

            <Grid
              container
              xs={12}
              sx={{ marginTop: "0px", paddingTop: "0px" }}
            >
              <Grid
                xs={5}
                alignItems="space-evenly"
                flexDirection="column"
                direction="column"
                flexGrow={1}
              >
                <Grid textAlign="left" className="card-headings" xs={12}>
                  Name
                </Grid>
                <Grid xs={12} className="card-data">
                  {isMobile ? (
                    <div
                      className="card-creation-date"
                      onClick={(event) => {
                        Demodal.open(FieldModal, {
                          fieldTitle: "Name:",
                          fieldData: token.name,
                          anchor: event.target
                        })
                      }}
                    >
                      {token.name && token.name.substring(0, 10)}
                    </div>
                  ) : (
                    <Tooltip title={token.name} placement="top" arrow>
                      <div className="card-creation-date">
                        {token.name && token.name.substring(0, 10)}
                      </div>
                    </Tooltip>
                  )}
                </Grid>
                <Grid textAlign="left" className="card-headings" xs={12}>
                  Creation Date
                </Grid>
                <Grid xs={12} className="card-data">
                  <Tooltip
                    title={`${moment(token.createdAt)
                      .format("llll")
                      .toLocaleString()}`}
                    placement="top-start"
                    arrow
                  >
                    <div>
                      {token.createdAt
                        ? moment(token.createdAt).fromNow()
                        : "-"}
                    </div>
                  </Tooltip>
                </Grid>
                <Grid textAlign="left" className="card-headings" xs={12}>
                  Contract
                </Grid>
                <Tooltip title="Copy Address" placement="top-start" arrow>
                  <Grid
                    xs={12}
                    className="card-data copyable-field"
                    onClick={() => handleCopyText(token.address)}
                    style={{ color: "#4a91ff" }}
                  >
                    {token.address ? truncateAddress(token.address) : "-"}
                  </Grid>
                </Tooltip>
                <Grid textAlign="left" className="card-headings" xs={12}>
                  Deployer
                </Grid>
                <Grid xs={12}>
                  {token.deployerAddress ? (
                    <a
                      className="card-data copyable-field"
                      href={`${
                        token.chain === "base"
                          ? "https://basescan.org"
                          : "https://etherscan.io"
                      }/address/${token.deployerAddress}`}
                      target="_blank"
                      rel="noreferrer noopener"
                    >
                      {truncateAddress(token.deployerAddress)}
                    </a>
                  ) : (
                    "-"
                  )}
                </Grid>
                {/* <Grid textAlign='left'  className='card-headings'  xs={12}>
                  Eth In Wallet
                </Grid>
                <Grid
                className='card-data'  
                  xs={12} 
                >
                {token.ethInWallet? token.ethInWallet: 'Loading...'}
                </Grid> */}
              </Grid>

              <Grid
                xs={7}
                flexDirection="column"
                direction="column"
                flexGrow={1}
                justifyContent="flex-end"
                alignItems="flex-end"
              >
                <Grid
                  className="card-headings"
                  container
                  xs={12}
                  justifyContent="flex-end"
                >
                  Marksman Score
                </Grid>
                <Grid
                  className="alpha-field"
                  container
                  alignContent="flex-end"
                  alignItems="flex-end"
                  justifyContent="flex-end"
                >
                  <div
                    style={{
                      width: "125px",
                      backgroundColor: `${getScoreColor({
                        score: token.alphaScore,
                      })}`,
                      color: "black",
                      textAlign: "center",
                    }}
                  >
                    {token.alphaScore ? token.alphaScore.toLocaleString() : "-"}
                  </div>
                </Grid>

                {/*             
                <Grid className='card-headings'  justifyContent='flex-end' textAlign='right'>
                  Sniper Score
                </Grid>
                
                <Grid container className='sniper-field'  alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end'>
                  <div  style={{ width:'125px', backgroundColor:`${getScoreColor({score: token.sniperScore})}`, color:'black', textAlign:'center', marginBottom:'10px'}}>
                    {token.sniperScore?
                      token.sniperScore.toLocaleString(): '-'  
                    }
                  </div>
                </Grid> 
                */}
                {/*      
                <Grid  container xs={12} justifyContent='flex-end' style={{marginTop:'10px'}}>
                  <span className='small-card-headings'>
                    Maestro:
                  </span>
                  <span className='small-card-data'  >

                  {token.alphaHitsMaestro?
                    token.alphaHitsMaestro: '-'  
                  }
                  </span>
                </Grid> */}

                <Grid
                  container
                  xs={12}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <span className="small-card-headings">Market Cap:</span>
                  <span
                    className="num-calls-button"
                    style={{
                      marginLeft: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={(event) =>
                      Demodal.open(SolanaMcapTooltip, {
                        symbol: token.symbol,
                        mCap: token.mCap,
                        mcapAt: token.mcapAt,
                        liquidityV2: token.liquidityV2,
                        anchor: event.target
                      })
                    }
                  >
                    <Grid
                      container
                      style={{ justifyContent: "center", alignItems: "center" }}
                    >
                      {token.mCap
                        ? "" + convertToInternationalCurrencySystem(token.mCap)
                        : "-"}
                      <div
                        className="info-icon"
                        style={{
                          margin: "auto",
                          padding: "auto",
                          marginLeft: "5px",
                          height: "16px",
                          width: "16px",
                          zIndex: 900,
                        }}
                      />
                    </Grid>
                  </span>
                </Grid>

                <Grid
                  container
                  xs={12}
                  justifyContent="flex-end"
                  alignItems="center"
                >
                  <span className="small-card-headings">Num Calls:</span>
                  <span
                    className="small-card-data num-calls-button"
                    style={{
                      marginLeft: "5px",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    onClick={
                      token.parsedAlphaCallChannels &&
                      token.parsedAlphaCallChannels.length > 0
                        ? () =>
                            Demodal.open(CallerModal, {
                              symbol: token.symbol,
                              callers: token.parsedAlphaCallChannels,
                            })
                        : () => {}
                    }
                  >
                    {token.parsedAlphaCallChannels
                      ? "" + token.parsedAlphaCallChannels.length
                      : "-"}
                  </span>
                </Grid>

                {/* <Grid container xs={12} justifyContent="flex-end">
                  <span className="small-card-headings">Holders:</span>
                  <span className="small-card-data">
                    {token.holdersCount ? token.holdersCount : "-"}
                  </span>
                </Grid> */}

                {/* <Grid textAlign='right' className='card-headings'  xs={12} style={{margin:0, marginBottom:'5px', padding:0}}>
                  Banana
                </Grid>

                <Grid textAlign='right' className='card-data'  xs={12} style={{margin:0, marginBottom:'5px', padding:0}}>
                  {token.alphaHitsMaestro && token.alphaHitsMaestro >= 0?
                    <>
                    {getBananaNumber(token.alphaBanana)}
                    </>
                    :
                    <>
                      -
                    </>
                  }
                </Grid> */}

                <Grid container xs={12} justifyContent="flex-end">
                  <span className="small-card-headings">Buys 24h:</span>
                  <span className="small-card-data">
                    {token.buys24h ? token.buys24h : " -"}
                  </span>
                </Grid>

                <Grid container xs={12} justifyContent="flex-end">
                  <span className="small-card-headings">Sells 24h:</span>
                  <span className="small-card-data">
                    {token.sells24h ? token.sells24h : " -"}
                  </span>
                </Grid>

                {/* <Grid  container xs={12} justifyContent='flex-end'>
                  <span className='small-card-headings'>
                    Buy / Sell?:
                  </span>
                  <span className='small-card-data'  >

                  {token.canBuy?
                    getCanBuySell(token.canBuy):"-"} / {token.canSell?getCanBuySell(token.canSell):'-'
                  }
                  </span>
                </Grid> */}

                <Grid container xs={12} justifyContent="flex-end">
                  <span className="small-card-headings">Owner Transfer %:</span>
                  <span className="small-card-data">
                    {token.parsedSecurityDetails.owner_transfer_pct
                      ? token.parsedSecurityDetails.owner_transfer_pct
                      : " -"}
                  </span>
                </Grid>
                <Grid container xs={12} justifyContent="flex-end">
                  <span className="small-card-headings">Owner Hold %:</span>
                  <span className="small-card-data">
                    {token.parsedSecurityDetails.owner_hold_pct
                      ? token.parsedSecurityDetails.owner_hold_pct
                      : " -"}
                  </span>
                </Grid>

                {/* <Grid container xs={12} justifyContent="flex-end">
                  <span className="small-card-headings">Checksum Matches:</span>
                  <span
                    className="small-card-data"
                    style={{ fontSize: "10pt" }}
                  >
                    {token.totalCopiesCount ? token.totalCopiesCount : "-"}
                  </span>
                </Grid>

                <Grid container xs={12} justifyContent="flex-end">
                  <span className="small-card-headings">Num Scams:</span>
                  <span
                    className="small-card-data"
                    style={{ fontSize: "10pt" }}
                  >
                    {token.scamsCount ? token.scamsCount : "-"}
                  </span>
                </Grid>

                <Grid container xs={12} justifyContent="flex-end">
                  <span className="small-card-headings">Scam %:</span>
                  <span
                    className="small-card-data"
                    style={{
                      color: getScamColor(
                        token.totalCopiesCount,
                        token.scamsCount,
                      ),
                      fontSize: "10pt",
                    }}
                  >
                    {token.totalCopiesCount && token.scamsCount
                      ? getScamPercent(token.totalCopiesCount, token.scamsCount)
                      : "-"}
                    {token.warningMessage && (
                      <span
                        style={{
                          marginLeft: token.warningMessage ? "5px" : "auto",
                        }}
                      >
                        |
                        {isMobile ? (
                          <div
                            onClick={() =>
                              Demodal.open(FieldModal, {
                                fieldTitle: "Warning",
                                fieldData: token.warningMessage,
                              })
                            }
                            className="warning-icon"
                            style={{
                              display: "inline-block",
                              width: "14px",
                              height: "14px",
                              margin: "0px",
                              padding: "0px",
                              marginLeft: "5px",
                              justifyContent: "center",
                              alignItems: "center",
                              verticalAlign: "center",
                            }}
                          />
                        ) : (
                          <Tooltip
                            title={
                              <div style={{ maxWidth: "300px" }}>
                                {token.warningMessage}
                              </div>
                            }
                            placement="top"
                            arrow
                          >
                            <div
                              className="warning-icon"
                              style={{
                                display: "inline-block",
                                width: "14px",
                                height: "14px",
                                margin: "0px",
                                padding: "0px",
                                marginLeft: "5px",
                                justifyContent: "center",
                                alignItems: "center",
                                verticalAlign: "center",
                              }}
                            />
                          </Tooltip>
                        )}
                      </span>
                    )}
                  </span>
                </Grid> */}
              </Grid>
            </Grid>
          </Grid>

          {/* <Grid
            className="card-data"
            justifyContent="center"
            container
            xs={12}
            sx={{ width: cardWidth }}
          >
            <Grid
              textAlign="center"
              className="block-sim-headings"
              xs={12}
              sx={{ marginTop: "0px", paddingTop: "0px", marginBottom: "5px" }}
            >
              Simulated Block Data
            </Grid>

            <Grid
              container
              className="small-card-headings"
              style={{ textAlign: "center", marginBottom: "5px" }}
            >
              ( #: Block Num, B: Buy Tax %, S: Sell Tax % )
            </Grid>
            {token.parsedSimulationJSON &&
            token.parsedSimulationJSON.length > 0 ? (
              <Table size="small" sx={{ height: "100px!important" }}>
                <TableBody sx={{ width: cardWidth }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#ffffff",
                        fontWeight: "bold",
                        paddingLeft: "2px",
                        paddingRight: "2px",
                        textAlign: "center",
                        border: "1px solid white",
                      }}
                    >
                      #
                    </TableCell>
                    {token.parsedSimulationJSON.map((block) => (
                      <TableCell
                        key={`${token.id}-${block.blockNum}-card`}
                        sx={{
                          paddingLeft: "0px",
                          paddingRight: "0px",
                          textAlign: "center",
                          border: "1px solid #ffffff",
                          color: "#ffffff",
                        }}
                      >
                        {block.blockNum}
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#ffffff",
                        fontWeight: "bold",
                        paddingLeft: "2px",
                        paddingRight: "2px",
                        textAlign: "center",
                        border: "1px solid white",
                      }}
                    >
                      B
                    </TableCell>
                    {token.parsedSimulationJSON.map((block) => (
                      <TableCell
                        key={`${token.id}-${block.blockNum}-card`}
                        sx={{
                          paddingLeft: "0px",
                          paddingRight: "0px",
                          textAlign: "center",
                          border: "1px solid #ffffff",
                          color: getTaxColor({ pct: block.buy }),
                        }}
                      >
                        <ParseDeadblockData pct={block.buy} />
                      </TableCell>
                    ))}
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#ffffff",
                        fontWeight: "bold",
                        paddingLeft: "2px",
                        paddingRight: "2px",
                        textAlign: "center",
                        border: "1px solid white",
                      }}
                    >
                      S
                    </TableCell>
                    {token.parsedSimulationJSON.map((block) => (
                      <TableCell
                        key={`${token.id}-${block.blockNum}-card`}
                        sx={{
                          paddingLeft: "0px",
                          paddingRight: "0px",
                          textAlign: "center",
                          border: "1px solid #ffffff",
                          color: getTaxColor({ pct: block.sell }),
                        }}
                      >
                        <ParseDeadblockData pct={block.sell} />
                      </TableCell>
                    ))}
                  </TableRow>
                </TableBody>
              </Table>
            ) : (
            <Grid
                container
                sx={{
                  fontSize: "10pt!important",
                  backgroundColor: "black",
                  height: "100px",
                  lineHeight: "10pt",
                  width: "100%",
                }}
                justifyContent="center"
                alignItems="center"
              >
                Data Not Yet Available
              </Grid>
            )}
          </Grid> */}

          <Grid
            container
            xs={12}
            justifyContent="center"
            flexDirection="row"
            flexGrow={1}
            style={{
              marginBottom: "0px!important",
              paddingBottom: "0px!important",
              marginTop: "0px!important",
              paddingTop: "0px!important",
            }}
          >
            <Grid
              container
              direction="row"
              xs={12}
              justifyContent="center"
              alignItems="flex-start"
            >
              <Grid>
                <a
                  className="card-links"
                  href={`https://solscan.io/token/${token.address}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Solscan
                </a>
              </Grid>

              <span style={{ marginLeft: 5, marginRight: 5 }}>|</span>

              <Grid>
                <a
                  className="card-links"
                  href={`https://dexscreener.com/solana/${token.address}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Dexscreener
                </a>
              </Grid>

              <span style={{ marginLeft: 5, marginRight: 5 }}>|</span>

              <Grid>
                <a
                  className="card-links"
                  href={`https://www.dextools.io/app/en/solana/pair-explorer/${token.address}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Dextools
                </a>
              </Grid>

              <span style={{ marginLeft: 5, marginRight: 5 }}>|</span>

              <Grid>
                <a
                  className="card-links"
                  href={`https://rugcheck.xyz/tokens/${token.address}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Rugcheck
                </a>
              </Grid>
            </Grid>

            <Grid
              container
              direction="row"
              xs={12}
              justifyContent="center"
              alignContent="space-evenly"
              mb="10px"
            >
              <Grid>
                <a
                  className="card-links"
                  href={`https://t.me/solana_unibot?start=r-marksmantools-${token.address}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Unibot
                </a>
              </Grid>

              <span style={{ marginLeft: 5, marginRight: 5 }}>|</span>

              <Grid>
                <a
                  className="card-links"
                  href={`https://t.me/BananaGunSolana_bot?start=snp_marksman_${token.address}`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Banana
                </a>
              </Grid>

              <span style={{ marginLeft: 5, marginRight: 5 }}>|</span>

              <Grid>
                <a
                  className="card-links"
                  href={`https://t.me/bonkbot_bot?start=ref_3th28_ca_${token.address}`}
                >
                  Bonk
                </a>
              </Grid>

              <span style={{ marginLeft: 5, marginRight: 5 }}>|</span>

              <Grid>
                <a
                  className="card-links"
                  href={`https://raydium.io/swap/?outputCurrency=${token.address}&inputCurrency=sol`}
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  Raydium
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
    )
}

