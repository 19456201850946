import  {useEffect, useState, useRef, forwardRef} from 'react'
import { 
    Address, 
    useAccount, 
    useNetwork, 
    useSignMessage, 
    useConnect, 
    Connector, 
    useDisconnect, 
    mainnet
} from 'wagmi'
import { SiweMessage } from 'siwe'
import { 
    authorizeUserAsync, 
    getUserData, 
    loginUserAsync, 
    logout, 
    selectAddress, 
    selectUser,
    selectLoginInProgress,
    selectNonce,
    AuthStep,
    selectCurrentAuthStep,
    selectSwapAddress
} from "../token-scan/tokenSlice"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import { ClickAwayListener } from "@mui/material"
import { Tooltip, Grid, Button } from '@mui/joy'
import { useNavigate } from 'react-router-dom';
import { watchAccount } from '@wagmi/core'
import { sign } from 'crypto'

const truncateAddress = (addr:Address | undefined) => {

if (addr === undefined || addr === null)
    return "";

var processedAddr = "";
processedAddr = processedAddr.concat(addr)

return processedAddr.substring(0,5) + ".." + processedAddr.substring(processedAddr.length - 3, processedAddr.length)
}


const SignInButton = ({
    onSuccess,
    onError,
    theRefs,
    }: {
        onSuccess: (args: { address: Address }) => void
        onError: (args: { error: Error }) => void
        theRefs: any | undefined
    }) => { 
        const [state, setState] = useState<{
            loading?: boolean
            nonce?: string
            currentStep?: AuthStep
        }>({})
        
        const { connect, connectors, error, isLoading, pendingConnector } = useConnect()
        const {isConnected} = useAccount()
        const dispatch = useAppDispatch()
        const {disconnect} = useDisconnect()
        const currentAuthStep = useAppSelector(selectCurrentAuthStep)

        const userAddress:Address | undefined = useAppSelector(selectAddress)
        const swapAddress:Address | undefined = useAppSelector(selectSwapAddress)


     

        const fetchNonce = async () => {
            setState((x) => ({ ...x, loading:true }))
            try {
            //   const nonceRes = await fetch('/api/nonce')
            //   const nonce = await nonceRes.text()
            
            const nonce = await dispatch(loginUserAsync(address)).unwrap()
            
            setState((x) => ({ ...x, nonce, loading:false }))
            } catch (error) {
            setState((x) => ({ ...x, error: error as Error, loading:false }))
            }
        }

        const { connector: activeConnector } = useAccount()



        // Pre-fetch random nonce when button is rendered
        // to ensure deep linking works for WalletConnect
        // users on iOS when signing the SIWE message

        const [walletModalOpen, setWalletModalOpen] = useState(false)

        
        const { address } = useAccount()
        const { chain } = useNetwork()
        const { signMessageAsync } = useSignMessage()
        const loginInProgress = useAppSelector(selectLoginInProgress)
        const loginNonce = useAppSelector(selectNonce)

        const handleConnectConnector = async (connector:Connector) => {
            await Promise.resolve(connect({connector})).then((ret)=>{
            })
        } 

        // useEffect(()=>{
        //     if(currentAuthStep && currentAuthStep !== state.currentStep){
        //         console.log(`changedStep to : ${currentAuthStep}`)
        //         setState({...state, currentStep: currentAuthStep})
        //     }
        // },[currentAuthStep])

        useEffect(()=>{
            if(!isConnected && loginInProgress === 'idle'){
           //     console.log('1. is conn use effect')
                handleConnectConnector(connectors[0])
            }
            else{
                activeConnector?.getAccount()
                //console.log(chain)
                let storedUser = localStorage.getItem('user')
                if(storedUser){
                   let parsedUser = JSON.parse(storedUser)
                   //console.log(parsedUser)
                   if( (parsedUser?.address?.toLowerCase() === address?.toLowerCase()) && loginInProgress ==='idle'){
                   // console.log(`LoginProgress: ${loginInProgress}  chain: ${chain}`)
                     //dispatch({type:'tokens/updateUser', payload:JSON.parse(storedUser)})
                     handleFetchUserDataAsync()
                   }
                }
            }
        },[isConnected, loginInProgress, chain])

        const userNonce = useAppSelector(selectNonce)

        // useEffect(()=>{
        //     if(userNonce && userNonce !== state.nonce){
        //         setState({...state, nonce: userNonce})
        //     }
        // },[userNonce])


   

        const signIn = async (connector:Connector | undefined, newAddr?:Address) => {

            try {
                if(!isConnected && connector){
                    await handleConnectConnector(connector).then(async(ret)=>{
                        if(connector){
                            const lc = await connector.getChainId()
                            if(lc !== mainnet.id){
                                if( connector && connector.switchChain){
                                    await connector.switchChain(mainnet.id)
                                    return
                                }
                            }
                        }
                    })
                }
                const chainId = chain?.id
                if (!address || !chainId) return
                var sopha: number | undefined

                if( connector && chainId !== mainnet.id && connector.switchChain){
                    await connector.switchChain(mainnet.id)
                }
                if(loginInProgress === 'loading')
                    return

                
                if(chainId !== mainnet.id){
                    return
                }

                // console.log(`chainId: ${chainId}`)
                // console.log(chain)
                
                const addrToUse = newAddr?newAddr:address

                await dispatch({type:'tokens/userLoginInitiated'})
              
                
                if(currentAuthStep === AuthStep.WaitingForSignature){
                    return
                }
                


                if(!userNonce){
                    
                    let newNonce = await dispatch(loginUserAsync(addrToUse)).unwrap()

                    setState((x) => ({ ...x, nonce: newNonce }))
                    // Create SIWE message with pre-fetched nonce and sign with wallet
                    const message = new SiweMessage({
                        domain: window.location.host,
                        address:addrToUse,
                        statement: 'Sign in with Ethereum to Marksman.app.',
                        uri: window.location.origin,
                        version: '1',
                        chainId,
                        nonce: newNonce,
                    })

    




                    const signature = await signMessageAsync({
                        message: message.prepareMessage(),
                    }).catch((err)=>{
                        dispatch({type:'tokens/userDeniedSignature'})
                        throw new Error(err)
                    })

                    // Verify signature
                    await dispatch({type:'tokens/setCurrentAuthStep', payload:AuthStep.CheckAuth})
                    const verifyRes =  await dispatch(authorizeUserAsync({message:message, signature:signature})).unwrap()
                    if (!verifyRes.ok){
    
                        await dispatch({type:'tokens/userDeniedSignature'})
                        throw new Error('Error verifying message')
                    }

                    const userData = await dispatch(getUserData(newAddr?newAddr:address)).unwrap()
                //  await dispatch({type:'user/userAccountChangedSuccessfully',payload:userData })
                    setState((x) => ({ ...x, loading: false }))
                    onSuccess({address: userData.address})
    
                }
                else{
                   
                }

                
            } catch (error) {
                setState((x) => ({ ...x, loading: false, nonce: undefined }))
                onError({ error: error as Error })
                //fetchNonce()
            }
    }

    const handleFetchUserDataAsync = async  () => {
        const userData = await dispatch(getUserData(address)).unwrap()
    }


    theRefs.childFuncs = {
        signIn: (connector:Connector | undefined, newAddr:Address | undefined)=>signIn(connector, newAddr)
    }

    useEffect(()=>{
      //  console.log(`frmya Cas: ${currentAuthStep}`)
        if(currentAuthStep === AuthStep.AccountChanged){
            if(userAddress !== undefined){

                //relogUser(swapAddress)
                //signIn(activeConnector, address)
            }
        }
    },[currentAuthStep])

    const relogUser = async (swapAddr:Address | undefined) => {
        const tempAct = await activeConnector?.getAccount()
        const tempChain = await activeConnector?.getChainId()
        const singInRef = useRef()
        await dispatch(logout(userAddress))


        await signIn(activeConnector, swapAddress)
        // if (tempAct ) {
        //     console.log('15. handle relog')
         
        //     //await disconnect()
        //     console.log('handle con calling sign in')
        //     await signIn(activeConnector, tempAct)
        // } 

    }


    const handleConnectorUpdate = async ({ account, chain }: {account:any, chain:any}) => {
        const tempAct = await activeConnector?.getAccount()
        const tempChain = await activeConnector?.getChainId()
        //console.log('dis')
        // console.log(chain)
        // console.log('10. handle con 1')
        // console.log(`currAuthStep in HCU: ${currentAuthStep}`)
        //console.log(`tempChain: ${tempChain}  fromProp: ${chain}`)
        if (tempAct && account && tempAct !== account && (loginInProgress === 'idle')) {
            // console.log('11. handle con 2')
            await dispatch(logout(account))
            //await disconnect()
            // console.log('handle con calling sign in')
            if(tempChain !== mainnet.id){
                console.log('User Changed to unsupported chain')

            }
            else{

                activeConnector?await signIn(activeConnector, tempAct):{}
            }
        } 
        else if (chain !== tempChain && tempChain !== mainnet.id) {
            await dispatch(logout(account))

           // await disconnect()
        }
        else if((chain !== tempChain && tempChain === mainnet.id)){
           // activeConnector?await signIn(activeConnector):{}
        }
    }
   
    useEffect(() => {
       
    //    console.log('12. active use efect 1')
        if (activeConnector) {
          //  console.log('13. active use efect 2')
            activeConnector.on("change",  ()=>handleConnectorUpdate ({account: address, chain: chain}));
        }

        return () => connectors[0]?.off("change", ()=>handleConnectorUpdate ({account: address, chain: chain})) as any;
    }, [activeConnector]);
    return (
        <> 
            <Button 
                className='connect-button'
                sx={{width:'200px!important', maxWidth:'200px!important', margin:'auto', marginRight:"10px!important"}}
                
                disabled={!connectors[0].ready || isLoading || loginInProgress === 'loading'}
                key={connectors[0].id}
                onClick={()=>signIn(connectors[0])}
            >
                {loginInProgress === 'idle' &&
                    'Connect'
                }
                {loginInProgress === 'loading' &&
                connectors[0].id === pendingConnector?.id &&
                ' (connecting)'
                }
            </Button >
        </>
  )
}
 
export const Profile = () => {
    const { isConnected } = useAccount()

    const [disconnectModalOpen, setDisconnectModalOpen] = useState(false)
    const dispatch = useAppDispatch()
    const {disconnect} = useDisconnect()
    const navigate = useNavigate();
    const user = useAppSelector(selectUser)
    const userAddr = useAppSelector(selectAddress)
    const {connector:activeConnector} = useAccount()

    const signInRef = {
        parentFuncs:{}, 
        childFuncs:{
            signIn:(activeConnector:Connector | undefined, address:Address | undefined)=>{},
        }

    }

    const [state, setState] = useState<{
        address?: Address | undefined
        error?: Error
        loading?: boolean
    }>({})

    const handleDisconnect = async () => {
        
        await dispatch(logout( userAddr)).unwrap()
        await disconnect()
    }

    // useEffect(() => {
        
    //     const unwatch = watchAccount(account => {
    //         // console.log('Account changed to:', account);
    //         // console.log(`address ${userAddr}`)
    //        // setState((x) => ({ ...x, account }))
           
    //        //dispatch({type:'tokens/userAccountChanged', payload:account.address})
    //       // signInRef?.childFuncs?.signIn(activeConnector, account.address)
    
    //       });
    
    //       // Cleanup by calling unwatch to unsubscribe from the account change event
    //       return () => unwatch();
    //   }, []);
    

    return (
      <>
        {userAddr &&

            <ClickAwayListener onClickAway={()=>setDisconnectModalOpen(false)}>
                <Tooltip     
                    sx={{backgroundColor:'transparent!important'}}
                    open={disconnectModalOpen}
                    title={
                        
                        <Grid container xs={12} justifyContent='center' alignItems='center' sx={{ margin:'auto',  width:'200px!important', border:'1px solid rgba(255,255,255,.3)', marginRight:'5px', padding:'auto', pb:'10px', backgroundColor:'black'}}>
                            
                           
                            <Grid xs={12} className='wallet-addr'>
                        
                                {truncateAddress( userAddr)}
                               
                            </Grid>
                            <Grid >
                                <Button
                                    sx={{maxWidth:'180px!important', width:'180px!important'}} 
                                    className='account-button'
                                    onClick={()=>{
                                        navigate('/profile')
                                        setDisconnectModalOpen(false)
                                    }}
                                >
                                    Account
                                </Button>
                            </Grid>
                            <Grid  justifyContent='center' alignItems='center'  sx={{ margin:'auto', padding:'auto'}}>
                                <Button
                                    className='account-button'
                                    sx={{width:'180px!important', maxWidth:'180px!important', textAlign:'center!important'}} 
                                    onClick={async () => {

                                        await dispatch(logout( userAddr)).unwrap()
                                        await disconnect()
                                        
                                        setState({})
                                    }}
                                >
                                    Disconnect
                                </Button>
                        
                            </Grid>
                         
                        </Grid>  
                       
                    } 
                     
                >
                    <Button 
                        onClick={()=>setDisconnectModalOpen(!disconnectModalOpen)}
                        className='connect-button'
                        sx={{width:'280px!important', marginRight:"10px", marginLeft:"10px", justifyContent:'center', alignItems:'center', bg:'transparent'}}
                    >
                        <Grid container xs={12} style={{ justifyContent:'center', alignItems:'center' }}>
                            <Grid xs={1} mr={2}>
                                <Grid className="account-icon-bg acct-icon" sx={{width:'22px', height:'22px', backgroundColor:'#ffffff'}}/>     
                            </Grid>
                            <Grid xs={9}>
                                Profile
                            </Grid>
                        </Grid>
                    </Button>
                </Tooltip>
            </ClickAwayListener>

        }
        { !userAddr &&

          <SignInButton
            theRefs={signInRef}
            onSuccess={({ address }) => setState((x) => ({ ...x, address }))}
            onError={({ error }) => setState((x) => ({ ...x, error }))}
          />
        }
        
      </>
    )
 
}