import  {useEffect, useState} from 'react'

import { 
    selectAddress, 
    setTelegramHandleAsync, 
    setNotificationThreshold,
    setCallerThreshold,
    // setSellTaxThreshold,
    // setBuyTaxThreshold,
    selectNotificationThreshold, 
    selectUserId,
    selectTgHandle,
    // selectScamThreshold,
    // setScamThreshold,
    selectMarksmanNotificationThreshold,
    selectCallerThreshold,
    getUserData,
    setMarksmanNotificationThreshold,
    selectNotificationThresholdSOL,
    selectMarksmanNotificationThresholdSOL,
    selectCallerThresholdSOL,
    setMarksmanNotificationThresholdSOL,
    setCallerThresholdSOL,
    setNotificationThresholdSOL,
    selectMarksmanNotificationThresholdBASE,
    selectCallerThresholdBASE,
    setCallerThresholdBASE,
    setMarksmanNotificationThresholdBASE,
    // selectBuyTaxThreshold,
    // selectSellTaxThreshold
} from "../token-scan/tokenSlice"
import { useAppSelector, useAppDispatch } from "../../app/hooks"
import { Input, ClickAwayListener, Button, Checkbox } from "@mui/material"
import { Grid, Card, Modal } from '@mui/joy'
import { useWindowDimensions } from '../../api/hooks/windowDimentionProvider'
import { Demodal } from 'demodal'
import { SetThresholdModal, ThresholdTypes } from './SetThresholdModal'



export const UserProfile = () => {

    const notificationThreshold: number = useAppSelector(selectNotificationThreshold)
    const userId: number| undefined = useAppSelector(selectUserId)
    const address = useAppSelector(selectAddress)
    const tgHandle: string | undefined = useAppSelector(selectTgHandle)
    //const scamThreshold = useAppSelector(selectScamThreshold)
    const marksmanNotificationThreshold: number = useAppSelector(selectMarksmanNotificationThreshold)
    const callerThreshold = useAppSelector(selectCallerThreshold)
    // const buyTaxThreshold = useAppSelector(selectBuyTaxThreshold)
    // const sellTaxThreshold = useAppSelector(selectSellTaxThreshold)
    const {width, height} = useWindowDimensions()
    
    
    const [tgPopoverOpen, setTgPopoverOpen] = useState(false)
    const [tgNameInput, setTgNameInput] = useState<string>(tgHandle?tgHandle:'')


    // Solana
    const notificationThresholdSOL: number = useAppSelector(selectNotificationThresholdSOL)
    const marksmanNotificationThresholdSOL: number = useAppSelector(selectMarksmanNotificationThresholdSOL)
    const callerThresholdSOL = useAppSelector(selectCallerThresholdSOL)

    const marksmanNotificationThresholdBASE: number = useAppSelector(selectMarksmanNotificationThresholdBASE)
    const callerThresholdBASE = useAppSelector(selectCallerThresholdBASE)
    
    const dispatch = useAppDispatch()

    useEffect(()=>{
        handleUpdateUserData()
    },[])

    const handleUpdateUserData  = async () =>{
        await dispatch(getUserData(address)).unwrap()
    }

    const closeTgPopover = () =>{
        setTgPopoverOpen(false)
    }

    
    const getCardWidth = () => {
    if(width > 450){
        return '400px!important'
    }
    else if(width > 360){
        return '320px!important'
    }
    else{
        return '260px!important'
    }
    }

    const isTgInputError = () => {
        if(tgNameInput.includes("\'"))
            return true;
        if(tgNameInput.includes("\""))
            return true;
        if(tgNameInput.includes("\`"))
            return true;
        if(tgNameInput.includes("\\"))
            return true;
        if(tgNameInput.includes("\/"))
            return true;
        if(tgNameInput.includes(" "))
            return true;
        if(tgNameInput.includes("@"))
            return true;
        if(tgNameInput.includes("["))
            return true;
        if(tgNameInput.includes("]"))
            return true;
        if(tgNameInput.includes("{"))
            return true;
        if(tgNameInput.includes("}"))
            return true;
        if(tgNameInput.includes("("))
            return true;
        if(tgNameInput.includes(")"))
            return true;


    }

    const handleSaveTgHandle = async () => {
        const tgName: string  = tgNameInput 
        await dispatch(setTelegramHandleAsync({id:userId, tgHandle: tgName}))
        setTgPopoverOpen(false)
    }

    const handleClearTgHandle = async () => {
        const tgName: string  = tgNameInput 
        await dispatch(setTelegramHandleAsync({id:userId, tgHandle: ""}))
        setTgNameInput('')
        setTgPopoverOpen(false)
    }

    
    const truncateAddress = (addr:string) => {

        if (addr === undefined || addr === null)
            return "";
    
        var processedAddr = "";
        processedAddr = processedAddr.concat(addr)
    
        return processedAddr.substring(0,5) + ".." + processedAddr.substring(processedAddr.length - 3, processedAddr.length)
    }


    // Ethereum functions
    const handleUpdateNotificationThreshold = async (thresholdValue:number) =>{
        await dispatch(setNotificationThreshold({notificationThreshold: thresholdValue, id: userId}))
        Demodal.close(SetThresholdModal)
    }

    // const handleUpdateScamThreshold = async () =>{
        
    //     await dispatch(setScamThreshold({scamThreshold: scamThresholdField, id: userId}))
    //     setScamPopoverOpen(false)
    // }

    const handleUpdateCallerThreshold = async (thresholdValue:number) =>{
        
        await dispatch(setCallerThreshold({callerThreshold: thresholdValue, id: userId}))
        Demodal.close(SetThresholdModal)
        
    }

    
    const toggleScoreNotification = () => {
        if(notificationThreshold > 0){
            dispatch(setNotificationThreshold({id:userId, notificationThreshold: 0}))
        }
        else{
            dispatch(setNotificationThreshold({id:userId, notificationThreshold: 40}))
        }
    }
    
    const toggleMarksmanScoreNotification = () => {
        if(marksmanNotificationThreshold > 0){
            dispatch(setMarksmanNotificationThreshold({id:userId, marksmanNotificationThreshold: 0}))
        }
        else{
            dispatch(setMarksmanNotificationThreshold({id:userId, marksmanNotificationThreshold: 200}))
        }
    }

    const toggleCallerNotification = () => {
        if(callerThreshold > 0){
            dispatch(setCallerThreshold({id:userId, callerThreshold: 0}))
        }
        else{
            dispatch(setCallerThreshold({id:userId, callerThreshold: 3}))
        }
    }

    const handleUpdateMarksmanNotificationThreshold = async (thresholdValue:number) =>{
        
        await dispatch(setMarksmanNotificationThreshold({marksmanNotificationThreshold: thresholdValue, id: userId}))
        Demodal.close(SetThresholdModal)
    }


    // Solana functions
    const handleUpdateNotificationThresholdSOL = async (thresholdValue: number) =>{
        
        await dispatch(setNotificationThresholdSOL({notificationThreshold: thresholdValue, id: userId}))
        Demodal.close(SetThresholdModal)
    }

    const toggleScoreNotificationSOL = () => {
        if(notificationThresholdSOL > 0){
            dispatch(setNotificationThresholdSOL({id:userId, notificationThreshold: 0}))
        }
        else{
            dispatch(setNotificationThresholdSOL({id:userId, notificationThreshold: 40}))
        }
    }

    // const handleUpdateScamThreshold = async () =>{
        
    //     await dispatch(setScamThreshold({scamThreshold: scamThresholdField, id: userId}))
    //     setScamPopoverOpen(false)
    // }

    const handleUpdateCallerThresholdSOL = async (thresholdValue:number) =>{
        
        await dispatch(setCallerThresholdSOL({callerThreshold: thresholdValue, id: userId}))
        Demodal.close(SetThresholdModal)
        
    }
    
    const toggleMarksmanScoreNotificationSOL = () => {
        if(marksmanNotificationThresholdSOL > 0){
            dispatch(setMarksmanNotificationThresholdSOL({id:userId, marksmanNotificationThreshold: 0}))
        }
        else{
            dispatch(setMarksmanNotificationThresholdSOL({id:userId, marksmanNotificationThreshold: 200}))
        }
    }

    const toggleCallerNotificationSOL = () => {
        if(callerThresholdSOL > 0){
            dispatch(setCallerThresholdSOL({id:userId, callerThreshold: 0}))
        }
        else{
            dispatch(setCallerThresholdSOL({id:userId, callerThreshold: 3}))
        }
    }

    const handleUpdateMarksmanNotificationThresholdSOL = async (thresholdValue:number) =>{
        
        await dispatch(setMarksmanNotificationThresholdSOL({marksmanNotificationThreshold: thresholdValue, id: userId}))
        Demodal.close(SetThresholdModal)
    }


    


    const handleUpdateCallerThresholdBASE = async (thresholdValue:number) =>{
        
        await dispatch(setCallerThresholdBASE({callerThreshold: thresholdValue, id: userId}))
        Demodal.close(SetThresholdModal)
        
    }
    
    const toggleMarksmanScoreNotificationBASE = () => {
        if(marksmanNotificationThresholdBASE > 0){
            dispatch(setMarksmanNotificationThresholdBASE({id:userId, marksmanNotificationThreshold: 0}))
        }
        else{
            dispatch(setMarksmanNotificationThresholdBASE({id:userId, marksmanNotificationThreshold: 200}))
        }
    }

    const toggleCallerNotificationBASE = () => {
        if(callerThresholdBASE > 0){
            dispatch(setCallerThresholdBASE({id:userId, callerThreshold: 0}))
        }
        else{
            dispatch(setCallerThresholdBASE({id:userId, callerThreshold: 3}))
        }
    }

    const handleUpdateMarksmanNotificationThresholdBASE = async (thresholdValue:number) =>{
        
        await dispatch(setMarksmanNotificationThresholdBASE({marksmanNotificationThreshold: thresholdValue, id: userId}))
        Demodal.close(SetThresholdModal)
    }

    return(
        <Grid container sx={{ margin:'auto', padding:'auto', height:'100%', backgroundColor:'#212529', paddingTop:'100px', paddingBottom:'100px'}} alignItems='center' justifyContent='center'>
            <Card className='card-back' sx={{filter:'drop-shadow(0px 0px 5px rgba(0,0,0,.7))', width: getCardWidth(), padding:0, border:'1px solid rgba(255,255,255,.3)'}}>
                <Grid className='card-back profile-heading' sx={{marginTop:'10px'}}>
                    Profile
                </Grid>
                <Grid className='card-mid' sx={{margin:0, padding:'10px'}}>
                    <Grid container xs={12}  sx={{marginTop:'0px', paddingTop:'0px'}}>
                        <Grid xs={5} alignItems='space-evenly' flexDirection='column' direction='column' flexGrow={1}  > 
                            <Grid textAlign='center'  className='profile-headings'  xs={12} >
                            Address
                            </Grid>
                            <Grid  textAlign='center' xs={12} className='card-data'>
                                {address?
                                width >= 400?address: truncateAddress(address)
                                :
                                '-'
                                }
                            </Grid>
                            <Grid textAlign='center'  className='profile-headings'  xs={12} >
                            Telegram Handle
                            </Grid>
                            <Grid  container justifyContent='center' alignItems='center' textAlign='center' xs={12} className='connect-button'  sx={{height:'35px', color: 'white'}} onClick={()=>setTgPopoverOpen(true)}>
                                {tgHandle?
                                tgHandle
                                :
                                '-'
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid className='card-back profile-heading' sx={{marginTop:'10px'}}>
                    Ethereum TG Notification Bot Thresholds
                </Grid>

                <Grid className='card-mid' sx={{margin:0, padding:'10px'}}>
                    <Grid container xs={12}  sx={{marginTop:'0px', paddingTop:'0px'}}>
                        <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            Eth Sniper Score Min <br/> 🎯 Potential Snipe Target 🎯
                        </Grid>
                        <Grid container justifyContent='center' alignItems='center' textAlign='center' xs={12} sx={{height:'35px', color: 'white', marginBottom:'20px'}}>
                            
                            <Checkbox 
                                checked={notificationThreshold !== 0? true: false}
                                onChange={()=>toggleScoreNotification()} 
                                size='medium'
                                sx={{
                                    color: 'white',
                                    '&.Mui-checked': {
                                        color:'#4a91ff',
                                    },
                                }} 
                            />
                            <Grid  
                                xs={9} 
                                justifyContent='center' alignItems='center' textAlign='center'
                                className='connect-button' 
                                sx={{height:'35px', color: 'white'}} 
                                onClick={
                                    ()=>Demodal.open( 
                                        SetThresholdModal,
                                        {
                                            fieldTitle: <>Ethereum Sniper Score Notification Threshold <br/> 🎯 Potential Snipe Target 🎯</>, 
                                            fieldData: notificationThreshold, 
                                            savedValue: notificationThreshold, 
                                            asyncUpdateCallback: handleUpdateNotificationThreshold,
                                            width: getCardWidth()
                                        }
                                    )
                                }
                            >

                            {notificationThreshold &&
                                notificationThreshold
                            }
                            </Grid>
                        </Grid>
                        <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            Eth Marksman Score Min <br/> 🚀 Post Launch 🚀
                        </Grid>
                        <Grid   container justifyContent='center' alignItems='center' textAlign='center' xs={12}  sx={{height:'35px', color: 'white', marginBottom:'20px'}} >
                            <Checkbox 
                                checked={marksmanNotificationThreshold !== 0? true: false}
                                onChange={()=>toggleMarksmanScoreNotification()} 
                                size='medium'
                                sx={{
                                    color: 'white',
                                    '&.Mui-checked': {
                                        color:'#4a91ff',
                                    },
                                }} 
                            />
                            <Grid  
                                justifyContent='center' alignItems='center' textAlign='center' 
                                xs={9} className='connect-button' 
                                sx={{height:'35px', color: 'white'}} 
                                onClick={
                                    ()=>Demodal.open( 
                                        SetThresholdModal,
                                        {
                                            fieldTitle: <>ETH Marksman Score Notification Threshold<br/>🚀 Post Launch 🚀</>, 
                                            fieldData: marksmanNotificationThreshold, 
                                            savedValue: marksmanNotificationThreshold, 
                                            asyncUpdateCallback: handleUpdateMarksmanNotificationThreshold,
                                            width: getCardWidth(),
                                            type: ThresholdTypes.MarksmanScore
                                        }
                                    )
                                }
                            >
                            {marksmanNotificationThreshold &&
                                marksmanNotificationThreshold
                            }
                            </Grid>
                                
                        </Grid>
                        {/* 
                        <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            TG Notification: Scam % Max Threshold
                        </Grid>
                        <Grid  container justifyContent='center' alignItems='center' textAlign='center' xs={12} className='connect-button' sx={{height:'35px', color: 'white'}} onClick={()=>setScamPopoverOpen(true)}>
                            {callerThreshold &&
                            callerThreshold
                            
                            }
                        </Grid> 
                        */}

                        <Grid container textAlign='center' className='profile-headings'  xs={12} justifyContent='center' alignItems='center'>
                            Eth Num Callers <br/> 👁 Caller Watch 👁
                        </Grid>
                        <Grid  container justifyContent='center' alignItems='center' textAlign='center' xs={12} sx={{height:'35px', color: 'white'}}>
                            <Checkbox 
                                checked={callerThreshold !== 0? true: false}
                                onChange={()=>toggleCallerNotification()} 
                                size='medium'
                                sx={{
                                    color: 'white',
                                    '&.Mui-checked': {
                                        color:'#4a91ff',
                                    },
                                }} 
                            />
                            <Grid   
                                justifyContent='center' alignItems='center' textAlign='center' 
                                xs={9} 
                                className='connect-button' 
                                sx={{height:'35px', color: 'white'}} 
                                onClick={
                                    ()=>Demodal.open( 
                                        SetThresholdModal,
                                        {
                                            fieldTitle: <>Eth Number of Callers Threshold <br/>👁 Caller Watch 👁</>, 
                                            fieldData: callerThreshold, 
                                            savedValue: callerThreshold, 
                                            asyncUpdateCallback: handleUpdateCallerThreshold,
                                            width: getCardWidth(),
                                            type: ThresholdTypes.NumCallers
                                        }
                                    )
                                }
                            >
                            {callerThreshold &&
                                callerThreshold
                            }
                            </Grid>
                        </Grid>
                        
                        {/* 
                        <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            TG Notification: Buy Tax % Max Threshold
                        </Grid>
                        
                        <Grid  container justifyContent='center' alignItems='center' textAlign='center' xs={12} className='connect-button' sx={{height:'35px', color: 'white'}} onClick={()=>setBuyPopoverOpen(true)}>
                            {buyTaxThreshold &&
                            buyTaxThreshold
                            
                            }
                        </Grid>

                        <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            TG Notification: Sell Tax % Max Threshold
                        </Grid>
                        <Grid  container justifyContent='center' alignItems='center' textAlign='center' xs={12} className='connect-button' sx={{height:'35px', color: 'white'}} onClick={()=>setSellPopoverOpen(true)}>
                            {sellTaxThreshold &&
                            sellTaxThreshold
                            
                            }
                        </Grid> 
                        */}
                        {/* 
                        <Grid textAlign='center'  className='profile-headings'  xs={12} >
                        Marks in Wallet
                        </Grid>
                        <Grid  textAlign='center' xs={12} className='card-data'>
                            {user.marksInWallet?
                            user.marksInWallet
                            :
                            '-'
                            }
                        </Grid> 
                        */}
                       
                    </Grid>
                </Grid>
                
                <Grid className='card-back profile-heading' sx={{marginTop:'10px'}}>
                    Solana TG Notification Bot Thresholds
                </Grid>

                <Grid className='card-mid' sx={{margin:0, padding:'10px'}}>
                    <Grid container xs={12}  sx={{marginTop:'0px', paddingTop:'0px'}}>
                        {/* <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            Sol Sniper Score Min <br/> 🎯 Potential Snipe Target 🎯
                        </Grid>
                        <Grid container justifyContent='center' alignItems='center' textAlign='center' xs={12} sx={{height:'35px', color: 'white', marginBottom:'20px'}}>
                            
                            <Checkbox 
                                checked={notificationThresholdSOL !== 0? true: false}
                                onChange={()=>toggleScoreNotificationSOL()} 
                                size='medium'
                                sx={{
                                    color: 'white',
                                    '&.Mui-checked': {
                                        color:'#4a91ff',
                                    },
                                }} 
                            />
                            <Grid  
                                xs={9} 
                                justifyContent='center' alignItems='center' textAlign='center' 
                                className='connect-button' 
                                sx={{height:'35px', color: 'white'}} 
                                onClick={
                                    ()=>Demodal.open( 
                                        SetThresholdModal,
                                        {
                                            fieldTitle: <>Sol Sniper Score Notification Threshold <br/> 🎯 Potential Snipe Target 🎯</>, 
                                            fieldData: notificationThresholdSOL, 
                                            savedValue: notificationThresholdSOL, 
                                            asyncUpdateCallback: handleUpdateNotificationThresholdSOL,
                                            width: getCardWidth(),
                                            type: ThresholdTypes.SniperScore
                                        }
                                    )
                                }
                            >

                            {notificationThresholdSOL &&
                                notificationThresholdSOL
                            }
                            </Grid>
                        </Grid> */}
                        <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            Sol Marksman Score Min <br/> 🚀 Post Launch 🚀
                        </Grid>
                        <Grid   container justifyContent='center' alignItems='center' textAlign='center' xs={12}  sx={{height:'35px', color: 'white', marginBottom:'20px'}} >
                            <Checkbox 
                                checked={marksmanNotificationThresholdSOL !== 0? true: false}
                                onChange={()=>toggleMarksmanScoreNotificationSOL()} 
                                size='medium'
                                sx={{
                                    color: 'white',
                                    '&.Mui-checked': {
                                        color:'#4a91ff',
                                    },
                                }} 
                            />
                            <Grid  
                                justifyContent='center' alignItems='center' textAlign='center' 
                                xs={9} className='connect-button' 
                                sx={{height:'35px', color: 'white'}} 
                                onClick={
                                    ()=>Demodal.open( 
                                        SetThresholdModal,
                                        {
                                            fieldTitle: <>Sol Marksman Score Notification Threshold<br/>🚀 Post Launch 🚀</>, 
                                            fieldData: marksmanNotificationThresholdSOL, 
                                            savedValue: marksmanNotificationThresholdSOL, 
                                            asyncUpdateCallback: handleUpdateMarksmanNotificationThresholdSOL,
                                            width: getCardWidth(),
                                            type: ThresholdTypes.MarksmanScore
                                        }
                                    )
                                }
                            >
                            {marksmanNotificationThresholdSOL &&
                                marksmanNotificationThresholdSOL
                            }
                            </Grid>
                                
                        </Grid>
                        {/* 
                        <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            TG Notification: Scam % Max Threshold
                        </Grid>
                        <Grid  container justifyContent='center' alignItems='center' textAlign='center' xs={12} className='connect-button' sx={{height:'35px', color: 'white'}} onClick={()=>setScamPopoverOpen(true)}>
                            {callerThreshold &&
                            callerThreshold
                            
                            }
                        </Grid> 
                        */}

                        <Grid container textAlign='center' className='profile-headings'  xs={12} justifyContent='center' alignItems='center'>
                            Sol Num Callers <br/> 👁 Caller Watch 👁
                        </Grid>
                        <Grid  container justifyContent='center' alignItems='center' textAlign='center' xs={12} sx={{height:'35px', color: 'white'}}>
                            <Checkbox 
                                checked={callerThresholdSOL !== 0? true: false}
                                onChange={()=>toggleCallerNotificationSOL()} 
                                size='medium'
                                sx={{
                                    color: 'white',
                                    '&.Mui-checked': {
                                        color:'#4a91ff',
                                    },
                                }} 
                            />
                            <Grid   
                                justifyContent='center' alignItems='center' textAlign='center' 
                                xs={9} 
                                className='connect-button' 
                                sx={{height:'35px', color: 'white'}} 
                                onClick={
                                    ()=>Demodal.open( 
                                        SetThresholdModal,
                                        {
                                            fieldTitle: <>SOL Number of Callers Threshold <br/>👁 Caller Watch 👁</>, 
                                            fieldData: callerThresholdSOL, 
                                            savedValue: callerThresholdSOL, 
                                            asyncUpdateCallback: handleUpdateCallerThresholdSOL,
                                            width: getCardWidth(),
                                            type: ThresholdTypes.NumCallers
                                        }
                                    )
                                }
                            >
                            {callerThresholdSOL &&
                                callerThresholdSOL
                            }
                            </Grid>
                        </Grid>
                        
                        {/* 
                        <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            TG Notification: Buy Tax % Max Threshold
                        </Grid>
                        
                        <Grid  container justifyContent='center' alignItems='center' textAlign='center' xs={12} className='connect-button' sx={{height:'35px', color: 'white'}} onClick={()=>setBuyPopoverOpen(true)}>
                            {buyTaxThreshold &&
                            buyTaxThreshold
                            
                            }
                        </Grid>

                        <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            TG Notification: Sell Tax % Max Threshold
                        </Grid>
                        <Grid  container justifyContent='center' alignItems='center' textAlign='center' xs={12} className='connect-button' sx={{height:'35px', color: 'white'}} onClick={()=>setSellPopoverOpen(true)}>
                            {sellTaxThreshold &&
                            sellTaxThreshold
                            
                            }
                        </Grid> 
                        */}
                        {/* 
                        <Grid textAlign='center'  className='profile-headings'  xs={12} >
                        Marks in Wallet
                        </Grid>
                        <Grid  textAlign='center' xs={12} className='card-data'>
                            {user.marksInWallet?
                            user.marksInWallet
                            :
                            '-'
                            }
                        </Grid> 
                        */}
                       
                    </Grid>
                </Grid>

                <Grid className='card-back profile-heading' sx={{marginTop:'10px'}}>
                    Base TG Notification Bot Thresholds
                </Grid>

                <Grid className='card-mid' sx={{margin:0, padding:'10px'}}>
                    <Grid container xs={12}  sx={{marginTop:'0px', paddingTop:'0px'}}>
                        {/* <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            Sol Sniper Score Min <br/> 🎯 Potential Snipe Target 🎯
                        </Grid>
                        <Grid container justifyContent='center' alignItems='center' textAlign='center' xs={12} sx={{height:'35px', color: 'white', marginBottom:'20px'}}>
                            
                            <Checkbox 
                                checked={notificationThresholdSOL !== 0? true: false}
                                onChange={()=>toggleScoreNotificationSOL()} 
                                size='medium'
                                sx={{
                                    color: 'white',
                                    '&.Mui-checked': {
                                        color:'#4a91ff',
                                    },
                                }} 
                            />
                            <Grid  
                                xs={9} 
                                justifyContent='center' alignItems='center' textAlign='center' 
                                className='connect-button' 
                                sx={{height:'35px', color: 'white'}} 
                                onClick={
                                    ()=>Demodal.open( 
                                        SetThresholdModal,
                                        {
                                            fieldTitle: <>Sol Sniper Score Notification Threshold <br/> 🎯 Potential Snipe Target 🎯</>, 
                                            fieldData: notificationThresholdSOL, 
                                            savedValue: notificationThresholdSOL, 
                                            asyncUpdateCallback: handleUpdateNotificationThresholdSOL,
                                            width: getCardWidth(),
                                            type: ThresholdTypes.SniperScore
                                        }
                                    )
                                }
                            >

                            {notificationThresholdSOL &&
                                notificationThresholdSOL
                            }
                            </Grid>
                        </Grid> */}
                        <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            Base Marksman Score Min <br/> 🚀 Post Launch 🚀
                        </Grid>
                        <Grid   container justifyContent='center' alignItems='center' textAlign='center' xs={12}  sx={{height:'35px', color: 'white', marginBottom:'20px'}} >
                            <Checkbox 
                                checked={marksmanNotificationThresholdBASE !== 0? true: false}
                                onChange={()=>toggleMarksmanScoreNotificationBASE()} 
                                size='medium'
                                sx={{
                                    color: 'white',
                                    '&.Mui-checked': {
                                        color:'#4a91ff',
                                    },
                                }} 
                            />
                            <Grid  
                                justifyContent='center' alignItems='center' textAlign='center' 
                                xs={9} className='connect-button' 
                                sx={{height:'35px', color: 'white'}} 
                                onClick={
                                    ()=>Demodal.open( 
                                        SetThresholdModal,
                                        {
                                            fieldTitle: <>Base Marksman Score Notification Threshold<br/>🚀 Post Launch 🚀</>, 
                                            fieldData: marksmanNotificationThresholdBASE, 
                                            savedValue: marksmanNotificationThresholdBASE, 
                                            asyncUpdateCallback: handleUpdateMarksmanNotificationThresholdBASE,
                                            width: getCardWidth(),
                                            type: ThresholdTypes.MarksmanScore
                                        }
                                    )
                                }
                            >
                            {marksmanNotificationThresholdBASE &&
                                marksmanNotificationThresholdBASE
                            }
                            </Grid>
                                
                        </Grid>
                        {/* 
                        <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            TG Notification: Scam % Max Threshold
                        </Grid>
                        <Grid  container justifyContent='center' alignItems='center' textAlign='center' xs={12} className='connect-button' sx={{height:'35px', color: 'white'}} onClick={()=>setScamPopoverOpen(true)}>
                            {callerThreshold &&
                            callerThreshold
                            
                            }
                        </Grid> 
                        */}

                        <Grid container textAlign='center' className='profile-headings'  xs={12} justifyContent='center' alignItems='center'>
                            Base Num Callers <br/> 👁 Caller Watch 👁
                        </Grid>
                        <Grid  container justifyContent='center' alignItems='center' textAlign='center' xs={12} sx={{height:'35px', color: 'white'}}>
                            <Checkbox 
                                checked={callerThresholdBASE !== 0? true: false}
                                onChange={()=>toggleCallerNotificationBASE()} 
                                size='medium'
                                sx={{
                                    color: 'white',
                                    '&.Mui-checked': {
                                        color:'#4a91ff',
                                    },
                                }} 
                            />
                            <Grid   
                                justifyContent='center' alignItems='center' textAlign='center' 
                                xs={9} 
                                className='connect-button' 
                                sx={{height:'35px', color: 'white'}} 
                                onClick={
                                    ()=>Demodal.open( 
                                        SetThresholdModal,
                                        {
                                            fieldTitle: <>Base Number of Callers Threshold <br/>👁 Caller Watch 👁</>, 
                                            fieldData: callerThresholdBASE, 
                                            savedValue: callerThresholdBASE, 
                                            asyncUpdateCallback: handleUpdateCallerThresholdBASE,
                                            width: getCardWidth(),
                                            type: ThresholdTypes.NumCallers
                                        }
                                    )
                                }
                            >
                            {callerThresholdBASE &&
                                callerThresholdBASE
                            }
                            </Grid>
                        </Grid>
                        
                        {/* 
                        <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            TG Notification: Buy Tax % Max Threshold
                        </Grid>
                        
                        <Grid  container justifyContent='center' alignItems='center' textAlign='center' xs={12} className='connect-button' sx={{height:'35px', color: 'white'}} onClick={()=>setBuyPopoverOpen(true)}>
                            {buyTaxThreshold &&
                            buyTaxThreshold
                            
                            }
                        </Grid>

                        <Grid textAlign='center'  className='profile-headings'  xs={12} justifyContent='center' alignItems='center' >
                            TG Notification: Sell Tax % Max Threshold
                        </Grid>
                        <Grid  container justifyContent='center' alignItems='center' textAlign='center' xs={12} className='connect-button' sx={{height:'35px', color: 'white'}} onClick={()=>setSellPopoverOpen(true)}>
                            {sellTaxThreshold &&
                            sellTaxThreshold
                            
                            }
                        </Grid> 
                        */}
                        {/* 
                        <Grid textAlign='center'  className='profile-headings'  xs={12} >
                        Marks in Wallet
                        </Grid>
                        <Grid  textAlign='center' xs={12} className='card-data'>
                            {user.marksInWallet?
                            user.marksInWallet
                            :
                            '-'
                            }
                        </Grid> 
                        */}
                       
                    </Grid>
                </Grid>
            </Card>
            
            <Modal
                disableAutoFocus
                disableEnforceFocus
                id='tg-name-modal' 
                open={tgPopoverOpen}
                hideBackdrop
                sx={{
                margin:'auto',
                padding:'auto',
                maxWidth:'600px!important', 
                maxHeight:'400px!important', 
                height:'40vh',
                width:getCardWidth(),
                borderRadius:'0px',
                overflow:'clip',
                backgroundColor:'#1a1b1d',
                filter:'drop-shadow(0px 4px 5px black)',
                }}
            >
                <div style={{height:'100%', width:'100%'}}>
                    <ClickAwayListener onClickAway={()=>closeTgPopover()}>
                        <div id='tg-handle-pop-div' style={{height:'100%', width:'100%'}}>
                            <Grid xs={12} container textAlign='center' alignItems='center'  justifyContent='center' className='profile-headings' sx={{backgroundColor:'#2a2b2d', height:'32px', marginTop:'0px!important'}} >
                                <Grid xs={11}>Telegram Handle</Grid>
                                <Grid xs={1} justifyContent='flex-end'>
                                    <div className='close-icon-bg-profile' style={{width: '25px', height:'25px'}} onClick={()=>closeTgPopover()}/>
                                </Grid>
                            </Grid>
                    
                            <Grid container justifyContent='center' alignItems='space-between' textAlign='center' sx={{margin:'auto', height:'100%'}}>
                            <Grid xs={12}>
                                <Input
                                    placeholder="Enter Telegram Handle"
                                    sx={{ textAlign:'center', color:'white', paddingTop:'10px', marginRight:'10px', paddingBottom:'10px',width:'60%', marginTop:'100px', backgroundColor:'#051020'}}
                                    onChange = {(e) => setTgNameInput(e.target.value.replaceAll('@',''))}
                                    value={`@${tgNameInput}`}
                                    name='tg-field'
                                    id='tg-name-input-field'
                                    error={ tgNameInput.length < 5 || tgNameInput.length > 32 || isTgInputError()}
                                    
                                />
                                <Button 
                                    disabled={ (tgNameInput.length < 5)  || tgNameInput.length > 32 || isTgInputError() || tgNameInput === tgHandle} 
                                    className='connect-button' 
                                    onClick={()=>handleSaveTgHandle()}
                                >
                                    Save
                                </Button>
                                <Button 
                                    className='connect-button' 
                                    disabled={(tgHandle === '' || tgHandle === null)}
                                    onClick={()=>handleClearTgHandle()}
                                >
                                    Clear Handle
                                </Button>
                            </Grid>
                            <Grid xs={12}>
                                {tgNameInput.length < 5 && tgNameInput.length !==0 &&
                                    <div style={{color:'red'}}>
                                    Handle must contain at least 5 characters.         
                                    </div>
                                }
                                {tgNameInput.length > 32 &&
                                    <div style={{color:'red'}}>
                                    Handle can not exceed 32 characters.
                                    </div>
                                }
                                {isTgInputError() &&
                                    <div style={{color:'red'}}>
                                        {`Handle contains spaces or illegal symbols: \' \" \` \\ \/ \[ \] \{ \} \( \)`}
                                    </div>
                                }
                                </Grid>
                            </Grid>
                        </div>
                    </ClickAwayListener>
                </div>
            </Modal>
        </Grid>
    )
}
